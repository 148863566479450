import { baseURL } from "../../Redux/baseURL.jsx"

export const saveJobClick = async (cred) => {
    let obj = {
        indeedLinks: cred?.indeedLinks,
        type: cred?.type,
    }
    return fetch(baseURL + `/api/analytics/saveClickAnalytics`, {
      method: "PATCH",
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + localStorage.getItem("token")
      },
      credentials: 'include',
    })
    .then(resp => resp.json())
    .then(resp => {
      // console.log("save click analytics", resp);
    })
    .catch(err => console.log(err));
}

