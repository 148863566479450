import React, { useContext, useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ArticleIcon from "@mui/icons-material/Article";
import MessageIcon from "@mui/icons-material/Message";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import LogoutIcon from "@mui/icons-material/Logout";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { message_notification, end_to_end_chat, getNotification, notification_success, socketio, getEndToEndChatInformation, getting_successful, onlineUsers, SidebarOpen } from "../../Redux/ActionCreator";
import { baseURL } from "../../Redux/baseURL";
import { getOneFromServerCache, deleteAKey } from "../../utils/saveIntoCache";

const { io } = require("socket.io-client");

const SideBar = (props) => {
  const dispatch = useDispatch();
  const profile_stat = useSelector((prev) => prev.login);
  const EmployerProfile = useSelector((prev) => prev.employer);
  const chat_info = useSelector(e => e.chat_info.message);
  const EmployeeProfile = useSelector((e) => e.profile);
  const body = EmployerProfile.message;
  const Type = profile_stat.profile_status.AcctType;
  const [profile, setProfile] = useState(null);
  const msg_notification = useSelector(e => e.msg_notification);
  const userInfo = useSelector(e => e.userInfo.user);
  let Acct_profile = useSelector((e) => e.profile);

  const navigate = useNavigate();
  const online = useSelector(e => e.online.message);
  const notify = useSelector(e => e.notification);
  let notification_list = notify.message;


  // const [ socket, setSocket ] = useState(null);
  const socket = useSelector(e => e.socket?.message);
  const [onlineUser, setOnlineUser] = useState([]);
  const newMessage = useSelector(e => e.end_to_end.message);
  const [newMsg, setNewMsg] = useState(newMessage?.message);
  const [notification, setNotification] = useState(0)
  const index = new URLSearchParams(window.location.search).get("i");
  const login_stat = useSelector(e => e.login);
  const AcctType = login_stat?.profile_status?.AcctType;
  const sidebar = useSelector(e => e.sidebarOpen.message);
  const apply = useSelector(e => e.apply);


  const getJobOwnerId = async () => {
    try {
      let response = await getOneFromServerCache("JobOwnerId");
      // console.log("response", response);
      let resparse = await response.json();
      // console.log("resparse", resparse);
      let data;
      if (resparse?.data !== undefined) {
        data = JSON.parse(resparse.data);
      }
      // console.log("data", data);
      return data;
    } catch (error) {
      throw error;
    }
  }


  const jobId = window.location.pathname.split("/")[2];
  const available_jobs = useSelector(e => e.available_jobs);
  let [jobOwnerId, setJobOwnerId] = useState(null);
  // = jobId !== null ? available_jobs.message?.filter(element => element._id == jobId) : null;

  useEffect(() => {
    if (jobId !== null) {
      jobOwnerId = available_jobs.message?.filter(element => element._id == jobId);
      setJobOwnerId(jobOwnerId);
      // console.log("jobOwnerId", jobOwnerId);
      if (jobOwnerId?.length == 0) {
        getJobOwnerId()
          .then(data => {
            // jobOwnerId = data;
            setJobOwnerId([data]);
            deleteAKey("JobOwnerId");
          });
      }
    } else {
      jobOwnerId = null;
    }
  }, []);

  // console.log("jobOwnerId", jobOwnerId);

  useEffect(() => {
    // dispatch(end_to_end_chat_loading(true));
    if (AcctType === "Client") {
      if (index !== null) {
        dispatch(getEndToEndChatInformation(chat_info[index]?.chatIds[0]?.employerId?._id, chat_info[index]?.chatIds[0]?.employeeId));
        dispatch(getting_successful([chat_info[index]?.chatIds[0]?.resumeId]));
      }
    }
  }, [index]);

  useEffect(() => {
    if (
      profile_stat?.profile_status?.profile_status === "false" ||
      profile_stat?.profile_status?.profile_status === false
    ) {
      setProfile(false);
    } else if (
      profile_stat?.profile_status?.profile_status === "true" ||
      profile_stat?.profile_status?.profile_status === true
    ) {
      setProfile(true);
    }
  }, [profile_stat?.profile_status]);

  useEffect(() => {
    if (online !== null && Acct_profile.message !== null) {
      if (socket == null || socket.connected == false) {
        const newSocket = io(baseURL, 
          {
            path: "/chat",
            cors: {
              origin: ["https://employee-beta.moilapp.com", 'https://employer-beta.moilapp.com' ], //  "http://localhost:3000", "http://localhost:3001"
            }
          }
        );
        // socketClient.connect();
        // setSocket(newSocket);
        dispatch(socketio(newSocket));
        // console.log("socket", socket);
      } else if (socket !== null && socket.connected == true) {
        let checkIfIAmOnline = online.filter(e => e.userId === Acct_profile.message?._id);
        // console.log("checkIfIAmOnline", checkIfIAmOnline);
        if (checkIfIAmOnline.length === 0) {
          socket?.emit("addUser", Acct_profile.message?._id);
          socket?.on("onlineUser", (resp) => {
            // setOnlineUser(resp);
            dispatch(onlineUsers(resp));
          })
        }
      }
    }
  }, []);

  useEffect(() => {
    const newSocket = io(baseURL, 
      {
        path: "/chat",
        cors: {
          origin: ["https://employee-beta.moilapp.com", 'https://employer-beta.moilapp.com' ], //  "http://localhost:3000", "http://localhost:3001"
        }
      }
    );
    // socketClient.connect();
    // setSocket(newSocket);
    dispatch(socketio(newSocket));
    // console.log("socket", socket);
    return () => {
      newSocket.disconnect();
    }
    // }
  }, [Acct_profile]);
  // console.log("socket", socket);
  // console.log("Online users", online) // onlineUser;
  useEffect(() => {
    // if (index === null) {
    if (socket === null) return;
    // console.log("Acct_profile.message?._id", Acct_profile.message?._id)
    socket.emit("addUser", Acct_profile.message?._id);
    socket.on("onlineUser", (resp) => {
      setOnlineUser(resp);
      dispatch(onlineUsers(resp));
    })
    // }
  }, [socket]);

  // useEffect(() => {
  //   dispatch(end_to_end_chat(newMessage?.message))
  // }, [newMessage]);

  useEffect(() => {
    if (newMessage !== null && newMessage !== undefined) {
      if (AcctType === "Client") {
        if (chat_info !== null) {
          if (chat_info[index]?._id !== newMessage?.chatId) { return };
          dispatch(end_to_end_chat(newMessage?.message));
        }
      }
    }
  }, [newMessage]);

  // useEffect(() => {
    // console.log("useEffect socket", socket);
  //   if (socket === null) return;

  //   if (newMessage?.message !== undefined) {
  //     if (AcctType === "Client") {
  //       if (chat_info !== null) {
  //         socket.emit("sendMessage", { ...newMessage, otherId: chat_info[index]?.chatIds[0]?.employerId?._id, AcctType: AcctType, name: Acct_profile.message?.Fullname });
  //         if (newMessage?.message !== null) {
  //           dispatch(end_to_end_chat(newMessage?.message));
  //         }
  //       }
  //     }
  //   }
  // }, [newMessage]);


  // job notification
  // useEffect(() => {
  //   if (socket === null) return;

  //   if (apply.message !== null) {
      // console.log("JobOwnerId", jobOwnerId);
  //     socket.emit("applyForJob", ({job: jobOwnerId }));
  //     // deleteAKey("JobOwnerId");
  //   }

  // }, [socket, apply.message]);

  useEffect(() => {
    if (socket === null) return;
    socket.on("jobNotification", (resp) => {
      // console.log("listening")
      if (Acct_profile?._id == resp?.officialId) {
        dispatch(getNotification());
      }
    });
    return () => {
      socket.off("jobNotification")
    }
  }, [socket]);

  // console.log("notification", notification);
  useEffect(() => {
    // console.log("useEffect socket line:185", socket);
    if (socket == null ) {
      const newSocket = io(baseURL, 
        {
          path: "/chat",
          cors: {
            origin: ["https://employee-beta.moilapp.com", 'https://employer-beta.moilapp.com' ], //  "http://localhost:3000", "http://localhost:3001"
          }
        }
      );
      // socketClient.connect();
      // setSocket(newSocket);
      dispatch(socketio(newSocket));
      // console.log("socket", socket);
    }
    // if (window.location.pathname.includes("inbox") && index !== null) {
      socket?.on("getMessage", (resp) => {
        dispatch(end_to_end_chat(resp?.message))
        // console.log("getmessage", resp);
        if (chat_info !== null) {
          if (chat_info[index]?._id !== resp?.chatId) {return};
        }
        if (resp !== null) {
          // setNewMsg(resp?.message);
          // dispatch(end_to_end_chat(resp?.message))
          // console.log("newMessage", newMessage);
        }
      });
    // }
    // console.log("info", chat_info);
    socket?.on("notification", (resp) => {
      // setNotification(prev => [resp, ...prev]);
      // console.log("information 1", chat_info);
      let msg_notification = 0;
      if (chat_info !== null) {
        chat_info.forEach(element => {
          let sender_profile_id = AcctType == "Client" ? element?.chatIds[0]?.employerId?._id : element?.chatIds[0]?.employeeId?._id;
          // if (element._id === chat_info[index]?._id) {
          //   return;
          // } else {
            if (sender_profile_id == resp.senderId) {
              // console.log("notify notification")
              element.Notification += 1;
            }
          // }
        })
        
          // loop tru resp.data to get the number of message notifications
          for ( let i = 0; i <= chat_info.length - 1; i++) {
            if (chat_info[i].Notification > 0) {
              msg_notification += 1;
            }
          }
          dispatch(message_notification(msg_notification)); 
        }
      });

    return () => {
      socket?.off("notification");
    }
  }, [socket]);

  useEffect(() => {
    if (socket === null) return;

    socket.on("getInterestnotification", (resp) => {
      // console.log("getInterestnotification", resp);
      if (resp !== null) {
        let data;
        if (notification_list?.length > 0) {
          data = [
            ...notification_list, resp
          ]
        } else {
          data = [
            resp
          ]
        }
        dispatch(notification_success(data));
      }
    })

  }, [socket]);

  useEffect(() => {
    if (socket === null) return;

    socket.on("decisionCardNotification", (resp) => {
      // console.log("decisionCardNotification", resp);
      if (resp !== null) {
        let data;
        if (notification_list?.length > 0) {
          data = [  ...notification_list, resp ]
        } else {
          data = [ resp ]
        }
        dispatch(notification_success(data));
      }
    });

  }, [socket]);

  
  useEffect(() => {
    if (chat_info !== null) {
      let msg_notification = 0;
        // loop tru resp.data to get the number of message notifications
        for ( let i = 0; i <= chat_info.length - 1; i++) {
          if (chat_info[i].Notification > 0) {
            msg_notification += 1;
          }
        }
        dispatch(message_notification(msg_notification)); // dispatch the overall notification.
    }
  }, [chat_info])


  const handleNoProfile = () => {
    setTimeout(() => {
      toast.error(
        "You don't have a profile yet. Kindly generate a resume to create your profile",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }, 50);
  };

  const handleNoResume = () => {
    setTimeout(() => {
      toast.error("You don't have a resume yet. Kindly generate a resume ", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }, 50);
  };

  const handleNoMessage = () => {
    setTimeout(() => {
      toast.error("You don't have any established chat now. Kindly create a profile ", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }, 50);
  };

  const handleNoJob = () => {
    setTimeout(() => {
      toast.error("You don't have any job. Kindly create a profile ", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }, 50);
  };

  const handleLogout = () => {
    navigate('/logout');
  }

  const activeClassName = ({ isActive }) => {
    return isActive
      ? `text-base rounded-[56px] max-[1024px]:px-0 max-[1024px]:w-[70px] ${sidebar ? 'w-[250px] pl-8' : 'w-[70px] justify-center px-0'}  py-4  btn-gradient flex max-[1024px]:justify-center items-center gap-2 text-white mx-auto`
      : `text-[#898989] text-base rounded-[56px] ${sidebar ? 'w-[250px] pl-10' : 'w-[70px] justify-center px-0'} max-[1024px]:px-0 max-[1024px]:w-[70px] py-4  flex max-[1024px]:justify-center items-center gap-2 mx-auto`;
  };
  const menuClassName = ({ isActive }) => {
    return isActive
      ? "text-base rounded-[56px]  w-full py-4 btn-gradient flex pl-4 items-center gap-2 text-white "
      : "text-[#898989] text-base w-full py-4 flex items-center gap-2";
  };

  const toggleSidebar = () => {
    dispatch(SidebarOpen())
  }

  return (
    <aside
      className={`${props.isForMenu
        ? "w-[280px] py-4 px-4"
        : `SideBar hidden md:block max-[1024px]:w-[100px] h-calc-70 w-full pt-16  col-start-1 col-end-2 row-start-2 row-end-3 relative before:content-[''] before:fixed before:w-[1.5px] before:min-h-[150vh] before:bg-sec-300 before:top-[-50%] before:left-[99px] ${sidebar ? 'cont:before:left-[278px]' : ''}`
        }`}
    >
      <button onClick={toggleSidebar} className={`hidden lg:block absolute ${sidebar ? 'left-[95%]' : 'left-[90%]'} top-[0px]`}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" class="icon-xl-heavy">
          <path fill="currentColor" fill-rule="evenodd" d="M8.857 3h6.286c1.084 0 1.958 0 2.666.058.729.06 1.369.185 1.961.487a5 5 0 0 1 2.185 2.185c.302.592.428 1.233.487 1.961.058.708.058 1.582.058 2.666v3.286c0 1.084 0 1.958-.058 2.666-.06.729-.185 1.369-.487 1.961a5 5 0 0 1-2.185 2.185c-.592.302-1.232.428-1.961.487C17.1 21 16.227 21 15.143 21H8.857c-1.084 0-1.958 0-2.666-.058-.728-.06-1.369-.185-1.96-.487a5 5 0 0 1-2.186-2.185c-.302-.592-.428-1.232-.487-1.961C1.5 15.6 1.5 14.727 1.5 13.643v-3.286c0-1.084 0-1.958.058-2.666.06-.728.185-1.369.487-1.96A5 5 0 0 1 4.23 3.544c.592-.302 1.233-.428 1.961-.487C6.9 3 7.773 3 8.857 3M6.354 5.051c-.605.05-.953.142-1.216.276a3 3 0 0 0-1.311 1.311c-.134.263-.226.611-.276 1.216-.05.617-.051 1.41-.051 2.546v3.2c0 1.137 0 1.929.051 2.546.05.605.142.953.276 1.216a3 3 0 0 0 1.311 1.311c.263.134.611.226 1.216.276.617.05 1.41.051 2.546.051h.6V5h-.6c-1.137 0-1.929 0-2.546.051M11.5 5v14h3.6c1.137 0 1.929 0 2.546-.051.605-.05.953-.142 1.216-.276a3 3 0 0 0 1.311-1.311c.134-.263.226-.611.276-1.216.05-.617.051-1.41.051-2.546v-3.2c0-1.137 0-1.929-.051-2.546-.05-.605-.142-.953-.276-1.216a3 3 0 0 0-1.311-1.311c-.263-.134-.611-.226-1.216-.276C17.029 5.001 16.236 5 15.1 5zM5 8.5a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1M5 12a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1" clip-rule="evenodd"></path>
        </svg>
      </button>
      <nav>
        <ul className="space-y-4">
          <li>
          {EmployeeProfile.message === null ? 
            <NavLink
                to={"/user/welcome"}
                className={
                  props.isForMenu ? menuClassName : activeClassName
                }
                id="dashboard-nav"
              >
                <DashboardIcon />
                <span
                  className={`${props.isForMenu  ? "" : `${sidebar ? 'max-[1024px]:sr-only' : ' max-[1024px]:sr-only lg:sr-only'}`
                    }`}
                >
                  Dashboard
                </span>
            </NavLink> : 
              <NavLink
              to={"/user/dashboard"}
              className={
                props.isForMenu ? menuClassName : activeClassName
              }
              id="dashboard-nav"
            >
              <DashboardIcon />
              <span
                className={`${props.isForMenu  ? "" : `${sidebar ? 'max-[1024px]:sr-only' : ' max-[1024px]:sr-only lg:sr-only'}`
                  }`}
              >
                Dashboard
              </span>
            </NavLink>}
          </li>

          <li>
            <>
              {EmployeeProfile.message === null ? (
                <div
                  id="profile-nav"
                  onClick={() => handleNoProfile()}
                  className="text-[#898989] text-base rounded-[56px] md:w-[250x]  py-4 vsm:pl-6 sm:pl-4 md:justify-start md:pl-12 flex items-center gap-2 cursor-pointer"
                >
                  <AccountBoxIcon />
                  <span
                     className={`${props.isForMenu  ? "" : `${sidebar ? 'max-[1024px]:sr-only' : ' max-[1024px]:sr-only lg:sr-only'}`
                      }`}
                  >
                    {" "}
                    Profile
                  </span>
                </div>
              ) : (
                <NavLink
                  to={`/user/profile/:${userInfo?.profile_id}`}
                  className={
                    props.isForMenu ? menuClassName : activeClassName
                  }
                  id="profile-nav"
                >
                  <AccountBoxIcon />
                  <span
                     className={`${props.isForMenu  ? "" : `${sidebar ? 'max-[1024px]:sr-only' : ' max-[1024px]:sr-only lg:sr-only'}`
                      }`}
                  >
                    {" "}
                    Profile
                  </span>
                </NavLink>
              )}
            </>
          </li>

          <li>
            <>
              {EmployeeProfile.message === null ? (
                <div
                  id="resume-nav"
                  onClick={() => handleNoResume()}
                  className="text-[#898989] text-base rounded-[56px] md:w-[250px]  py-4 vsm:pl-6 sm:pl-2 md:justify-start md:pl-12 flex items-center gap-2 cursor-pointer"
                >
                  <ArticleIcon />
                  <span
                    className={`${props.isForMenu  ? "" : `${sidebar ? 'max-[1024px]:sr-only' : ' max-[1024px]:sr-only lg:sr-only'}`
                      }`}
                  >
                    My Resumes
                  </span>
                </div>
              ) : (
                <NavLink
                  to={"/resume"}
                  className={
                    props.isForMenu ? menuClassName : activeClassName
                  }
                  id="resume-nav"
                >
                  <ArticleIcon />
                  <span
                   className={`${props.isForMenu  ? "" : `${sidebar ? 'max-[1024px]:sr-only' : ' max-[1024px]:sr-only lg:sr-only'}`
                      }`}
                  >
                    My Resumes
                  </span>
                </NavLink>
              )}
            </>
          </li>

          <li>
            {EmployeeProfile.message === null ?
              (<div
                id="jobs-nav"
                className="text-[#898989] text-base rounded-[56px] md:w-[250px]  py-4 vsm:pl-6 sm:pl-2 md:justify-start md:pl-12 flex items-center gap-2 cursor-pointer"
                onClick={() => handleNoJob()}
              >
                <BusinessCenterIcon />
                <span
                   className={`${props.isForMenu  ? "" : `${sidebar ? 'max-[1024px]:sr-only' : ' max-[1024px]:sr-only lg:sr-only'}`
                    }`}
                >
                  My Jobs
                </span>
              </div>)
              : (
                <NavLink
                  id="jobs-nav"
                  to={"/user/jobs"}
                  className={
                    props.isForMenu ? menuClassName : activeClassName
                  }
                >
                  <BusinessCenterIcon />
                  <span
                     className={`${props.isForMenu  ? "" : `${sidebar ? 'max-[1024px]:sr-only' : ' max-[1024px]:sr-only lg:sr-only'}`
                      }`}
                  >
                    My Jobs
                  </span>
                </NavLink>
              )}
          </li>


          {/* Ending job routes input here */}
          <li>
            <>
              {EmployeeProfile.message === null ? (
                <div
                  className="text-[#898989] text-base rounded-[56px] md:w-[250px]  py-4 vsm:pl-6 sm:pl-2 md:justify-start md:pl-12 flex items-center gap-2 cursor-pointer"
                  onClick={() => handleNoMessage()}
                  id="message-nav"
                >
                  <MessageIcon />
                  <span
                    className={`${props.isForMenu  ? "" : `${sidebar ? 'max-[1024px]:sr-only flex items-center' : ' max-[1024px]:sr-only lg:sr-only'}`
                      }`}
                  >
                    Messages
                  </span>
                </div>
              ) : (
                <NavLink
                  to={"/user/messages"}
                  className={
                    props.isForMenu ? menuClassName : activeClassName
                  }
                  onClick={() => dispatch(message_notification(0))}
                  id="message-nav"
                >
                  <MessageIcon />
                  <span
                   className={`${props.isForMenu  ? "" : `${sidebar ? 'max-[1024px]:sr-only flex items-center' : ' max-[1024px]:sr-only lg:sr-only'}`
                      }`}
                  >
                    Messages

                    {msg_notification.message === null || msg_notification.message === 0 ? null :
                      <span className=" ml-4 px-[7.76px] py-[2.91px] bg-[#FF6633] text-white font-medium text-xs rounded-[20px]">{msg_notification.message} </span>}
                  </span>
                </NavLink>
              )}
            </>
          </li>
          <li>
            {EmployeeProfile.message === null ? (
              <div
                className="text-[#898989] text-base rounded-[56px] md:w-[250px]  py-4 vsm:pl-6 sm:pl-2 md:justify-start md:pl-12 flex items-center gap-2 cursor-pointer"
                onClick={() => handleLogout()}
                id="logout-nav"
              >
                <LogoutIcon />
                <span
                 className={`${props.isForMenu  ? "" : `${sidebar ? 'max-[1024px]:sr-only flex items-center' : ' max-[1024px]:sr-only lg:sr-only'}`
                    }`}
                >
                  Logout
                </span>
              </div>
            ) : (
              <NavLink
                to={"/logout"}
                className={
                  props.isForMenu ? menuClassName : activeClassName
                }
                id="logout-nav"
              >
                <LogoutIcon />
                <span
                   className={`${props.isForMenu  ? "" : `${sidebar ? 'max-[1024px]:sr-only' : ' max-[1024px]:sr-only lg:sr-only'}`}`}
                >
                  Logout
                </span>
              </NavLink>
            )}
          </li>
        </ul>
      </nav>
      <ToastContainer />
    </aside>
  );
};

export default SideBar;
