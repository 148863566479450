import { createPortal } from "react-dom";
import { GamefiedVector } from "../../Resume/svgs/svgs";
import AddMore from "../../Resume/add_more/add_more";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import { useSelector } from "react-redux";

export default function ProfilePicsGamifiedModal(props) {
  const profile = useSelector((state) => state.profile);

  return (
    <div>
      {
        createPortal(
          <div onClick={props.handleClose} className="fixed top-0 left-0 bottom-0 right-0 z-10 bg-tet-600 overflow-y-scroll min-h-screen py-7 backdrop-blur-[2px] flex justify-center items-center">
            <section onClick={(e) => e.stopPropagation()} className="bg-white sm:max-w-[500px] min-w-[350px] w-[80%] z-100 m-auto rounded-[24px] pb-5 overflow-hidden relative">
              <div className="w-full max-w-[660px] p-4 flex justify-between items-center bg-[#5843BD] border-b-8 border-[#E9F1FF] rounded-tr-[24px] rounded-tl-[24px] ">
                <p className=" text-lg font-medium leading-normal text-left text-white">
                  Profile Completion
                </p>
                <div className="w-[64px] h-[64px] bg-[#F5F9FF] rounded-[50%] font-semibold">
                  <CircularProgressbarWithChildren
                    value={profile.message?.CompletionPercentage}
                    styles={buildStyles({
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: 'round',
                      // How long animation takes to go from one percentage to another, in seconds
                      pathTransitionDuration: 0.5,
                      // Can specify path transition in more detail, or remove it entirely
                      // pathTransition: 'none',
                      // Colors
                      pathColor: profile.message?.CompletionPercentage < 50 ? '#FF6633' : `#5843BD`,

                      trailColor: '#FFFFFF80',
                    })}
                  >
                    <p className="text-[20px] text-[#000000DE]">{profile.message?.CompletionPercentage}%</p>
                  </CircularProgressbarWithChildren>
                </div>
              </div>

              <div className="absolute top-[-8%] -z-1 left-[-20%]">
                <GamefiedVector />
              </div>

              <div className="flex flex-col items-center relative z-[1000] justify-center gap-y-4">
                <div className="px-4 py-2 w-full justify-between md:w-max gap-x-4 flex  items-center">
                  <img src="https://res.cloudinary.com/drlcisipo/image/upload/v1717681129/noto_smiling-face_tkevnm.png" />
                  <p className=" text-2xl font-semibold leading-normal text-center text-[#5843BD]">
                    Put your face to your profile
                  </p>
                  <img src="https://res.cloudinary.com/drlcisipo/image/upload/v1717681129/noto_smiling-face_tkevnm.png" />
                </div>

                <p className=" text-base font-semibold rounded-[4px] leading-tight p-2 w-max text-center text-[#5843BD] bg-[#FF66331A]">
                  Your profile is <span className="text-[#FF6633]">{profile.message?.CompletionPercentage}% complete</span>
                </p>

                <p className=" text-base font-normal leading-[1.2] text-center max-w-[280px] sm:max-w-[400px]">
                  Employers will like to know your face whenever <br className="hidden md:block" />they hire you and you report for work. <br /><br />
                  Click the button below to add <span className="text-[#5843BD]"><br className="md:hidden" />your profile photo </span><br className="hidden md:block" />now.
                </p>

                {
                  profile.message?.CompletionPercentage == 95 && (
                    <button
                      className={`h-11 px-8 py-2.5 rounded-3xl justify-start items-center gap-2 sm:gap-2.5 inline-flex bg-orange-500`} onClick={() => props?.handleClose()}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 17V7"
                          stroke="#FFFFFF"
                          stroke-width="1.5"
                          stroke-linecap="round"
                        />
                        <path
                          d="M7 12L17 12"
                          stroke="#FFFFFF"
                          stroke-width="1.5"
                          stroke-linecap="round"
                        />
                      </svg>
                      <span className="text-center text-white text-500 text-base font-medium">
                        Upload My Profile Photo
                      </span>
                    </button>
                  )
                }

                <p class=" text-sm font-normal py-4 leading-[1.2] text-center text-[#5843BD33] cursor-pointer" onClick={props.handleClose}>
                  Remind me later
                </p>
              </div>

            </section>
          </div>, document.getElementById("modal")
        )
      }
    </div>
  )
}