import React, { useState, useEffect } from "react";
import TopBar from "../../topbar/Top-Bar";
import ResumeFormat from "../resume_format/resume_format";
import BackButton from "../../common/back_button";
import { useNavigate } from "react-router-dom/dist";
import { useSelector, useDispatch } from "react-redux";
import HelmetProvide from "../../common/helmet";
import { saveCvDetails, ClickLoading,  } from "../../../Redux/ActionCreator"
import { v4 as uuidv4 } from "uuid";
import LoaderModal from "../../common/LoaderModal"
import App1 from "../../pdf/DownloadableRresume";
import InterviewModal from "../../common/interviewModal"
import DownloadResumeModal from "../DownloadResume"
import { getOneFromServerCache } from "../../../utils/saveIntoCache";

const obj = [
  {
    format: 'https://res.cloudinary.com/drlcisipo/image/upload/v1707771214/Resume%20assets/resume_acjg0i.png',
    id: 1,
    name: `Golden Resume`,
  },
  {
    format: 'https://res.cloudinary.com/drlcisipo/image/upload/v1707771214/Resume%20assets/sample_bphs7a.png',
    id: 2,
    name: `sample`,
  },
  {
    format: 'https://res.cloudinary.com/drlcisipo/image/upload/v1719985893/minimal_e1k8m3.jpg',
    id: 3,
    name: `Black-minimal`,
  },
  {
    format: 'https://res.cloudinary.com/drlcisipo/image/upload/v1708562444/minimalist_a8bfps.png',
    id: 4,
    name: `Minimalist`,
  },
  {
    format: 'https://res.cloudinary.com/drlcisipo/image/upload/v1719985903/purple_pro_zgyj06.jpg',
    id: 5,
    name: `pro_purple`,
  },
  {
    format:  'https://res.cloudinary.com/drlcisipo/image/upload/v1705704239/Website%20images/cv_template_5_qd8dyg.webp',
    id: 6,
    name: "Moil Mingle Magic",
  },
  {
    format: 'https://res.cloudinary.com/drlcisipo/image/upload/v1710155891/Website%20images/BlackTalent_orufvm.png',
    id: 7,
    name: `Essential Avenger`,
  },
  {
    format: 'https://res.cloudinary.com/drlcisipo/image/upload/v1710152204/Website%20images/freeway_c7tsv4.png',
    id: 8,
    name: `Freeway Freelancer`,
  },
  {
    format: 'https://res.cloudinary.com/drlcisipo/image/upload/v1705704237/Website%20images/cv_template_4_jotdls.webp',
    id: 9,
    name: `Gig Galaxy Explorer`,
  },
  {
    format: 'https://res.cloudinary.com/drlcisipo/image/upload/v1710153106/Website%20images/painter_fi7xmt.png',
    id: 10,
    name: `Toolbelt Titan`,
  },
  {
    format: 'https://res.cloudinary.com/drlcisipo/image/upload/v1705704240/Website%20images/cv_template_6_ittp5w.webp',
    id: 11,
    name: `Skillzilla Showcase`,
  },
  {
    format: 'https://res.cloudinary.com/drlcisipo/image/upload/v1705704233/Website%20images/cv_template_7_cqovwo.webp',
    id: 12,
    name: `Code 'n' Wrench Wizard`,
  },
];
export default function UserResumeType() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const saved_cv_details = useSelector((prev) => prev.saved_cv);
  const profile = useSelector(e => e.profile.message);
  const userInfo = useSelector((e) => e.userInfo);
  const [ saveResume, setSaveResume ] = useState(false);
  const clickStat = useSelector(e => e.click);
  const generated_cv = useSelector(e => e.generateCV.message);

  const queryParam = new URLSearchParams(window.location.search);
  let download = queryParam.get("download");
  const [ language, setLanguage ] = useState();

  useEffect(() => {
    if (download !== null) {
      let Language = getOneFromServerCache("Language");
      Language.then(resp => resp.json())
        .then(resp => {
          // console.log("Language", resp.data);
          if (resp.success == true) {
            if (resp.data == null) {
              // setLanguage(JSON.parse(resp.data));
            } else {
              setLanguage(JSON.parse(resp.data));
            }
          } else if (resp.success === false && resp.message == "Unauthorized") {
            dispatch(returnHome(resp.status));
          }
        }).catch(err => {
          // console.log("Language getting error", err);
        });
    }
  }, []);

  useEffect(() => {
    // let get the last resume aws_link and download it
    let lastCv = saved_cv_details?.message[saved_cv_details?.message.length - 1];
    let lastCvId = lastCv?.pdfId;
    // console.log("Last cv Id", lastCvId);
    if (clickStat.message == true) {
      if (parseInt(lastCvId, 10) < 6 && lastCv?.aws_link !== undefined) {
        // then perform the download here.
        downloadFile(lastCv)
      } else {
        setSaveResume(false);
        dispatch(ClickLoading(true));
      }
    }

  }, [saved_cv_details.message]);

  const downloadFile = async (lastCv) => {
    const fileUrl = lastCv?.aws_link;
    let link = document.createElement('a');
    link.href = fileUrl;
    link.download = lastCv?.CvType;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setSaveResume(false);
    dispatch(ClickLoading(true));
    navigate(`/user/profile/:${userInfo.user.profile_id}`);
  }

  const [ show, setShow ] = useState(false);
  let [ cv_details, setCv_details ] = useState();
  // console.log("generated_cv", generated_cv);
  const handleSelection = (data) => {
    if (download) {
      // setCv_details(cv_details)
      const clientSecret = uuidv4();
      let Secret = clientSecret;
      // after selecting here, show the loadmodal till the resume is fully downloaded, after which you redirect back to home page.
      let details
      if (generated_cv !== null && generated_cv !== undefined) {
        details = {
          Fullname: generated_cv.Fullname,
          Email: generated_cv.Email,
          Phone_number: generated_cv.Phone_number,
          Address: generated_cv.Address,
          Professional_summary: generated_cv.Professional_summary,
          Education: generated_cv.Education,
          Experience: generated_cv.Experience,
          Technical_skills: generated_cv.Technical_skills,
          Personal_skills: generated_cv.Personal_skills, 
          Reference: generated_cv.Reference || [],
          CvType: generated_cv.Job_type,
          Language: generated_cv.Language,
          Lang: [{lang: language}],
          Award: [],
          year_of_experience: generated_cv.Years_of_experience,
          pdfId: data.id
        }
      } else {
        details = {
          Fullname: profile.FirstName + " " + profile.LastName,
          FirstName: profile.FirstName,
          LastName: profile.LastName,
          Email: profile.Email,
          Phone_number: profile.Phone_number,
          Address: profile.Address,
          Professional_summary: profile.About,
          Education: profile.Education,
          Experience: profile.Experience,
          Technical_skills: profile.Skills,
          Personal_skills: profile.Soft_skills, 
          Reference: profile.Reference || [],
          CvType: profile.Job_type,
          Language: profile.Language,
          Lang: [{lang: language}],
          Award: [],
          year_of_experience: profile.Years_of_experience,
          pdfId: data.id
        }
      }
      // console.log("pdfid", data.id);
      if (data.id >= 6) {
        setCv_details({...details, Secret: Secret});
        setShow(true);
      } else {
        setSaveResume(true);
        dispatch(saveCvDetails(details, data.id, Secret, language ));
      }
      // navigate("/download");
    } else {
      navigate(`/form/${data?.id}/${data?.name}?template=true`);
    }
  }
  // console.log("cv_details", cv_details);
  const handleClick = () => {
    if (download) {
      navigate(`/user/profile/:${userInfo.user.profile_id}?download=true`);
    } else {
      window.history.back();
    }
    // navigate("/upload");
  };

  const downloadModal = show ? cv_details !== undefined ? <DownloadResumeModal details={cv_details} hide={setShow} /> : null : null

  return (
    <>
      <HelmetProvide
        title="Moil | Create Your Resume"
        description="Build your professional resume with Moil's easy-to-use resume creation form. Showcase your skills, experience, and qualifications to potential employers."
        className="notranslate"
      />
      <div className="grid grid-rows-dash grid-cols-auto">
        <TopBar />
        <div className="w-full overflow-y-scroll ScrollOnly h-calc-70">
          <div className="flex max-[320px]:w-full flex-col gap-6 justify-center items-center my-8 max-w-[1028px] mx-auto px-4">
            <BackButton onClick={handleClick} />
            <div className="w-full">
              <p className="text-orange-500 text-base font-normal">
                In this step, select your favourite template.
              </p>
            </div>
            <div className="pl-4 vsm:p-2 sm:pl-8 md:pl-12 py-1.5 bg-white bg-opacity-50 rounded-[20px] shadow border border-indigo-400 border-opacity-30 justify-start items-start gap-2.5 inline-flex">
              <div className="text-stone-950 text-[20px] vsm:text-base font-semibold text-wrap">
                Select your favorite design and create your professional resume effortlessly. Our platform makes it quick and convenient for you to showcase your skills and experiences effectively.
              </div>
            </div>
            <div className="w-full grid sm:grid-cols-2 md:grid-cols-3 bg-white bg-opacity-80 rounded-2xl shadow border border-indigo-400 border-opacity-30 gap-6 px-4 py-14 lg:px-10 lg:py-18">
              {obj?.map((data, id) => (
                <div onClick={() => handleSelection(data)}>
                  <ResumeFormat format={data.format} name={data?.name}/>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {
        saveResume == true ? <LoaderModal text="Creating your resume. This can take a couple of minutes..." /> : null
      }
      {downloadModal}
    </>
  );
}

