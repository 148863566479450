const Skills_sp = [
  {
    value: 'Instalación Eléctrica',
    label: 'Instalación Eléctrica',
  },
  {
    value: 'Fontanería',
    label: 'Fontanería',
  },
  {
    value: 'Carpintería',
    label: 'Carpintería',
  },
  {
    value: 'Soldadura',
    label: 'Soldadura',
  },
  {
    value: 'HVAC Systems',
    label: 'HVAC Systems',
  },
  {
    value: 'Operación de Maquinaria',
    label: 'Operación de Maquinaria',
  },
  {
    value: 'Mantenimiento de Equipos',
    label: 'Mantenimiento de Equipos',
  },
  {
    value: 'Reparación de Automóviles',
    label: 'Reparación de Automóviles',
  },
  {
    value: 'Habilidades de Construcción',
    label: 'Habilidades de Construcción',
  },
  {
    value: 'Operación de Equipos Pesados',
    label: 'Operación de Equipos Pesados',
  },
  {
    value: 'Trabajo en Metal',
    label: 'Trabajo en Metal',
  },
  {
    value: 'Ajuste de Tuberías',
    label: 'Ajuste de Tuberías',
  },
  {
    value: 'Albura de Ladrillo',
    label: 'Albura de Ladrillo',
  },
  {
    value: 'Paisajismo',
    label: 'Paisajismo',
  },
  {
    value: 'Pintura',
    label: 'Pintura',
  },
  {
    value: 'Techado',
    label: 'Techado',
  },
  {
    value: 'Trabajo de Hormigón',
    label: 'Trabajo de Hormigón',
  },
  {
    value: 'Operación de Montacargas',
    label: 'Operación de Montacargas',
  },
  {
    value: 'Andamiaje',
    label: 'Andamiaje',
  },
  {
    value: 'CNC Machining',
    label: 'CNC Machining',
  },
  {
    value: 'Trabajo de Chapa Metálica',
    label: 'Trabajo de Chapa Metálica',
  },
  {
    value: 'Enlucido',
    label: 'Enlucido',
  },
  {
    value: 'Demolición',
    label: 'Demolición',
  },
  {
    value: 'Azulejado',
    label: 'Azulejado',
  },
  {
    value: 'Enmarcado',
    label: 'Enmarcado',
  },
  {
    value: 'Soldadura',
    label: 'Soldadura',
  },
  {
    value: 'Ensamblaje',
    label: 'Ensamblaje',
  },
  {
    value: 'Instalación de Aislamiento',
    label: 'Instalación de Aislamiento',
  },
  {
    value: 'Instalación de Pisos',
    label: 'Instalación de Pisos',
  },
  {
    value: 'Trabajo de Conductos',
    label: 'Trabajo de Conductos',
  },
  {
    value: 'Instalación de Tablaroca',
    label: 'Instalación de Tablaroca',
  },
  {
    value: 'Instalación de Vidrios',
    label: 'Instalación de Vidrios',
  },
  {
    value: 'Pavimentación',
    label: 'Pavimentación',
  },
  {
    value: 'Cerrajería',
    label: 'Cerrajería',
  },
  {
    value: 'Albañilería',
    label: 'Albañilería',
  },
  {
    value: 'Mantenimiento de Carreteras',
    label: 'Mantenimiento de Carreteras',
  },
  {
    value: 'Instalación de Barandas',
    label: 'Instalación de Barandas',
  },
  {
    value: 'Carpintería de Gabinete',
    label: 'Carpintería de Gabinete',
  },
  {
    value: 'Instalación de Ascensores',
    label: 'Instalación de Ascensores',
  },
  {
    value: 'Acristalamiento',
    label: 'Acristalamiento',
  },
  {
    value: 'Trabajo de Terrazo',
    label: 'Trabajo de Terrazo',
  },
  {
    value: 'Limpieza de Alcantarillado y Tuberías',
    label: 'Limpieza de Alcantarillado y Tuberías',
  },
  {
    value: ' Montaje de Andamios',
    label: ' Montaje de Andamios',
  },
  {
    value: 'Mecánica de Refrigeración',
    label: 'Mecánica de Refrigeración',
  },
  {
    value: 'Pilotaje',
    label: 'Pilotaje',
  },
  {
    value: 'Control de Plagas',
    label: 'Control de Plagas',
  },
  {
    value: 'Mantenimiento de Sistemas Sépticos',
    label: 'Mantenimiento de Sistemas Sépticos',
  },
  {
    value: 'Instalación de Rociadores contra Incendios',
    label: 'Instalación de Rociadores contra Incendios',
  },
  {
    value: 'Fabricación de Metal',
    label: 'Fabricación de Metal',
  },
  {
    value: ' Cantería',
    label: ' Cantería',
  },
  {
    value: 'Instalación de Cercas',
    label: 'Instalación de Cercas',
  },
  {
    value: 'Operación de Herramientas Eléctricas',
    label: 'Operación de Herramientas Eléctricas',
  },
  {
    value: 'Instalación de Utilidades Subterráneas',
    label: 'Instalación de Utilidades Subterráneas',
  },
  {
    value: ' Lijado y Acabado',
    label: ' Lijado y Acabado',
  },
  {
    value: 'Cableado de Telecomunicaciones',
    label: 'Cableado de Telecomunicaciones',
  },
  {
    value: 'Instalación y Reparación de Bombas',
    label: 'Instalación y Reparación de Bombas',
  },
  {
    value: 'Instalación de Paneles Solares',
    label: 'Instalación de Paneles Solares',
  },
  {
    value: 'Pavimentación Asfáltica',
    label: 'Pavimentación Asfáltica',
  },
  {
    value: 'Instalación de Techos',
    label: 'Instalación de Techos',
  },
  {
    value: 'Montaje de Estructuras de Acero',
    label: 'Montaje de Estructuras de Acero',
  },
  {
    value: 'Instalación de Chimeneas',
    label: 'Instalación de Chimeneas',
  },
  {
    value: 'Instalación de Gabinetes',
    label: 'Instalación de Gabinetes',
  },
  {
    value: 'Instalación de Bordillos y Cunetas',
    label: 'Instalación de Bordillos y Cunetas',
  },
  {
    value: ' Instalación de Alfombras',
    label: ' Instalación de Alfombras',
  },
  {
    value: ' Tapizado de Muebles',
    label: ' Tapizado de Muebles',
  },
  {
    value: ' Zanjeo y Excavación',
    label: ' Zanjeo y Excavación',
  },
  {
    value: 'Operación de Bomba de Concreto',
    label: 'Operación de Bomba de Concreto',
  },
  {
    value: 'Operación de Grúa Móvil',
    label: 'Operación de Grúa Móvil',
  },
  {
    value: ' Instalación de Adoquines',
    label: ' Instalación de Adoquines',
  },
  {
    value: 'Reparación y Mantenimiento de Neumáticos',
    label: 'Tire Repair and Maintenance',
  },
  {
    value: 'Instalación de Equipos de Juego en el Patio',
    label: 'Instalación de Equipos de Juego en el Patio',
  },
  {
    value: 'Reparación de Fibra de Vidrio',
    label: 'Reparación de Fibra de Vidrio',
  },
  {
    value: 'Instalación de Estanques y Características de Agua',
    label: 'Instalación de Estanques y Características de Agua',
  },
  {
    value: 'Ignifugación',
    label: 'Ignifugación',
  },
  {
    value: 'Riego de Paisaje',
    label: 'Riego de Paisaje',
  },
  {
    value: 'Instalación y Reparación de Turbinas',
    label: 'Instalación y Reparación de Turbinas',
  },
  {
    value: 'Instalación de Canalones',
    label: 'Instalación de Canalones',
  },
  {
    value: 'Nivelación y Grading',
    label: 'Nivelación y Grading',
  },
  {
    value: 'Señalización y Control de Tráfico',
    label: 'Señalización y Control de Tráfico',
  },
  {
    value: 'Trabajo de Fundición',
    label: 'Trabajo de Fundición',
  },
  {
    value: 'Instalación de Soportes',
    label: 'Instalación de Soportes',
  },
  {
    value: 'Dragado',
    label: 'Dragado',
  },
  {
    value: 'Levantamiento y Riggings Pesados',
    label: 'Levantamiento y Riggings Pesados',
  },
  {
    value: 'Instalación de Revestimientos',
    label: 'Instalación de Revestimientos',
  },
  {
    value: 'Instalación de HVAC Geotérmico',
    label: 'Instalación de HVAC Geotérmico',
  },
  {
    value: 'Aislamiento de Espuma en Aerosol',
    label: 'Aislamiento de Espuma en Aerosol',
  },
  {
    value: 'Instalación de Mármol y Granito',
    label: 'Instalación de Mármol y Granito',
  },
  {
    value: 'Carpintería',
    label: 'Carpintería',
  },
  {
    value: 'Remediación de Moho',
    label: 'Remediación de Moho',
  },
  {
    value: 'Pulido de Terrazo',
    label: 'Pulido de Terrazo',
  },
  {
    value: 'Corte y Rompimiento de Concreto',
    label: 'Corte y Rompimiento de Concreto',
  },
  {
    value: 'Impermeabilización',
    label: 'Impermeabilización',
  },
  {
    value: 'Voladura y Demolición',
    label: 'Voladura y Demolición',
  },
  {
    value: 'Instalación de Sistemas de Rociadores',
    label: 'Instalación de Sistemas de Rociadores',
  },
  {
    value: 'Aislamiento Mecánico',
    label: 'Aislamiento Mecánico',
  },
  {
    value: 'Corte de Piedra',
    label: 'Corte de Piedra',
  },
  {
    value: 'Aislamiento de Fibra de Vidrio',
    label: 'Aislamiento de Fibra de Vidrio',
  },
  {
    value: 'Mantenimiento de Equipamiento para Parques Infantiles',
    label: 'Mantenimiento de Equipamiento para Parques Infantiles',
  },
  {
    value: 'Mezcla de Mortero',
    label: 'Mezcla de Mortero',
  },
  {
    value: 'Operaciones Logísticas y de Almacén',
    label: 'Operaciones Logísticas y de Almacén',
  },
  {
    value: 'Refuerzo de Acero',
    label: 'Refuerzo de Acero',
  },
  {
    value: 'Sistemas de Aire Comprimido',
    label: 'Sistemas de Aire Comprimido',
  },
  {
    value: 'Estructuras de Madera',
    label: 'Estructuras de Madera',
  },
  {
    value: 'Armazón de Madera',
    label: 'Armazón de Madera',
  },
  {
    value: 'Instalación de Sistema Séptico',
    label: 'Instalación de Sistema Séptico',
  },
  {
    value: 'Aplicación de Estuco, Encofrado de Concreto',
    label: 'Stucco Application',
  },
  {
    value: 'Recubrimiento de Epoxy',
    label: 'Recubrimiento de Epoxy',
  },
  {
    value: ' Operación de Helicóptero',
    label: ' Operación de Helicóptero',
  },
  {
    value: 'Instalación y Reparación de Electrodomésticos de Gas',
    label: 'Instalación y Reparación de Electrodomésticos de Gas',
  },
  {
    value: 'Barrido de Calles',
    label: 'Barrido de Calles',
  },
  {
    value: 'Mantenimiento de Estación de Bombeo',
    label: 'Mantenimiento de Estación de Bombeo',
  },
  {
    value: 'Mantenimiento de Pista de Aeropuerto',
    label: 'Mantenimiento de Pista de Aeropuerto',
  },
  {
    value: 'Aparejo de Velero',
    label: 'Aparejo de Velero',
  },
  {
    value: 'Inspección y Prueba de Tuberías',
    label: 'Inspección y Prueba de Tuberías',
  },
  {
    value: 'Marcado de Estacionamiento en el Lote',
    label: 'Marcado de Estacionamiento en el Lote',
  },
  {
    value: 'Remoción de Pintura',
    label: 'Remoción de Pintura',
  },
  {
    value: 'Control de Erosión',
    label: 'Control de Erosión',
  },
  {
    value: 'Instalación de Rampa',
    label: 'Instalación de Rampa',
  },
  {
    value: 'Instalación de Techo de Metal',
    label: 'Instalación de Techo de Metal',
  },
  {
    value: 'Pulido de Concreto',
    label: 'Pulido de Concreto',
  }
]



export default Skills_sp;
