import React from "react";

export default function AddedItem(props) {
  return (
    <div className={`${props.color == undefined && props.plain ? "shadow" : props.color === undefined && !props.plain ?  "border-[2px] border-red-500" :   "border-[2px] border-green-500"}  px-5 py-3 bg-indigo-700 bg-opacity-10 rounded-3xl shadow border  justify-start items-center gap-2 inline-flex cursor-pointer`} >
      <div className="text-indigo-700 text-base font-normal w-full" onClick={props.onClick}>
        {props.item}
      </div>
      <button onClick={props.delete}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
            fill="#5843BD"
          />
        </svg>
      </button>
    </div>
  );
}
