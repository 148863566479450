import React from "react";

export default function QuestionItem(props) {
  return (
    <div className="relative pb-1.5 border-b border-indigo-400 border-opacity-30 flex items-center gap-[12px] pl-2 tablet:pl-4">
      <div className="w-[4px] h-[4px] rounded-full bg-black"></div>
      <p className="text-black text-[12px] tablet:text-base font-medium capitalize">
        {props.question}
      </p>
      <div className={`tablet:w-14 w-8 h-1 ${props.question === "Generate CV/Resume" ? "bg-orange-500" : "bg-indigo-700"} absolute rounded bottom-[-2px] left-[23px] tablet:left-[31px]`} />
    </div>
  );
}
