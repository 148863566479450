import React, { useEffect, useState } from "react"
import { Document, Page, StyleSheet, View, Text, PDFViewer, Svg, Line, Image, Link, Circle } from '@react-pdf/renderer';

import { DetailsOutlined } from "@mui/icons-material";
import { type } from "jquery";

// require fonts


import font from "./font/poppins-v20-latin-600.ttf";
import font1 from "./font/poppins-v20-latin-300.ttf";
import { Font } from '@react-pdf/renderer'

Font.register({ family: 'Poppins', src: font1});
Font.register({ family: 'Poppins-bold', src: font});


const styles = StyleSheet.create({
  page: {
    fontFamily: "Poppins",
/*     backgroundColor: "#222D65", */

    backgroundColor: "#FFFFFF",
    color: "#000000",
    paddingBottom: 40,
    paddingTop: 40
  },
  viewer: {
    width: '55vw', height: '80vh',
    '@media maxWidth: 500' : {
      width: "60vw", height: "80vh"
    }
  }
});

function BlueTalent(props) {
  let details;
  details = [props.details]
  
  const [ wrap_stat, setWrap_stat ] = useState(false);
  useEffect(() => {
    if (details[0].Experience.length >= 4) {
      setWrap_stat(true);
    } else {
      setWrap_stat(false);
    }

  }, [])

  let my_interest;
  if (details[0] !== null) {
    if (Array.isArray(details[0]?.Interest)) {
      my_interest = details[0]?.Interest;
    } else if (typeof details[0]?.Interest === "string") {
      my_interest = details[0]?.Interest.split(", ");
    }
  }
  if (Array.isArray(details[0]?.Technical_skills) === false ) {
    if (typeof details[0]?.Technical_skills === "string") {
      details[0].Technical_skills = details[0]?.Technical_skills.split(", ");
    }
   }

   if (Array.isArray(details[0]?.Personal_skills) === false ) {
    if (typeof details[0]?.Personal_skills === "string") {
      details[0].Personal_skills = details[0]?.Personal_skills.split(", ");
    }
  }

  const chunkSubstr = (str, size) => {
    const numChunks = Math.ceil(str.length / size);
    const chunks = new Array(numChunks);

    for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
      chunks[i] = str.substr(o, size);
    }

    return chunks;
  };

  Font.registerHyphenationCallback((word) => { // to avoid breaking of words.
    if (word.length > 16) {
      return chunkSubstr(word, 14);
    } else {
      return [word];
    }
  });


  return (

            <Document>
              <Page size="A4" style={styles.page} wrap={wrap_stat} >
            {
              details?.map((data, key) => (
                  <View key={key}>
                    <View style={{marginTop: 10, marginLeft: 45, display: "flex", flexDirection: "row"}}>
                      <View>
                        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: 500, }} >
                                <Text style={{ fontFamily: "Poppins-bold", fontSize: "28px" }} >{data.Fullname} </Text>
                        </View>
                        {data.CvType !== null || data.CvType !== undefined ? 
                        <View  style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: 500, marginTop: 10 }} >
                          <Text style={{ fontFamily: "Poppins", fontSize: "15px", color: "#FF6633", marginTop: -10}}>A </Text>
                            <Text style={{ fontFamily: "Poppins", fontSize: "15px", color: "#FF6633", marginTop: -10}} key={key}>{data.CvType} </Text>
                        </View> : null}

                        <View style={{ width: 500, marginTop: 10}} >
                          <Text style={{ fontFamily: "Poppins", fontSize: 8, }} key={key} >{data.Professional_summary} </Text>
                        </View>
                      </View>
                    </View>
                    {/* header section end */}
        
                    {/* horizontal Line */}
                    <View style={{ marginLeft: 45, marginTop: 5, position: "relative"}}>
                      <Svg height="2" width="100%">
                        <Line x1="0"
                          y1="0"
                          x2="500"
                          y2="0"
                          strokeWidth={1}
                          stroke="#FF6633" 
                        />
                      </Svg>
                    </View>
        
                    {/* Address section start */}
                    <View style={{ display: "flex", flexDirection: "row", marginLeft: 45, marginTop: 20}}>

                      {/* left side start */}
                      <View style={{ width: "30vw", marginRight: 10}}>
                        <View>
                        {
                        data.Lang[0]?.lang === 'English' ? 
                        <Text style={{ fontFamily: "Poppins-bold", fontSize: 10}}>Contact</Text>  : <Text style={{ fontFamily: "Poppins-bold", fontSize: 10}}>Contacto </Text> 
                        }                 
                          <View style={{marginTop: 2, marginLeft: 0}}>
                            <Svg height="2" width="30">
                              <Line x1="0"
                                y1="0"
                                x2="200"
                                y2="0"
                                strokeWidth={1}
                                stroke="#FF6633" 
                              />
                            </Svg>
                          </View>
                          <View style={{width: 110, marginTop: 5}}>
                            <Text style={{ fontFamily: "Poppins", fontSize: 8 }} >{data.Phone_number}</Text>
                            <Text style={{ fontFamily: "Poppins", fontSize: 8 }} >{data.Address}</Text>
                          </View>
                        </View>
                        {/* Education section start */}
                        { data.Education[0]?.Degree === "No Formal Education" || data.Education?.length === 0 ? null : 
                          <View style={{marginTop: 20}}>
                          {
                            data.Lang[0]?.lang === 'English' ?
                            <Text style={{ fontFamily: "Poppins-bold", fontSize: 10}}>Education</Text> : <Text style={{ fontFamily: "Poppins-bold", fontSize: 10}}> Educación </Text>
                          }                
                            <View style={{marginTop: 2, marginLeft: 0}}>
                              <Svg height="2" width="30">
                                <Line x1="0"
                                  y1="0"
                                  x2="200"
                                  y2="0"
                                  strokeWidth={1}
                                  stroke="#FF6633" 
                                />
                              </Svg>
                            </View>
                              {/* map Education */}
                              <View>
                                { data.Education?.map((E_data, key) => (
                                  <View style={{width: 150, marginTop: 10}}>
                                    <Text style={{ fontFamily: "Poppins-bold", fontSize: 8 }} >{E_data.End_date}</Text>
                                      <View style={{ width: 150, display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                                        <Text style={{ fontFamily: "Poppins", fontSize: 8 }}>{E_data.School}</Text>
                                      </View>

                                      <View style={{ display: "flex", flexDirection: "row"}} >
                                        <Text style={{ fontFamily: "Poppins", fontSize: 8, marginTop: 5, display: "flex", flexDirection: "row", flexWrap: "wrap",  }}>{E_data.Degree} {E_data.Course} </Text>
                                      </View>
                                  </View>
                                ))}
                            </View>
                          </View>
                        }
                        {/* Education section end */}
        
                        {/* skills section start */}
                          {data.Technical_skills?.length === 0 || data.Personal_skills?.length === 0 ? null : 
                          <View style={{marginTop: 20}}>
                              {
                              data.Lang[0]?.lang === 'English' ? <Text style={{ fontFamily: "Poppins-bold", fontSize: 10}}>Skills</Text> : <Text style={{ fontFamily: "Poppins-bold", fontSize: 10}}> Habilidades </Text> 
                              }
                                              
                            <View style={{marginTop: 2, marginLeft: 0}}>
                              <Svg height="2" width="30">
                                <Line x1="0"
                                  y1="0"
                                  x2="200"
                                  y2="0"
                                  strokeWidth={1}
                                  stroke="#FF6633" 
                                />
                              </Svg>
                            </View>
                            {/* map the user skills */}
                            { data.Technical_skills?.length === 0 ? null : 
                            <View>
                              {
                              data.Lang[0]?.lang === 'English' ? <Text style={{fontFamily: 'Poppins-bold', fontSize: 10, marginTop: 10}}>Technical skills</Text> : <Text style={{fontFamily: 'Poppins-bold', fontSize: 10, marginTop: 10}}> Habilidades Técnicas </Text> 
                              }
                              
                              { data.Technical_skills?.map((T_data, key) => (
                                <View style={{width: 150, marginTop: 2}} key={key}>
                                  {T_data.value !== undefined ? <Text style={{ color: "#000000",fontSize: 8, fontFamily: "Poppins",}}>{T_data.value}</Text> : <Text style={{ color: "#000000",fontSize: 8, fontFamily: "Poppins",}}>{T_data}</Text>}
                                </View> 
                              ))}
                            </View>
                            }
                            { data.Personal_skills?.length === 0 ? null :
                              <View>
                                {
                                data.Lang[0]?.lang === 'English' ? 
                                <Text style={{fontFamily: 'Poppins-bold', fontSize: 10, marginTop: 10}}>Personal skills</Text> : <Text style={{fontFamily: 'Poppins-bold', fontSize: 8, marginTop: 10}}> habilidades personales </Text> 
                                }
                                { data.Personal_skills?.map((P_data, key) => (
                                  <View style={{width: 150, marginTop: 2}} key={key}>
                                    <Text>{P_data.value !== undefined ? <Text style={{ fontFamily: "Poppins", fontSize: 8}}>{P_data.value}</Text> : <Text style={{ fontFamily: "Poppins", fontSize: 8}}>{P_data}</Text> }</Text>
                                  </View> 
                                ))}
                              </View>
                            }
                          </View>
                        }
                        {/* skills section end */}

                        {/* Language section start */}
                        { data.Language?.length === 0 ? null : 
                          <View style={{marginTop: 20}}>
                            {
                            data.Lang[0]?.lang === 'English' ? 
                            <Text style={{fontFamily: "Poppins-bold", fontSize: 12}}>Languages</Text> : <Text style={{fontFamily: "Poppins-bold", fontSize: 12}}> Idiomas </Text> 
                            }
                            { data.Language?.map((L_data, key) => (
                            <View style={{width: 200, marginTop: 10, display: "flex", flexDirection: "row"}} key={key}>
                              <Text style={{fontFamily: "Poppins-bold", fontSize: 8, marginTop:2, marginRight: 3, color: "#000000"}}>{L_data.language} -</Text>
                              <Text style={{fontFamily: "Poppins", fontSize: 8, marginTop: 2, color: "#000000"}}>{L_data.rate}</Text>
                            </View> 
                            ))}
                          </View>
                        }
                        {/* Language sectio end */}
                      </View>
                      {/* left side end */}
        
                      {/* Right side start */}
                      <View style={{marginLeft: 10, display: "flex", flexDirection: 'row', justifyContent: "flex-start",  }}>
                        {/* Experience section start */}
                        <View>
                          { data.Experience?.length === 0 ? null : 
                          <View>
                            <View>
                            {
                              data.Lang[0]?.lang === 'English' ?
                              <Text style={{ fontFamily: "Poppins-bold", fontSize: 10}}>Experience</Text> :  <Text style={{ fontFamily: "Poppins-bold", fontSize: 10}}>Experiencia</Text>
                            }
                              <View style={{marginTop: 2, marginLeft: 0}}>
                                <Svg height="2" width="30">
                                  <Line x1="0"
                                    y1="0"
                                    x2="200"
                                    y2="0"
                                    strokeWidth={1}
                                    stroke="#FF6633" 
                                  />
                                </Svg>
                              </View>
                            </View>
                            {/* note */}
                            <View>
                              {/* map Experience */}
                              { data.Experience?.map((Ex_data, key) => (
                                <View style={{display: "block", marginTop: 10, width: 300}}>
                                  <View style={{display: "flex", flexDirection: "row", marginTop: 5}}>
                                      <View>
                                        <Image src="https://res.cloudinary.com/drlcisipo/image/upload/v1705704130/Website%20images/icons8-star-50_hxtpzx.png" style={{width: 10, height: 10, transform: "rotate(-48.48deg)"}}/>
                                      </View>
                                      <View style={{width: 300, marginLeft: 10,}}>
                                        <View  style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: 300}} >
                                          <Text style={{ fontFamily: "Poppins-bold", fontSize: "8px", }} key={key}>{Ex_data.Where_i_worked} </Text>
                                        </View>
                                        <Text style={{ marginTop: 5, fontFamily: "Poppins-bold", fontSize: "8px"}}>{Ex_data.Work_start_date} - {Ex_data.Work_end_date}</Text>
                                        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: 300 }} >
                                          <Text style={{fontFamily: "Poppins", marginTop: 5, fontSize: 8, }} key={key}>{Ex_data.My_job_title} </Text>
                                        </View>
                                        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: 280, marginTop: 2 }}>
                                          {Array.isArray(Ex_data.Day_to_day_work) === true ? 
                                          <View>
                                            {Ex_data.Day_to_day_work?.map((exp_text, key) => (
                                            <View style={{ display: "flex", flexDirection: "row", }}> 
                                              <View>
                                                <Text style={{ marginRight: 5, marginTop: -5 }}>•</Text>
                                              </View>
                                              <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", }} key={key}>
                                                {exp_text[0] === "-" || exp_text[0] == "•" || exp_text[0] == "," ? <Text style={{ fontFamily: "Poppins", fontSize: 8, color: "#000000", marginTop: 2 }} >{exp_text.slice(1, exp_text?.length).trim()}</Text> : <Text style={{ fontFamily: "Poppins", fontSize: 8, color: "#000000", marginTop: 2 }} >{exp_text.replace(/^(\d[^\w\s]*|[^\w\s])+/g, '').trim()}</Text>}
                                              </View>
                                            </View>
                                          ))}
                                          </View> : <View>
                                            <Text style={{ fontFamily: "Poppins", fontSize: 8, color: "#000000" }} >{Ex_data.Day_to_day_work.replace(/^(\d[^\w\s]*|[^\w\s])+/g, '').trim()} </Text>
                                          </View>
                                        }
                                        </View>
                                      </View>
                                  </View>
                                </View>
                              ))}
                            </View>
                          </View>
                          }
                        {/* Experience section end */}
        
                        {/* Achievement section start */}
                        {data.Award?.length !== 0 && data.Award[0]?.Award_name.length !== 0 ? 
                        <View>
                          { data.Award[0]?.Award_name === null ? null : 
                            <View style={{display: "block", marginTop: 20, marginLeft: 0}}>
                            <View style={{marginTop: 0}}>
                            {
                              data.Lang[0]?.lang === 'English' ?
                              <Text style={{ fontFamily: "Poppins-bold", fontSize: 10, marginLeft: 0}}>Achievement</Text> : <Text style={{ fontFamily: "Poppins-bold", fontSize: 10, marginLeft: 0 }}>Logro</Text>
                            }
                              <View style={{marginTop: 2, marginLeft: 0}}>
                                <Svg height="2" width="30">
                                  <Line x1="0"
                                    y1="0"
                                    x2="200"
                                    y2="0"
                                    strokeWidth={1}
                                    stroke="#FF6633"
                                  />
                                </Svg>
                              </View>
                              {/* Map Achievement */}
                              { data.Award?.map((A_data, key) => (
                                <View style={{marginTop: 15}}  key={key}>
                                  <Text style={{marginLeft: 20, fontFamily: "Poppins-bold", fontSize: "8px"}}>{A_data.Award_year} - {A_data.Award_name}</Text>
                                  <Text style={{marginLeft: 20, marginTop: 2, fontFamily: "Poppins", width: 220, fontSize: 8, }}>{A_data.Award_giver}</Text>
                                </View>
                              ))}
                            </View>
                          </View>
                        }
                        </View> : null
                        }
                        </View>
                        {/* Achievement section end */}
                      </View>
                      {/* Right side end */}
                    </View>
                    {/* Address section end */}
                  </View> 
                  
              ))
            }
          </Page>
        </Document>
       
  )
}

export default BlueTalent