import React, { useEffect, useState, useCallback } from 'react'
import { ToastContainer, toast } from "react-toastify";
import axios from 'axios';
import LoaderModal from '../common/LoaderModal';
import BackButton from '../common/back_button';
import ReactHtmlParser from 'react-html-parser';
import { baseURL, baseURL1 } from '../../Redux/baseURL';
import HelmetProvide from '../common/helmet';
import timeResolver from './timeresolver';
import { useSelector, useDispatch } from "react-redux/es";
import { useParams, useNavigate } from 'react-router-dom';
import {
  JobDurationSvg,
  JobTitleSvg,
  JobTypeSvg,
  LocationSvg,
} from "../common/svgs/svgs";
import {
  signInFunction,
  userGoogleAuth,
  userLinkedinAuth,
  clearLogin,
  login_failed,
  login_loading,
  savelink
} from "../../Redux/ActionCreator";
import PreviewButton from '../common/preview_components/preview_button';
import { ShareIcon } from '../common/svgs/svgs';
import { createPortal } from 'react-dom';
import LoginForm from './loginForm';
import { useAnalytics } from '../../context/AnalyticsContext';
import TranslateButton from '../translate_btn';
import SignupForm from '../common/signup_form';
// import FormLogin from '../Login/index';

function PublicJobDetails() {
  const analytics = useAnalytics();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [job, setJob] = useState();
  const [loginModal, setLoginModal] = useState(false);
  const Job = useParams();
  let title = new URLSearchParams(window.location.search).get("title");
  let location = new URLSearchParams(window.location.search).get("location");

  // create a state to handle the form value
  const [cred, setCred] = useState({ username: "", password: "", confirmPassword: "", AcctType: "Client", });

  useEffect(() => {
    if (title == null) title = "";
    if (location == null) location = "";
    const getJob = async () => {
      fetch(baseURL + `/api/job/getAjob/${Job.jobName}`, {
        headers: {
          "Content-Type": "application/json",
        }
      })
      .then((resp) => resp.json())
      .then(resp => {
        // console.log("resp", resp);
        setJob(resp.data);
        return
      }).catch(err => {
        if (err.message == "timeout exceeded") {
          getJob();
        }
      })
    }

    getJob();
  }, []);

  useEffect(() => {
    axios.patch(`${baseURL}/api/job/clicks/${Job.jobName}`)
      .then(response => {
        // console.log('Data:', response.data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
      axios.patch(`${baseURL}/api/job/impressions/${Job.jobName}`)
      .then(response => {
        // console.log('Data:', response.data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, [])

  // submit function
  const handleSubmit = useCallback(
    () => {
      if (cred.username === "") {
        setTimeout(() => {
          toast.error("Username is required", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }, 50);
      } else if (cred.password === "") {
        setTimeout(() => {
          toast.error("Password is required", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }, 50);
      } else if (cred.confirmPassword === "") {
        setTimeout(() => {
          toast.error("Confirm Password is required", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }, 50);
      } else {
        dispatch(signInFunction(cred));
        analytics.track("Login", {
          Type: "Employee login",
          time: new Date(),
          Email: cred.username
        });
        setCred((prev) => ({ ...prev, username: "", password: "", AcctType: "Client" }));
      }
    }, []
  )

  const displayLoginModal = loginModal &&
    createPortal(
      <div className="fixed top-0 left-0 bottom-0 right-0 z-10 bg-tet-600 overflow-y-scroll min-h-screen py-7 backdrop-blur-[2px] flex justify-center items-center">
        {/* <LoginForm link={`/jobs/${Job.jobName}`} closeModal={() => setLoginModal(false)} /> */}
        <SignupForm type="employee" googleSignup={() => dispatch(userGoogleAuth())} username={cred.username} password={cred.password} confirmPassword={cred.confirmPassword} setUserCred={setCred} handleSubmit={handleSubmit} link={`/jobs/${Job.jobName}`} />
        {/* <FormLogin type="employee" handleSubmit={handleSubmit} username={cred.username} password={cred.password} setCred={setCred} googleLogin={() => dispatch(userGoogleAuth())}  /> */}
      </div>, document.getElementById('modal'));

  const copyToClipboard = async () => {
    try {
     // console.log("analytics", analytics);
      await navigator.clipboard.writeText(window.location.href);
      toast.success('Link copied to clipboard!', { position: toast.POSITION.TOP_CENTER });
      analytics.track("Web job share before login", {
        Type: "Pre-login Job share web",
        time: new Date(),
      });
    } catch (error) {
      console.error('Unable to copy to clipboard:', error);
      toast.error('Failed to copy link to clipboard.', { position: toast.POSITION.TOP_CENTER });
    }
  };

  const shareMobileLink = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Share Link',
          text: 'Check out this link!',
          url: window.location.href,
        });
        analytics.track("Mobile job share before login", {
          Type: "Pre-login Job share mobile",
          time: new Date(),
        });
      } catch (error) {
        console.error('Error sharing link:', error);
      }
    } else {
      // Fallback for browsers that don't support navigator.share
      console.warn('navigator.share is not supported.');
      // You can provide a fallback UI or inform the user about alternative ways to share.
    }
  };

  const shareLink = async () => {
    const screenwidth = window.innerWidth;
    // console.log(screenwidth);

    screenwidth < 1024 ? await shareMobileLink() : await copyToClipboard();
  }

  return (
    <>
      <HelmetProvide
        title={`Moil | ${job?.jobTitle} at ${job?.companyName}`}
        description={`Explore and apply for the ${job?.jobTitle} position  at ${job?.companyName}.`}
        image={job?.logo}

      />
      {job === undefined ? <LoaderModal text="Loading" /> :
        <div className="px-3 lg:pl-6 py-8 w-full flex justify-center h-screen overflow-y-scroll">
          <div className="flex flex-col">
            {/* <BackButton onClick={() => window.history.back()} /> */}
            <div className='flex justify-center mb-2'>
              <TranslateButton className="flex items-center gap-0" />
            </div>
            <div className="flex flex-col w-full gap-y-10 xtraxl:max-w-calculate max-w-[800px] pb-10 border-b border-indigo-400 border-opacity-30">
              <div className="bg-white bg-opacity-80 rounded-lg shadow border border-indigo-400 border-opacity-30 py-8 px-4 sm:px-12 gap-y-8 flex flex-col">
                <p>
                  <span className="text-gray-600 text-base font-medium">
                    Posted{" "}
                  </span>
                  <span className="text-indigo-700 text-base font-medium">
                    {job?.indeed == true ? <>{job?.posted_on}</> : <>{timeResolver(job?.createdAt)}</>}
                  </span>
                  <span className="text-gray-600 text-base font-medium"> </span>
                </p>
                <div className="flex flex-col gap-4">
                  <div className="text-indigo-700 text-2xl font-semibold">
                    {`Professional ${job?.jobTitle}`}
                  </div>
                  <div className="w-full justify-start items-start gap-4 flex flex-wrap">
                    <div className="flex gap-x-2 flex-none">
                      <JobTitleSvg />
                      <p className="text-slate-800 text-opacity-80 text-sm font-medium">
                        {`${job?.jobTitle}`}
                      </p>
                    </div>
                    <div className="flex gap-x-2 flex-none">
                      <JobTypeSvg />
                      <p className="text-slate-800 text-opacity-80 text-sm font-medium">
                        {job?.jobType}
                      </p>
                    </div>
                    <div className="flex gap-x-2 flex-none">
                      <LocationSvg />
                      <p className="text-slate-800 text-opacity-80 text-sm font-medium">
                        {`${job?.location?.city}, ${job?.location?.state}.`}
                      </p>
                    </div>
                    {job?.applicationDeadline !== undefined ?
                      <div className="flex gap-x-2">
                        <JobDurationSvg />
                        <p className="whitespace-normal">
                          <span className="text-slate-800 text-opacity-80 text-sm font-medium">
                            Recruiting until{" "}
                          </span>
                          <span className="text-indigo-700 text-sm font-semibold">
                            {job?.applicationDeadline}
                          </span>
                        </p>
                      </div> : null}

                  </div>
                </div>
                <div className="w-full justify-between items-center flex">
                  <button
                    className="px-5 py-3 bg-indigo-700 rounded-lg shadow justify-start items-start gap-2.5 flex focus:bg-indigo-700"
                    onClick={() => setLoginModal(true)}
                  >
                    <span className="text-center text-white text-base font-semibold">
                      Apply Now
                    </span>
                  </button>

                  {/* This is the public share job button */}
                  <button
                    className="px-5 py-3 bg-indigo-700 rounded-lg shadow justify-start items-start gap-2.5 flex focus:bg-indigo-700"
                    onClick={shareLink}
                  >
                    <ShareIcon className="fill-[#fff]" />
                    <span className="text-center text-white text-base font-semibold">
                      Share
                    </span>
                  </button>

                </div>
              </div>
              {job?.companyAbout !== undefined ? (
                <div className="bg-white bg-opacity-80 rounded-lg shadow border border-indigo-400 border-opacity-30 py-8 px-4 sm:px-12 gap-y-8 flex flex-col">
                  <p className="text-gray-600 text-base font-semibold">
                    About this business
                  </p>
                  <div className="flex flex-col md:flex-row justify-between gap-x-8 gap-y-4 max-w-[1280px]">
                    <div className="flex gap-2 min-w-[150px]">
                      <img src={job?.logo} className="w-5 h-5" />
                      <div>
                        <p className="text-indigo-700 text-sm font-semibold font-['Poppins']">
                          {`${job?.companyName}`}
                        </p>
                        <ul className="">
                          <li>{job?.companyBuinsessType}</li>
                          <li>{`${job?.location.city}, ${job?.location.state}.`}</li>
                          <li>{`${job?.companyEmployeeRange} employees`}</li>
                        </ul>
                      </div>
                    </div>
                    <div className="flex flex-col gap-6">
                      <div className="w-full lg:max-w-[400px] xtraxl:max-w-[1024px] text-gray-600 word-spacing-2 text-sm font-medium">
                        {job?.companyAbout}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="px-4 sm:px-12 py-8 bg-white bg-opacity-80 rounded-lg shadow border border-indigo-400 border-opacity-30 flex-col justify-start items-start gap-8 inline-flex">
                <p className="text-indigo-700 text-2xl font-semibold font-['Poppins']">
                  Job Details
                </p>
                <div className="justify-start items-start inline-flex">
                  <div className="flex-col justify-start items-start gap-2 inline-flex">
                    <p className="text-indigo-700 text-sm font-semibold font-['Poppins']">
                      Job Description
                    </p>
                    <p className="text-gray-600 text-sm font-medium word-spacing-2 font-['Poppins']">
                      {ReactHtmlParser(job?.jobDescription)}
                    </p>
                  </div>
                </div>
                {job?.indeed == "true" || job?.indeed == true ? null :
                  <div className="justify-start items-start inline-flex">
                    <div className="flex-col justify-start items-start gap-2 inline-flex">
                      <div className="text-indigo-700 text-sm font-semibold font-['Poppins']">
                        Key Responsibilities
                      </div>
                      <ul className="flex flex-col gap-2 text-gray-600 text-sm font-medium font-['Poppins'] list-disc  word-spacing-2">
                        {job?.responsibilities?.map((responsibility, key) => {
                          return <li key={key}>{ReactHtmlParser(responsibility)}</li>;
                        })}
                      </ul>
                    </div>
                  </div>
                }
                {job?.indeed == "true" || job?.indeed == true ? null :
                  <div className="justify-start items-start inline-flex">
                    <div className="flex-col justify-start items-start gap-4 inline-flex">
                      <div className="text-indigo-700 text-sm font-semibold">
                        Required Skills
                      </div>
                      <div className="items-center gap-3 inline-flex w-full flex-wrap">
                        {job?.skills?.map((data, key) => (
                          <>
                            {data.value ? (
                              <PreviewButton text={data.value} key={key} />
                            ) : (
                              <PreviewButton text={data} key={key} />
                            )}
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div className="w-full px-4 sm:px-12  py-8 bg-white bg-opacity-80 rounded-lg shadow border border-indigo-400 border-opacity-30 flex-col justify-start items-start gap-8 flex">
                <div className="justify-start items-center inline-flex">
                  <div className="self-stretch flex-col justify-start items-start gap-4 inline-flex">
                    <p className="text-indigo-700 text-xl sm:text-2xl font-semibold font-['Poppins']">
                      Payment Details
                    </p>
                  </div>
                </div>
                <div className="self-stretch justify-start items-start inline-flex">
                  <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
                    <p className="text-indigo-700 text-sm font-semibold font-['Poppins']">
                      Payment Plan
                    </p>
                    <div className="px-4 py-2 bg-white bg-opacity-50 rounded-lg border border-indigo-400 border-opacity-30 justify-center items-center gap-2 inline-flex">
                      <div className="w-4 h-4 bg-red-500 rounded-full border border-indigo-400 border-opacity-30" />
                      <p className="text-black text-base font-normal lowercase">
                        {job?.paymentPlan}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                  <p className="text-indigo-700 text-sm font-semibold font-['Poppins']">
                    Compensation
                  </p>
                  <p className="word-spacing-2 text-gray-600 text-sm font-medium font-['Poppins']">
                    This is the expected salary range for this job.
                  </p>
                  <p className="text-black text-base font-medium font-['Poppins']">
                    {job?.compensation}
                  </p>
                </div>
              </div>
              <div className="max-w-[840px]">
                <span className="text-gray-600 text-sm font-medium font-['Poppins']">
                  Click on{" "}
                </span>
                <span className="text-indigo-700 text-sm font-bold font-['Poppins']">
                  ‘Apply Now’
                </span>
                <span className="text-gray-600 text-sm font-medium font-['Poppins']">
                  {" "}
                  if you are in this job,
                  <br />
                  or click on{" "}
                </span>
                <span className="text-orange-500 text-sm font-bold font-['Poppins']">
                  ‘Save Job’
                </span>
                <span className="text-gray-600 text-sm font-medium font-['Poppins']">
                  {" "}
                  to add it to your job interests.
                </span>
              </div>
              <div className="w-full justify-between items-center flex">
                <button
                  className="px-5 py-3 bg-indigo-700 rounded-lg shadow justify-start items-start gap-2.5 flex"
                  onClick={() => setLoginModal(true)}
                >
                  <span className="text-center text-white text-base font-semibold">
                    Apply Now
                  </span>
                </button>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      }
      {displayLoginModal}
    </>
  );
}

export default PublicJobDetails
