import React, { useState, useEffect, useRef } from 'react';

const TranslateBtnDropdown = (props) => {

  const [text, setText] = useState("Traducir a Español");
  const [show, setShow] = useState(false);
  const [selectedLang, setSelectedLang] = useState('en');
  const [scriptAdded, setScriptAdded] = useState(false);
  const [ english, setEnglish ] = useState("Translate to English");
  const [ spanish, setSpanish ]= useState("Traducir a Español");
  const languageSelectRef = useRef(null);

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        includedLanguages: 'es,en',
        layout: google.translate.TranslateElement.InlineLayout.TEXT,
        autoDisplay: false
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (languageSelectRef.current && !languageSelectRef.current.contains(event.target)) {
        // Click occurred outside the languageSelect div
        // Hide the languageSelect div here, for example:
        languageSelectRef.current.style.display = 'none';
      }
    }

    // Add event listener when component mounts
    window.addEventListener('mousedown', handleClickOutside);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, []); // Empty dependency array to run effect only once

  useEffect(() => {
    if (!scriptAdded) {
      const myTranslateScript = document.querySelectorAll(
        'script[src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"]'
      );

      if (myTranslateScript.length < 1) {
        const addScript = document.createElement("script");
        addScript.setAttribute(
          "src",
          "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        );

        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;

        setScriptAdded(true);
      }
    }
  }, [scriptAdded]);

  const handleEnglishClick = () => {
    const newLang = "en";
    setSelectedLang(newLang);
    translatePage(newLang);
    triggerHtmlEvent(document.querySelector('.goog-te-combo'), 'change');
    // console.log("was clicked")
    setShow(false);
    setText("Traducir a Español")
  }

  const handleSpanishClick = () => {
    const newLang = "es";
    setSelectedLang(newLang);
    translatePage(newLang);
    triggerHtmlEvent(document.querySelector('.goog-te-combo'), 'change');
    // console.log("was clicked");
    setShow(false);
    setText("Translate to English")
  }
  const triggerHtmlEvent = (element, eventName) => {
    // console.log("was implemented")
    let event;
    if (document.createEvent) {
      event = document.createEvent('HTMLEvents');
      event.initEvent(eventName, true, true);
      element.dispatchEvent(event);
    } else {
      event = document.createEventObject();
      event.eventType = eventName;
      element.fireEvent('on' + event.eventType, event);
    }
  };

  const translatePage = (newLang) => {
    if (document.querySelector('.goog-te-combo') == undefined) {
      if ( window.location.pathname == "/") {
        window.location.reload()
      }
    } else {
      document.querySelector('.goog-te-combo').value = newLang;
    }
  };

  const handleShow = () => {
    setShow(true);
    if (languageSelectRef.current) {
      languageSelectRef.current.style.display = 'block';
    }
  }

  return (
    <div className={`${props.className}`}>
        <div id="google_translate_element" className={`hidden ${props.className}`}></div>
        <div className='sm:justify-center'>
          <div className='translateBTN cursor-pointer py-4 px-4 notranslate w-full' onClick={handleShow}>
            {text}
          </div>
          { show && (
            <div ref={languageSelectRef} id="languageSelect"  className=' py-4 absolute top-13 bg-white'>
              <p className='option cursor-pointer py-4 px-2 border-white  notranslate' onClick={handleEnglishClick}>{english}</p>
              <p className='option cursor-pointer py-4 px-2 border-white notranslate' onClick={handleSpanishClick}>{spanish}</p>
              {/* Add more options as needed with corresponding language codes and flags */}
            </div>
          )}
        </div>
    </div>
  );
};

export default TranslateBtnDropdown;

