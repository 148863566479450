import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import reference from "../../common/reference";
import { useNavigate } from "react-router-dom";

function EmployerProfileComponent(props) {
  const employer = useSelector((e) => e.employer);
  const profile = employer.message;
  const userPics = useSelector((prev) => prev.userInfo);
  const [picLink, setPicsLink] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (userPics.user !== null) {
      setPicsLink(userPics.user.profile_pics);
    }
  }, [userPics]);
 // console.log("props", props.profileId, props.jobName);
  return (
    <div className="w-full tablet:w-[50%] mb-8 tablet:mb-0 py-8 bg-[#EFECFF] tablet:rounded-2xl flex justify-center">
      {/* div for profile */}
      <div className="flex flex-col items-center gap-4 tablet:gap-9">
        {/* profile photo  */}
        {profile?.photo !== undefined ? (
          <div className="relative h-20 w-20 tablet:h-40 tablet:w-40 rounded-full">
            <img
              src={profile?.photo}
              alt=""
              className="rounded-[16px] border-[5px] border-white h-20 w-20 object-cover tablet:min-h-[158px] tablet:min-w-[158px]"
            />
          </div>
        ) : (
          <div className="relative h-20 w-20 tablet:h-40 tablet:w-40 rounded-full">
            <img
              src={picLink === "" ? reference.profilephoto1 : picLink}
              alt=""
              className="rounded-[16px] border-[5px] border-white h-20 w-20 object-cover tablet:min-h-[158px] tablet:min-w-[158px]"
            />
          </div>
        )}
        <div className=" flex flex-col gap-10 items-center ">
          {/* text content here  */}
          <div className="flex flex-col gap-3 items-center">
            <h2 className="text-center font-semibold tablet:text-2xl text-lg">
              {profile?.name}
            </h2>
            <p className="font-semibold text-base text-[#898989] text-center">
              {profile?.type_of_business}
            </p>
          </div>
          {/* buttons  */}
          <div className="mt-4 tablet:mt-0 font-semibold text-base flex-row flex gap-2 tablet:gap-6">
            <button
              onClick={() => navigate(`/user/available_jobs?id=${props.profileId}&jid=${props.jobName}`)}
              className=" bg-[#5843BD] border border-[#5843BD] hover:text-[#5843BD] hover:bg-[#E1DCF9] px-5 py-3 rounded-2xl text-[#ffffff] whitespace-nowrap"
            >
              View Available Jobs
            </button>
            {/* <button className=" text-xs tablet:text-base bg-[#5843BD] border border-[#5843BD] hover:text-[#5843BD] hover:bg-[#E1DCF9] px-[10px] tablet:px-5 py-[6.55px] tablet:py-3 rounded-2xl text-[#ffffff] whitespace-nowrap">
              View Available Jobs
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployerProfileComponent;