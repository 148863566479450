import React from "react";

function HorizontalNFilterIcon() {
  return (
    <div className=" relative">
      <hr className=" my-2 h-[2px] w-full bg-[#777DEA4D] bg-opacity-30" />
      <div className="absolute top-[50%] translate-y-[-50%] left-[26px]">
        <svg
          width="26"
          height="24"
          viewBox="0 0 26 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.3276 3.42947H25.0001"
            stroke="black"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M1 3.42947H16.9957"
            stroke="black"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M19.6686 5.85894C21.1445 5.85894 22.341 4.77123 22.341 3.42947C22.341 2.08771 21.1445 1 19.6686 1C18.1926 1 16.9961 2.08771 16.9961 3.42947C16.9961 4.77123 18.1926 5.85894 19.6686 5.85894Z"
            stroke="black"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M22.3276 20.4596H25.0001"
            stroke="black"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M1 20.4596H16.9957"
            stroke="black"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M19.6686 22.889C21.1445 22.889 22.341 21.8013 22.341 20.4596C22.341 19.1178 21.1445 18.0301 19.6686 18.0301C18.1926 18.0301 16.9961 19.1178 16.9961 20.4596C16.9961 21.8013 18.1926 22.889 19.6686 22.889Z"
            stroke="black"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.00439 11.9445H25.0001"
            stroke="black"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M1 11.9445H3.67243"
            stroke="black"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.33161 14.374C7.80755 14.374 9.00408 13.2863 9.00408 11.9445C9.00408 10.6028 7.80755 9.51505 6.33161 9.51505C4.85567 9.51505 3.65918 10.6028 3.65918 11.9445C3.65918 13.2863 4.85567 14.374 6.33161 14.374Z"
            stroke="black"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  );
}

export default HorizontalNFilterIcon;
