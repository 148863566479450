import React, { useEffect, useState, useRef } from "react";
import { createPortal } from "react-dom";
import Avatar from "@mui/material/Avatar";
import { Link } from "react-router-dom";
import SideBar from "../sidebar/sidebar";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { notification_success } from "../../Redux/ActionCreator"
import NotificationModal from "../common/Notification/NotificationModal";
// import GoogleTranslate from "./googleTranslate";
import TranslateButton from "../translate_btn";
import { baseURL } from "../../Redux/baseURL";
import { NotificationSvg, NoNotificationSvg } from "../common/svgs/svgs";
import { Popover } from "@mui/material";

const TopBar = (props) => {
  const navigate = useNavigate();
  const t_lang = useSelector((lg) => lg.t_lang);
  const profile_stat = useSelector((prev) => prev.login);
  const employee = useSelector((e) => e.profile.message);
  const employer = useSelector((e) => e.employer.message);
  const msg_notification = useSelector(e => e.msg_notification);
  const trans = useSelector((e) => e.t_lang);
  const dispatch = useDispatch();
  const [anchorEL, setAnchorEL] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const open = Boolean(anchorEL);
  const [showNot, setShowNot] = useState(false);
  const notify = useSelector(e => e.notification);
  let notification_list = notify.message;
  const sidebar = useSelector(e => e.sidebarOpen.message);
  const handleClick = (event) => {
    setAnchorEL(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEL(null);
  };
  const id = open ? 'notification-popover' : undefined;

  // function to toggle the mobile navigation
  const handleToggleMenu = () => {
    setShowMenu(!showMenu);
  };



  // get decision card from database
  const getDecisionCards = async () => {
    try {
      const response = await fetch(baseURL + '/api/decision/getDecisionCards', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        credentials: "include"
      });
      const data = await response.json();
      if (response.ok) {
        // handle the data as needed
        // console.log("Decision card", data.data);
        dispatch(notification_success(data.data));
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      // console.error('Error:', error);
    }
  };


  useEffect(() => {
    if (profile_stat.profile_status.AcctType === "Client") {
      // console.log("Called decision card notification");
      getDecisionCards();
    }
  }, [])

  // const handleClose = (lg) => {
  //   handleLanguageChange(lg);
  //   dispatch(dispatchT_Language(lg));

    // console.log("language selected", lg);
  //   setTimeout(() => {
  //     console.log(language["Submit"]);
  //   }, 3000);

  //   setAnchorEL(null);
  // };

  const userPics = useSelector((prev) => prev.userInfo);
  const [picLink, setPicsLink] = useState("");
  const popupRef = useRef(null);
  const NotRef = useRef(null);

  useEffect(() => {
    // dispatch(LanguageTranslate_loading(true));
    if (profile_stat.profile_status?.AcctType === "Client") {
      if (employee !== null && employee?.photo !== undefined) {
        setPicsLink(employee?.photo);
      } else {
        if (userPics.user !== null) {
          setPicsLink(userPics.user?.profile_pics);
        }
      }
    }
  }, [profile_stat.profile_status.AcctType, userPics]);

  // This allow the user to click any part of their screen to close the mobile navigation
  useEffect(() => {
    // function that allow the user to close their mobile navigation
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleToggleMenu();
      }
    };

    const handleNotClickOutside = (event) => {
      if (NotRef.current && !NotRef.current.contains(event.target)) {
        setShowNot(!showNot);
      }
    };

    if (showNot) {
      document.addEventListener("mousedown", handleNotClickOutside);
    } else {
      document.removeEventListener("mousedown", handleNotClickOutside);
    }

    // monitor the user action on the navigation bar and close the bar
    if (showMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("mousedown", handleNotClickOutside);

    };
  }, [showMenu, handleToggleMenu, showNot]);

  // Serves as a portal for our mobile navigation to get to the <div id="sidebarMobile"></div> in index.html 
  const sidebarModal = showMenu
    ? createPortal(
      <div
        className="absolute z-[11111] max-w-[280px] bg-white rounded-xl right-0 top-[45px] md:hidden"
        ref={popupRef}
      >
        <SideBar isForMenu={true} />
      </div>,
      document.getElementById("sidebarMobile")
    )
    : null;

  // console.log(showMenu);
  return (
    <header className="flex justify-between items-center px-8 py-3 border-b-[1.5px] border-sec-300 col-start-1 col-end-3 row-start-1 row-end-2 max-w-[100vw]">
      {profile_stat.profile_status?.AcctType === "Client" ? (
        <Link
          to={
            (profile_stat.profile_status?.profile_status === true || profile_stat.profile_status?.profile_status === "true") &&
              profile_stat.profile_status?.AcctType === "Client"
              ? "/user/dashboard"
              : "/user/dashboard"
          }
        >
          <figure>
            <img
              src="https://res.cloudinary.com/drlcisipo/image/upload/v1705704261/Website%20images/logo_gox0fw.png"
              alt="Moil Logo"
              className={`w-[50px] ${sidebar ? 'cont:w-[100px]' : ''}`}
            />
            <figcaption className="sr-only">Moil Logo</figcaption>
          </figure>
        </Link>
      ) : (
        null
      )}

      {/* ipad:left-[90%] xtraxl:left-[94%] xxlarge:left-[95%] */}
      <div className="flex justify-between items-center gap-5 nav-right">
        <>
          {profile_stat.profile_status.AcctType === "Client" ?
            <>
              <button id="notification-bell" aria-describedby={id} className="px-2 py-2 ml-[20px] rounded-full bg-white bg-indigo-400 border relative border-indigo-400 border-opacity-50 border-4" onClick={handleClick}>
                {notification_list?.length > 0 ?
                  <>
                    <NotificationSvg />
                    <p className="absolute right-[-5px] top-[-5px] w-[24px] h-[24px] text-white bg-green-400 rounded-full flex items-center justify-center text-sm">{notification_list?.length}</p>
                  </>
                  : <NoNotificationSvg />}
              </button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEL}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
              >
                <NotificationModal />
              </Popover></> :
            null
          }
        </>
        <div className="">
          <TranslateButton className='flex items-center' />
        </div>
        <figure className="xsm:mr-3 sm:mr-2 mr-0">
          <Avatar alt="Remy Sharp" src={picLink} />
        </figure>
        <div>
          <div className="flex items-center">
            <div
              className={`${props.topBarShowMenu === true ? "visible" : "hidden"
                } md:hidden  mr-3`}
            >
              {/* <TranslateIcon
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              /> */}
            </div>

            {props.topBarShowMenu && (
              <button id="mobile-toggle" className="md:hidden relative" onClick={handleToggleMenu}>
                <svg
                  width="12"
                  height="10"
                  viewBox="0 0 12 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 0.75C0 0.551088 0.0790175 0.360322 0.21967 0.21967C0.360322 0.0790175 0.551088 0 0.75 0H11.25C11.4489 0 11.6397 0.0790175 11.7803 0.21967C11.921 0.360322 12 0.551088 12 0.75C12 0.948912 11.921 1.13968 11.7803 1.28033C11.6397 1.42098 11.4489 1.5 11.25 1.5H0.75C0.551088 1.5 0.360322 1.42098 0.21967 1.28033C0.0790175 1.13968 0 0.948912 0 0.75ZM0 4.75C0 4.55109 0.0790175 4.36032 0.21967 4.21967C0.360322 4.07902 0.551088 4 0.75 4H11.25C11.4489 4 11.6397 4.07902 11.7803 4.21967C11.921 4.36032 12 4.55109 12 4.75C12 4.94891 11.921 5.13968 11.7803 5.28033C11.6397 5.42098 11.4489 5.5 11.25 5.5H0.75C0.551088 5.5 0.360322 5.42098 0.21967 5.28033C0.0790175 5.13968 0 4.94891 0 4.75ZM0 8.75C0 8.55109 0.0790175 8.36032 0.21967 8.21967C0.360322 8.07902 0.551088 8 0.75 8H11.25C11.4489 8 11.6397 8.07902 11.7803 8.21967C11.921 8.36032 12 8.55109 12 8.75C12 8.94891 11.921 9.13968 11.7803 9.28033C11.6397 9.42098 11.4489 9.5 11.25 9.5H0.75C0.551088 9.5 0.360322 9.42098 0.21967 9.28033C0.0790175 9.13968 0 8.94891 0 8.75Z"
                    fill="#AAAAAA"
                  />
                </svg>
                <div className="absolute max-w-[280px] bg-white rounded-xl right-0 top-[10px] ">
                  {sidebarModal}
                </div>
              </button>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default TopBar;
