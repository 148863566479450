import React from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BackButton from "../back_button";
import { useNavigate } from "react-router-dom";

const SendMailModal = (props) => {
    const navigate = useNavigate();
    return (
        <div className="w-full h-[100vh] bg-transparent flex justify-center ">
            <div className="w-full flex md:items-center justify-center">
                <div className="bg-white md:rounded-xl md:w-[45vw] md:h-[350px] py-8 px-4 w-full xsm:h-[420px] ">
                    <BackButton onClick={() => navigate("/login")} />
                    <div className="flex items-center justify-center pt-4 pb-6">
                        <CheckCircleIcon style={{ color: "#5843BD", width: "50px", height: "50px" }} />
                    </div>
                    <h1 className="text-xl vsm:text-md font-semibold text-[#5843BD] text-center pb-6">We Sent you a link.</h1>
                    <p className="text-center text-base text-black font-medium">Check your email and open the link we <br />{props.signup ? 'sent you to verify your account' : 'sent you to reset your password.'}</p>
                    <div className="flex items-center justify-center pt-6">
                       <p className="text-center text-xs text-black font-medium">Check <span className="text-[#5843BD] font-base font-500">spam folder</span> if you can find the mail in your primary folder</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SendMailModal;