export const Languages = [
  {
    value: 'English',
    label: 'English',
  },
  {
    value: 'Spanish',
    label: 'Spanish',
  },
  {
    value: 'Mandarin Chinese',
    label: 'Mandarin Chinese',
  },
  {
    value: 'Hindi',
    label: 'Hindi',
  },
  {
    value: 'Arabic',
    label: 'Arabic',
  },
  {
    value: 'Bengali',
    label: 'Bengali',
  },
  {
    value: 'Russian',
    label: 'Russian',
  },
  {
    value: 'Portuguese',
    label: 'Portuguese',
  },
  {
    value: 'Indonesian',
    label: 'Indonesian',
  },
  {
    value: 'Urdu',
    label: 'Urdu',
  },
  {
    value: 'Standard German',
    label: 'Standard German',
  },
  {
    value: 'Japanese',
    label: 'Japanese',
  },
  {
    value: 'Swahili',
    label: 'Swahili',
  },
  {
    value: 'Telugu',
    label: 'Telugu',
  },
  {
    value: 'Marathi',
    label: 'Marathi',
  },
  {
    value: 'Turkish',
    label: 'Turkish',
  },
  {
    value: 'Tamil',
    label: 'Tamil',
  },
  {
    value: 'Vietnamese',
    label: 'Vietnamese',
  },
  {
    value: 'Korean',
    label: 'Korean',
  },
  {
    value: 'Italian',
    label: 'Italian',
  },
  {
    value: 'Yue Chinese',
    label: 'Yue Chinese',
  },
  {
    value: 'Javanese',
    label: 'Javanese',
  },
  {
    value: 'Gujarati',
    label: 'Gujarati',
  },
  {
    value: 'Polish',
    label: 'Polish',
  },
  {
    value: 'Ukrainian',
    label: 'Ukrainian',
  },
  {
    value: 'Persian',
    label: 'Persian',
  },
  {
    value: 'Xiang Chinese',
    label: 'Xiang Chinese',
  },
  {
    value: 'Malayalam',
    label: 'Malayalam',
  },
  {
    value: 'Sunda',
    label: 'Sunda',
  },
  {
    value: 'Pashto',
    label: 'Pashto',
  },
  {
    value: 'Kannada',
    label: 'Kannada',
  },
  {
    value: 'Xhosa',
    label: 'Xhosa',
  },
  {
    value: 'Hausa',
    label: 'Hausa',
  },
  {
    value: 'Odia',
    label: 'Odia',
  },
  {
    value: 'Burmese',
    label: 'Burmese',
  },
  {
    value: 'Hakka Chinese',
    label: 'Hakka Chinese',
  },
  {
    value: 'Uyghur',
    label: 'Uyghur',
  },
  {
    value: 'Amharic',
    label: 'Amharic',
  },
  {
    value: 'Fula',
    label: 'Fula',
  },
  {
    value: 'Romanian',
    label: 'Romanian',
  },
  {
    value: 'Oromo',
    label: 'Oromo',
  },
  {
    value: 'Maithili',
    label: 'Maithili',
  },
  {
    value: 'Saraiki',
    label: 'Saraiki',
  },
  {
    value: 'Bhojpuri',
    label: 'Bhojpuri',
  },
  {
    value: 'Azerbaijani',
    label: 'Azerbaijani',
  },
  {
    value: 'Dutch',
    label: 'Dutch',
  },
  {
    value: 'Yoruba',
    label: 'Yoruba',
  },
  {
    value: 'Sindhi',
    label: 'Sindhi',
  },
  {
    value: 'Bulgarian',
    label: 'Bulgarian',
  }
];

export const Rate = [
  {
    value: 'Beginner',
    label: 'Beginner',

  },
  {
    value: 'Intermediate',
    label: 'Intermediate ',
  },
  {
    value: "Conversational",
    label: "Conversational"
  },
  {
    value: 'Native',
    label: 'Native ',
  },
  {
    value: 'Professional',
    label: 'Professional ',
  }
]

export const Rate_sp = [
  {
    value: 'Principiante',
    label: 'Principiante',

  },
  {
    value: 'Intermedio',
    label: 'Intermedio ',
  },
  {
    value: 'Nativo',
    label: 'Nativo ',
  },
  {
    value: 'Profesional',
    label: 'Profesional ',
  }
]

export const Langu = [
  {
    value: "English",
    label: "English"
  },
  {
    value: "Spanish",
    label: "Spanish"
  }
]