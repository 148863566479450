import { useNavigate } from "react-router-dom";
import timeResolver from "../../Job/timeresolver";

export default function JobInterest({
  logo,
  jobTitle,
  createdAt,
  companyName,
  jobUrl
}) {

  const navigate = useNavigate();

  return (
    <div onClick={() => navigate(jobUrl)} className="w-full px-6 py-3 bg-gradient-to-r from-indigo-50 to-indigo-50 rounded-[9.37px] shadow border gap-4  justify-between items-center flex">
      <div className="flex gap-x-4 items-center">
        <div className="w-10 h-10 rounded-lg flex-none">
          <img
            className="h-full rounded-md w-full border-2 border-white"
            src={logo}
          />
        </div>
        <div className="flex-col justify-start items-start gap-[5.35px] inline-flex ">
          <p className="text-indigo-700 text-base font-semibold font-['Poppins']">
            {jobTitle}
          </p>
          <div>
            <p className="text-gray-600 text-[10px] font-medium font-['Poppins'] hidden sm:block xl:hidden xtraxl:block">
              Posted {` ${timeResolver(createdAt)}`} by {`${companyName}`}
            </p>
            <p className="text-indigo-700 text-[10px] font-medium font-['Poppins']">
              {`${companyName}`}
            </p>
          </div>
        </div>
      </div>
      <button className="px-2 py-2 bg-zinc-800 rounded-sm focus:bg-zinc-800">
        <svg
          width="32"
          height="32"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.7666 10.2559H16.0138"
            stroke="white"
            stroke-width="1.60674"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.3916 4.63477L16.0152 10.2584L10.3916 15.882"
            stroke="white"
            stroke-width="1.60674"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
  );
}
