import React, { useState, useRef } from "react";

export default function ResetForm(props) {

    const [focus, setFocused] = useState({ password: false, confirm: false });
    const [passwordView, setpasswordView] = useState({ password: false, confirm: false });
    const passwordRef = useRef();
    const confirmRef = useRef();

    const handleFocus = (toFocus) => {
        if (toFocus === "password") {
            setFocused({ confirm: false, [toFocus]: true });
            passwordRef.current.focus();
        } else {
            setFocused({ password: false, [toFocus]: true });
            confirmRef.current.focus();
        }
    };

    const handlePasswordView = (toView) => {
        setpasswordView({ ...focus, [toView]: !passwordView[toView] });
    };

    const goBack = () => {
        window.history.back();
    };

    return (
        <div className="border border-pry-900 border-opacity-10 border-[6px] w-full max-w-[523px] py-4 bg-white rounded-2xl border-pry-900 bg-opacity-100 border-opacity-10 px-8 sm:px-10 lg:px-16">
            <div className="flex flex-col gap-4">
                <div>
                    <img src="https://res.cloudinary.com/drlcisipo/image/upload/v1705704280/Website%20images/small_logo_sw9gv9.png" alt="Logo Image" />
                </div>
                <p class="font-poppins text-[24px] font-semibold leading-36 tracking-normal text-left">
                    Reset your password
                </p>

                <p class="font-poppins text-xs font-normal leading-18 tracking-normal text-left">
                    Enter a new password and make sure you don’t forget.
                </p>

            </div>
            <div className="flex flex-col gap-4">

                <div>
                    <label class="font-poppins text-xs font-medium leading-4 tracking-normal text-left" for="password" onClick={() => handleFocus("password")}>
                        Password
                    </label>

                    <div className={`border-solid border-indigo-400 w-full border-opacity-50 border ${focus.password ? "ring-2 ring-indigo-400" : ""} rounded-[6px] flex gap-2 w-full items-center px-3`}>
                        <input ref={passwordRef} type={`${passwordView.password ? "password" : "text"}`} id="password" class="w-full border-none outline-none focus:border-transparent focus:outline-transparent focus:ring-0" onClick={() => handleFocus("password")} onChange={(e) => props.cred((prev) => ({
                            ...prev, password: e.target.value
                        }))} value={props.password} />
                        {
                            passwordView.password ?
                                <svg onClick={() => handlePasswordView("password")} xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 cursor-pointer" viewBox="0 0 512 512"><path d="M432 448a15.92 15.92 0 0 1-11.31-4.69l-352-352a16 16 0 0 1 22.62-22.62l352 352A16 16 0 0 1 432 448z" fill="currentColor"></path><path d="M255.66 384c-41.49 0-81.5-12.28-118.92-36.5c-34.07-22-64.74-53.51-88.7-91v-.08c19.94-28.57 41.78-52.73 65.24-72.21a2 2 0 0 0 .14-2.94L93.5 161.38a2 2 0 0 0-2.71-.12c-24.92 21-48.05 46.76-69.08 76.92a31.92 31.92 0 0 0-.64 35.54c26.41 41.33 60.4 76.14 98.28 100.65C162 402 207.9 416 255.66 416a239.13 239.13 0 0 0 75.8-12.58a2 2 0 0 0 .77-3.31l-21.58-21.58a4 4 0 0 0-3.83-1a204.8 204.8 0 0 1-51.16 6.47z" fill="currentColor"></path><path d="M490.84 238.6c-26.46-40.92-60.79-75.68-99.27-100.53C349 110.55 302 96 255.66 96a227.34 227.34 0 0 0-74.89 12.83a2 2 0 0 0-.75 3.31l21.55 21.55a4 4 0 0 0 3.88 1a192.82 192.82 0 0 1 50.21-6.69c40.69 0 80.58 12.43 118.55 37c34.71 22.4 65.74 53.88 89.76 91a.13.13 0 0 1 0 .16a310.72 310.72 0 0 1-64.12 72.73a2 2 0 0 0-.15 2.95l19.9 19.89a2 2 0 0 0 2.7.13a343.49 343.49 0 0 0 68.64-78.48a32.2 32.2 0 0 0-.1-34.78z" fill="currentColor"></path><path d="M256 160a95.88 95.88 0 0 0-21.37 2.4a2 2 0 0 0-1 3.38l112.59 112.56a2 2 0 0 0 3.38-1A96 96 0 0 0 256 160z" fill="currentColor"></path><path d="M165.78 233.66a2 2 0 0 0-3.38 1a96 96 0 0 0 115 115a2 2 0 0 0 1-3.38z" fill="currentColor"></path></svg>
                                :
                                <svg onClick={() => handlePasswordView("password")} xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 cursor-pointer" viewBox="0 0 512 512"><path d="M255.66 112c-77.94 0-157.89 45.11-220.83 135.33a16 16 0 0 0-.27 17.77C82.92 340.8 161.8 400 255.66 400c92.84 0 173.34-59.38 221.79-135.25a16.14 16.14 0 0 0 0-17.47C428.89 172.28 347.8 112 255.66 112z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"></path><circle cx="256" cy="256" r="80" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"></circle></svg>
                        }
                    </div>
                </div>

                <div>
                    <label class="font-poppins text-xs font-medium leading-4 tracking-normal text-left" for="password" onClick={() => handleFocus("confirm")}>
                        Confirm password
                    </label>

                    <div className={`border-solid border-indigo-400 w-full border-opacity-50 border ${focus.confirm ? "ring-2 ring-indigo-400" : ""} rounded-[6px] flex gap-2 w-full items-center px-3`}>
                        <input ref={confirmRef} type={`${passwordView.confirm ? "password" : "text"}`} id="password" class="w-full border-none outline-none focus:border-transparent focus:outline-transparent focus:ring-0" onClick={() => handleFocus("confirm")} onChange={(e) => props.cred((prev) => ({
                            ...prev, confirm: e.target.value
                        }))} value={props.confirm} />
                        {
                            passwordView.confirm ?
                                <svg onClick={() => handlePasswordView("confirm")} xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 cursor-pointer" viewBox="0 0 512 512"><path d="M432 448a15.92 15.92 0 0 1-11.31-4.69l-352-352a16 16 0 0 1 22.62-22.62l352 352A16 16 0 0 1 432 448z" fill="currentColor"></path><path d="M255.66 384c-41.49 0-81.5-12.28-118.92-36.5c-34.07-22-64.74-53.51-88.7-91v-.08c19.94-28.57 41.78-52.73 65.24-72.21a2 2 0 0 0 .14-2.94L93.5 161.38a2 2 0 0 0-2.71-.12c-24.92 21-48.05 46.76-69.08 76.92a31.92 31.92 0 0 0-.64 35.54c26.41 41.33 60.4 76.14 98.28 100.65C162 402 207.9 416 255.66 416a239.13 239.13 0 0 0 75.8-12.58a2 2 0 0 0 .77-3.31l-21.58-21.58a4 4 0 0 0-3.83-1a204.8 204.8 0 0 1-51.16 6.47z" fill="currentColor"></path><path d="M490.84 238.6c-26.46-40.92-60.79-75.68-99.27-100.53C349 110.55 302 96 255.66 96a227.34 227.34 0 0 0-74.89 12.83a2 2 0 0 0-.75 3.31l21.55 21.55a4 4 0 0 0 3.88 1a192.82 192.82 0 0 1 50.21-6.69c40.69 0 80.58 12.43 118.55 37c34.71 22.4 65.74 53.88 89.76 91a.13.13 0 0 1 0 .16a310.72 310.72 0 0 1-64.12 72.73a2 2 0 0 0-.15 2.95l19.9 19.89a2 2 0 0 0 2.7.13a343.49 343.49 0 0 0 68.64-78.48a32.2 32.2 0 0 0-.1-34.78z" fill="currentColor"></path><path d="M256 160a95.88 95.88 0 0 0-21.37 2.4a2 2 0 0 0-1 3.38l112.59 112.56a2 2 0 0 0 3.38-1A96 96 0 0 0 256 160z" fill="currentColor"></path><path d="M165.78 233.66a2 2 0 0 0-3.38 1a96 96 0 0 0 115 115a2 2 0 0 0 1-3.38z" fill="currentColor"></path></svg>
                                :
                                <svg onClick={() => handlePasswordView("confirm")} xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 cursor-pointer" viewBox="0 0 512 512"><path d="M255.66 112c-77.94 0-157.89 45.11-220.83 135.33a16 16 0 0 0-.27 17.77C82.92 340.8 161.8 400 255.66 400c92.84 0 173.34-59.38 221.79-135.25a16.14 16.14 0 0 0 0-17.47C428.89 172.28 347.8 112 255.66 112z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"></path><circle cx="256" cy="256" r="80" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"></circle></svg>
                        }
                    </div>

                </div>

                <div className="flex items-center gap-2">
                    <input type="checkbox" id="remember_me" className="border-none gradient-radio checked:outline-none checked:border-none" />
                    <label class="font-poppins text-xs font-normal leading-3 tracking-normal text-left" for="remember_me">
                        Remember  me
                    </label>
                </div>


                <button className="submit-form-gradient w-full flex items-center justify-center py-4 rounded-[8px]" onClick={props.handle}>
                    <span class="font-poppins text-base font-medium leading-4 tracking-normal text-center text-white">
                        Reset
                    </span>
                </button>

                <div className="w-full h-auto py-2 px-0 flex flex-col justify-center items-center gap-4">
                    <p className="font-poppins text-xs font-normal leading-3 text-left w-max">
                        <a href="#" onClick={goBack} className="mr-1 font-poppins text-xs italic font-semibold leading-3 text-left">sign in</a>
                        with another account
                    </p>
                </div>
            </div>
        </div>
    )
}