import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import TopBar from '../../topbar/Top-Bar';
import SideBar from '../../sidebar/sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMyCv, delete_a_resume, return_CV_state_back_to_null, ActivitiesDetails_loading, EducationDetails_loading, skillsDetails_loading, p_skillsDetails_loading } from '../../../Redux/ActionCreator';
import '../../../styles/resume.css';
import { ToastContainer, toast } from 'react-toastify';
import DashboardOptionLink from '../../common/dashboard_options_link';
import { SortSvg } from '../../common/svgs/svgs';
import ResumeItem from './resume_item';
import ResumeItemMobile from './resume_mobile';
import CompleteConfirmationGamifiedModal from "../../gamified_modals/complete_confirm";
import ExperienceGamifiedModal from "../../gamified_modals/experience_gamified_modal";
import EducationGamifiedModal from "../../gamified_modals/education_gamified_modal";
import SkillsGamifiedModal from "../../gamified_modals/skills_gamified_modal";
import SummaryGamifiedModal from "../../gamified_modals/summary_gamified_modal";
import ProfilePicsGamifiedModal from "../../gamified_modals/profile_pics_gamified_modal";

export default function Resume(props) {
	// initialize useDispatch hook
	const dispatch = useDispatch();
	const getMyCv = useSelector((prev) => prev.saved_cv);
	const profile = useSelector((state) => state.profile);
	const details = getMyCv.message;

	// navigate variable
	const navigate = useNavigate();

	let [realCvDetails, setRealCvDetails] = useState(details);

	const [experienceModal, setExperienceModal] = useState(false);
	const [educationModal, setEducationModal] = useState(false);
	const [skillsModal, setSkillsModal] = useState(false);
	const [summaryModal, setSummaryModal] = useState(false);
	const [profilePics, setProfilePics] = useState(false);
	const [hundredModal, sethundredModal] = useState(false);
	const [updateText, setUpdateText] = useState("");
	const [urlLink, setUrlLink] = useState("");

	useEffect(() => { }, [details]);

	useEffect(() => {
		dispatch(p_skillsDetails_loading(true));
		dispatch(skillsDetails_loading(true));
		dispatch(EducationDetails_loading(true));
		dispatch(ActivitiesDetails_loading(true));
	}, []);

	const handleClick = useCallback(
		(id, secret) => {
			const token = localStorage.getItem('token');
			realCvDetails = realCvDetails.filter((element) => {
				if (element.payment_intent_client_secret !== secret) {
					return element;
				}
			})
			if (details !== null && details.length !== 1) {
				dispatch(delete_a_resume(id));
			} else {
				setTimeout(() => {
					toast.error("You cannot delete this resume.", {
						position: toast.POSITION.TOP_RIGHT,
					});
				}, 50);
			}
			window.location.reload();
		}, [dispatch]
	)
	const [load, SetLoad] = useState(0);

	// useMemo
	const memoizedFetchData = useMemo(() => {
		const fetchresumeData = () => {
			const token = localStorage.getItem('token');
			dispatch(getAllMyCv(token));
			setRealCvDetails(details);
		}
		return fetchresumeData;
	}, [dispatch]);

	useEffect(() => {
		memoizedFetchData()
	}, [memoizedFetchData]);


	useEffect(() => {
		if (experienceModal) {
			setUrlLink("/user/profile/update/experience");
			setUpdateText("Experience");
		} else if (educationModal) {
			setUrlLink("/user/profile/update/education");
			setUpdateText("Education");
		} else if (skillsModal) {
			setUrlLink("/user/profile/update/skills");
			setUpdateText("Skills/Language");
		} else if (summaryModal) {
			setUrlLink("/user/profile/update/about");
			setUpdateText("Summary");
		} else if (profilePics) {
			setUpdateText("Profile pics");
		}
	}, [experienceModal, educationModal, skillsModal, summaryModal, profilePics]);

	const handleClickResume = async () => {
		if (profile.message !== null) {
			if (profile.message?.Experience?.length === 0 || !profile.message?.Experience) {
				setExperienceModal(true);
			} else if (profile.message?.Education?.length === 0 || !profile.message?.Education) {
				setEducationModal(true);
			} else if (profile.message?.Skills?.length === 0 || !profile.message?.Skills) {
				setSkillsModal(true);
			} else if (!profile.message?.About) {
				setSummaryModal(true);
			} else if (!profile.message?.photo) {
				setProfilePics(true)
			} else {
				navigate("/upload")
			}
		}
	}

	const experience = experienceModal ? <ExperienceGamifiedModal updateText={updateText} handleClose={() => setExperienceModal(false)} handleContinue={() => navigate(urlLink)} /> : null;
	const education = educationModal ? <EducationGamifiedModal updateText={updateText} handleClose={() => setEducationModal(false)} handleContinue={() => navigate(urlLink)} /> : null;
	const skills = skillsModal ? <SkillsGamifiedModal updateText={updateText} handleClose={() => setSkillsModal(false)} handleContinue={() => navigate(urlLink)} /> : null;
	const summary = summaryModal ? <SummaryGamifiedModal updateText={updateText} handleClose={() => setSummaryModal(false)} handleContinue={() => navigate(urlLink)} /> : null;
	const profile_pics = profilePics ? <ProfilePicsGamifiedModal updateText={updateText} handleClose={() => setProfilePics(false)} handleContinue={() => setProfilePics(false)} /> : null;
	const hundred = hundredModal ? <CompleteConfirmationGamifiedModal updateText={updateText} handleClose={() => sethundredModal(false)} handleContinue={sethundredModal} /> : null;

	// else if (cvId === '8') {
	// 	cv1 = './assets/CVTemplate/cv_template_7.webp';
	// }
	const getDate = (date) => {
		// console.log("date", date);
		if (date !== null && date !== undefined) {
			let split_date = date.split("T")[0].split("-");
			const return_date =
				split_date[2] +
				"/" +
				split_date[1] +
				"/" +
				split_date[0][2] +
				split_date[0][3];
			return return_date;
		}
	};

	return (
		<>
			<div className="bg-[#FAFAFA] px-3 lg:pl-6 py-8 w-full overflow-y-scroll ScrollOnly h-calc-70 flex justify-center">
				<div className="w-full flex justify-center">
					<div className="max-w-[900px] xtraxl:max-w-calculate py-5 w-full bg-opacity-80 flex-col justify-start items-start gap-y-6 inline-flex">
						<div className='w-full flex-col md:flex-row gap-y-2 flex gap-x-6 justify-between'>
							<div className='w-full max-w-[400px] xl:max-w-[500px]'>
								<p className='font-semibold text-[36px] leading-normal text-[#000000DE]'>My Resumes</p>
								<p className='font-normal leading-normal text-base text-[#000000DE]'>These are your resumes.<br />
									You can preview, download, and share them</p>
							</div>

							<DashboardOptionLink
								header="Generate Resume"
								onClick={() => handleClickResume()}
								description={`with an AI powered technology, get the edge over others`}
								ellipseStyle="bg-white left-[60%] top-[10%]  bg-opacity-80 rounded-full blur-[77.64px] ipad:left-[181px] top-[-100px]"
								leftBordStyle="bg-orange-500"
								bgBack="bg-indigo-500"
							/>
						</div>


						<div className='w-full max-w-[500px]'>
							{/* <div className='w-full bg-white flex rounded-[40px] gap-x-[10px] items-center pl-[14px] border border-[#777DEA54]'>
								<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M9.88649 18.773C4.97838 18.773 1 14.7946 1 9.88649C1 4.97838 4.97838 1 9.88649 1C14.7946 1 18.773 4.97838 18.773 9.88649C18.773 14.7946 14.7946 18.773 9.88649 18.773Z" stroke="#898989" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
									<path d="M20.9999 20.9999L16.5566 16.5566" stroke="#898989" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
								</svg>

								<input type='search' className='w-full py-[9px] rounded-[40px] pr-[14px] border border-transparent' placeholder='Search your resumes here' />
							</div> */}
						</div>

						<div className='w-full'>
							<table className="hidden md:table w-full">
								<thead className='bg-[#EFEFEF] rounded-tl-[8px] rounded-tr-[8px]'>
									<tr className="text-[#00000099] text-sm font-medium tracking-wide font-Urbanist bg-[#EFEFEF] rounded-lg">
										<th className="pl-4 py-3 w-[45%] lg:w-[40%] text-start max-w-[270px] whitespace-nowrap truncate ">
											Resume Title
											<span>
												<SortSvg />
											</span>
										</th>
										<th className="w-[30%] lg:w-[30%] text-start max-w-[150px] whitespace-nowrap">
											Date Created
											<span>
												<SortSvg />
											</span>
										</th>
										<th className="lg:w-full text-start whitespace-nowrap pr-4">
											ACTION
										</th>
									</tr>
								</thead>
								<tbody>
									{
										details?.map((cv_data, key) => {
											return (
												<ResumeItem
													key={key}
													aws_link={cv_data?.aws_link}
													CvType={cv_data?.CvType}
													bordered={key !== (details.length - 1)}
													createdAt={getDate(cv_data?.createdAt)}
													pdfId={cv_data?.pdfId}
													data={cv_data}
												/>
											)
										})
									}
								</tbody>
							</table>

							{
								details?.length === 0 &&
								<div className="w-full md:shadow rounded-bl-lg rounded-br-lg md:jobShadow md:bg-[#FFFFFFCC] h-[180px] md:h-[300px] flex justify-center items-center">
									<p className=" text-[20px] font-normal leading-[1.2] text-center max-w-[350px] md:max-w-[550px] px-4 py-6">
										You have not created or uploaded any resume yet.  <br /> <br className="md:hidden" /><Link to={'/upload'} className="text-[#5843BD]">Click here </Link>tto create one or use the ‘Generate CV/Resume’’ button above.
									</p>
								</div>
							}

							{/* Mobile Version */}
							<div className='md:hidden'>
								{
									details?.map((cv_data, key) => {
										return (
											<ResumeItemMobile
												key={key}
												aws_link={cv_data?.aws_link}
												CvType={cv_data?.CvType}
												bordered={key !== (details.length - 1)}
												createdAt={getDate(cv_data?.createdAt)}
												pdfId={cv_data?.pdfId}
												data={cv_data} />
										)
									})
								}
							</div>


						</div>
					</div>
				</div>
			</div>
			<ToastContainer />
			{experience}
			{education}
			{skills}
			{summary}
			{profile_pics}
			{hundred}
		</>
	);
}
