const Skills = [
    {
      value: 'Electrical Wiring',
      label: 'Electrical Wiring',
    },
    {
      value: 'Plumbing',
      label: 'Plumbing',
    },
    {
      value: 'Carpentry',
      label: 'Carpentry',
    },
    {
      value: 'Welding',
      label: 'Welding',
    },
    {
      value: 'HVAC Systems',
      label: 'HVAC Systems',
    },
    {
      value: 'Machinery Operation',
      label: 'Machinery Operation',
    },
    {
      value: 'Equipment Maintenance',
      label: 'Equipment Maintenance',
    },
    {
      value: 'Automotive Repair',
      label: 'Automotive Repair',
    },
    {
      value: 'Construction Skills',
      label: 'Construction Skills',
    },
    {
      value: 'Heavy Equipment Operation',
      label: 'Heavy Equipment Operation',
    },
    {
      value: 'Metalworking',
      label: 'Metalworking',
    },
    {
      value: 'Pipefitting',
      label: 'Pipefitting',
    },
    {
      value: 'Bricklaying',
      label: 'Bricklaying',
    },
    {
      value: 'Landscaping',
      label: 'Landscaping',
    },
    {
      value: 'Painting',
      label: 'Painting',
    },
    {
      value: 'Roofing',
      label: 'Roofing',
    },
    {
      value: 'Concrete Work',
      label: 'Concrete Work',
    },
    {
      value: 'Forklift Operation',
      label: 'Forklift Operation',
    },
    {
      value: 'Scaffolding',
      label: 'Scaffolding',
    },
    {
      value: 'CNC Machining',
      label: 'CNC Machining',
    },
    {
      value: 'Sheet Metal Work',
      label: 'Sheet Metal Work',
    },
    {
      value: 'Plastering',
      label: 'Plastering',
    },
    {
      value: 'Demolition',
      label: 'Demolition',
    },
    {
      value: 'Tiling',
      label: 'Tiling',
    },
    {
      value: 'Framing',
      label: 'Framing',
    },
    {
      value: 'Soldering',
      label: 'Soldering',
    },
    {
      value: 'Rigging',
      label: 'Rigging',
    },
    {
      value: 'Insulation Installation',
      label: 'Insulation Installation',
    },
    {
      value: 'Flooring Installation',
      label: 'Flooring Installation',
    },
    {
      value: 'Ductwork',
      label: 'Ductwork',
    },
    {
      value: 'Drywall Installation',
      label: 'Drywall Installation',
    },
    {
      value: 'Glass Installation',
      label: 'Glass Installation',
    },
    {
      value: 'Paving',
      label: 'Paving',
    },
    {
      value: 'Locksmithing',
      label: 'Locksmithing',
    },
    {
      value: 'Masonry',
      label: 'Masonry',
    },
    {
      value: 'Road Maintenance',
      label: 'Road Maintenance',
    },
    {
      value: 'Railing Installation',
      label: 'Railing Installation',
    },
    {
      value: 'Cabinetmaking',
      label: 'Cabinetmaking',
    },
    {
      value: 'Elevator Installation',
      label: 'Elevator Installation',
    },
    {
      value: 'Glazing',
      label: 'Glazing',
    },
    {
      value: 'Terrazzo Work',
      label: 'Terrazzo Work',
    },
    {
      value: 'Sewer and Pipe Cleaning',
      label: 'Sewer and Pipe Cleaning',
    },
    {
      value: 'Scaffolding Erection',
      label: 'Scaffolding Erection',
    },
    {
      value: 'Refrigeration Mechanics',
      label: 'Refrigeration Mechanics',
    },
    {
      value: 'Pile Driving',
      label: 'Pile Driving',
    },
    {
      value: 'Pest Control',
      label: 'Pest Control',
    },
    {
      value: 'Septic System Maintenance',
      label: 'Septic System Maintenance',
    },
    {
      value: 'Fire Sprinkler Installation',
      label: 'Fire Sprinkler Installation',
    },
    {
      value: 'Metal Fabrication',
      label: 'Metal Fabrication',
    },
    {
      value: 'Stonemasonry',
      label: 'Stonemasonry',
    },
    {
      value: 'Fencing Installation',
      label: 'Fencing Installation',
    },
    {
      value: 'Power Tool Operation',
      label: 'Power Tool Operation',
    },
    {
      value: 'Underground Utility Installation',
      label: 'Underground Utility Installation',
    },
    {
      value: 'Sanding and Finishing',
      label: 'Sanding and Finishing',
    },
    {
      value: 'Telecommunications Cabling',
      label: 'Telecommunications Cabling',
    },
    {
      value: 'Pump Installation and Repair',
      label: 'Pump Installation and Repair',
    },
    {
      value: 'Solar Panel Installation',
      label: 'Solar Panel Installation',
    },
    {
      value: 'Asphalt Paving',
      label: 'Asphalt Paving',
    },
    {
      value: 'Ceiling Installation',
      label: 'Ceiling Installation',
    },
    {
      value: 'Steel Erection',
      label: 'Steel Erection',
    },
    {
      value: 'Fireplace Installation',
      label: 'Fireplace Installation',
    },
    {
      value: 'Cabinetry Installation',
      label: 'Cabinetry Installation',
    },
    {
      value: 'Curb and Gutter Installation',
      label: 'Curb and Gutter Installation',
    },
    {
      value: 'Carpet Installation',
      label: 'Carpet Installation',
    },
    {
      value: 'Furniture Upholstery',
      label: 'Furniture Upholstery',
    },
    {
      value: 'Trenching and Excavation',
      label: 'Trenching and Excavation',
    },
    {
      value: 'Concrete Pump Operation',
      label: 'Concrete Pump Operation',
    },
    {
      value: 'Mobile Crane Operation',
      label: 'Mobile Crane Operation',
    },
    {
      value: 'Brick Paver Installation',
      label: 'Brick Paver Installation',
    },
    {
      value: 'Tire Repair and Maintenance',
      label: 'Tire Repair and Maintenance',
    },
    {
      value: 'Playground Equipment Installation',
      label: 'Playground Equipment Installation',
    },
    {
      value: 'Fiberglass Repair',
      label: 'Fiberglass Repair',
    },
    {
      value: 'Pond and Water Feature Installation',
      label: 'Pond and Water Feature Installation',
    },
    {
      value: 'Fireproofing',
      label: 'Fireproofing',
    },
    {
      value: 'Landscape Irrigation',
      label: 'Landscape Irrigation',
    },
    {
      value: 'Turbine Installation and Repair',
      label: 'Turbine Installation and Repair',
    },
    {
      value: 'Gutter Installation',
      label: 'Gutter Installation',
    },
    {
      value: 'Grading and Leveling',
      label: 'Grading and Leveling',
    },
    {
      value: 'Flagging and Traffic Control',
      label: 'Flagging and Traffic Control',
    },
    {
      value: 'Foundry Work',
      label: 'Foundry Work',
    },
    {
      value: 'Shoring Installation',
      label: 'Shoring Installation',
    },
    {
      value: 'Dredging',
      label: 'Dredging',
    },
    {
      value: 'Heavy Lifting and Rigging',
      label: 'Heavy Lifting and Rigging',
    },
    {
      value: 'Veneer Installation',
      label: 'Veneer Installation',
    },
    {
      value: 'Geothermal HVAC Installation',
      label: 'Geothermal HVAC Installation',
    },
    {
      value: 'Spray Foam Insulation',
      label: 'Spray Foam Insulation',
    },
    {
      value: 'Marble and Granite Installation',
      label: 'Marble and Granite Installation',
    },
    {
      value: 'Woodworking',
      label: 'Woodworking',
    },
    {
      value: 'Mold Remediation',
      label: 'Mold Remediation',
    },
    {
      value: 'Terrazzo Polishing',
      label: 'Terrazzo Polishing',
    },
    {
      value: 'Concrete Cutting and Breaking',
      label: 'Concrete Cutting and Breaking',
    },
    {
      value: 'Waterproofing',
      label: 'Waterproofing',
    },
    {
      value: 'Blasting and Demolition',
      label: 'Blasting and Demolition',
    },
    {
      value: 'Sprinkler System Installation',
      label: 'Sprinkler System Installation',
    },
    {
      value: 'Mechanical Insulation',
      label: 'Mechanical Insulation',
    },
    {
      value: 'Stone Cutting',
      label: 'Stone Cutting',
    },
    {
      value: 'Fiberglass Insulation',
      label: 'Fiberglass Insulation',
    },
    {
      value: 'Playground Equipment Maintenance',
      label: 'Playground Equipment Maintenance',
    },
    {
      value: 'Mortar Mixing',
      label: 'Mortar Mixing',
    },
    {
      value: 'Logistics and Warehouse Operations',
      label: 'Logistics and Warehouse Operations',
    },
    {
      value: 'Steel Reinforcement',
      label: 'Steel Reinforcement',
    },
    {
      value: 'Compressed Air Systems',
      label: 'Compressed Air Systems',
    },
    {
      value: 'Wood Framing',
      label: 'Wood Framing',
    },
    {
      value: 'Septic System Installation',
      label: 'Septic System Installation',
    },
    {
      value: 'Concrete Formwork',
      label: 'Concrete Formwork',
    },
    {
      value: 'Stucco Application',
      label: 'Stucco Application',
    },
    {
      value: 'Epoxy Coating',
      label: 'Epoxy Coating',
    },
    {
      value: 'Helicopter Operation',
      label: 'Helicopter Operation',
    },
    {
      value: 'Gas Appliance Installation and Repair',
      label: 'Gas Appliance Installation and Repair',
    },
    {
      value: 'Street Sweeping',
      label: 'Street Sweeping',
    },
    {
      value: 'Pump Station Maintenance',
      label: 'Pump Station Maintenance',
    },
    {
      value: 'Airport Runway Maintenance',
      label: 'Airport Runway Maintenance',
    },
    {
      value: 'Sailboat Rigging',
      label: 'Sailboat Rigging',
    },
    {
      value: 'Pipe Inspection and Testing',
      label: 'Pipe Inspection and Testing',
    },
    {
      value: 'Parking Lot Striping',
      label: 'Parking Lot Striping',
    },
    {
      value: 'Paint Removal',
      label: 'Paint Removal',
    },
    {
      value: 'Erosion Control',
      label: 'Erosion Control',
    },
    {
      value: 'Ramp Installation',
      label: 'Ramp Installation',
    },
    {
      value: 'Metal Roofing Installation',
      label: 'Metal Roofing Installation',
    },
    {
      value: 'Concrete Polishing',
      label: 'Concrete Polishing',
    }
]



export default Skills;
  