import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { last_page } from "../../Redux/ActionCreator";
import { baseURL } from "../../Redux/baseURL";
import { useNavigate } from "react-router-dom";
import {
  userName_from_db,
  getProfile,
  getEmployerProfile,
  getChatInformation
} from "../../Redux/ActionCreator";
import { saveIntoServerCache } from "../../utils/saveIntoCache";

const PageTrackingHOC = ({children}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const profile_state = useSelector((prev) => prev.login);
    const profile_stat = profile_state.profile_status.profile_status;
    const AcctType = profile_state.profile_status.AcctType;
    // const profileStatus = useSelector((prev) => prev.login);
    const lastPage = useSelector(e => e.lastPage);
    const userName = useSelector((prev) => prev.userInfo);
    const profile = useSelector((prev) => prev.profile);
    const EmployerProfile = useSelector((prev) => prev.employer);
    const employer = EmployerProfile.message;
    // const userInfo = useSelector(e => e.userInfo.user);

    useEffect(() => {
        if (token !== null) {
            if (userName.user == null) {
                dispatch(userName_from_db(token));
            }

            // console.log("frustrated")
            if (AcctType == "Client") {
                if (profile_stat !== "false" && profile_stat !== false) {
                    if (profile.message === null || profile.message === undefined) {
                        dispatch(getProfile(token));
                    }
                }
            }
        }

        if (userName.user !== null && userName.user !== undefined) {
            dispatch(getChatInformation(userName.user?.profile_id));
        }

    }, [])

    useEffect(() => {
        if ( token !== null) {
            dispatch(last_page(window.location.pathname));
            // lets also update the backend cache
            saveIntoServerCache("lastPage", window.location.pathname);
        }
    }, [window.location.pathname]);

    return (
       <>{children}</>
    )
}

export default PageTrackingHOC;
