import { Link } from "react-router-dom";
import timeResolver from "../timeresolver";

export default function JobDetailItem(props) {


	// const dateString = props.createdAt;
	// const date = new Date(dateString);

	// const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${String(date.getFullYear()).slice(-2)}`;



	return (
		<tr className="text-[#111111] text-base font-medium tracking-wide bg-white py-1 font-Poppins rounded-lg border-b border-[#5843BD4D]">
			<td className="pl-8">
				<div className="flex py-4 gap-x-2 h-full items-center">
					<div>
						<img className="w-[45px] h-[45px] rounded-[4px]" src={props.profilePics} />
					</div>
					<div>
						<p class="text-base font-medium leading-normal text-left text-[#5843BD]">
							{props.jobPosition}
						</p>
						<p class="text-[10px] text-[#4E5161] font-normal leading-[15px] text-left">
							Posted {`${timeResolver(props.createdAt)}`} by
							<span class="text-[#5843BD]"> {props.employer}</span>
						</p>
					</div>
				</div>
			</td>
			<td>
				<p className="text-base py-4 leading-normal font-normal text-[#000000CC]">{props.date}</p>
			</td>
			<td>
				<div className="py-4 w-max">
					{
						props.status === "Interview" ? <div className="bg-[#5843BD26] rounded-[4px] py-2 px-3 text-base font-medium leading-normal text-left text-[#111111]">
							Interview
						</div> : props.status === "Pending" ? <div className="bg-[#FF663326] rounded-[4px] py-2 px-3 text-base font-medium leading-normal text-left text-[#111111]">
							Pending
						</div> : props.status === "Hired" ? <div className="bg-[#5843BD80] rounded-[4px] text-white py-2 px-3 text-base font-medium leading-normal text-left">
							Hired
						</div> : <div className="bg-[#FF6633] py-2 px-3 rounded-[4px] text-base font-medium leading-normal text-left text-white">
							Closed
						</div>
					}
				</div>
			</td>
			<td>
				<Link to={props.jobUrl} className="py-2 px-5 text-base font-medium leading-normal text-left text-[#5843BD]">
					View Details
				</Link>
			</td>
		</tr>
	)
}

