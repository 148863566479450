// recommendation function section
import { baseURL } from "../../Redux/baseURL"

// employee recommendation
export const getRecommendedJobsByJobTitle = (page) => {
    return fetch(baseURL + "/api/client/filterByResTitle", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            // "Authorization": "Bearer " + localStorage.getItem("token")
        },
        body: JSON.stringify({page: page}),
        credentials: "include"
    })
} 

export const getRecommendedJobsByCategory = (page) => {
    return fetch(baseURL + "/api/client/filterByResCategory", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            // "Authorization": "Bearer " + localStorage.getItem("token")
        },
        body: JSON.stringify({page: page}),
        credentials: "include"
    });
} 

export const getRecommendedJobsByProfileTags = (page) => {
    return fetch(baseURL + "/api/client/filterByTags", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            // "Authorization": "Bearer " + localStorage.getItem("token")
        },
        body: JSON.stringify({page: page}),
        credentials: "include"
    });
} 

// employer recommendation

// export const getRecommendedCandidate = (page) => {
//   // default page is 1 till further notice
//   let p = {
//     page: !!page ? page : 1,
//   };
//   return fetch(baseURL + "/api/employer/recommendedClients", {
//     method: "POST",
//     body: JSON.stringify(p),
//     headers: {
//       "Content-Type": "application/json",
//       // Authorization: "Bearer " + localStorage.getItem("token"),
//     },
//     credentials: "include"
//   })
// };

// export const getRecommendedCandidateForAJob = (page, jobId) => {
//   // default page is 1 till further notice
//   let p = {
//     page: !!page ? page : 1,
//     jobId: jobId,
//   };
//   return fetch(baseURL + "/api/employer/recommendedClientsForAJob", {
//     method: "POST",
//     body: JSON.stringify(p),
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: "Bearer " + localStorage.getItem("token"),
//     },
//   })
// };

// export const getRecommendedCandidateByCategory = (page) => {
//   // default page is 1 till further notice
//   let p = {
//     page: !!page ? page : 1,
//   };
//   return fetch(baseURL + "/api/employer/recommendedClientsCategory", {
//     method: "POST",
//     body: JSON.stringify(p),
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: "Bearer " + localStorage.getItem("token"),
//     },
//   });
// };
