
export const handleDeleteValue = (arr, chipToDelete, idParams ) => {
    let newArray = new Array();;
    if (idParams == "_id") {
        newArray.push(...arr.filter((element) => {
            if (element._id !== chipToDelete._id) return element;
        }))
        console.log("new arr", newArray);
        newArray.forEach((element) => {
            element._id = element._id;
        });
    } else if (idParams == "key") {
        newArray.push(...arr.filter((element) => {
            if (element.key !== chipToDelete.key) return element;
        }));
        console.log("new arr", newArray);
        newArray.forEach((element) => {
            element.key = newArray.indexOf(element);
        });
    }
    
    return newArray;
};

