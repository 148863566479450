import React, { useEffect, useState, useContext, useCallback } from "react";
import refrence from "../common/reference";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useDropzone } from "react-dropzone";

import {
  getAllMyCv, getting_profile_successful,
  getting_employer_profile_successful,
  SiteErrorLoading,
  SiteError,
  getting_employer_profile_loading,
  userInfo,
  returnHome,
  getProfile
} from "../../Redux/ActionCreator";
import { RatingSvg } from "../common/svgs/svgs";
import LoaderModal from "../common/LoaderModal/index"
import ResumeDownloadModal from "../Resume/download_resume_modal/index";
import { baseURL } from "../../Redux/baseURL";
import { LinearProgress } from "@mui/material";


function WorkerHeader(props) {
  const navigate = useNavigate();
  const getMyCv = useSelector((prev) => prev.saved_cv);
  const lang_type = useSelector((e) => e.t_lang);
  const details = getMyCv.message;
  const [lan1, setLan1] = useState(null);
  const userPics = useSelector((prev) => prev.userInfo);
  const [picLink, setPicsLink] = useState("");
  const [files, setFiles] = useState([]);
  const [previewUrl, setPreviewUrl] = useState("");
  const [select, setSelect] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [uploadStatus, setUploadStatus] = useState("");
  const [upload, setUpload] = useState(false);
  const profile = useSelector((prev) => prev.profile);
  const sp_profile = useSelector((e) => e.spanish_profile);
  const dispatch = useDispatch();

  const [showMoreJobInterest, setShowMoreJobInterest] = useState(false);

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    acceptedFiles.forEach((file) => {
      // console.log("dropped!!!")
      setSelectedImages(() => [file]);
      setUploadStatus("loading");
    });
  }, []);

  // console.log(uploadStatus);

  useEffect(() => {
    let download = new URLSearchParams(window.location.search).get("download");
    if (download) {
      setSelect(true);
    }
  }, [])

  useEffect(() => {
    // console.log("called");
    handleFileChange();
    // sendBase64UrlToBackend();
  }, [selectedImages])

  const handleFileChange = () => {
    const file = selectedImages[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        // e.target.result contains the base64-encoded image data URL
        setPreviewUrl(e.target.result);
        // console.log("We did it baby"); // yes bro!!
      };

      // Read the file as a data URL (base64)
      reader.readAsDataURL(file);
    } else {
      // Handle the case where no file is selected.
      // console.error("No file selected.");
    }
  };

  useEffect(() => {
    if (previewUrl !== "") {
      sendBase64UrlToBackend();
    }
  }, [previewUrl]);

  const sendBase64UrlToBackend = () => {
    if (previewUrl) {
      setUpload(true);
      let token = localStorage.getItem("token");
      const body = {
        image_uri: previewUrl, // Send the base64 image data URL
      };

      const headers = {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      fetch(baseURL + "/api/client/upload_avatar", {
        method: "PATCH",
        body: JSON.stringify(body), // Send the JSON object containing the base64 URL
        headers,
        credentials: "include"
      })
        .then((resp) => resp.json())
        .then((resp) => {
          // console.log(resp);
          if (resp.success === true) {
            setUpload(false);
            dispatch(getting_profile_successful(resp.data));
            setUploadStatus("done");
          }
        }).catch(err => {
          // console.log("Error", err);
        });

    } else {
      // console.error("No file selected.");
    }
  };

  // console.log(selectedImages)

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({ onDrop });



  useEffect(() => {
    if (sp_profile.message !== null) {
      setLan1((e) => ({ ...e, ...sp_profile.message }));
    }
  }, [sp_profile.message]);

  useEffect(() => {
    if (userPics.user !== null) {
      setPicsLink(userPics.user.profile_pics);
    }
    if (getMyCv.message === null) {
      dispatch(getAllMyCv(localStorage.getItem("token")));
    }
  }, [userPics, profile]);


  // const handleShareProfile = () => {
  //   if (profile.message === null) {
  //     setTimeout(() => {
  //       toast.error("You must have a profile before you can share.", {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //     }, 50);
  //   } else {
  //     navigate(`/user/:${userPics.user?.profile_id}/share`);
  //   }
  // };

  const handleCreateResume = () => {
    try {
      if (profile.message == null || profile.message?.CompletionPercentage < 95) {
        setTimeout(() => {
          toast.error("You must complete your profile before you can create a resume.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }, 50);
      } else {
        setSelect(true);
        // direct to select the template they want.
        // navigate("/generateCV?download=true");
      }
    } catch (error) {
      // console.log(error);
    }
  }

  const handleShareProfile = () => {
    try {
      if (profile.message == null || profile.message?.CompletionPercentage < 95) {
        setTimeout(() => {
          toast.error("You must complete your profile before you can share your profile.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }, 50);
      } else {
        navigate(`/user/:${userPics.user?.profile_id}/share`);
      }
    } catch (error) {
      // console.log(error);
    }
  }


  const selectResumeLanguage = select ? (<ResumeDownloadModal details={profile.message} show={setShowModal} />) : null;

  return (
    <div class="p-4 md:p-8 lg:p-12 gap-9 rounded-xl bg-[#5843BD]">
      <div className="flex gap-x-8 flex-col lg:flex-row  items-center lg:items-start gap-y-4 justify-between">
        {
          profile.message?.photo !== undefined ? (

            <div {...getRootProps()} className="w-60 md:w-[60%] md:max-w-[280px] lg:max-w-[305px] relative rounded-[16px]  cursor-pointer">
              <input {...getInputProps()} />
              {uploadStatus === "loading" ? <div className="aspect-[1/1] object-cover border-4 border-solid border-white w-full rounded-[16px] flex justify-center items-center"><LinearProgress /></div> :
                <>
                  <img
                    src={profile.message?.photo}
                    alt="employee profile picture"
                    className="aspect-[1/1] object-cover border-4 border-solid border-white w-full rounded-[16px]"
                  />

                  <svg width="28" height="28" viewBox="0 0 28 28" className="absolute top-[85%] left-[85%]" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="13.2734" cy="13.002" r="11.5" stroke="white" />
                    <path d="M9.93945 9.66846H9.27279C8.91916 9.66846 8.58003 9.80893 8.32998 10.059C8.07993 10.309 7.93945 10.6482 7.93945 11.0018V17.0018C7.93945 17.3554 8.07993 17.6946 8.32998 17.9446C8.58003 18.1946 8.91916 18.3351 9.27279 18.3351H15.2728C15.6264 18.3351 15.9655 18.1946 16.2156 17.9446C16.4656 17.6946 16.6061 17.3554 16.6061 17.0018V16.3351" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M15.9401 8.33536L17.9401 10.3354M18.8634 9.39202C19.126 9.12946 19.2735 8.77335 19.2735 8.40202C19.2735 8.0307 19.126 7.67459 18.8634 7.41202C18.6009 7.14946 18.2448 7.00195 17.8734 7.00195C17.5021 7.00195 17.146 7.14946 16.8834 7.41202L11.2734 13.002V15.002H13.2734L18.8634 9.39202Z" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </>}

            </div>
          ) : (
            <form class="w-full p-4 gap-4 rounded-lg bg-white shadow-md h-max max-w-[310px]">
              {/* Have to make the entire of this compoenet a <label> */}
              {/* <input /> {...getInputProps()} */}
              <div {...getRootProps()} class="p-4 gap-4 profile-pics-upload border-[#FF6633] flex gap-y-4 flex-col itmes-center cursor-pointer">
                <input {...getInputProps()} />
                <div>
                  <p class=" text-xl font-bold leading-normal text-center text-[#777777]">
                    Upload a Profile Photo
                  </p>
                  <p class=" text-sm font-normal leading-normal text-center text-[#777777]">
                    File can be .jpeg,  .jpg or .png
                  </p>
                </div>

                <div className="flex flex-col items-center gap-y-2">
                  <svg width="42" height="48" viewBox="0 0 42 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M39.7501 10.1786C40.2501 10.6786 40.6787 11.3571 41.0359 12.2143C41.393 13.0714 41.5716 13.8571 41.5716 14.5714V45.4286C41.5716 46.1429 41.3216 46.75 40.8216 47.25C40.3216 47.75 39.7144 48 39.0001 48H3.00014C2.28585 48 1.67871 47.75 1.17871 47.25C0.678711 46.75 0.428711 46.1429 0.428711 45.4286V2.57143C0.428711 1.85714 0.678711 1.25 1.17871 0.75C1.67871 0.25 2.28585 0 3.00014 0H27.0001C27.7144 0 28.5001 0.178571 29.3573 0.535714C30.2144 0.892857 30.893 1.32143 31.393 1.82143L39.7501 10.1786ZM27.8573 3.64286V13.7143H37.9287C37.7501 13.1964 37.5537 12.8304 37.3394 12.6161L28.9555 4.23214C28.7412 4.01786 28.3751 3.82143 27.8573 3.64286ZM38.143 44.5714V17.1429H27.0001C26.2859 17.1429 25.6787 16.8929 25.1787 16.3929C24.6787 15.8929 24.4287 15.2857 24.4287 14.5714V3.42857H3.85728V44.5714H38.143ZM34.7144 32.5714V41.1429H7.28585V36L12.4287 30.8571L15.8573 34.2857L26.143 24L34.7144 32.5714ZM12.4287 27.4286C11.0001 27.4286 9.78585 26.9286 8.78585 25.9286C7.78585 24.9286 7.28585 23.7143 7.28585 22.2857C7.28585 20.8571 7.78585 19.6429 8.78585 18.6429C9.78585 17.6429 11.0001 17.1429 12.4287 17.1429C13.8573 17.1429 15.0716 17.6429 16.0716 18.6429C17.0716 19.6429 17.5716 20.8571 17.5716 22.2857C17.5716 23.7143 17.0716 24.9286 16.0716 25.9286C15.0716 26.9286 13.8573 27.4286 12.4287 27.4286Z" fill="#777777" />
                  </svg>
                  <p class=" text-sm font-semibold leading-[18px] text-center text-[#777777]">
                    Drag a profile photo here
                  </p>
                </div>

                <div>
                  <p class=" text-sm font-semibold leading-[18px] text-center text-[#77777780]">
                    - or -
                  </p>
                </div>

                <div class="w-full flex items-center justify-center py-3 px-2 gap-2 rounded-md border border-[#FF6633] shadow-md text-xs font-semibold leading-[18px] text-center text-[#FF6633] cursor-pointer">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 21C2.45 21 1.979 20.804 1.587 20.412C1.195 20.02 0.999335 19.5493 1 19V7C1 6.45 1.196 5.979 1.588 5.587C1.98 5.195 2.45067 4.99933 3 5H6.15L8 3H14V5H8.875L7.05 7H3V19H19V10H21V19C21 19.55 20.804 20.021 20.412 20.413C20.02 20.805 19.5493 21.0007 19 21H3ZM19 7V5H17V3H19V1H21V3H23V5H21V7H19ZM11 17.5C12.25 17.5 13.3127 17.0623 14.188 16.187C15.0633 15.3117 15.5007 14.2493 15.5 13C15.5 11.75 15.0623 10.6873 14.187 9.812C13.3117 8.93667 12.2493 8.49933 11 8.5C9.75 8.5 8.68734 8.93767 7.812 9.813C6.93667 10.6883 6.49934 11.7507 6.5 13C6.5 14.25 6.93767 15.3127 7.813 16.188C8.68834 17.0633 9.75067 17.5007 11 17.5ZM11 15.5C10.3 15.5 9.70834 15.2583 9.225 14.775C8.74167 14.2917 8.5 13.7 8.5 13C8.5 12.3 8.74167 11.7083 9.225 11.225C9.70834 10.7417 10.3 10.5 11 10.5C11.7 10.5 12.2917 10.7417 12.775 11.225C13.2583 11.7083 13.5 12.3 13.5 13C13.5 13.7 13.2583 14.2917 12.775 14.775C12.2917 15.2583 11.7 15.5 11 15.5Z" fill="#F24E1E" />
                  </svg>

                  Select a photo from your gallery
                </div>
              </div>
            </form>

          )
        }
        {/* section */}
        <div className="w-full flex flex-col gap-y-6">
          <div className="flex flex-col gap-y-4 md:flex-row justify-between">
            <div className="flex flex-col gap-y-4">
              <p class=" text-base font-normal leading-normal text-left text-[#FEFEFE80]">
                Personal Details
              </p>
              <div>
                <p class=" text-xl font-semibold leading-normal text-left text-[#FEFEFE] notranslate">
                  {profile.message?.Fullname}
                </p>
                <p class=" text-base font-medium leading-normal text-left text-[#FEFEFE]">
                  {profile.message?.JobInterests[0]?.value}
                </p>
                {showMoreJobInterest && (
                  <div >
                    <ul className="list-none">
                      {profile.message?.JobInterests.map((e) => (
                        <>{
                          e.value !== profile.message?.JobInterests[0].value ? (
                            <li className="text-base font-medium leading-normal text-left text-[#FEFEFE] py-2 cursor-pointer">{e.value}</li>
                          ) : null
                        }</>
                      ))}
                    </ul>
                  </div>
                )}
                {
                  profile.message?.JobInterests.length > 1 ? (
                    <p class="text-sm mt-1 italic font-normal leading-normal text-left text-[#FFFFFFB2] cursor-pointer" onClick={() => setShowMoreJobInterest(!showMoreJobInterest)}>
                  {showMoreJobInterest ? "(See less)" : `(See ${profile.message?.JobInterests.length - 1} other jobs)`}
                  </p>
                  ) : null
                }
              </div>

              {/* <div className="flex gap-x-2 items-center">
                <div>
                  <RatingSvg />
                  <RatingSvg />
                  <RatingSvg />
                  <RatingSvg />
                  <RatingSvg />
                </div>
                <p class=" text-sm font-medium leading-normal text-[#FEFEFE]">
                  5.0
                </p>

                <p class="text-sm font-normal text-left text-white">
                  (See all 25 reviews)
                </p>
              </div> */}
            </div>

            <button class="max-w-[70px] h-max self-start py-1 px-5 gap-1.5 rounded-sm bg-[#FF6633]  text-base font-normal leading-6 text-center text-white" onClick={() => navigate("/user/profile/update/personalInfo")}>
              Edit
            </button>

          </div>

          {/* location and experience */}

          <div className="flex justify-between md:items-center flex-col md:flex-row ">
            <div className="flex flex-col">
              <p class=" text-base font-normal leading-normal text-left text-[#FEFEFE80]">Location</p>
              <p class=" text-base font-medium leading-6 text-left text-[#FEFEFE]">{profile.message?.Address}</p>
            </div>

            <div className="flex flex-col">
              <p class=" text-base font-normal leading-normal text-left text-[#FEFEFE80]">Work Experience</p>
              {profile.message?.Years_of_experience !== undefined ? (<p class=" text-base font-medium leading-6 text-left text-[#FEFEFE]">{profile.message?.Years_of_experience}+ years Experience</p>) : null}

            </div>
          </div>

          {/* create resume and share profile button */}
          <div className="flex justify-between gap-y-4 flex-col md:flex-row items-center">
            <button class="text-base w-full md:w-max font-semibold leading-normal text-center text-white px-5 py-3 gap-3 rounded-lg bg-[#FF6633] shadow-lg" onClick={handleCreateResume}>
              Create Resume
            </button>

            <button class="text-base w-full md:w-max font-semibold leading-normal text-center text-white px-5 py-3 gap-3 rounded-lg border border-white shadow-lg"
              onClick={handleShareProfile}>
              Share Profile
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
      {selectResumeLanguage}
      {showModal && <LoaderModal text="Translating your resume into spanish" />}
      {upload ? <LoaderModal text="Uploading profile picture..."/> : null}
    </div>
  );
}

export default WorkerHeader;
