export function AppleSvg() {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.9579 5.4352C4.16098 5.4352 2.2582 6.53016 1.054 8.39178C1.04651 8.40306 1.03863 8.41515 1.03105 8.42767L1.02197 8.44267V8.52301L18.3733 8.52419L18.3909 8.50921C18.653 8.28615 18.9464 8.08304 19.2719 7.90403L19.3372 7.86808L19.2907 7.8098C18.0905 6.30712 16.4023 5.43376 14.8036 5.43376C13.7493 5.43376 12.999 5.68553 12.3343 5.93518C12.2741 5.95779 12.2147 5.98036 12.1559 6.00269C11.5643 6.22729 11.034 6.42857 10.3885 6.42857C9.68933 6.42857 9.06951 6.20674 8.40775 5.96991C8.37581 5.95848 8.34378 5.94702 8.31164 5.93554C7.61562 5.68705 6.87195 5.4352 5.9579 5.4352Z"
        fill="#61BB46"
      />
      <path
        d="M14.4078 0.0574495L14.3993 0L14.3414 0.00398835C13.0841 0.0905323 11.6206 0.891716 10.765 1.93405C9.99127 2.87767 9.34592 4.28434 9.59724 5.65821L9.60636 5.70811L9.65706 5.70969C11.0331 5.75266 12.4492 4.92935 13.2707 3.87082C14.0348 2.88548 14.6199 1.48959 14.4078 0.0574495Z"
        fill="#61BB46"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.02304 8.43311C0.508558 9.24252 0.152574 10.4026 0.0581055 11.4202L16.7491 11.4198C16.9296 10.3065 17.5296 9.24294 18.4808 8.43434L1.02304 8.43311H1.02403L1.02304 8.43311Z"
        fill="#FDB827"
      />
      <path
        d="M16.7461 11.4298L16.7579 11.3569L0.0668542 11.3574L0.0606597 11.4133C-0.0418933 12.3385 -0.0153814 13.3461 0.147584 14.3904L0.209641 14.3855L0.209639 14.4435L17.1642 14.4441L17.1252 14.3559C16.7203 13.4396 16.5861 12.4145 16.7461 11.4298Z"
        fill="#F5821F"
      />
      <path
        d="M17.1086 14.3185L0.13623 14.3179L0.147514 14.3903C0.298194 15.3582 0.565764 16.3577 0.956125 17.3529V17.4038L19.8391 17.4051L19.874 17.3276C19.9059 17.257 19.9388 17.184 19.9727 17.1083L19.9999 17.0475L19.9377 17.0239C18.5944 16.5144 17.6407 15.5232 17.1251 14.3559L17.1086 14.3185Z"
        fill="#E03A3E"
      />
      <path
        d="M19.8613 17.2791L0.926758 17.2778L0.960479 17.3636C1.32601 18.2936 1.79896 19.2199 2.38312 20.1134L2.38322 20.1135C2.43193 20.1876 2.4814 20.2622 2.53135 20.3369L2.55 20.3648L18.1776 20.3634L18.1963 20.336C18.2902 20.1986 18.3802 20.0625 18.4673 19.9298L18.4673 19.9297C19.1157 18.9387 19.3878 18.4007 19.8557 17.3678L19.8613 17.3555V17.2791Z"
        fill="#963D97"
      />
      <path
        d="M18.1963 20.3359L18.263 20.2378L2.46582 20.2392L2.53132 20.337C3.011 21.053 3.56807 21.7941 4.21026 22.3584C4.85261 22.9229 5.58506 23.3148 6.41447 23.3225C7.13381 23.3292 7.61481 23.114 8.1228 22.8866C8.16016 22.8699 8.19768 22.8531 8.23544 22.8363C8.78458 22.5925 9.39584 22.3474 10.41 22.3417C11.4237 22.3361 12.0245 22.5801 12.5631 22.8257C12.6035 22.8442 12.6437 22.8627 12.6837 22.8811C13.1785 23.1089 13.6495 23.3257 14.3644 23.3192C15.1611 23.3117 15.8824 22.9122 16.5204 22.3451C17.1586 21.7777 17.7195 21.0373 18.1963 20.3359Z"
        fill="#009DDC"
      />
    </svg>
  );
}

export function GmailSvg() {
  return (
    <svg
      width="13"
      height="11"
      viewBox="0 0 13 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.884521 10.2319H2.9484V5.2196L0 3.0083V9.34737C0 9.83606 0.395823 10.2319 0.884521 10.2319Z"
        fill="#4285F4"
      />
      <path
        d="M10.0244 10.2319H12.0883C12.577 10.2319 12.9728 9.83606 12.9728 9.34737V3.0083L10.0244 5.2196V10.2319Z"
        fill="#34A853"
      />
      <path
        d="M10.0244 1.3869V5.21983L12.9728 3.00853V1.82916C12.9728 0.736044 11.7249 0.11172 10.85 0.76774L10.0244 1.3869Z"
        fill="#FBBC04"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.94824 5.21964V1.38672L6.48633 4.04028L10.0244 1.38672V5.21964L6.48633 7.87321L2.94824 5.21964Z"
        fill="#EA4335"
      />
      <path
        d="M0 1.82916V3.00853L2.9484 5.21983V1.3869L2.12285 0.76774C1.24791 0.11172 0 0.736044 0 1.82916Z"
        fill="#C5221F"
      />
    </svg>
  );
}

export function PhoneSvg() {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.16157 6.82733C8.88051 9.54628 9.49551 6.40007 11.224 8.12207C12.9524 9.84406 13.8523 10.1224 11.7419 12.2393C11.4765 12.453 9.79977 15.0101 3.87636 9.09959C-2.04705 3.18913 0.522996 1.51245 0.736627 1.24703C2.85352 -0.876339 3.18368 0.088238 4.85388 1.75845C6.52409 3.42865 3.44262 4.10839 6.16157 6.82733Z"
        fill="#5843BD"
        fill-opacity="0.6"
      />
    </svg>
  );
}

export function TaskSvg() {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9997 6.95165C19.9862 7.37348 19.8723 7.78602 19.6673 8.15496C19.4624 8.5239 19.1723 8.83859 18.8212 9.07285L11.1731 13.5863C10.8138 13.791 10.4082 13.9005 9.99468 13.9045C9.58132 13.9113 9.1739 13.8054 8.81623 13.5981L1.17991 9.07285C0.827464 8.84002 0.536338 8.52566 0.331199 8.15641C0.12606 7.78716 0.0129537 7.3739 0.00146484 6.95165C0.0148232 6.53141 0.128817 6.12052 0.333873 5.75347C0.53893 5.38642 0.829063 5.07392 1.17991 4.84223L8.81623 0.305218C9.17675 0.105042 9.58231 0 9.99468 0C10.407 0 10.8126 0.105042 11.1731 0.305218L18.8094 4.84223C19.166 5.06886 19.4611 5.37993 19.6687 5.74787C19.8764 6.11581 19.99 6.52928 19.9997 6.95165Z"
        fill="#5843BD"
      />
      <path
        d="M19.9997 11.7834C20.0057 12.2075 19.9 12.6257 19.6931 12.996C19.4863 13.3663 19.1855 13.6756 18.8212 13.8928L11.1731 18.418C10.8136 18.6222 10.4081 18.7317 9.99467 18.7362C9.58267 18.7323 9.17794 18.6271 8.81623 18.4298L1.17991 13.8928C0.811396 13.6802 0.507277 13.3719 0.299803 13.0006C0.0923291 12.6292 -0.0107626 12.2086 0.00146184 11.7834C0.000808986 11.4433 0.0689539 11.1065 0.201798 10.7935L0.319642 10.8759L7.97953 15.4365C8.60003 15.7804 9.29704 15.9628 10.0065 15.9668C10.7322 15.9668 11.4446 15.7714 12.0687 15.4012L19.7051 10.8759L19.8111 10.8052C19.9399 11.115 20.0041 11.4479 19.9997 11.7834Z"
        fill="#5843BD"
      />
      <path
        d="M19.9995 16.6031C20.0065 17.029 19.9012 17.4492 19.6944 17.8215C19.4875 18.1938 19.1863 18.5052 18.8211 18.7243L11.173 23.2495C10.8135 23.4538 10.408 23.5632 9.99455 23.5677C9.58254 23.5639 9.17782 23.4586 8.8161 23.2613L1.17978 18.7243C0.809467 18.5108 0.50418 18.2007 0.296598 17.827C0.0890151 17.4534 -0.0130475 17.0303 0.00133489 16.6031C0.00162146 16.2669 0.0697605 15.9342 0.201671 15.625L0.319515 15.7075L7.97941 20.2563C8.59774 20.6056 9.29617 20.7883 10.0063 20.7866C10.7328 20.792 11.4466 20.5962 12.0686 20.2209L19.7049 15.7075L19.811 15.6368C19.9385 15.9428 20.0027 16.2716 19.9995 16.6031Z"
        fill="#5843BD"
      />
    </svg>
  );
}

export function NoteSvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.776 0H4.236C1.896 0 0 1.896 0 4.236V19.764C0 22.104 1.896 24 4.236 24H19.776C22.104 24 24 22.104 24 19.764V4.236C24 1.896 22.104 0 19.776 0ZM4.8 18.9C4.308 18.9 3.9 18.492 3.9 18C3.9 17.508 4.308 17.1 4.8 17.1H19.2C19.692 17.1 20.1 17.508 20.1 18C20.1 18.492 19.692 18.9 19.2 18.9H4.8ZM10.2 5.1C10.692 5.1 11.1 5.508 11.1 6C11.1 6.492 10.692 6.9 10.2 6.9H4.8C4.308 6.9 3.9 6.492 3.9 6C3.9 5.508 4.308 5.1 4.8 5.1H10.2ZM19.2 12.9H4.8C4.308 12.9 3.9 12.492 3.9 12C3.9 11.508 4.308 11.1 4.8 11.1H19.2C19.692 11.1 20.1 11.508 20.1 12C20.1 12.492 19.692 12.9 19.2 12.9Z"
        fill="#5843BD"
      />
    </svg>
  );
}

export function AddSvg() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9331 1.99996L16.1421 30.0752"
        stroke="#5843BD"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 15.9331L30.0753 16.1421"
        stroke="#5843BD"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function DriveSvg() {
  return (
    <svg
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.69231 17.141L2.67948 18.8461C2.88461 19.2051 3.17949 19.4872 3.52564 19.6923L7.05128 13.5897H0C0 13.9872 0.102559 14.3846 0.307688 14.7436L1.69231 17.141Z"
        fill="#0066DA"
      />
      <path
        d="M11.1923 6.41018L7.66667 0.307617C7.32051 0.512745 7.02565 0.794797 6.82052 1.15377L0.307688 12.4358C0.106332 12.7871 0.000268093 13.1848 0 13.5897H7.05128L11.1923 6.41018Z"
        fill="#00AC47"
      />
      <path
        d="M18.8592 19.6923C19.2053 19.4872 19.5002 19.2051 19.7053 18.8461L20.1156 18.141L22.0771 14.7436C22.2822 14.3846 22.3848 13.9872 22.3848 13.5897H15.333L16.8335 16.5384L18.8592 19.6923Z"
        fill="#EA4335"
      />
      <path
        d="M11.1926 6.41026L14.7183 0.307693C14.3721 0.102564 13.9747 0 13.5644 0H8.82084C8.41058 0 8.01315 0.115385 7.66699 0.307693L11.1926 6.41026Z"
        fill="#00832D"
      />
      <path
        d="M15.3331 13.5897H7.05103L3.52539 19.6923C3.87154 19.8974 4.26898 20 4.67924 20H17.7049C18.1151 20 18.5126 19.8846 18.8587 19.6923L15.3331 13.5897Z"
        fill="#2684FC"
      />
      <path
        d="M18.8206 6.7948L15.5642 1.15377C15.359 0.794797 15.0642 0.512745 14.718 0.307617L11.1924 6.41018L15.3334 13.5897H22.3719C22.3719 13.1922 22.2693 12.7948 22.0642 12.4358L18.8206 6.7948Z"
        fill="#FFBA00"
      />
    </svg>
  );
}

export function DropboxSvg() {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.26817 0L0.384766 3.74835L6.26817 7.4967L12.1526 3.74835L6.26817 0Z"
        fill="#0061FF"
      />
      <path
        d="M18.0365 0L12.1526 3.74869L18.0365 7.49739L23.9205 3.74869L18.0365 0Z"
        fill="#0061FF"
      />
      <path
        d="M6.26817 14.9941L0.384766 11.2457L6.26817 7.49739L12.1526 11.2457L6.26817 14.9941Z"
        fill="#0061FF"
      />
      <path
        d="M18.0365 7.49739L12.1526 11.2461L18.0365 14.9947L23.9204 11.2461L18.0365 7.49739Z"
        fill="#0061FF"
      />
      <path
        d="M12.1531 20L6.26867 16.2517L12.1531 12.5033L18.0365 16.2517L12.1531 20Z"
        fill="#0061FF"
      />
    </svg>
  );
}

export function PlusSvg() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.431 9.09764L1.45753 9.2091"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 1.66663L8.88854 16.6401"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function IndigoTick(props) {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`flex-none ${props.className}`}
    >
      <circle cx="15" cy="15.5" r="15" fill="#5843BD" />
      <path
        d="M12.4631 19.0991L21.218 10.794C21.4246 10.598 21.6702 10.5 21.9546 10.5C22.239 10.5 22.4842 10.598 22.6901 10.794C22.8967 10.99 23 11.2229 23 11.4927C23 11.7625 22.8967 11.9951 22.6901 12.1904L13.1862 21.206C12.9796 21.402 12.7386 21.5 12.4631 21.5C12.1877 21.5 11.9466 21.402 11.74 21.206L7.29799 16.9922C7.09138 16.7962 6.99221 16.5633 7.00048 16.2935C7.00874 16.0237 7.11652 15.7911 7.32381 15.5958C7.53042 15.3998 7.77594 15.3018 8.06036 15.3018C8.34479 15.3018 8.58996 15.3998 8.79588 15.5958L12.4631 19.0991Z"
        fill="white"
      />
    </svg>
  );
}

export function CopySvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.75 3H7.1875C7.08437 3 7 3.08437 7 3.1875V4.5C7 4.60312 7.08437 4.6875 7.1875 4.6875H18.8125V20.8125C18.8125 20.9156 18.8969 21 19 21H20.3125C20.4156 21 20.5 20.9156 20.5 20.8125V3.75C20.5 3.33516 20.1648 3 19.75 3ZM16.75 6H4.75C4.33516 6 4 6.33516 4 6.75V19.1883C4 19.3875 4.07969 19.5773 4.22031 19.718L8.28203 23.7797C8.33359 23.8312 8.39219 23.8734 8.45547 23.9086V23.9531H8.55391C8.63594 23.9836 8.72266 24 8.81172 24H16.75C17.1648 24 17.5 23.6648 17.5 23.25V6.75C17.5 6.33516 17.1648 6 16.75 6ZM9.20312 22.5H9.19844L5.6875 18.9891V18.9844H9.20312V22.5Z"
        fill="#5843BD"
      />
    </svg>
  );
}

export function ShareSvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 16.08C17.24 16.08 16.56 16.38 16.04 16.85L8.91 12.7C8.96 12.47 9 12.24 9 12C9 11.76 8.96 11.53 8.91 11.3L15.96 7.19C16.5 7.69 17.21 8 18 8C19.66 8 21 6.66 21 5C21 3.34 19.66 2 18 2C16.34 2 15 3.34 15 5C15 5.24 15.04 5.47 15.09 5.7L8.04 9.81C7.5 9.31 6.79 9 6 9C4.34 9 3 10.34 3 12C3 13.66 4.34 15 6 15C6.79 15 7.5 14.69 8.04 14.19L15.16 18.35C15.11 18.56 15.08 18.78 15.08 19C15.08 20.61 16.39 21.92 18 21.92C19.61 21.92 20.92 20.61 20.92 19C20.92 17.39 19.61 16.08 18 16.08Z"
        fill="#5843BD"
      />
    </svg>
  );
}

export function ShareIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 16.08C17.24 16.08 16.56 16.38 16.04 16.85L8.91 12.7C8.96 12.47 9 12.24 9 12C9 11.76 8.96 11.53 8.91 11.3L15.96 7.19C16.5 7.69 17.21 8 18 8C19.66 8 21 6.66 21 5C21 3.34 19.66 2 18 2C16.34 2 15 3.34 15 5C15 5.24 15.04 5.47 15.09 5.7L8.04 9.81C7.5 9.31 6.79 9 6 9C4.34 9 3 10.34 3 12C3 13.66 4.34 15 6 15C6.79 15 7.5 14.69 8.04 14.19L15.16 18.35C15.11 18.56 15.08 18.78 15.08 19C15.08 20.61 16.39 21.92 18 21.92C19.61 21.92 20.92 20.61 20.92 19C20.92 17.39 19.61 16.08 18 16.08Z"
        fill="#fff"
      />
    </svg>
  );
}

export function BackSvg() {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.65625 16.4062H29.5312C29.8213 16.4062 30.0995 16.5215 30.3046 16.7266C30.5098 16.9317 30.625 17.2099 30.625 17.5C30.625 17.7901 30.5098 18.0683 30.3046 18.2734C30.0995 18.4785 29.8213 18.5938 29.5312 18.5938H7.65625C7.36617 18.5938 7.08797 18.4785 6.88285 18.2734C6.67773 18.0683 6.5625 17.7901 6.5625 17.5C6.5625 17.2099 6.67773 16.9317 6.88285 16.7266C7.08797 16.5215 7.36617 16.4062 7.65625 16.4062Z"
        fill="#FF6633"
      />
      <path
        d="M8.10906 17.4994L17.1806 26.5688C17.386 26.7742 17.5014 27.0527 17.5014 27.3432C17.5014 27.6336 17.386 27.9122 17.1806 28.1176C16.9753 28.3229 16.6967 28.4383 16.4063 28.4383C16.1158 28.4383 15.8373 28.3229 15.6319 28.1176L5.78813 18.2738C5.68627 18.1722 5.60546 18.0515 5.55032 17.9186C5.49518 17.7857 5.4668 17.6433 5.4668 17.4994C5.4668 17.3556 5.49518 17.2131 5.55032 17.0802C5.60546 16.9474 5.68627 16.8267 5.78813 16.7251L15.6319 6.8813C15.8373 6.67593 16.1158 6.56055 16.4063 6.56055C16.6967 6.56055 16.9753 6.67593 17.1806 6.8813C17.386 7.08668 17.5014 7.36523 17.5014 7.65568C17.5014 7.94613 17.386 8.22468 17.1806 8.43005L8.10906 17.4994Z"
        fill="#FF6633"
      />
    </svg>
  );
}

export function JobTitleSvg() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1251_7187)">
        <path
          d="M18.3336 2.72266H1.66688C1.51954 2.72266 1.37823 2.78119 1.27405 2.88537C1.16986 2.98956 1.11133 3.13087 1.11133 3.27821V4.20599C1.11143 4.3372 1.1375 4.4671 1.18804 4.58819C1.23857 4.70929 1.31257 4.81918 1.40577 4.91155L7.778 11.3782V17.0449L8.88911 17.4671V11.056C8.88953 10.9829 8.87551 10.9104 8.84787 10.8427C8.82022 10.775 8.77948 10.7135 8.728 10.6615L2.22244 4.16155V3.83377H17.778V4.17266L11.2947 10.6615C11.2391 10.7116 11.1943 10.7723 11.1628 10.8401C11.1313 10.9079 11.1138 10.9813 11.1113 11.056V18.3949L12.2224 18.8338V11.3338L18.5947 4.94488C18.6894 4.85017 18.7642 4.7375 18.8148 4.61348C18.8653 4.48945 18.8906 4.35658 18.8891 4.22266V3.27821C18.8891 3.13087 18.8306 2.98956 18.7264 2.88537C18.6222 2.78119 18.4809 2.72266 18.3336 2.72266Z"
          fill="#CCCCCC"
        />
      </g>
      <defs>
        <clipPath id="clip0_1251_7187">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function JobTypeSvg() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.125 11.125V18H1.875V11.125M10 14.25V11.75M12.5 5.5C12.5 5.5 12.5 3 10 3C7.5 3 7.5 5.5 7.5 5.5M1.25 5.5H18.75V10.5C18.75 10.5 15 13 10 13C5 13 1.25 10.5 1.25 10.5V5.5Z"
        stroke="#CCCCCC"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function LocationSvg() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 11.75C9.38193 11.75 8.77775 11.5667 8.26384 11.2233C7.74994 10.88 7.3494 10.3919 7.11288 9.82089C6.87635 9.24987 6.81447 8.62154 6.93505 8.01534C7.05563 7.40915 7.35325 6.85233 7.79029 6.41529C8.22733 5.97825 8.78415 5.68063 9.39034 5.56005C9.99654 5.43947 10.6249 5.50135 11.1959 5.73788C11.7669 5.9744 12.255 6.37494 12.5983 6.88884C12.9417 7.40275 13.125 8.00693 13.125 8.625C13.124 9.4535 12.7945 10.2478 12.2086 10.8336C11.6228 11.4195 10.8285 11.749 10 11.75ZM10 6.75C9.62916 6.75 9.26665 6.85997 8.95831 7.066C8.64997 7.27202 8.40964 7.56486 8.26773 7.90747C8.12581 8.25008 8.08868 8.62708 8.16103 8.9908C8.23338 9.35451 8.41195 9.6886 8.67418 9.95083C8.9364 10.2131 9.27049 10.3916 9.63421 10.464C9.99792 10.5363 10.3749 10.4992 10.7175 10.3573C11.0601 10.2154 11.353 9.97504 11.559 9.6667C11.765 9.35835 11.875 8.99584 11.875 8.625C11.8745 8.12787 11.6768 7.65125 11.3253 7.29972C10.9738 6.9482 10.4971 6.7505 10 6.75Z"
        fill="#CCCCCC"
      />
      <path
        d="M10 19.25L4.72751 13.0319C4.65425 12.9385 4.58174 12.8445 4.51001 12.75C3.60937 11.5636 3.12282 10.1145 3.12501 8.625C3.12501 6.80164 3.84934 5.05295 5.13865 3.76364C6.42796 2.47433 8.17664 1.75 10 1.75C11.8234 1.75 13.5721 2.47433 14.8614 3.76364C16.1507 5.05295 16.875 6.80164 16.875 8.625C16.8772 10.1138 16.3909 11.5623 15.4906 12.7481L15.49 12.75C15.49 12.75 15.3025 12.9963 15.2744 13.0294L10 19.25ZM5.50751 11.9969C5.50876 11.9969 5.65376 12.1894 5.68688 12.2306L10 17.3175L14.3188 12.2237C14.3463 12.1894 14.4925 11.9956 14.4931 11.995C15.2289 11.0257 15.6265 9.84189 15.625 8.625C15.625 7.13316 15.0324 5.70242 13.9775 4.64752C12.9226 3.59263 11.4918 3 10 3C8.50816 3 7.07742 3.59263 6.02253 4.64752C4.96764 5.70242 4.37501 7.13316 4.37501 8.625C4.3737 9.84265 4.77112 11.0271 5.50751 11.9969Z"
        fill="#CCCCCC"
      />
    </svg>
  );
}

export function JobDurationSvg() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 11.75C9.38193 11.75 8.77775 11.5667 8.26384 11.2233C7.74994 10.88 7.3494 10.3919 7.11288 9.82089C6.87635 9.24987 6.81447 8.62154 6.93505 8.01534C7.05563 7.40915 7.35325 6.85233 7.79029 6.41529C8.22733 5.97825 8.78415 5.68063 9.39034 5.56005C9.99654 5.43947 10.6249 5.50135 11.1959 5.73788C11.7669 5.9744 12.255 6.37494 12.5983 6.88884C12.9417 7.40275 13.125 8.00693 13.125 8.625C13.124 9.4535 12.7945 10.2478 12.2086 10.8336C11.6228 11.4195 10.8285 11.749 10 11.75ZM10 6.75C9.62916 6.75 9.26665 6.85997 8.95831 7.066C8.64997 7.27202 8.40964 7.56486 8.26773 7.90747C8.12581 8.25008 8.08868 8.62708 8.16103 8.9908C8.23338 9.35451 8.41195 9.6886 8.67418 9.95083C8.9364 10.2131 9.27049 10.3916 9.63421 10.464C9.99792 10.5363 10.3749 10.4992 10.7175 10.3573C11.0601 10.2154 11.353 9.97504 11.559 9.6667C11.765 9.35835 11.875 8.99584 11.875 8.625C11.8745 8.12787 11.6768 7.65125 11.3253 7.29972C10.9738 6.9482 10.4971 6.7505 10 6.75Z"
        fill="#CCCCCC"
      />
      <path
        d="M10 19.25L4.72751 13.0319C4.65425 12.9385 4.58174 12.8445 4.51001 12.75C3.60937 11.5636 3.12282 10.1145 3.12501 8.625C3.12501 6.80164 3.84934 5.05295 5.13865 3.76364C6.42796 2.47433 8.17664 1.75 10 1.75C11.8234 1.75 13.5721 2.47433 14.8614 3.76364C16.1507 5.05295 16.875 6.80164 16.875 8.625C16.8772 10.1138 16.3909 11.5623 15.4906 12.7481L15.49 12.75C15.49 12.75 15.3025 12.9963 15.2744 13.0294L10 19.25ZM5.50751 11.9969C5.50876 11.9969 5.65376 12.1894 5.68688 12.2306L10 17.3175L14.3188 12.2237C14.3463 12.1894 14.4925 11.9956 14.4931 11.995C15.2289 11.0257 15.6265 9.84189 15.625 8.625C15.625 7.13316 15.0324 5.70242 13.9775 4.64752C12.9226 3.59263 11.4918 3 10 3C8.50816 3 7.07742 3.59263 6.02253 4.64752C4.96764 5.70242 4.37501 7.13316 4.37501 8.625C4.3737 9.84265 4.77112 11.0271 5.50751 11.9969Z"
        fill="#CCCCCC"
      />
    </svg>
  );
}


export function ForwardSvg() {
  return (
    <svg width="9" height="18" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 9.00086L1 16.3809" stroke="#797979" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M9 9L1 1.62" stroke="#797979" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  )
}
