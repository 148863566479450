import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import timeResolver from "../../Job/timeresolver";
import { useSelector, useDispatch } from "react-redux";

export default function JobPostItem({ isEmployee, ...props }) {
  const navigate = useNavigate();
  const log_stat = useSelector(e => e.login);
  const AcctType = log_stat.profile_status.AcctType;
  // console.log("AcctType", AcctType);
  // const [isEmployee, setIsEmployee] = useState(false);
  isEmployee ? true : false;

  return (
    <div className="w-full py-4 job_shadow rounded-xl">
      <div className="w-full md:flex flex-col sm:flex-row px-4 justify-between items-center bg-white gap-3">
        <div className="w-full flex flex-col">
        <div className="flex gap-3 items-center">
            <img src={props.logo} className="w-8 h-8" alt={props?.jobPosition}/>
            <div className="flex flex-col">
              <p className="text-black text-base font-medium whitespace-nowrap">
                {props?.jobPosition}
              </p>
              <p className="text-black text-opacity-50 text-xs font-normal">
                {timeResolver(props?.postAge)}
              </p>
            </div>
          </div>
        </div>
        <div className="w-full md:flex md:items-center justify-between gap-2 tablet:gap-4 sm:justify-end vsm:mt-4 md:mt-0">
          <button
            className={`w-full ${
              isEmployee ? " tablet:max-w-[180px]" : "vsm:w-full md:max-w-[180px]"
            } py-3 px-5 rounded-lg shadow border bg-indigo-700 text-base border-neutral-50\
                    border-opacity-20 text-white `}
                    // idk where im to navigate to yet 
            onClick={isEmployee ? () => navigate(props?.whereWeGoin) : () => navigate(props?.urlToDetails)}
          >
            {props.display == true ? <span>View details</span> : <span>View job</span>}
          </button>
        </div>
      </div>
    </div>
  );
}
