const Sp_personal = [
  {
    value: 'Gestión del Tiempo',
    label: 'Gestión del Tiempo',
  },
  {
    value: 'Trabajo en Equipo',
    label: 'Trabajo en Equipo',
  },
  {
    value: 'Comunicación',
    label: 'Comunicación',
  },
  {
    value: 'Resolución de Problemas',
    label: 'Resolución de Problemas',
  },
  {
    value: 'Adaptabilidad',
    label: 'Adaptabilidad',
  },
  {
    value: 'Atención al Detalle',
    label: 'Atención al Detalle',
  },
  {
    value: 'Resistencia Física',
    label: 'Resistencia Física',
  },
  {
    value: 'Pensamiento Crítico',
    label: 'Pensamiento Crítico',
  },
  {
    value: 'Habilidades Mecánicas',
    label: 'Habilidades Mecánicas',
  },
  {
    value: 'Organización',
    label: 'Organización',
  },
  {
    value: 'Toma de Decisiones',
    label: 'Toma de Decisiones',
  },
  {
    value: 'Liderazgo',
    label: 'Liderazgo',
  },
  {
    value: 'Pensamiento Analítico',
    label: 'Pensamiento Analítico',
  },
  {
    value: 'Análisis de Problemas',
    label: 'Análisis de Problemas',
  },
  {
    value: 'Habilidades Interpersonales',
    label: 'Habilidades Interpersonales',
  },
  {
    value: 'Auto-Motivación',
    label: 'Auto-Motivación',
  },
  {
    value: 'Liderazgo de Equipo',
    label: 'Liderazgo de Equipo',
  },
  {
    value: 'Iniciativa',
    label: 'Iniciativa',
  },
  {
    value: 'Servicio al Cliente',
    label: 'Servicio al Cliente',
  },
  {
    value: 'Flexibilidad',
    label: 'Flexibilidad',
  },
  {
    value: 'Conciencia de la Seguridad',
    label: 'Conciencia de la Seguridad',
  },
  {
    value: 'Orientación al Detalle',
    label: 'Orientación al Detalle',
  },
  {
    value: 'Destreza Física',
    label: 'Destreza Física',
  },
  {
    value: 'Solución de Problemas',
    label: 'Solución de Problemas',
  },
  {
    value: 'Cooperación',
    label: 'Cooperación',
  },
  {
    value: 'Perseverancia',
    label: 'Perseverancia',
  },
  {
    value: 'Resolución de Conflictos',
    label: 'Resolución de Conflictos',
  },
  {
    value: 'Toma de Decisiones',
    label: 'Toma de Decisiones',
  },
  {
    value: 'Planificación',
    label: 'Planificación',
  },
  {
    value: 'Orientación a Objetivos',
    label: 'Orientación a Objetivos',
  },
  {
    value: 'Construcción de Equipos',
    label: 'Construcción de Equipos',
  },
  {
    value: 'Habilidades de Escucha',
    label: 'Habilidades de Escucha',
  },
  {
    value: 'Iniciativa',
    label: 'Iniciativa',
  },
  {
    value: 'Resolución de Problemas',
    label: 'Resolución de Problemas',
  },
  {
    value: 'Atención al Detalle',
    label: 'Atención al Detalle',
  },
  {
    value: 'Enfoque en el Cliente',
    label: 'Enfoque en el Cliente',
  },
  {
    value: 'Actitud Positiva',
    label: 'Actitud Positiva',
  },
  {
    value: 'Ética de Trabajo',
    label: 'Ética de Trabajo',
  },
  {
    value: 'Adaptabilidad',
    label: 'Adaptabilidad',
  },
  {
    value: 'Responsabilidad',
    label: 'Responsabilidad',
  },
  {
    value: 'Escucha Activa',
    label: 'Escucha Activa',
  },
  {
    value: 'Calma Bajo Presión',
    label: 'Calma Bajo Presión',
  },
  {
    value: 'Confianza en Uno Mismo',
    label: 'Confianza en Uno Mismo',
  },
  {
    value: 'Colaboración en Equipo',
    label: 'Colaboración en Equipo',
  },
  {
    value: 'Actitud Positiva',
    label: 'Actitud Positiva',
  },
  {
    value: 'Ética de Trabajo',
    label: 'Ética de Trabajo',
  },
  {
    value: 'Creatividad',
    label: 'Creatividad',
  },
  {
    value: 'Resolución de Conflictos',
    label: 'Resolución de Conflictos',
  },
  {
    value: 'Resolución de Problemas',
    label: 'Resolución de Problemas',
  },
  {
    value: 'Adaptabilidad',
    label: 'Adaptabilidad',
  },
  {
    value: 'Flexibilidad',
    label: 'Flexibilidad',
  },
  {
    value: 'Gestión del Tiempo',
    label: 'Gestión del Tiempo',
  },
  {
    value: 'Liderazgo',
    label: 'Liderazgo',
  },
  {
    value: 'Comunicación',
    label: 'Comunicación',
  },
  {
    value: 'Toma de Decisiones',
    label: 'Toma de Decisiones',
  },
  {
    value: 'Pensamiento Crítico',
    label: 'Pensamiento Crítico',
  },
  {
    value: 'Análisis de Problemas',
    label: 'Análisis de Problemas',
  },
  {
    value: 'Habilidades Interpersonales',
    label: 'Habilidades Interpersonales',
  },
  {
    value: 'Servicio al Cliente',
    label: 'Servicio al Cliente',
  },
  {
    value: 'Iniciativa',
    label: 'Iniciativa',
  },
  {
    value: 'Atención al Detalle',
    label: 'Atención al Detalle',
  },
  {
    value: 'Trabajo en Equipo',
    label: 'Trabajo en Equipo',
  },
  {
    value: 'Actitud Positiva',
    label: 'Actitud Positiva',
  },
  {
    value: 'Adaptabilidad',
    label: 'Adaptabilidad',
  },
  {
    value: 'Dependabilidad',
    label: 'Dependabilidad',
  }
  
];


export default Sp_personal;

