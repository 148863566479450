import React, { useEffect, useState } from "react";
import UserResume from "./screens/moil_user_resume";
import UserResumeType from "./screens/moil_user_resume_type";
import TopBar from "../topbar/Top-Bar";
import SideBar from "../sidebar/sidebar";
import UserPreference from "./screens/moil_user_preference";
import UserEducation from "./screens/moil_user_education";
import UserWorkExperience from "./screens/moil_user_work";
import UserSKillsAndLicensing from "./screens/moil_user_license";
import UserVolunteerExperience from "./screens/moil_user_volunteer";
import UserAwardAndHonor from "./screens/moil_user_award";
import UserReference from "./screens/moil_user_reference";
import UserLanguage from "./screens/moil_user_language";
import UserJobType from "./screens/moil_user_job_type";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import $ from 'jquery';
import { Professional_summary, dispatchSkills, dispatchPskills, dispatchAward, resume_step_add_function, resume_step_sub_function, returnHome, Cv_id, cleanEX, resume_step_jump_function } from "../../Redux/ActionCreator";
import { getOneFromServerCache } from "../../utils/saveIntoCache";

export default function UserResumeForm() {
  const params = useParams();
	console.log('params', params);
  const dispatch = useDispatch();
  const id = useSelector(id => id.cvId);
  const existingCv = useSelector(e => e.existingCV);
  const message = existingCv.message;
  const educ = useSelector(edu => edu.education);
  const exp = useSelector(exp => exp.activities);
  const personal_data = useSelector(p => p.personal_info)
  const technicalSkill = useSelector(sk => sk.skills);
  const personalSkill = useSelector(pk => pk.p_skills);
  const voluntary = useSelector(vol => vol.voluntary)
  const award_data = useSelector(awd => awd.award)
  const refe = useSelector(ref => ref.reference)
  const title = useSelector(title => title.jobTitle);
  const summary = useSelector(sum => sum.summary);

  const [resumeData, setResumeData] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    linkedIn: "",
    website: "",
    education: [],
    workExperience: [],
    jobSkills: [],
    proudSkills: [],
    certificationAffirm: false,
    certifications: [],
    volunteerExperience: [],
    Awards: [],
    langauges: [],
  });
 
  let step = useSelector(step => step.resumeStep);

  const [ education, setEducation ] = useState([]);
  const [ experience, setExperience ] = useState([]);
  const [ skills, setSkills ] = useState([]);
  const [ personal_skills, setPersonal_skills ] = useState([]);
  let [ volunteer, setVolunteer ] = useState([]);
  const [ award, setAward ] = useState([]);
  const [ reference, setReference ] = useState([]);
  const [ language, setLanguage ] = useState([]);
  const [ cvtype, setCvtype] = useState([]);
  const [ sum, setSummary] = useState("");

  // useEffect(() => {
  //   if (!window.location.pathname.includes("update")) {
  //     console.log("Step was gotten back from the server since the route does not include 'update'")
  //     // get the last key here.
  //     let cacheStep = getOneFromServerCache("step");
  //     cacheStep.then(res => res.json())
  //     .then(res => {
  //       if (res.success === true) {
  //         if (res.data !== undefined) {
  //           let resObj = JSON.parse(res.data);
  //           console.log("resObj type and step", typeof resObj, resObj);
  //           if (resObj > 1) {
  //             dispatch(resume_step_jump_function(resObj));
  //           }
  //         }
  //       } else if (res.success === false && res.message == "Unauthorized") {
  //         dispatch(returnHome(res.status));
  //       }
  //     })
  //   }
  // }, [])

  useEffect(() => {
    dispatch(Cv_id(params));
    dispatch(cleanEX());
    if (message !== null && message !== undefined) {
      const firstname = message.Fullname?.split(" ")[0];
      const lastname = message.Fullname?.split(" ")[1];
        $('[name="firstname"]').val(firstname),
        $('[name="lastname"]').val(lastname),
        $('[name="address"]').val(message.Address),
        $('[name="email"]').val(message.Email),
        $('[name="linkedin"]').val(message.Linkedin),
        $('[name="website"]').val(message.Website),
        $('[name="phone_number"]').val(message.Phone_number);
        $('[name="cvtype"]').val(message.CvType);

        
        if (educ.education !== null) {
          setEducation(educ.education);
        } else {
          if (message?.Education !== null && message?.Education !== undefined) {
            let edu = new Array();
            message?.Education.forEach(element => {
              if (element.hasOwnProperty("key")) {
                edu.push(element);
              } else {
                element.key = message?.Education.indexOf(element);
                edu.push(element);
              }
            })
            setEducation(edu);
          }
        }

      if (exp.activities !== null) {
        setExperience(exp.activities);
      } else {
        if (message?.Experience !== null) {
          let exp = new Array();
          message?.Experience.forEach(element => {
            if (element.hasOwnProperty("key")) {
              exp.push(element);
            } else {
              element.key = message?.Experience.indexOf(element);
              exp.push(element);
            }
          })
          setExperience(exp);
        }
      }

      if (technicalSkill.skills !== null && technicalSkill.skills !== undefined) {
        setSkills(technicalSkill.skills);
      } else {
        if (message.Technical_skills !== null && message.Technical_skills !== undefined) {
          dispatch(dispatchSkills(message.Technical_skills))
          setSkills(message.Technical_skills);
        }
      }

      if (personalSkill.p_skills !== null && personalSkill.p_skills !== undefined) {
        setPersonal_skills(personalSkill.p_skills)
      } else {
        if (message.Personal_skills !== null && message.Personal_skills !== undefined) {
          dispatch(dispatchPskills(message.Personal_skills))
          setPersonal_skills(message.Personal_skills)
        }
      }

      if (voluntary.voluntary !== null) {
        setVolunteer(voluntary.voluntary);
      } else {
        if (message.voluntary !== null || message.voluntary !== undefined) {
          setVolunteer(message.voluntary);
        } else {
          volunteer = [];
        }
      }

      if (award_data.award !== null && award_data.award !== undefined) {
        setAward(award_data.award);
      } else {
        if (message.Award !== null && message.Award !== undefined) {
          dispatch(dispatchAward(message.Award))
          setAward(message.Award);
        }
      }

      if (refe.ref !== null) {
        setReference(refe.ref);
      } else {
        if (message.reference !== null || message.reference !== undefined) {
          setReference(message.reference)
        }
      }

      if (title.jobtitle !== null && title.jobtitle !== undefined) {
        setCvtype(title.jobtitle)
      } else {
        if (message.CvType !== null && message.CvType !== undefined) {
          setCvtype(message.CvType);
        }
      }

      if (summary.summary !== null && summary.summary !== undefined) {
        $('[name="goal"]').val(summary.summary);
      } else {
        if (message.Professional_summary !== null && message.Professional_summary !== undefined) {
          dispatch(Professional_summary(message.Professional_summary))
          $('[name="goal"]').val(message.Professional_summary);
        }
      }

    } else {

      if (personal_data.message !== null) {
        $('[name="firstname"]').val(personal_data.message.Firstname);
        $('[name="lastname"]').val(personal_data.message.LastName);
        $('[name="address"]').val(personal_data.message.Address);
        $('[name="email"]').val(personal_data.message.Email);
        $('[name="phone_number"]').val(personal_data.message.Phone_number);
        $('[name="linkedin"]').val(personal_data.message.Linkedin);
        $('[name="website"]').val(personal_data.message.Website);
      }

      if (educ.education !== null) {
        setEducation(educ.education);
      }

      if (exp.activities !== null) {
        setExperience(exp.activities);
      }
      if (technicalSkill.skills !== null) {
        setSkills(technicalSkill.skills);
      }
      if (personalSkill.p_skills !== null) {
        setPersonal_skills(personalSkill.p_skills)
      }

      if (voluntary.voluntary !== null) {
        setVolunteer(voluntary.voluntary);
      }
      if (award_data.award !== null) {
        setAward(award_data.award);
      }
      if (refe.ref !== null) {
        setReference(refe.ref);
      }
      if (title.jobtitle !== null && title.jobtitle !== undefined) {
        setCvtype(title.jobtitle)
      }
      if (summary.summary !== null && summary.summary !== undefined) {
        $('[name="goal"]').val(summary.summary);
      }
    }
  }, [])


/*   const handleSubmit = () => {}; */

  const handleStepChange = () => {
    if (step !== 8) {
      dispatch(resume_step_add_function());
    }
  };

  const handleStepChangeBack = () => {
    if (step > 0) {
      dispatch(resume_step_sub_function());
    }
  };

  const handlejump = (step) => {
    dispatch(resume_step_jump_function(step));
  }

  return (
    <div>
        {
            step === 0 ? <UserPreference handleStepChange={handleStepChange} handleStepChangeBack={handleStepChangeBack} handlejump={handlejump}/> : 
            step === 1 ? <UserWorkExperience handleStepChange={handleStepChange}  handleStepChangeBack={handleStepChangeBack} experience={experience} handlejump={handlejump}/> :
            step === 2 ? <UserEducation handleStepChange={handleStepChange}  handleStepChangeBack={handleStepChangeBack} education={education} handlejump={handlejump}/> :
            step === 3 ? <UserSKillsAndLicensing handleStepChange={handleStepChange}  handleStepChangeBack={handleStepChangeBack} skills={skills} personal_skill={personal_skills} handlejump={handlejump}/> :
            step === 4 ? <UserJobType handleStepChange={handleStepChange}  handleStepChangeBack={handleStepChangeBack} title={cvtype} sum={sum} handlejump={handlejump}/>:
            step === 5 ? <UserReference handleStepChange={handleStepChange}  handleStepChangeBack={handleStepChangeBack} reference={reference}  handlejump={handlejump}/>:
            step === 6 ? <UserVolunteerExperience handleStepChange={handleStepChange}  handleStepChangeBack={handleStepChangeBack} volunteer={volunteer} handlejump={handlejump}/> : <UserAwardAndHonor handleStepChange={handleStepChange}  handleStepChangeBack={handleStepChangeBack} award={award} handlejump={handlejump}/>
        }
    </div>
  );
}
