import { createPortal } from "react-dom";
import { GamefiedVector } from "../../Resume/svgs/svgs";
import { Link } from "react-router-dom";

export default function NoResumeModal({ OpenInterviewModal, Closemodal, profileLink }) {
	return (
		<div
			onClick={Closemodal}
			className={`min-w-screen w-[100vw] min-h-screen bg-tet-600 backdrop-blur-[2px] z-[9996] ${OpenInterviewModal
				? "flex fixed top-0 left-0 justify-center items-center"
				: "hidden relative"
				}`}
		>
			<section onClick={(e) => e.stopPropagation()} className="bg-white md:max-w-[600px] sm:max-w-[500px] min-w-[350px] w-[80%] z-100 m-auto rounded-[24px] pb-5 overflow-hidden relative">
				<div className="w-full max-w-[660px] py-4 md:py-8 flex justify-center items-center bg-[#FF6633] border-b-8 border-[#E9F1FF] rounded-tr-[24px] rounded-tl-[24px] ">

					<div className="flex flex-col gap-4 items-center w-max">
						<svg
							width="64"
							height="64"
							viewBox="0 0 64 64"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect
								x="-2.12935"
								y="2.12935"
								width="59.7413"
								height="59.7413"
								rx="29.8706"
								transform="matrix(-1 0 0 1 59.7413 0)"
								stroke="white"
								strokeWidth="4.25871"
							/>
							<path
								d="M62 32C62 48.6 48.6 62 32 62C15.4 62 2 48.6 2 32C2 15.4 15.4 2 32 2C48.6 2 62 15.4 62 32Z"
								fill="#FFDD67"
							/>
							<path
								d="M16.3 37.6002C16.3 46.2002 4.60005 46.2002 4.60005 37.6002C4.60005 31.3002 10.4001 25.2002 10.4001 25.2002C10.4001 25.2002 16.3 31.3002 16.3 37.6002Z"
								fill="#65B1EF"
							/>
							<path
								d="M25.5 40C22.7386 40 20.5 37.7614 20.5 35C20.5 32.2386 22.7386 30 25.5 30C28.2614 30 30.5 32.2386 30.5 35C30.5 37.7614 28.2614 40 25.5 40Z"
								fill="#664E27"
							/>
							<path
								d="M48.5 40C45.7386 40 43.5 37.7614 43.5 35C43.5 32.2386 45.7386 30 48.5 30C51.2614 30 53.5 32.2386 53.5 35C53.5 37.7614 51.2614 40 48.5 40Z"
								fill="#664E27"
							/>
							<path
								d="M43.4002 19.9001C46.6002 22.6001 50.9002 23.8001 55.1002 23.0001C55.7002 22.9001 56.2002 25.0001 55.5002 25.2001C50.7002 26.1001 45.7002 24.7001 42.0002 21.6001C41.5002 21.1001 43.0002 19.5001 43.4002 19.9001ZM18.9002 22.9001C23.1002 23.6001 27.4002 22.5001 30.6002 19.8001C31.0002 19.4001 32.6002 21.0001 32.0002 21.5001C28.3002 24.7001 23.3002 26.0001 18.5002 25.1001C17.8002 24.9001 18.3002 22.8001 18.9002 22.9001Z"
								fill="#917524"
							/>
							<path
								d="M25.9001 47.3C31.7001 45.8 37.9001 46.9 42.8001 50.3C44.0001 51.2 41.7001 54.3 40.5001 53.5C37.3001 51.2 32.1001 49.7 26.8001 51.1C25.5001 51.4 24.4001 47.8 25.9001 47.3Z"
								fill="#664E27"
							/>
						</svg>

						<p className="text-[24px] leading-[1.2] text-center md:text-start text-white font-semibold">
							You don't have a resume <br />
							to apply for this job!
						</p>
					</div>

				</div>

				<div className="hidden md:block absolute top-[-10%] -z-1 left-[-10%]">
					<GamefiedVector />
				</div>

				<div className="flex flex-col items-center relative z-[1000] justify-center">
					<div className="max-w-[80%] flex flex-col pt-6 pb-8 md:pb-12 gap-y-6">
						<p className="text-base leading-[1.1] text-center text-black font-semibold">
							But guess what?<br className="md:hidden"/> You can create one now.
						</p>

						<p className="text-base leading-[1.5] max-w-[400px] text-center text-black font-normal">
							Click the button below to create your resume and apply for this job.
						</p>

						<div className="flex flex-col md:flex-row items-center justify-between gap-x-4 gap-y-4">
							<Link to={`/upload?noResume=${window.location.pathname.split("/")[2]}`} className="py-4 text-base w-full md:w-max leading-[1.5] rounded-[28px] flex items-center justify-center text-white px-8 bg-[#FF6633]">Create my resume</Link>

							<Link to={profileLink} className="text-[#FF6633] text-base leading-[1.5] py-4">Create resume from profile</Link>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

