import React, { useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AppliedJobsItem from "../common/applied_jobs";
import {
  getJobsApplied,
  getSavedJobs,
  search_total_page,
  total_page,
  search_success,
} from "../../Redux/ActionCreator";
import { useEffect } from "react";
import SearchBarsOnTop from "../common/SearchBars/SearchBarsOnTop";
import JobDetailItem from './job_detail_item';
import JobInterest from "../common/job_interest";
import PaginationBtn from "../common/paginationBtn";
import JobDetailItemMobile from "./job_detail_item_mobile";
import { baseURL } from "../../Redux/baseURL";
import HorizontalNFilterIcon from "../common/HorizontalNFilterIcon";
import DashboardQueryItem from "../common/dashboard_query_item";
import LoaderModal from "../common/LoaderModal";
import { Link } from "react-router-dom";

export default function EmployeeJobs() {
  const dispatch = useDispatch();
  const profile = useSelector((e) => e.profile);
  const jobsAppliedState = useSelector((e) => e.jobsApplied);
  const jobsApplied = jobsAppliedState.message?.Jobs;
  const savedJobsState = useSelector((e) => e.saved_jobs);
  const savedJobs = savedJobsState.message?.joblist;
  const userInfoState = useSelector((e) => e.userInfo);
  const userInfo = userInfoState.user;
  const [name, setName] = useState("");
  const [hideSearchResults, setHideSearchResults] = useState(false);
  const [OurSearchLoading, setOurSearchLoading] = useState(false);
  const [searchJob, setSearchJob] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const [JobListData, setJobListData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [initialSearchDone, setInitialSearchDone] = useState(false);
  const [ourError, setOurError] = useState("");
  let [suggestion, setSuggestion] = useState([]);
  let [hideJob, setHideJob] = useState(false);
  const total_page = useSelector((e) => e.total_page);
  const ourtotalpage = total_page.message;
  const [totalPages, setTotalPages] = useState(1);
  const [currentDashboardPage, setCurrentDashboardPage] = useState(1);
  const searchedJob = useSelector(e => e.searchResult.message);
  const seachTotalPage = useSelector(e => e.seach_total_page.message);
  const [totalJobs, setTotalJobs] = useState(0);

  const searchParams = new URLSearchParams(location.search);
  let title = searchParams.get("title");
  let loc = searchParams.get("location");
  let page = searchParams.get("page");

  // Job Search
  function HandleCancelJobSearch() {
    setSearchJob("");
    HandleOverAllSearch();
    setHideSearchResults(false);
  }

  //    Location Search
  function HandleCancelLocationSearch() {
    setSearchLocation("");
    HandleOverAllSearch();
    setHideSearchResults(false);
  }

  const setQueryParams = (params) => {
    const searchParams = new URLSearchParams(location.search);

    Object.keys(params).forEach(key => {
      searchParams.set(key, params[key]);
    });
    navigate(location.pathname + "?" + searchParams.toString());
  };


  const HandleOverAllSearch = async () => {

    if (loc) {
      setSearchLocation(loc);
    }
    if (title) {
      setSearchJob(title);
    }

    if (searchJob || searchLocation !== "") {
      setHideSearchResults(true);
      setInitialSearchDone(true);
      setOurSearchLoading(true);
      setOurError("");

      const queryParams = [];
      if (searchJob) {
        queryParams.push(`title=${encodeURIComponent(searchJob.toLowerCase().trim())}`);
      }
      if (searchLocation) {
        queryParams.push(
          `location=${encodeURIComponent(searchLocation.toLowerCase().trim())}`
        );
      }
      if (currentPage) {
        queryParams.push(`limit=10&page=${currentPage}&flag=Client`);
      }
      const queryString = queryParams.join("&");
      // setQueryParams({ title: searchJob, location: searchLocation, page: currentPage });
      try {
        await fetch(
          baseURL + `/api/job/search_job_posts?${queryString}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              // Authorization: "Bearer " + localStorage.getItem("token"),
            },
            credentials: "include"
          }
        )
          .then((res) => res.json())
          .then((data) => {
            if (data.success) {
              const respJson = data;
              setOurSearchLoading(false);
              setHideJob(true);
              setJobListData(respJson?.data["Job posts"]);
              dispatch(search_success(respJson?.data["Job posts"]));
              dispatch(search_total_page(respJson?.data.number_of_pages));
              setTotalPages(respJson?.data.number_of_pages);
              setTotalJobs(respJson?.data?.number_of_matches);
              // console.log("OurJobListData 🚀🚀🚀🚀", JobListData);
              // console.log("RespJsonUserSearch 🚀🚀🚀🚀", respJson);
            } else {
              setOurError("No records match this search criteria");
              setOurSearchLoading(false);
            }
          });
      } catch (error) {
        // console.error("Error fetching data:", error);
        setOurSearchLoading(false);
      }
    }
  };

  const memoizedFetchData = useMemo(() => {
    const fetchData = async () => {
      if (profile.message !== null) {
        if (profile.message?.Fullname !== null) {
          let n = profile?.message?.Fullname.split(" ")[1].slice(
            1,
            profile.message?.Fullname.length - 1
          );
          setName(
            `${profile?.message?.Fullname.split(" ")[1]
              .charAt(0)
              .toUpperCase()}` + `${n.toLowerCase()}`
          );
        }
        dispatch(getJobsApplied(currentDashboardPage)); // pass in the page number
        dispatch(getSavedJobs());
      }
    }
    return fetchData;
  }, [profile.message, currentDashboardPage])

  useEffect(() => {
    memoizedFetchData();
  }, [memoizedFetchData]);

  useEffect(() => {
    // console.log("currentPage", currentPage);
    // console.log("initialSearchDone", initialSearchDone);
    if (initialSearchDone) {
      HandleOverAllSearch();
      // debouncedSearch();
    }
  }, [currentPage, initialSearchDone]);

  // console.log("name", name);

  const getDate = (date) => {
    // console.log("date", date);
    if (date !== null && date !== undefined) {
      let split_date = date.split("T")[0].split("-");
      const return_date =
        split_date[2] +
        "/" +
        split_date[1] +
        "/" +
        split_date[0][2] +
        split_date[0][3];
      return return_date;
    }
  };


  return (
    <div className="h-calc-70 bg-[#FAFAFA] overflow-y-scroll flex justify-center w-full px-6 sm:px-4 py-8">
      <div className="max-w-[900px] w-full xtraxl:min-w-[1000px] xtraxl:max-w-[1200px] flex flex-col gap-y-6">
        <div className='w-full max-w-[500px]'>
          <p className='font-semibold text-[36px] leading-normal text-[#000000DE]'>My Jobs</p>
          <p className='font-normal leading-normal text-base text-[#000000DE]'>Here, you can find your jobs, job interests and take needed <br />
            action on them.</p>
        </div>

        <div className="flex flex-col flex-row lg:flex-col xl:flex-row w-full justify-between items-center">

          <div className="px-0 md:px-6 py-4 bg-slate-50 w-full rounded-lg flex-col justify-start items-start gap-y-8 inline-flex">
            <p className="text-black text-opacity-60 text-xl font-medium font-['Poppins']">
              Quick Stats
            </p>
            <div className="self-stretch justify-start items-start gap-4 flex flex-col md:flex-row w-full">
              <div className="px-4 py-4 bg-[#5843BD] rounded-xl justify-start gap-2 flex w-full items-center md:items-start xl:w-max  flex-row md:flex-col">
                <p className="text-white text-4xl font-medium font-['Poppins'] leading-[50.40px]">
                  {`${jobsAppliedState.message?.Jobs?.length}` | "0"}
                </p>
                <p className="text-white text-sm font-normal font-['Poppins'] leading-tight">
                  Total number of jobs you <br />have completed
                </p>
              </div>
              <div className="px-4 py-4 bg-[#DDEAFF] rounded-xl justify-start gap-2 flex w-full items-center md:items-start xl:w-max flex-row md:flex-col">
                <p className="text-slate-800 text-4xl font-medium font-['Poppins'] leading-[50.40px]">
                  {`${savedJobsState.message?.length}` | "0"}
                </p>
                <p className="text-slate-800 text-sm font-normal font-['Poppins'] leading-tight">
                  The total number of jobs <br />interested in.
                </p>
              </div>
            </div>
          </div>

          {/* search section */}
          <div className="w-full xl:min-w-[400px] xtraxl:min-w-[450px]">
            <SearchBarsOnTop
              JobListData={JobListData}
              searchJob={searchJob}
              setSearchJob={setSearchJob}
              searchLocation={searchLocation}
              setSearchLocation={setSearchLocation}
              HandleCancelJobSearch={HandleCancelJobSearch}
              HandleCancelLocationSearch={HandleCancelLocationSearch}
              HandleOverAllSearch={HandleOverAllSearch}
              OurSearchLoading={OurSearchLoading}
              suggestion={suggestion}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              searchOnPage={'job'}
            />
          </div>
        </div>

        {
          hideJob ? (
            <>
              {title !== null || loc !== null || page !== null ? (
                <>
                  <div>
                    <HorizontalNFilterIcon />
                    <p className=" mt-[8px] pl-[26px] text-base font-normal text-black text-opacity-60">
                      Filter result
                    </p>
                  </div>

                  <p className=" mt-4 text-xl font-normal tablet:text-[28px] tablet:font-medium text-black text-opacity-60">
                    <span>{searchedJob?.length} {totalJobs !== 0 ? `out of ${totalJobs}` : null}</span> results found
                  </p>

                  <div className=" mt-4 grid grid-cols-1 ipad:grid-cols-2 tablet:gap-x-4 xtraxl:grid-cols-3 place-items-start gap-y-6">
                    {searchedJob?.map((items, index) => (
                      <DashboardQueryItem
                        experienceLevel=""
                        jobPosition={items.jobTitle}
                        profilePics={items?.logo}
                        employer={items?.companyName}
                        jobType={items?.jobType}
                        recruitmentDuration={items?.applicationDeadline}
                        JobLocation={`${items?.location?.city}, ${items?.location?.state}`}
                        key={index}
                        indeed={items?.indeed}
                        jobUrl={`/jobs/${items.custom_name}`}
                      />
                    ))}
                  </div>
                  <PaginationBtn
                    totalPages={seachTotalPage}
                    currentPage={page}
                    onNextPage={() => {
                      if (currentPage < seachTotalPage) {
                        setCurrentPage((prev) => prev + 1);
                        setInitialSearchDone(true);
                      }
                    }}
                    onPrevPage={() => {
                      if (currentPage > 1) {
                        setCurrentPage((prev) => prev - 1);
                        setInitialSearchDone(true);
                      }
                    }}
                    onSelectPage={(selectedPage) => {
                      setCurrentPage(selectedPage);
                      setInitialSearchDone(true);
                    }}
                  />
                </>
              ) : (
                <>
                  {ourError ? (<h1 className="mt-4 tablet:font-medium text-xl font-normal tablet:text-[28px] text-black text-opacity-60 w-full">
                    {ourError}
                  </h1>) :
                    <div className={`${hideSearchResults ? "block" : "hidden"} mt-8`}>
                      {OurSearchLoading ? (
                        // "Loading..."
                        <LoaderModal text="Loading..." />
                      ) : (
                        <>
                          <div>
                            <HorizontalNFilterIcon />
                            <p className=" mt-[8px] pl-[26px] text-base font-normal text-black text-opacity-60">
                              Filter result
                            </p>
                          </div>

                          <p className=" mt-4 text-xl font-normal tablet:text-[28px] tablet:font-medium text-black text-opacity-60">
                            <span>{JobListData?.length} {totalJobs !== 0 ? `out of ${totalJobs}` : null}</span> results found
                          </p>

                          <div className=" mt-4 grid grid-cols-1 ipad:grid-cols-2 tablet:gap-x-4 xtraxl:grid-cols-3 place-items-start gap-y-6">
                            {searchedJob?.map((items, index) => (
                              <DashboardQueryItem
                                experienceLevel=""
                                jobPosition={items.jobTitle}
                                profilePics={items?.logo}
                                employer={items?.companyName}
                                jobType={items?.jobType}
                                recruitmentDuration={items?.applicationDeadline}
                                JobLocation={`${items?.location?.city}, ${items?.location?.state}`}
                                key={index}
                                indeed={items?.indeed}
                                jobUrl={`/jobs/${items.custom_name}`}
                              />
                            ))}
                          </div>
                          <PaginationBtn
                            totalPages={seachTotalPage}
                            currentPage={currentPage}
                            onNextPage={() => {
                              if (currentPage < seachTotalPage) {
                                setCurrentPage((prev) => prev + 1);
                              }
                            }}
                            onPrevPage={() => {
                              if (currentPage > 1) {
                                setCurrentPage((prev) => prev - 1);
                              }
                            }}
                            onSelectPage={(selectedPage) => {
                              setCurrentPage(selectedPage);
                            }}
                          />
                        </>
                      )}
                    </div>}
                </>
              )}
            </>
          ) : (
            <>
              <div>
                <p class="text-xl font-medium leading-8 text-left text-black">
                  Applied Jobs
                </p>

                <p class="text-base font-medium leading-6 text-left text-[#00000099]">
                  You applied to the following jobs. Click on ‘View Details’ to see  plication status.
                </p>
              </div>


              <div>
                <table className="hidden md:table w-full rounded-lg shadow border border-[#5843BD]">
                  <thead className='bg-[#EFEFEF] rounded-tl-[8px] rounded-tr-[8px]'>
                    <tr className="leading-normal font-[200]  bg-[#5843BD] rounded-lg">
                      <th className="pl-8 py-3 w-[45%] lg:w-[35%] max-w-[270px]">
                        <p className="whitespace-nowrap truncate text-white text-start text-[20px] font-medium">Job Title</p>
                      </th>
                      <th className="w-[20%] max-w-[150px]p">
                        <p className="whitespace-nowrap truncate text-white text-start text-[20px] font-medium ">Date Applied</p>

                      </th>
                      <th className="w-[20%] pr-4">
                        <p className="whitespace-nowrap truncate text-white text-start text-[20px] font-medium ">Status</p>

                      </th>

                      <th className="w-full pr-8">
                        <p className="whitespace-nowrap truncate text-white text-start text-[20px] font-medium ">Action</p>

                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {jobsApplied?.length > 0 && jobsApplied?.map((job, key) => (
                      <>{job?.JobId !== null ?
                        <JobDetailItem
                          jobPosition={job?.JobId?.jobTitle}
                          profilePics={job?.JobId?.logo}
                          employer={job?.JobId?.companyName}
                          key={key}
                          jobUrl={`/jobs/${job?.JobId?.custom_name}?apply=applied`}
                          status={job?.status}
                          createdAt={job?.JobId?.createdAt}
                          date={getDate(job?.createdAt)}
                        /> : null}</>
                    )
                    )}
                  </tbody>
                </table>
                {
                  jobsApplied?.length === 0 || !jobsApplied &&
                  <div className="w-full md:shadow rounded-bl-lg rounded-br-lg md:jobShadow bg-[#DDEAFF33] md:bg-transparent h-[180px] flex justify-center items-center">
                    <p className=" text-base font-normal leading-[1.2] text-center max-w-[300px] md:max-w-[400px] px-4 py-6">
                      You have not applied for any jobs yet.  <br /> <br className="md:hidden" /><Link to={'/user/dashboard'} className="text-[#5843BD]">Click here </Link>to see recommended jobs or search
                      for jobs above.
                    </p>
                  </div>
                }
              </div>
              <div className="md:hidden flex gap-y-4  flex-col ">
                {jobsApplied?.map((job, key) => (
                  <>{job?.JobId !== null ?
                    <JobDetailItemMobile
                      jobPosition={job?.JobId?.jobTitle}
                      profilePics={job?.JobId?.logo}
                      employer={job?.JobId?.companyName}
                      key={key}
                      jobUrl={`/jobs/${job?.JobId?.custom_name}?apply=applied`}
                      status={job?.status}
                      createdAt={job?.JobId?.createdAt}
                      date={getDate(job?.createdAt)} /> : null}
                  </>
                )
                )}
              </div>
            </>
          )
        }

        <div className="pb-10">
          <div className="bg-[#FF66330D] p-4">
            <div className="bg-blue-100 bg-opacity-20 rounded-lg flex-col justify-start items-start gap-y-4 inline-flex w-full">
              <div className="justify-start items-center gap-2.5 inline-flex w-full">
                <div className="p-1 rounded shadow border border-orange-500 justify-start items-start gap-2.5 flex">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.25033 3.33399C3.71908 3.33399 1.66699 5.38607 1.66699 7.91732C1.66699 12.5007 7.08366 16.6673 10.0003 17.6365C12.917 16.6673 18.3337 12.5007 18.3337 7.91732C18.3337 5.38607 16.2816 3.33399 13.7503 3.33399C12.2003 3.33399 10.8295 4.10357 10.0003 5.28149C9.57769 4.67949 9.01623 4.18819 8.36346 3.84919C7.7107 3.51019 6.98587 3.33347 6.25033 3.33399Z"
                      fill="#FF6633"
                    />
                  </svg>
                </div>
                <p className="text-orange-500 text-xl font-medium font-['Poppins']">
                  My Job Interests
                </p>
              </div>
              <div className="self-stretch flex-col justify-start items-start gap-4 flex-wrap  flex">
                {savedJobs?.map((job, index) => {
                  return (
                    <JobInterest
                      jobTitle={job?.jobTitle}
                      createdAt={job?.createdAt}
                      companyName={job?.companyName}
                      jobId={job._id}
                      logo={job?.logo}
                      jobUrl={`/jobs/${job?.custom_name}`}
                    />
                  );
                })}
              </div>

              {
                savedJobs?.length === 0 || !savedJobs &&
                <div className="w-full h-[180px] flex justify-center items-center">
                  <p className=" text-base font-normal leading-[1.2] text-center max-w-[300px] md:max-w-[400px] px-4 py-6">
                    You have not saved any jobs yet.  <br /> <br className="md:hidden" /><Link to={'/user/dashboard'} className="text-[#5843BD]">Click here </Link>to see recommended jobs or search
                    for jobs above.
                  </p>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
