import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import DecisionCard from "../decison_card_message_component";
import { useParams } from "react-router-dom";
import { getDecisionCard } from "../../../../Redux/ActionCreator";
import axios from "axios";
import { baseURL } from "../../../../Redux/baseURL";

export default function MessageItem(props) {
  const params = useParams().receiverId;
  const dispatch = useDispatch();
  const employer = useSelector((e) => e.employer);
  const employee = useSelector((e) => e.profile);
  const login_stat = useSelector((e) => e.login);
  const userInfo = useSelector((state) => state.userInfo);
  let AcctType = login_stat.profile_status?.AcctType;
  const index = new URLSearchParams(window.location.search).get("i");
  const decisionCard = useSelector((e) => e.decisionCard);
  let cardText = "";
  let offerText = "";

  const [ opendecisioncard, setOpendecisioncard ] = useState(true);
  useEffect(() => {
    if (decisionCard.message === null || decisionCard.message === undefined) {
      if (
        props.message?.split(/[:&]/)?.[1] !== null ||
        props.message?.split(/[:&]/)?.[1] !== undefined
      ) {
        let cardId = props.message?.split(/[:&]/)?.[1];
        if (cardId !== undefined) {
          dispatch(getDecisionCard(cardId));
        }
      }
    }

    if (props.chat_information !== null && props.chat_information !== undefined) {
      // get the job Id
      // get the job from the db in order to know the job status.
      // set a state to either true or false if the job is still open or closed.
      // then that determines if the user can still open the offer card or not.
     // console.log("chat information", props.chat_information);
      let job = props.chat_information[0]?.chatIds[0]?.jobId; // now jobId is being populated.
      // let job = getJob(jobId);
      if (job?.availability == false) { // it means the job is closed;
        let hiredValue = job?.applications.filter(e => {
          if (e.applicant == userInfo.user?._id && e.stages == "Hired") {
            return e;
          }
        });
        if (hiredValue.length > 0) {
          setOpendecisioncard(true);
        } else {
          setOpendecisioncard(false);
        }
        // for (let i = 0; i < job?.applications.length; ++i) {
        //   if ((job?.applications[i].applicant == userInfo.user?._id) && (job?.applications[i].
        //     stages == "Hired")) {
        //       console.log("Was true");
        //     setOpendecisioncard(true);
        //   } else {
        //     console.log("Was false");
        //     setOpendecisioncard(false);
        //   }
        // }
      } else {
        setOpendecisioncard(true);
      }
    }
  }, []);

  const getJob = async (jobId) => {
    try {
      let job = await fetch(`${baseURL}/api/job/getAjobById/${jobId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        credentials: "include"
      }).then((res) => res.json())
     // console.log("job gotten by Id", job);
      return job;
    } catch (error) {
     // console.log("error", error);
    }
  }

  let classStyle;
  let chatImage;
  if (AcctType === "Official" && employer.message !== null) {
    if (props.isUser === employer.message?._id) {
      classStyle = "justify-end";
      chatImage =
        employer.message && employer.message?.photo
          ? employer.message?.photo
          : userInfo.user?.profile_pics;
      cardText = "You just sent an offer card to this candidate.";
      offerText = "Offer Card.";
    } else {
      classStyle = "justify-start";
      chatImage = props.receiverImage;
      cardText = `${props.neededName} has accepted your offer`;
      offerText = "Offer Accepted.";
      if (offerText === "Offer Accepted." && props.isDecisionCard) {
        classStyle = "justify-center";
      }
    }
  } else if (AcctType === "Client" && employee.message !== null) {
    if (props.isUser === employee.message?._id) {
      classStyle = "justify-end";
      chatImage =
        employee.message && employee.message?.photo
          ? employee.message?.photo
          : userInfo.user?.profile_pics;

      cardText = "You have accepted the offer.";
      offerText = "Offer Accepted.";
      if (offerText === "Offer Accepted." && props.isDecisionCard) {
        classStyle = "justify-center";
      }
    } else {
      classStyle = "justify-start";
      chatImage = props.receiverImage;
      cardText = "This employer just sent you an offer card.";
      offerText = "Offer Card.";
    }
  }
  return (
    <div
      className={`px-2 sm:px-6 flex mb-4 ${
        props.isDecisionCard & (AcctType === "Official") && "justify-end"
      } ${classStyle}`}
      ref={props.lastMessageRef}
    >
      <div className="flex items-end gap-x-4">
        <div
          className={`${
            props.isUser === employee.message?._id ||
            props.isUser === employer.message?._id
              ? ""
              : "order-2"
          }`}
        >
          {props.isDecisionCard ? (
            <DecisionCard
              acctType={AcctType}
              params={params}
              index={index}
              cardId={props.message?.split(/[:&]/)?.[1]}
              cardText={cardText}
              offerText={offerText}
              open={opendecisioncard}
            />
          ) : (
            <div
              className={`xsm:w-max w-full xsm:max-w-[300px] sm:max-w-[350px] px-4 py-3 ${
                (AcctType === "Client" && props.isUser === employee.message?._id) ||
                  (AcctType === "Official" && props.isUser === employer.message?._id)
                  ? "bg-indigo-700 rounded-tl-3xl rounded-tr-3xl rounded-bl-3xl"
                  : "bg-pry-980 rounded-tr-3xl rounded-tl-3xl rounded-br-3xl"
              }  justify-start items-start gap-2.5 inline-flex`}
            >
              <p
                className={`${
                  (AcctType === "Client" && props.isUser === employee.message?._id) ||
                  (AcctType === "Official" && props.isUser === employer.message?._id)
                    ? "text-white"
                    : "text-black"
                } text-xs font-normal font-['Poppins'] whitespace-pre-wrap`}
              >
                {props.message}
              </p>
            </div>
          )}
        </div>
        {(offerText !== "Offer Accepted." || !props.isDecisionCard) && (
          <img
            src={chatImage}
            className={`h-5 w-5 rounded-full  ${
              (AcctType === "Client" && props.isUser === employee.message?._id) ||
              (AcctType === "Official" && props.isUser === employer.message?._id)
                ? "self-end"
                : "self-end order-first"
            }`}
          />
        )}
      </div>
    </div>
  );
}
