import React, { useState, useEffect, useContext } from "react";
import Tab from "../tab/tab";
import TopBar from "../../topbar/Top-Bar";
import SideBar from "../../sidebar/sidebar";
import TextInput from "../text_input/text_input";
import { useDispatch, useSelector } from "react-redux";
import {
  dispatchReference,
  generate_my_cv,
  updateRef,
  saveReference,
  returnHome
} from "../../../Redux/ActionCreator";
import AddedItem from "../added_item/added_item";
import AddMore from "../add_more/add_more";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HelmetProvide from "../../common/helmet";
import { handleDeleteValue } from "../utils/handleDelete";
import { getOneFromServerCache, saveIntoServerCache } from "../../../utils/saveIntoCache";

export default function UserReference(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const existingCv = useSelector((e) => e.existingCV);
  const generated = useSelector((e) => e.generateCV);
  const cv = useSelector((prev) => prev.cvId);
  const profile1 = useSelector((e) => e.login);
  let stat = profile1.profile_status.profile_status;
  const secret = useSelector((prev) => prev.secret);
  const userInfo = useSelector((e) => e.userInfo);
  const message = existingCv.message;
  const profile = useSelector((e) => e.profile);
  const refe = useSelector((ref) => ref.reference);
  const [reference, setReference] = useState([]);
  const [chips, setChips] = useState([]);
  const [visibility, setVisibility] = useState(false);
  const [obj, setObj] = useState();
  
  useEffect(() => {
    let cacheInfo =  getOneFromServerCache("reference")
    cacheInfo.then(res => res.json())
    .then(res => {
      // console.log("server cache value", res);
      if (res.success == true) {
        if (res.data !== null && res.data !== undefined) {
          let resObj = JSON.parse(res.data);
          // console.log("respObj", resObj);
          dispatch(dispatchReference(resObj));
          setReference(resObj);
          setChips(resObj);
        }
        // console.log("Done");
      } else if (res.success === false && res.message == "Unauthorized") {
        dispatch(returnHome(res.status));
      }
    })
    if (!window.location.pathname.includes("update")) {
      // console.log("was called")
      saveIntoServerCache("step", 5);
    }
  }, []);


  useEffect(() => {
    if (refe.ref !== null && refe.ref !== undefined) {
      setReference(refe.ref);
      setChips(refe.ref);
    } else if (message !== null && message !== undefined) {
      if (message.Reference !== null && message.Reference !== undefined) {
        setReference(message.Reference);
        setChips(message.Reference);
      } else {
        setReference([]);
        setChips([]);
      }
    } else if (
      window.location.pathname === "/user/profile/update/reference" &&
      profile.message !== null
    ) {
      setVisibility(true);
      setReference(profile.message.Reference);
      setChips(profile.message.Reference);
    } else {
      setReference(reference);
    }
  }, []);

  useEffect(() => {
    if (obj !== undefined) {
      dispatch(generate_my_cv(obj));
    }
  }, [obj]);

  // console.log("reference", refe.ref)
  // console.log("reference real", reference)
  const handleClickOperation = (desiredKey) => {
    if (
      window.location.pathname === "/user/profile/update/reference" &&
      profile.message !== null
    ) {
      let r = reference;
      let desiredObject = r.find((item) => {
        if (item.key === undefined) {
          return item._id === desiredKey._id;
        } else {
          return item.key === desiredKey.key;
        }
      });
      let w = r.filter((chip) => {
        if (chip.key !== undefined) {
          return chip.key !== desiredObject.key;
        } else {
          return chip._id !== desiredObject._id;
        }
      });

      $('[name="ref_name"]').val(desiredObject.Reference_person_name);
      $('[name="ref_relationship"]').val(
        desiredObject.How_do_i_know_this_person
      );
      $('[name="job_title_work"]').val(desiredObject.Referencee_job_title);
      $('[name="ref_email"]').val(desiredObject.Reference_email);
      $('[name="ref_number"]').val(desiredObject.Reference_phone_number);

      // console.log("desired object", desiredObject);
      w.forEach((element) => {
        element.key = w.indexOf(element);
        // console.log("reference key", w.indexOf(element));
      });
      setReference(w);
      setChips(w);
      dispatch(dispatchReference(w));
      desiredObject = [];

      // console.log("inner chips", chips);
      // console.log("inner refence", w);
    } else {
      let r = reference;
      let desiredObject = r.find((item) => item.key === desiredKey.key);
      let w = r.filter((chip) => chip.key !== desiredObject.key);

      $('[name="ref_name"]').val(desiredObject.Reference_person_name);
      $('[name="ref_relationship"]').val(
        desiredObject.How_do_i_know_this_person
      );
      $('[name="job_title_work"]').val(desiredObject.Referencee_job_title);
      $('[name="ref_email"]').val(desiredObject.Reference_email);
      $('[name="ref_number"]').val(desiredObject.Reference_phone_number);

      // console.log("desired object", desiredObject);
      w.forEach((element) => {
        element.key = w.indexOf(element);
        // console.log("reference key", w.indexOf(element));
      });
      setReference(w);
      setChips(w);
      dispatch(dispatchReference(w));
      desiredObject = [];

      // console.log("inner chips", chips);
      // console.log("inner refence", w);
    }
  };

  // handleClick function
  const handleClick = (desiredKey) => {
    const ref = $('[name="ref_name"]').val();
    if (ref !== "") {
      handleAdd(); // add already mapped values back to the work array if the client/user forget to click add, this is so, so as to save users experience.
      handleClickOperation(desiredKey);
    } else {
      handleClickOperation(desiredKey);
    }
  };

  const handleDelete = (chipToDelete) => {
    let newref = handleDeleteValue(reference, chipToDelete, "key");
    setReference(newref);
    setChips(newref);
    dispatch(dispatchReference(newref));
    // console.log("chips", chips);
    // console.log("delete cert", reference);
  };

  const handleAdd = () => {
    if ($('[name="ref_name"]').val() === "") {
      setTimeout(() => {
        toast.error("About updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
    } else {
      let newRef = reference;

      let ref_num;
      if (
        $('[name="refCode"]').val() !== null &&
        $('[name="refCode"]').val() !== undefined
      ) {
        ref_num =
          $('[name="refCode"]').val() + " " + $('[name="ref_number"]').val();
      } else {
        ref_num = $('[name="ref_number"]').val();
      }
      let k = reference.length;
      const data = {
        key: k,
        Reference_person_name: $('[name="ref_name"]').val(),
        How_do_i_know_this_person: $('[name="ref_relationship"]').val(),
        Referencee_job_title: $('[name="job_title_work"]').val(),
        Reference_email: $('[name="ref_email"]').val(),
        Reference_phone_number: ref_num, // consdier this part for ny debug
        Review: true
      };

      newRef?.push(data);
      setReference(newRef);
      setChips(newRef);
      dispatch(dispatchReference(newRef));
      $('[name="ref_name"]').val("");
      $('[name="ref_relationship"]').val("");
      $('[name="job_title_work"]').val("");
      $('[name="ref_email"]').val("");
      $('[name="ref_number"]').val("");
      $('[name="refCode"]').val("");
    }
  };

  // console.log("window path", window.location.pathname);
  // console.log("window path", `/form/${cv.id}/update`);

  const handleReferenceNext = () => {
    if (
      window.location.pathname === "/user/profile/update/reference" &&
      profile.message !== null
    ) {
      // dispatch here
      dispatch(updateRef(reference));
      navigate(`/user/profile/:${userInfo.user.profile_id}`);
    } else if (window.location.pathname === `/form/${cv.id}/update`) {
      if ($('[name="ref_name"]').val() !== "") {
        handleAdd();
      }
      let generated_value = generated.message;
      generated_value.Reference = reference;
      setObj(generated_value);
      navigate("/allCv");
    } else {
      if ($('[name="ref_name"]').val() !== "") {
        setTimeout(() => {
          toast.error("Add the reference you just filled.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }, 50);
      } else {
        if (profile.message == null || (profile.message?.CompletionPercentage !== undefined && profile.message?.CompletionPercentage < 95)) {
          dispatch(saveReference(reference, secret.secret));
          saveIntoServerCache("reference", reference);
        }
        if (reference.length > 0) {
          let reviewInfo = reference.filter(e => !e.hasOwnProperty("Review"));
          if (reviewInfo.length > 0) {
            // throw an error 
            setTimeout(() => {
              toast.error("Please review the informations with red borders at the top.", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }, 50);
          } else {
            saveIntoServerCache("reference", reference);
            props.handlejump(4);
          }
        } else {
          props.handlejump(4);
        }
      }
    }
  };

  const handleBackBtn = () => {
    if ($('[name="ref_name"]').val() !== "") {
      setTimeout(() => {
        toast.error("Add the reference you just filled.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
    } else {
      props.handleStepChangeBack();
    }
  };

  const Years = [];

  for (var i = 1998; i <= 2023; i++) {
    var s = { value: i, label: i };
    Years.push(s);
  }

  return (
    <>
    <HelmetProvide
        title="Moil | Create Your Resume"
        description="Build your professional resume with Moil's easy-to-use resume creation form. Showcase your skills, experience, and qualifications to potential employers."
        className="notranslate"
      />
    <div className="grid grid-cols-auto md:grid-cols-smdash cont:grid-cols-dash grid-rows-dash">
      <TopBar topBarShowMenu={true} />
      <SideBar />
      <div className="px-3 lg:pl-6 py-8 w-full overflow-y-scroll ScrollOnly h-calc-70 flex justify-center">
        <div className="w-full xtraxl:max-w-calculate">
          <div className="w-full xtraxl:max-w-calculate max-w-[793px] h-[54px] bg-white bg-opacity-50 rounded-[40px] shadow border border-indigo-400 border-opacity-30 flex flex-col justify-center">
            <p className="text-stone-950 text-base md:text-[28px] font-semibold ml-12 max-[300px]:ml-6">
              Resume Text Capturing Form
            </p>
          </div>
          <p className="w-full max-w-[741px] xtraxl:max-w-calculate mt-2">
            <span className="text-black text-opacity-50 text-[14px] md:text-base font-normal px-3 lg:pl-6">
              Complete this page with any awards you have received in the past. If you have no awards, click on{" "}
              <span className="text-orange-500 text-[14px] md:text-base font-medium">
                'Skip this page'{" "}
              </span>
              at the bottom of the page.
            </span>
          </p>
          <div className="max-w-[800px] xtraxl:max-w-calculate w-full mt-8 p-8 bg-white bg-opacity-80 rounded-2xl shadow border border-indigo-400 border-opacity-30 flex-col justify-start items-start gap-16 inline-flex">
            <div className="tabarea justify-start items-center gap-6 inline-flex w-full flex-shrink-0 max-w-[calc(100vw-88px)] overflow-hidden">
              <Tab
                num="1"
                current={false}
                completed={true}
                className="max-[420px]:hidden"
              />
              <Tab
                num="2"
                current={false}
                completed={true}
                className="max-[420px]:hidden"
              />
              <Tab
                num="3"
                current={false}
                completed={true}
                className="max-[420px]:hidden"
              />
              <Tab
                num="4"
                current={false}
                completed={true}
                className="max-[420px]:hidden"
              />
              <Tab
                num="5"
                current={false}
                completed={true}
                className="max-[360px]:hidden"
              />
              <Tab num="6" current={false} completed={true} />
              <Tab num="7" current={false} completed={false} />
              <Tab
                label="Reference"
                current={true}
                completed={false}
              />
            </div>
            <div className="flex-col justify-start items-start gap-12 flex w-full">

              <div className="flex-col justify-start items-start gap-3 flex w-full">
                <div className="flex flex-wrap gap-3">
                  {chips?.map((ref, key) => (
                    <AddedItem
                      item={ref.Reference_person_name}
                      key={key}
                      color={ref.Review}
                      onClick={() => handleClick(ref)}
                      delete={() => handleDelete(ref)}
                    />
                  ))}
                </div>
              </div>
              <div className="flex-col justify-start items-start gap-2 flex w-full">
                <div className="flex gap-3 items-center">
                  <div className="px-1 w-2 h-2 rounded-full bg-black"></div>
                  <label
                    className="text-black text-base font-medium"
                    for="refreeName"
                  >
                    Who would you like to list as a reference?
                  </label>
                </div>
                <TextInput
                  placeholder="Enter their full name here"
                  id="refreeName"
                  name="ref_name"
                />
              </div>
              <div className="flex-col justify-start items-start gap-2 flex w-full">
                <div className="flex gap-3 items-center">
                  <div className="px-1 w-2 h-2 rounded-full bg-black"></div>
                  <label
                    className="text-black text-base font-medium"
                    for="relationship"
                  >
                    How do you know this person?
                  </label>
                </div>
                <input
                  type="text"
                  className="p-4 w-full rounded-lg border border-indigo-400 border-opacity-30"
                  placeholder="Answer here"
                  id="relationship"
                  name="ref_relationship"
                />
              </div>
              <div className="flex-col justify-start items-start gap-2 flex w-full">
                <div className="flex gap-3 items-center">
                  <div className="px-1 w-2 h-2 rounded-full bg-black"></div>
                  <label
                    className="text-black text-base font-medium"
                    for="refreeJobTitle"
                  >
                    What’s their job title and where do they work?
                  </label>
                </div>
                <TextInput
                  placeholder="Answer here"
                  id="refreeJobTitle"
                  name="job_title_work"
                />
              </div>
              <div className="flex-col justify-start items-start gap-2 flex w-full">
                <div className="flex gap-3 items-center">
                  <div className="px-1 w-2 h-2 rounded-full bg-black"></div>
                  <label
                    className="text-black text-base font-medium"
                    for="phoneNumber"
                  >
                    What is their phone number?
                  </label>
                </div>
                <div className="w-full flex gap-2">
                  <div className="w-[89px]">
                    <TextInput
                      placeholder="+1"
                      labelId="code"
                      name="refCode"
                      id="countryCode"
                    />
                  </div>
                  <div className="w-full">
                    <TextInput
                      placeholder="Phone number here"
                      id="phoneNumber"
                      name="ref_number"
                    />
                  </div>
                </div>
              </div>
              <div className="flex-col justify-start items-start gap-2 flex w-full">
                <div className="flex gap-3 items-center">
                  <div className="px-1 w-2 h-2 rounded-full bg-black"></div>
                  <label
                    className="text-black text-base font-medium"
                    for="refreeEmail"
                  >
                    What is their email address?
                  </label>
                </div>
                <input
                  type="email"
                  className="p-4 w-full rounded-lg border border-indigo-400 border-opacity-30 focus:outline-indigo-400"
                  placeholder="Enter your Email"
                  id="refreeEmail"
                  name="ref_email"
                />
              </div>
              <div className="flex-col justify-start items-start gap-3 flex w-full">
                <AddMore text="reference" onClick={handleAdd} />
                <p className="text-red-500 text-base font-medium">
                  {chips?.length == 0 ? "No reference yet." : "You can add more reference"}
                </p>
                {/* <div className="flex flex-wrap gap-3">
                  {chips?.map((ref, key) => (
                    <AddedItem
                      item={ref.Reference_person_name}
                      key={key}
                      onClick={() => handleClick(ref)}
                      delete={() => handleDelete(ref)}
                    />
                  ))}
                </div> */}
              </div>
            </div>
            <div className="self-stretch flex-col sm:flex-row justify-between items-start gap-4 inline-flex">
              {visibility === true ? null : (
                <button
                  className="px-5 py-3 rounded-lg shadow border border-orange-500 justify-center items-start gap-2.5 flex w-full sm:max-w-[200px]"
                  onClick={handleBackBtn}
                >
                  <span className="text-center text-orange-500 text-base font-semibold">
                    Previous
                  </span>
                </button>
              )}
              <button
                className="px-5 py-3 bg-indigo-700 rounded-lg shadow justify-center items-start gap-2.5 flex w-full sm:max-w-[200px]"
                onClick={handleReferenceNext}
              >
                <span className="text-center text-white text-base font-semibold whitespace-nowrap">
                  Save and Continue
                </span>
              </button>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
