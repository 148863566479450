import React, { useEffect, useState, useContext, useRef } from "react";
import Tab from "../tab/tab";
import TopBar from "../../topbar/Top-Bar";
import SideBar from "../../sidebar/sidebar";
import BasicSelect from "../select/select";
import Radio from "../radio/radio";
import AddedItem from "../added_item/added_item";
import AddMore from "../add_more/add_more";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { dispatchEducation, updateEducation, saveEducation, returnHome, generate_my_cv, save_secret, existing_resume,resume_step_jump_function } from "../../../Redux/ActionCreator";
import { convertEducation } from "../../../Redux/convertResume";
import $ from "jquery";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import dayjs from "dayjs";
import HelmetProvide from "../../common/helmet";
import { handleDeleteValue } from "../utils/handleDelete";
import { saveIntoServerCache, getOneFromServerCache } from "../../../utils/saveIntoCache";
import ViewProp from "../view_prop/view";
import { baseURL } from "../../../Redux/baseURL";
import LoaderModal from "../../common/LoaderModal/index.jsx";
import EducationCompleteGamifiedModal from "../../gamified_modals/education_complete_gamified_confirm_modal/index.jsx";
import { arrangeEducationByDate } from "../../../utils/pdfUtil/Adjust_gptRes.js";
import dateJson from "../../../utils/date_object.js"

export default function UserEducation(props) {
  const dispatch = useDispatch();
  const educ = useSelector(edu => edu.education);
  const existingCv = useSelector(e => e.existingCV);
  const generated = useSelector(e => e.generateCV);
  const cv = useSelector(prev => prev.cvId);
  const message = existingCv.message;
  const profile = useSelector(e => e.profile);
  const [isRedux, setRedux] = useState(false);
  let [education, setEducation] = useState(props.education ? props.education : []);
  let [chips, setChips] = useState(props.education ? props.education : []);
  const [startDateValue, setStartDateValue] = useState("");
  const [endDateValue, setEndDateValue] = useState("");
  const [startDateValue1, setStartDateValue1] = useState("");
  const [endDateValue1, setEndDateValue1] = useState("");
  const secret = useSelector(prev => prev.secret);
  const userInfo = useSelector(e => e.userInfo);
  const profile1 = useSelector(e => e.login);
  const sidebar = useSelector(e => e.sidebarOpen.message);
  let stat = profile1.profile_status.profile_status
  const [present, setPresent] = useState(false);
  // console.log("education", education);
  // console.log("chips", chips);
  let [path, setPath] = useState(false)
  const [obj, setObj] = useState();
  const [newEdu, setNewEdu] = useState();
  let [degreeState, setDegreeState] = useState("");
  const [id, setId] = useState();
  const [saving, setSaving] = useState(false);
  const [educationAllowed, setEducationAllowed] = useState(4);
  const [expStart, setExpStart] = useState("");
  const [expEnd, setExpEnd] = useState("");
  const [ reviewedChips, setReviewedChips ] = useState([]);

  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  let process = searchParams.get("create profile");
  // const [ sixty, setSixty ] = useState(false);

  const [language, setLanguage] = useState("");
  const schoolRef = useRef(null);

  const StyledDatePicker = styled(DatePicker)({
    '.MuiPickersToolbar-root': {
      color: '#1565c0',
      borderRadius: '10px',
      borderWidth: '1px',
      borderColor: '#2196f3',
      border: '1px solid',
    },
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8px 16px',
      width: "100%"
    },
    ':-khtml-any-link.MuiStack-root': {
      width: "100%"
    },
  })

  const scrollToEducationFields = () => {
    schoolRef.current?.scrollIntoView({ behavior: 'smooth' });
    schoolRef.current?.focus();
  }

  useEffect(() => {
    let SelectedLanguage = getOneFromServerCache("Language");
    SelectedLanguage.then(resp => resp.json())
      .then(resp => {
        // console.log("SelectedLanguage", resp.data);
        if (resp.success == true) {
          if (resp.data == null) {
            if (window.location.pathname !== "/user/profile/update/education" && profile.message !== null && profile.message?.CompletionPercentage == 100) {
              setTimeout(() => {
                toast.error("You need to select the language you want your resume to be in.", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }, 50);
              saveIntoServerCache("step", 0);
              dispatch(resume_step_jump_function(0));
            }
          } else {
            setLanguage(JSON.parse(resp.data));
          }
        }
        //  else if (resp.success === false && resp.message == "Unauthorized") {
        //   dispatch(returnHome(resp.status));
        // }
      }).catch(err => {
        // console.log("Language getting error", err);
      });
  }, []);

  // console.log("language", language);

  useEffect(() => {
    if ((education == null || education == undefined || education?.length == 0) && !window.location.pathname == "/user/profile/update/education") {
      let cacheInfo = getOneFromServerCache("education")
      cacheInfo.then(res => res.json())
        .then(res => {
          if (res.success == true) {
            // console.log("server cache value", res);
            if (res.data !== null && res.data !== undefined) {
              let resObj = JSON.parse(res.data);
              // console.log("respObj", resObj);
              dispatch(dispatchEducation(resObj));
              setChips(resObj);
              setEducation(resObj);
              // setReviewedChips(temp_edu);
            }
            // console.log("Done");
          } else if (res.success === false && res.message == "Unauthorized") {
            dispatch(returnHome(res.status));
          }
        })
    }
    // if (!window.location.pathname.includes("update")) {
    //   // console.log("was called")
    //   saveIntoServerCache("step", 1);
    // }
  }, []);



  useEffect(() => {
    if (
      window.location.pathname === "/user/profile/update/education" &&
      profile.message !== null && profile.message?.CompletionPercentage >= 95
    ) {
      setPath(true);
      for (let i = 0; i < profile.message.Education?.length; i++) {
        profile.message.Education[i].Review = true;
      }
      setChips(profile.message.Education);
      setEducation(profile.message.Education);
      let reviewEdu = profile.message.Education?.filter(e => e?.Review == true);
      setReviewedChips(reviewEdu);
    } else if (window.location.pathname === `/form/${cv.id}/update`) {
      setChips(generated.message?.Education);
      setEducation(generated.message?.Education);
      let reviewEdu = generated.message?.Education?.filter(e => e?.Review == true);
      let edu = new Array();
      reviewEdu?.forEach(element => {
        if (element.hasOwnProperty("key")) {
          edu.push(element);
        } else {
          element.key = reviewEdu?.indexOf(element);
          edu.push(element);
        }
      })
      setReviewedChips(edu);
    } else {
      if (educ.education !== null) {
        setChips(educ.education);
        setEducation(educ.education);
        let reviewEdu = educ.education?.filter(e => e?.Review == true);
        setReviewedChips(reviewEdu);
      } else if (message !== null) {
        if (message?.Education !== null && message?.Education !== undefined) {
          // check if education has keys
          let edu = new Array();
          message?.Education?.forEach(element => {
            if (element.hasOwnProperty("key")) {
              edu.push(element);
            } else {
              element.key = message?.Education.indexOf(element);
              edu.push(element);
            }
          })
          setChips(edu);
          setEducation(edu);
        } else {
          setChips([]);
          setEducation([]);
        }
      } else if (message === null) {
        const getExistingCv = async () => {
          fetch(baseURL + "/api/temp/getTempRes", {
            headers: {
              // "Authorization": `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
            credentials: "include"
          }).then(resp => resp.json())
            .then(resp => {
              if (resp.success === true) {
                // console.log("Temp response", resp);
                if (resp.data !== null) {
                  let response_data = new Array();
                  resp.data?.Education?.forEach(e => {
                    if (e.hasOwnProperty("key")) {
                      response_data.push(e);
                    } else {
                      e.key = resp.data?.Education.indexOf(e);
                      response_data.push(e);
                    }
                  });
                  dispatch(existing_resume(resp.data));
                  setChips(response_data);
                  setEducation(response_data);
                  dispatch(save_secret(resp.data?.Secret))
                }
              } else if (resp.success === false && resp.message == "Unauthorized") {
                dispatch(returnHome(resp.status));
              } else {
                setChips([]);
                setEducation([]);
              }
            }).catch(err => {
              // console.log("Error getting temp resume", err)
            })
        }
        getExistingCv();
      }
    }

    if (message !== null) {
      setRedux(true);
    }
  }, []);
  useEffect(() => {
    if (obj !== undefined) {
      dispatch(generate_my_cv(obj));
    }

    if (newEdu !== undefined) {
      dispatch(updateEducation(education));
    }
  }, [obj, newEdu])


  useEffect(() => {
    $('input[name="present"]').on("click", () => {
      setPresent(!present)
      if (present === true) {
        $('#present').prop('checked', false);
      } else {
        $('#present').prop('checked', true);
      }
    });
  }, [present]);
  // console.log("Edu_present", present)


  const [twentyModal, setTwentyModal] = useState(false);
  const [fourtyModal, setfourtyModal] = useState(false);
  const [sixtyModal, setsixtyModal] = useState(false);
  const [eightyModal, seteightyModal] = useState(false);
  const [nintyFiveModal, setnintyFiveModal] = useState(false);
  const [hundredModal, sethundredModal] = useState(false);
  const [processModal, setProcessModal] = useState(false);

  useEffect(() => {
    setSaving(false);
    if (profile.message !== null && processModal == true) {
      // if (profile.message?.CompletionPercentage >= 20 && profile.message?.CompletionPercentage < 40) {
      //   setTwentyModal(true)
      // } else if (profile.message?.CompletionPercentage  >= 40 && profile.message?.CompletionPercentage < 60) {
      //   setfourtyModal(true);
      // } else if (profile.message?.CompletionPercentage  >= 60 && profile.message?.CompletionPercentage < 80) {
      //   setsixtyModal(true);
      // } else if (profile.message?.CompletionPercentage  >= 80 && profile.message?.CompletionPercentage < 95) {
      //   seteightyModal(true);
      // } else if (profile.message?.CompletionPercentage  >= 95 && profile.message?.CompletionPercentage <= 100) {
      //   setnintyFiveModal(true);
      // } else if (profile.message?.CompletionPercentage === 100 && profile.message?.ProfileStatus !== "Completed") {
      //   sethundredModal(true);
      // }
      setsixtyModal(true);
    }
  }, [profile.message]);



  let mth = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"]
  let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

  const handleClickOperation = (arg) => {
    if (
      window.location.pathname === "/user/profile/update/education" &&
      profile.message !== null && profile.message?.CompletionPercentage >= 95
    ) {
      // do sth here
      if (education.length == 0 && reviewedChips.length > 0) {
        setEducation(reviewedChips);
      }
      const desiredObject = education.find((item) => item._id == arg._id);
      // console.log("desired object edu", desiredObject);
      let e = education.filter((chip) => chip._id !== desiredObject._id);
      // e.forEach((element) => {
      //   element._id = e.indexOf(element); // change the element key to their index number in the new eduction variable `e`;
      //   // console.log("edu new key", e.indexOf(element));
      // });
      var name = desiredObject.School;
      let startMonth;
      let startYear
      let endYear, endMonth;
      if (
        desiredObject.End_date !== null &&
        desiredObject.End_date !== undefined
      ) {
        if (desiredObject.End_date === "Present") {
          $('#present').prop('checked', true);
          setPresent(true);
        } else {
          setPresent(false);
          $('#present').prop('checked', false);
          endMonth = desiredObject.End_date.split(" ")[0];
          if ((isRedux == true && profile.message?.method !== "Manual" && profile.message.CompletionPercentage < 95)) {
            endYear = desiredObject.End_date.split(" ")[1];
          } else {
            endYear = `${parseInt(desiredObject.End_date.split(" ")[1], 10) + 1}`;
          }
          const secondMonth = new Date(endYear, mth.indexOf(endMonth));
          const secondYear = new Date(endYear);
          let sm = secondMonth.getTime();
          let sy = secondYear.getTime();
          // console.log("sm", sm);
          // console.log("sy", sy);
          if ((isRedux == true && profile.message?.method !== "Manual" && profile.message.CompletionPercentage < 95)) {
            let monthIndex = mth.indexOf(endMonth);
            setExpStart(months[monthIndex]);
            setExpEnd(endYear);
          } else {
            setStartDateValue1(sm);
            setEndDateValue1(sy);
          }
        }
      }

      // console.log(startMonth, startYear, endMonth, endYear);

      $('[name="school"]').val(name);
      setDegreeState(desiredObject.Degree);
      $('[name="course"]').val(desiredObject.Course);
      $('[name="award"]').val(desiredObject.Award);

      if ((isRedux == true && profile.message?.method !== "Manual" && profile.message.CompletionPercentage < 95)) {
        $('[name="course"]').val(desiredObject.Course);
        // $('[name="startDate"]').val(desiredObject.Start_date);
        $('[name="endDate"]').val(desiredObject.End_date);
      } else {
        $('[name="course"]').val(desiredObject.Course);
        $('[name="levelType"]').val(desiredObject.Degree);
      }

      // console.log(desiredObject);
      setEducation(e);
      setChips(e);
      if (e[0]?.Review !== undefined && e[0]?.Review == true) {
        setReviewedChips(e);
      }
      // console.log("inner education", e);
    } else {
      if (education.length == 0 && reviewedChips.length > 0) {
        setEducation(reviewedChips);
      }
      const desiredObject = education.find((item) => item.key === arg.key);
      // console.log("desired object edu", desiredObject);
      let e = education.filter((chip) => chip.key !== desiredObject.key);
      e?.forEach((element) => {
        element.key = e.indexOf(element);
        // console.log("edu new key", e.indexOf(element));
      });

      var name = desiredObject.School;
      let startMonth;
      let startYear;
      let endYear, endMonth;
      if (
        desiredObject.End_date !== null &&
        desiredObject.End_date !== undefined && desiredObject.End_date !== ""
      ) {
        if (desiredObject.End_date === "Present") {
          $('#present').prop('checked', true);
          setPresent(true);
        } else {
          setPresent(false);
          $('#present').prop('checked', false);
          endMonth = desiredObject.End_date.split(" ")[0];
          if ((isRedux == true && profile.message?.method !== "Manual" && profile.message.CompletionPercentage < 95)) {
            endYear = desiredObject.End_date.split(" ")[1];
          } else {
            endYear = `${parseInt(desiredObject.End_date.split(" ")[1], 10) + 1}`;
          }
          const secondMonth = new Date(endYear, mth.indexOf(endMonth));
          const secondYear = new Date(endYear);
          let sm = secondMonth.getTime();
          let sy = secondYear.getTime();
          // console.log("sm", sm);
          // console.log("sy", sy);
          if ((isRedux == true && profile.message?.method !== "Manual" && profile.message.CompletionPercentage < 95)) {
            let monthIndex = mth.indexOf(endMonth);
            setExpStart(months[monthIndex]);
            setExpEnd(endYear);
          } else {
            setStartDateValue1(sm);
            setEndDateValue1(sy);
          }
        }
      }

      // console.log(startMonth, startYear, endMonth, endYear);

      $('[name="school"]').val(name);
      setDegreeState(desiredObject.Degree);
      $('[name="course"]').val(desiredObject.Course);
      $('[name="award"]').val(desiredObject.Award);

      if ((isRedux == true && profile.message?.method !== "Manual" && profile.message.CompletionPercentage < 95)) {
        // $('[name="course"]').val(desiredObject.Course);
        // $('[name="startDate"]').val(desiredObject.Start_date);
        // $('[name="endDate"]').val(desiredObject.End_date);
      } else {
        $('[name="course"]').val(desiredObject.Course);
        $('[name="levelType"]').val(desiredObject.Degree);
      }

      // console.log(desiredObject);
      setEducation(e);
      setChips(e);
      if (e.length > 0 && e[0]?.Review !== undefined && e[0]?.Review == true) {
        setReviewedChips(e);
      }
      // console.log("inner education", e);
    }
  };

  const handleClick = (edu) => {
    if ($('[name="school"]').val() !== "") {
      handleAdd();
      handleClickOperation(edu);
    } else {
      handleClickOperation(edu);
    }
  };

  const handleEduNext = async () => {
    if (window.location.pathname === "/user/profile/update/education" && profile.message !== null && profile.message?.CompletionPercentage >= 95) {
      if ($('[name="school"]').val() !== "" || $('[name="course"]').val() !== "") {
        handleAdd();
      }
      dispatch(dispatchEducation(reviewedChips));
      dispatch(updateEducation(reviewedChips));
      navigate(`/user/profile/:${userInfo.user?.profile_id}`);
    } else if (window.location.pathname === `/form/${cv.id}/update`) {
      if ($('[name="school"]').val() !== "") {
        handleAdd();
        dispatch(dispatchEducation(education));
      }
      let generated_value = generated.message;
      generated_value.Education = reviewedChips;
      dispatch(dispatchEducation(reviewedChips));
      setObj(generated_value);
      navigate("/allCv");
    } else {
      if (degreeState == "No Formal Education") {
        props.handleStepChange();
      } else {
        if ($('[name="school"]').val() !== "") {
          setTimeout(() => {
            toast.error("Add the education you just filled.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }, 50);
        } else {
          // let try to filter the chips/info that hasn't been reviewed
          let unReviewedChip = chips?.filter(e => !(e.hasOwnProperty("Review")));
          // console.log(unReviewedChip);
          if (unReviewedChip.length > 0) {
            // throw an error toast
            setTimeout(() => {
              toast.error("Please review the informations with red border at the top.", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }, 50);
          } else {
            // console.log("Called education", education);
            if ((window.location.pathname == "/user/profile/update/education" && profile.message !== null && profile.message?.CompletionPercentage < 95) || process !== null) {
              setSaving(true);
            }
            dispatch(dispatchEducation(reviewedChips));
            if (profile.message !== null && profile.message?.CompletionPercentage == 100) {
              dispatch(convertEducation({ Education: reviewedChips, Lang: [{ lang: language }] }, "resume"));
            }
            if (profile.message == null || (profile.message?.CompletionPercentage !== undefined && profile.message?.CompletionPercentage < 95)) {
              dispatch(saveEducation(reviewedChips, secret.secret));
              // save into server cache
            }
            // save into server cache
            saveIntoServerCache("education", reviewedChips);
            saveIntoServerCache("step", 2)
            if ((window.location.pathname == "/user/profile/update/education" && profile.message !== null && profile.message?.CompletionPercentage < 95) || process !== null) {
              // setSixty(true);
              setProcessModal(true)
            } else {
              props.handleStepChange();
            }
          }
        }
      }
    }
  };

  const handlePreviousBTN = () => {
    if ($('[name="school"]').val() !== "") {
      setTimeout(() => {
        toast.error("Add the education you just filled.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
    } else {
      saveIntoServerCache("step", 0)
      if (window.location.pathname === "/user/profile/update/education" && profile.message !== null && profile.message?.CompletionPercentage < 95) {
        navigate("/user/profile/update/experience")
      } else {
        props.handleStepChangeBack();
      }
    }
  }

  const handleDelete = (edu) => {
    if (window.location.pathname === "/user/profile/update/education" && profile.message !== null && profile.message?.CompletionPercentage >= 95) {
      if (education.length == 1) {
        setTimeout(() => {
          toast.error("You must have at least one education", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }, 50);
      } else {
        const temp_edu = handleDeleteValue(edu?.Review !== undefined && edu?.Review == true ? reviewedChips : education, edu, "_id")
        if (edu?.Review !== undefined && edu?.Review == true) {
          setReviewedChips(temp_edu);
          dispatch(dispatchEducation(temp_edu));
        } else {
          setEducation(temp_edu);
          setChips(temp_edu);
          dispatch(dispatchEducation(temp_edu));
        }
      }
    } else {
      const temp_edu = handleDeleteValue(edu?.Review !== undefined && edu?.Review == true ? reviewedChips : education, edu, "key")
      if (edu?.Review !== undefined && edu?.Review == true) {
        setReviewedChips(temp_edu);
        dispatch(dispatchEducation(temp_edu));
      } else {
        setEducation(temp_edu);
        setChips(temp_edu);
        dispatch(dispatchEducation(temp_edu));
      }
    }
  };

  // handleAdd
  const handleAdd = () => {
    // console.log("startDateValue1", startDateValue1, typeof startDateValue1);
    // console.log("endDateValue1", endDateValue1);
    // console.log("expStart", expStart);
    // console.log("expEnd", expEnd);
    if (degreeState == "Certification" && $('[name="course"]').val() == "") {
      setTimeout(() => {
        toast.error("Please fill the course of study field.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
    } else if (($('[name="school"]').val() == "" || $('[name="endDate"]').val() == "") && degreeState !== "Certification") {
      setTimeout(() => {
        toast.error("Please fill form field before you can add.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
    } else if (((((isRedux == true && profile.message?.method !== "Manual" && profile.message.CompletionPercentage < 95) && expStart == "") || ((isRedux == true && profile.message.CompletionPercentage >= 95) && isNaN(startDateValue1) == true ) || ((isRedux == true && profile.message?.method !== "Manual" && profile.message.CompletionPercentage < 95) && expEnd == "") || ((isRedux == true && profile.message.CompletionPercentage >= 95) && isNaN(endDateValue1) == true)) && present == false) && degreeState !== "Certification") {
      setTimeout(() => {
        toast.error("Please dates cannot be empty.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
    } else if (((((isRedux == false && profile.message.CompletionPercentage >= 95) && startDateValue1 == "" ) || ((isRedux == false && profile.message.CompletionPercentage >= 95) && endDateValue1 == "" )) && present == false) && degreeState !== "Certification" ) {
      setTimeout(() => {
        toast.error("Please dates cannot be empty.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
    } else {
      let temp_edu = reviewedChips // education;
      var school = $('[name="school"]').val();
      var Degree = degreeState;
      var Course = $('[name="course"]').val();
      var Start_date;
      var End_date;
      var Award = $('[name="award"]').val();

      const date1 = new Date(startDateValue);
      const date2 = new Date(endDateValue);

      const date3 = new Date(startDateValue1);
      const date4 = new Date(endDateValue1);

      const month1 = date1.toLocaleDateString("en-US", { month: "short" });
      const year1 = date2.getFullYear();

      const month2 = date3.toLocaleDateString("en-US", { month: "short" });
      const year2 = date4.getFullYear();

      if ((isRedux == true && profile.message?.method !== "Manual" && profile.message.CompletionPercentage < 95)) {
        // Start_date = $('[name="startDate"]').val();
        if (present == true) {
          End_date = "Present";
        } else {
          End_date = expStart + " " + expEnd;
        }
      } else {
        // Start_date = month1 + " " + year1;
        if (present == true) {
          End_date = "Present";
        } else {
          End_date = month2 + " " + year2;
        }
      }
      // console.log("id", id);
      const k = education.length + 1;
      let educational_data;
      if (window.location.pathname === "/user/profile/update/education" && profile.message !== null && profile.message?.CompletionPercentage >= 95) {
        educational_data = {
          key: k,
          _id: id,
          School: school,
          Degree: Degree,
          Course: Course !== undefined && Course !== "N/A" ? Course : null,
          End_date: degreeState == "No Formal Education" ? null : End_date,
          Start_date: degreeState == "No Formal Education" ? null : Start_date,
          Award: Award,
          Review: true // this shows that this chip/info has been reviewed.
        };
      } else {
        educational_data = {
          key: k,
          School: school,
          Degree: Degree,
          Course: Course !== undefined && Course !== "N/A" ? Course : null,
          End_date: degreeState == "No Formal Education" ? null : End_date,
          Start_date: degreeState == "No Formal Education" ? null : Start_date,
          Award: Award,
          Review: true // this shows that this chip/info has been reviewed.
        };
      }
      // console.log("educational data", educational_data);
      temp_edu.push(educational_data);
      temp_edu?.forEach(element => {
        element.key = temp_edu.indexOf(element);
      });
      if (window.location.pathname === "/user/profile/update/education" && profile.message !== null && profile.message?.CompletionPercentage >= 95) {
        setNewEdu(temp_edu);
        setReviewedChips(temp_edu);
      } else {
        // setEducation(temp_edu);
        setReviewedChips(temp_edu);
        // setChips(education);
        dispatch(dispatchEducation(temp_edu));
        // save into server cache
        saveIntoServerCache("education", temp_edu);

      }

      // console.log("Education inner", education);
      // after adding to education, then return all fields back to null.
      $('[name="school"]').val("");
      setDegreeState("");
      setStartDateValue("");
      setEndDateValue("");
      setStartDateValue1("")
      setEndDateValue1("");
      $('#present').prop("checked", false);
      setPresent(false);
      $('[name="course"]').val("");
      $('[name="startDate"]').val("");
      $('[name="endDate"]').val("");
      $('[name="award"]').val("");
      setExpStart("");
      setExpEnd("");
    }

  }
  // console.log("startDateValue1", startDateValue1, typeof startDateValue1);
  // console.log("endDateValue1", endDateValue1);
  // console.log("expStart", expStart);
  // console.log("expEnd", expEnd);

// console.log("reviewedChips", reviewedChips);
  useEffect(() => {
    // Assuming the component renders an element with class 'flowbite-datepicker'
    const datePickerElement = document.querySelector('.MuiDialog-container');

    if (datePickerElement) {
      // Apply the notranslate attribute to prevent automatic translation
      datePickerElement.setAttribute('data-notranslate', 'true');
      // datePickerElement.addClass('notranslate');
    }
  }, []);

  // const twenty = !saving && twentyModal ? <TwentyPercentConfirmationGamifiedModal profileLink={`/user/profile/:${userInfo.user.profile_id}`} handleStepChange={props.handleStepChange} /> : null;
  // const fourty = !saving && fourtyModal ? <FortyPercentConfirmationGamifiedModal profileLink={`/user/profile/:${userInfo.user.profile_id}`} handleStepChange={props.handleStepChange} /> : null;
  const sixty = !saving && sixtyModal ? <EducationCompleteGamifiedModal profileLink={`/user/profile/:${userInfo.user.profile_id}`} handleStepChange={props.handleStepChange} /> : null;
  // const eighty = !saving && eightyModal ? <EightyPercentConfirmationGamifiedModal profileLink={`/user/profile/:${userInfo.user.profile_id}`} handleStepChange={props.handleStepChange} /> : null;
  // const nintyFive = !saving && nintyFiveModal ? <NinetyFivePercentConfirmationGamifiedModal profileLink={`/user/profile/:${userInfo.user.profile_id}`} handleStepChange={props.handleStepChange} /> : null;
  const savingloader = saving ? <LoaderModal text="Saving your information" /> : null;
  // let Acknowledge = sixty ? <SixtyPercentConfirmationGamifiedModal   profileLink={`/user/profile/:${userInfo.user.profile_id}`} handleStepChange={props.handleStepChange}  /> : null;

  return (
    <>
      <HelmetProvide
        title="Moil | Create Your Resume"
        description="Build your professional resume with Moil's easy-to-use resume creation form. Showcase your skills, experience, and qualifications to potential employers."
        className="notranslate"
      />

      <div className={`grid grid-cols-auto md:grid-cols-smdash ${sidebar ? 'cont:grid-cols-dash' : ''} grid-rows-dash`}>
        <TopBar topBarShowMenu={true} />
        <SideBar />
        <div className="bg-[#FAFAFA] px-3 lg:pl-6 py-8 w-full overflow-y-scroll ScrollOnly h-calc-70 flex justify-center">
          <div className="w-full xtraxl:max-w-calculate flex flex-col items-center">

            <p class="max-w-[900px] xtraxl:max-w-calculate w-full text-[24px] font-semibold leading-9 text-left text-black text-opacity-87">
              Complete your profile
            </p>
            <div className="max-w-[900px] xtraxl:max-w-calculate w-full py-5 rounded-2xl flex-col justify-start items-start gap-5 inline-flex">
              <div className="tabarea justify-start items-center gap-6 inline-flex w-full flex-shrink-0 max-w-[calc(100vw-88px)] overflow-hidden">
                <Tab
                  // label="Personal Information"
                  label="Personal Information"
                  current={false}
                  completed={true}
                  num="1"
                />
                <Tab num="2" current={false} label="Experience" completed={true} />
                <Tab num="3" current={true} label="Education" completed={false} />
                <Tab num="4" current={false} label="Skill/Language" completed={false} />
                <Tab num="5" current={false} label="Professional Summary" completed={false} />
              </div>

              <p class="text-base font-medium leading-6 text-left text-[#0D0D0D]">
                Fill this form to let employers know about your education.
              </p>


              {/* <div className="sm:mt-2 mb-4 py-2 px-4 sm:mb-2 sm:py-0">
                <h6 className="top-2 text-sm sm:text-[18px] lg:text-[24px] text-pry-920 leading-normal">
                  In this page, please add any and all previous education (High School, Certification, College, etc)
                </h6>
              </div> */}

              <div className="flex-col justify-start items-start gap-4 flex w-full rounded-[16px] bg-white resume_page py-6 px-4 ">

                <p class="text-xl font-semibold leading-8 text-left text-[#5843BD]">
                  Educational Information
                </p>

                {chips?.length > 0 && <div className="flex flex-col gap-y-4">
                  {chips?.length > 0 ? <h1 className="text-black text-base font-medium">Please update your pre loaded existing education added in the buttons below!</h1> : null}
                  {chips?.length > 0 && <div className="flex-col justify-start items-start gap-3 flex w-full">
                    <div className="flex flex-wrap gap-3">
                      {chips?.map((edu, key) => (
                        <>{edu?.Review == undefined ?
                          <AddedItem
                          item={edu.Degree == "No Formal Education" ? "No School" : edu.Degree == "Certification" ? edu.Course: edu.School}
                            key={key}
                            color={edu.Review}
                            onClick={() => handleClick(edu)}
                            delete={() => handleDelete(edu)}
                          /> : null}</>
                      ))}
                    </div>
                  </div>}
                </div>}

                <div className="flex w-full flex-col md:flex-row gap-y-4 items-center gap-x-8">
                  <div className="flex-col justify-start items-start gap-2 flex w-full">
                    <div className="flex gap-3 items-center">
                      <div className="p-[3px] w-1 h-1 rounded-full bg-black"></div>
                      <label
                        className="text-black text-base font-medium"
                        for="levelType"
                      >
                        What level of education do you have?
                      </label>
                    </div>
                    <div className="w-full flex">
                      <BasicSelect
                        placeholder="Select level"
                        labelId="level"
                        selectMenu="levelType"
                        value={degreeState}
                        id="levelType"
                        onChange={(event) => setDegreeState(event.target.value)}
                        menuItems={[
                          "No Formal Education",
                          "Primary Education",
                          "Secondary Education",
                          "High School Diploma",
                          "Certification",
                          "Vocational/Technical Training",
                          "Some College",
                          "Associate Degree",
                          "Bachelor's Degree",
                          "Master's Degree",
                          "PHD",
                        ]}
                      />
                    </div>
                  </div>

                  {degreeState == "No Formal Education" ? null : <div className="flex-col justify-start items-start gap-2 flex w-full">
                    <div className="flex gap-3 items-center">
                      <div className="p-[3px] w-1 h-1 rounded-full bg-black"></div>
                      <label
                        className="text-black text-base font-medium"
                        for="school"
                      >
                        Where did you go to school?
                      </label>
                    </div>
                    <input
                      type="text"
                      className="py-2 px-4 w-full rounded-lg border placeholder:text-[#0000004D] focus:ring-indigo-400 focus:border-indigo-400 border-indigo-400 border-opacity-30"
                      placeholder="Enter your School"
                      id="school"
                      name="school"
                      ref={schoolRef}
                    />
                  </div>}

                </div>

                {degreeState == "No Formal Education" ? null :
                  <>
                    {degreeState == "Primary Education" || degreeState == "Secondary Education" ? null :
                      <div className="flex-col justify-start items-start gap-2 flex w-full">
                        <div className="flex gap-3 items-center">
                          <div className="p-[3px] w-1 h-1 rounded-full bg-black"></div>
                          <label
                            className="text-black text-base font-medium"
                            for="course"
                          >
                            What course did you study ?
                          </label>
                        </div>
                        <div className="w-full flex">
                          <input
                            type="text"
                            className="py-2 px-4 w-full rounded-lg border placeholder:text-[#0000004D] focus:ring-indigo-400 focus:border-indigo-400 border-indigo-400 border-opacity-30"
                            placeholder="Philosophy, Science, Engineering, Archaeology ..."
                            id="course"
                            name="course"
                          />
                        </div>
                      </div>
                    }

                    <div className="flex-col justify-start items-start gap-2 flex w-full">
                      <div className="flex gap-3 items-center">
                        <div className="p-[3px] w-1 h-1 rounded-full bg-black"></div>
                        <label
                          className="text-black text-base font-medium"
                          for="endDate"
                        >
                          Are you currently studying this course
                        </label>
                      </div>

                      <Radio name="present" label="Present(I am currently studying)" id="present" opacity={true} />
                    </div>


                    {!present && <div className="flex-col justify-start items-start gap-2 flex w-full">
                      <div className="flex gap-3 items-center">
                        <div className="p-[3px] w-1 h-1 rounded-full bg-black"></div>
                        <label
                          className="text-black text-base font-medium"
                          for="endDate"
                        >
                          When did you finish your studies?
                        </label>
                      </div>
                      {/* {message !== null ? null : (
                        <p className="text-zinc-400 text-base font-medium">
                          Select the end month and year accordingly.
                        </p>
                      )} */}

                      {(isRedux == true && profile.message?.method !== "Manual" && profile.message.CompletionPercentage < 95) ? (
                        <div className="flex-col justify-start items-start gap-2 flex w-full">
                          <div className="flex gap-3 items-center"></div>
                          <div className="w-full flex flex-col sm:flex-row gap-2 gap-x-8 notranslate">
                            {/* <input
                              type="text"
                              className="p-4 w-full rounded-lg placeholder:text-[#0000004D] focus:ring-indigo-400 focus:border-indigo-400 border border-indigo-400 border-opacity-30"
                              placeholder="Month Year"
                              id="endDate"
                              name="endDate"
                            /> */}
                            <BasicSelect
                              placeholder="Month"
                              labelId="start_month"
                              selectMenu="start_month"
                              value={expStart}
                              onChange={(event) => setExpStart(event.target.value)}
                              id="startMonth"
                              menuItems={[...dateJson.months]}
                            />
                            
                            <BasicSelect
                              placeholder="Year"
                              labelId="start_year"
                              selectMenu="start_year"
                              value={expEnd}
                              onChange={(e) => setExpEnd(e.target.value)}
                              id="startYear"
                              menuItems={[...dateJson.years]}
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="w-full flex flex-col sm:flex-row gap-2 gap-x-8 notranslate">
                            <LocalizationProvider dateAdapter={AdapterDayjs} data-notranslate>
                              <DemoContainer
                                components={[
                                  "DatePicker",
                                  "DatePicker",
                                  "DatePicker",
                                ]}
                                className="notranslate w-full "
                                data-notranslate

                              >
                                <StyledDatePicker
                                  notranslate
                                  data-notranslate
                                  label="Month"
                                  views={["month"]}
                                  value={dayjs(startDateValue1)}
                                  onChange={(event) => setStartDateValue1(event)}
                                  disabled={present}

                                />
                              </DemoContainer>
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer
                                components={[
                                  "DatePicker",
                                  "DatePicker",
                                  "DatePicker",
                                ]} className="notranslate w-full "
                              >
                                <StyledDatePicker
                                  label="Year"
                                  name="endYear"
                                  views={["year"]}
                                  value={dayjs(endDateValue1)}
                                  onChange={(endValue) =>
                                    setEndDateValue1(endValue)
                                  }
                                  disabled={present}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </div>
                        </>
                      )}
                    </div>}
                  </>
                }
                <div className="w-full">
                  <div className="flex flex-col gap-y-4">
                    <div className="flex-col justify-start items-start gap-y-4 flex w-full">
                      <h6 className="text-[#FF6633] text-base font-medium">When completed click ‘Add Education’.  </h6>
                      <AddMore text="education" onClick={handleAdd} />
                      <p className={`${reviewedChips?.length == 0 ? "text-[#FF6633]" : "text-black"} text-[#FF6633] text-base font-medium`}>
                        {reviewedChips?.length == 0 ? "No education yet." : "You have added"}
                      </p>

                      <div className="flex-col justify-start items-start gap-3 flex w-full mt-[10px]">
                        {reviewedChips?.length > 0 ? 
                        <div className="flex flex-wrap gap-3">
                          {reviewedChips?.slice(0, educationAllowed)?.map((edu, key) => (
                            <>{edu?.Review !== undefined ?
                              <AddedItem
                                item={edu.Degree == "No Formal Education" ? "No School" : edu.Degree == "Certification" ? edu.Course: edu.School}
                                key={key}
                                color={edu.Review}
                                onClick={() => handleClick(edu)}
                                delete={() => handleDelete(edu)}
                              /> : null}</>
                          ))}
                        </div> : null}
                      </div>

                      {reviewedChips?.length > 4 && <ViewProp length={educationAllowed} normalLength={reviewedChips?.length} setAllowed={setEducationAllowed} />}

                      <button onClick={scrollToEducationFields} class="text-base font-medium leading-6 text-center px-5 py-2.5 gap-2.5 rounded-lg text-[#FF6633] flex gap-1 items-center">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 17V7" stroke="#FF6633" stroke-width="1.5" stroke-linecap="round" />
                          <path d="M7 12L17 12" stroke="#FF6633" stroke-width="1.5" stroke-linecap="round" />
                        </svg>
                        <>Add another education</>
                      </button>


                    </div>
                  </div>
                </div>
              </div>
              <div className="self-stretch justify-between items-start gap-4 inline-flex pb-8">
                {path === true ? null : (
                  <button
                    className="pr-2 md:pr-0 md:px-5 py-3 rounded-lg self-end justify-center items-center gap-1 md:gap-2.5 flex w-max max-w-[238px]"
                    onClick={handlePreviousBTN}

                  >
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16 8L17.43 9.393L11.85 15H24V17H11.85L17.43 22.573L16 24L8 16L16 8Z" fill="#FF6633" />
                      <path d="M16 30C18.7689 30 21.4757 29.1789 23.778 27.6406C26.0803 26.1022 27.8747 23.9157 28.9343 21.3576C29.9939 18.7994 30.2712 15.9845 29.731 13.2687C29.1908 10.553 27.8574 8.05845 25.8995 6.10051C23.9416 4.14258 21.447 2.80921 18.7313 2.26901C16.0155 1.72882 13.2006 2.00607 10.6424 3.06569C8.08426 4.12532 5.89776 5.91973 4.35942 8.22202C2.82108 10.5243 1.99999 13.2311 1.99999 16C2.00423 19.7117 3.48059 23.2702 6.10518 25.8948C8.72977 28.5194 12.2883 29.9958 16 30ZM16 4.00001C18.3734 4.00001 20.6934 4.70379 22.6668 6.02237C24.6402 7.34095 26.1783 9.21509 27.0865 11.4078C27.9948 13.6005 28.2324 16.0133 27.7694 18.3411C27.3064 20.6689 26.1635 22.8071 24.4853 24.4853C22.807 26.1635 20.6688 27.3064 18.3411 27.7694C16.0133 28.2325 13.6005 27.9948 11.4078 27.0866C9.21508 26.1783 7.34094 24.6402 6.02236 22.6668C4.70378 20.6935 3.99999 18.3734 3.99999 16C4.0037 12.8185 5.26917 9.76845 7.5188 7.51882C9.76844 5.26918 12.8185 4.00371 16 4.00001Z" fill="#FF6633" />
                    </svg>


                    <span className="text-center text-[#FF6633] text-sm md:text-base font-medium whitespace-nowrap">
                      Previous
                    </span>
                  </button>
                )}

                {/* continue button */}
                <button
                  className="px-3 md:px-5 py-3 bg-indigo-700 rounded-lg self-end shadow justify-center items-center gap-2 md:gap-2.5 flex w-max max-w-[238px]"
                  onClick={handleEduNext}

                >
                  {!path ? <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 8L14.57 9.393L20.15 15H8V17H20.15L14.57 22.573L16 24L24 16L16 8Z" fill="white" />
                    <path d="M16 30C13.2311 30 10.5243 29.1789 8.22202 27.6406C5.91973 26.1022 4.12532 23.9157 3.06569 21.3576C2.00607 18.7994 1.72882 15.9845 2.26901 13.2687C2.80921 10.553 4.14258 8.05845 6.10051 6.10051C8.05845 4.14258 10.553 2.80921 13.2687 2.26901C15.9845 1.72882 18.7994 2.00607 21.3576 3.06569C23.9157 4.12532 26.1022 5.91973 27.6406 8.22202C29.1789 10.5243 30 13.2311 30 16C29.9958 19.7117 28.5194 23.2702 25.8948 25.8948C23.2702 28.5194 19.7117 29.9958 16 30ZM16 4.00001C13.6266 4.00001 11.3066 4.70379 9.33316 6.02237C7.35977 7.34095 5.8217 9.21509 4.91345 11.4078C4.0052 13.6005 3.76756 16.0133 4.23058 18.3411C4.69361 20.6689 5.83649 22.8071 7.51472 24.4853C9.19296 26.1635 11.3312 27.3064 13.6589 27.7694C15.9867 28.2325 18.3995 27.9948 20.5922 27.0866C22.7849 26.1783 24.6591 24.6402 25.9776 22.6668C27.2962 20.6935 28 18.3734 28 16C27.9963 12.8185 26.7308 9.76845 24.4812 7.51882C22.2316 5.26918 19.1815 4.00371 16 4.00001Z" fill="white" />
                  </svg> : <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 4V16C18 16.55 17.8043 17.021 17.413 17.413C17.0217 17.805 16.5507 18.0007 16 18H2C1.45 18 0.979333 17.8043 0.588 17.413C0.196667 17.0217 0.000666667 16.5507 0 16V2C0 1.45 0.196 0.979333 0.588 0.588C0.98 0.196667 1.45067 0.000666667 2 0H14L18 4ZM16 4.85L13.15 2H2V16H16V4.85ZM9 15C9.83333 15 10.5417 14.7083 11.125 14.125C11.7083 13.5417 12 12.8333 12 12C12 11.1667 11.7083 10.4583 11.125 9.875C10.5417 9.29167 9.83333 9 9 9C8.16667 9 7.45833 9.29167 6.875 9.875C6.29167 10.4583 6 11.1667 6 12C6 12.8333 6.29167 13.5417 6.875 14.125C7.45833 14.7083 8.16667 15 9 15ZM3 7H12V3H3V7ZM2 4.85V16V2V4.85Z" fill="white" />
                  </svg>
                  }


                  <span className="text-center text-white text-sm md:text-base font-medium whitespace-nowrap">
                    {!path ? 'Save and Continue' : 'save to profile'}
                  </span>
                </button>
              </div>
            </div>
            <ToastContainer />
            {/* {Acknowledge} */}
            {/* {twenty}
            {fourty} */}
            {sixty}
            {/* {eighty}
            {nintyFive} */}
            {savingloader}
          </div>
        </div>
      </div>
    </>
  );
}