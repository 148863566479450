import React from "react";

function Skillsbtn({ btntxt,  btnid, key}) {
  return (
    <div
      key={key}
      type="button"
      data-te-ripple-init=""
      className="my-2 inline-block ml-2 .. font-normal text-base text-[#1F1F1F] py-3 px-4 bg-[#EEECF9] rounded-full border border-[#D9D9D9] ..  .uppercase leading-normal shadow-md transition duration-150 ease-in-out hover:bg-neutral-300 hover:shadow-lg focus:bg-neutral-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-neutral-400 active:shadow-lg cursor-pointer"
    >
      {btntxt}
    </div>
  );
}

export default Skillsbtn;
