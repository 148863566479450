import React, { useState } from "react"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BackButton from "../back_button"
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { saveIntoServerCache } from "../../../utils/saveIntoCache";

const SelectLanguage = (props) => {
	const [defaultLang, setDefaultLang] = useState("");
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleNavigate = () => {
		// do sth base on the route. because this component is reuseable.
		navigate(`${props.back}`);
	}
	const handleEnglishClick = async () => {
		setDefaultLang("English");
		await saveIntoServerCache("Language", "English");
		props.handleClick();
	}

	const handleSpanishClick = async () => {
		setDefaultLang("Spanish");
		await saveIntoServerCache("Language", "Spanish");
		props.handleClick();
	}

	return (
		<div lassName="fixed top-0 left-0 bottom-0 right-0 z-10 bg-tet-600 overflow-y-scroll min-h-screen py-7 backdrop-blur-[2px] flex justify-center items-center">
			<section onClick={(e) => e.stopPropagation()} className="w-full shadow bg-white sm:max-w-[500px] min-w-[350px] w-[80%] z-100 m-auto rounded-[24px] pb-5 overflow-hidden relative">
				<div className="w-full max-w-[660px] p-4 flex justify-between items-center bg-[#5843BD] border-b-8 border-[#E9F1FF] rounded-tr-[24px] rounded-tl-[24px] ">
					<p className=" text-lg font-medium leading-normal text-left text-white">
						Select Language
					</p>
				</div>

				<BackButton className="ml-6" onClick={() => window.history.back()} />
				<div className="flex flex-col items-center gap-y-4">
					<p class="text-2xl font-semibold leading-normal text-center py-2 px-4 text-[#5843BD]">
						Select Resume Language
					</p>

					<div className="flex items-center justify-center">
						<h3 className="text-lg sm:text-2xl font-medium text-[#5843BD]">
							Thank you for choosing
						</h3>
						<figure>
							<img
								width={48}
								height={48}
								src='https://res.cloudinary.com/drlcisipo/image/upload/v1706475719/logo_m0jvut.png' // coin.png
								className="pointer-events-none ml-2"
								alt="coin"
							/>
						</figure>
					</div>

					<p class="text-base font-normal leading-[1.25] text-center text-[#0D0D0D]">
						What language do you want <br /> your {window.location.pathname == "/employer/create-job" ? "job" : "resume"} to be in?
					</p>

					<div className="flex md:items-center md:px-8 md:justify-between w-full px-2 md:px-0 flex-col md:flex-row gap-y-4 gap-x-6 mb-6">
						<button
							className={` border-2 ${defaultLang == "English" ? " border-[#FF6633]" : " border-[#DCDCDC]"} p-4 w-full  md:w-[250px] rounded-lg border text-gray-900 focus:ring-orange-400 focus:border-orange-400 font-['Poppins'] text-base flex items-center justify-between `} onClick={handleEnglishClick}
						>
							<div className="flex items-center">
								<img src="https://res.cloudinary.com/drlcisipo/image/upload/v1705704286/Website%20images/English_uj5huz.jpg" alt="English icon" className="object-cover" />
								<h1 className="text-base font-medium text-black ml-4">English</h1>
							</div>
							<CheckCircleIcon className={` ${defaultLang == "English" ? "text-[#FF6633]" : "text-[#DCDCDC]"}`} />
						</button>

						<button
							className={`md:mt-0 border-2 ${defaultLang == "Spanish" ? " border-[#FF6633]" : "border-[#DCDCDC]"} p-4 w-full md:w-[250px] rounded-lg border text-gray-900 focus:ring-orange-400 focus:border-orange-400 font-['Poppins'] text-base flex items-center justify-between `} onClick={handleSpanishClick}
						>
							<div className="flex items-center">
								<img src="https://res.cloudinary.com/drlcisipo/image/upload/v1705704281/Website%20images/Spain_shstdy.jpg" lt="spanish icon" />
								<h1 className="text-base font-medium text-black ml-4">Spanish</h1>
							</div>
							<CheckCircleIcon className={` ${defaultLang == "Spanish" ? "text-[#FF6633]" : "text-[#DCDCDC]"}`} />
						</button>
					</div>
				</div>
			</section>
		</div>
	)
}

export default SelectLanguage;