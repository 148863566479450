import React from 'react';
import TopBar from "../../components/topbar/Top-Bar";
import SideBar from "../../components/sidebar/sidebar";
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import UserDashboard from "../../components/Dashboard/UserDashboard"

const HomeDashboard = () => {
	const stat = useSelector(e => e.login);
	const sidebar = useSelector(e => e.sidebarOpen.message);
	// console.log(sidebar)
	// console.log("header", stat.profile_status.profile_status)
	return (
		<div className={`Dashboard grid grid-cols-auto h-full md:grid-cols-smdash ${sidebar ? 'cont:grid-cols-dash' : ''} grid-rows-dash bg-tet-912 overflow-x-hidden`}>
			<TopBar topBarShowMenu={true}/>
			<SideBar />
			<Outlet />
		</div>
	);
};

export default HomeDashboard;
