import React from "react";

export default function TextInput(props) {
  return (
    <input
      type="text"
      className="px-4 py-2 w-full rounded-lg border border-indigo-400 border-opacity-30 text-gray-900 placeholder:text-[#0000004D] focus:ring-indigo-400 focus:border-indigo-400 font-['Poppins'] text-base "
      placeholder={props.placeholder}
      id={props.id}
      name={props.name}
      onChange={props.onChangeHandler}
      disabled={props.disabled}
      ref={props.reff}
    />
  );
}
