import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../common/custom_select";
import { sendOtp, verifyOtp } from "../../Redux/ActionCreator";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PhoneVerificationModal from "../common/phone_verification_modal";
import { baseURL } from '../../Redux/baseURL';
import { createPortal } from "react-dom";

const PhoneVerification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.userInfo);
  const p_profile = useSelector((state) => state.profile);
  const send_otp = useSelector((state) => state.send_otp);
  const verify_otp = useSelector((state) => state.otp);

  const [countryCode, setCountryCode] = useState("+1");
  const [countryCodeCountry, setCountryCodeCountry] = useState("United States");
  const [phoneNumber, setPhoneNumber] = useState(userInfo.user?.phone_number_verified ? userInfo.user?.phone_number : '+1');
  const [isValidNumber, setIsValidNumber] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState(null); // 'pending', 'success', 'failed'
  const [disable, setDisable] = useState(false);
  const [verifyModal, setVerifyModal] = useState(false);
  const [resendTimed, setResendTimed] = useState(false);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(null);

  // Added state for showing/hiding password
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Country codes data
  const countryCodes = useMemo(() => {
    return require('country-data').callingCountries.all.map((country) => ({
      code: `${country.countryCallingCodes[0]}`,
      alpha2: country.alpha2,
      name: country.name
    }));
  }, []);

  useEffect(() => {
    if (userInfo?.user?.phone_number) {
      setPhoneNumber(userInfo.user.phone_number);
    }
  }, [userInfo]);



  const updatePassword = async (newPassword) => {
    try {
      console.log(newPassword)
      const obj = { newPassword };
      const response = await axios.post(
        `${baseURL}/api/users/updatePassword`,
        obj,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      if (response.data && response.data.success) {
        toast.success("Password updated successfully!");
        navigate("/user/dashboard");
      } else {
        toast.error(response.data.message || "Failed to update the password");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while updating the password.");
    }
  };

  // Handle phone number input change
  const handleInputChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);
    // Set isValidNumber to true as validation rules are removed
    setIsValidNumber(true);
  };

  const handleSelectCountryCode = (code) => {
    const selectedCountry = countryCodes.find(country => country.code === code);
    setCountryCode(code);
    setPhoneNumber(code);
    setCountryCodeCountry(selectedCountry ? selectedCountry.name : "");
  }

  const handleSetCountryCodeCountry = (country) => {
    setCountryCodeCountry(country);
  }

  // Handle sending verification OTP
  const handleSendVerification = async (e) => {
    e.preventDefault();
    if (isValidNumber) {
      try {
        setDisable(true);
        if (!resendTimed) setResendTimed(true);
        if (!verifyModal) setVerifyModal(true);
        const response = await dispatch(sendOtp(phoneNumber));
        console.log(response);
        if (response.success) {
          setVerificationStatus('pending');
          setVerifyModal(true);
        } else {
          setVerificationStatus('failed');
          toast.error(response.message || "Failed to send verification code.");
        }
      } catch (error) {
        console.error(error);
        setVerificationStatus('failed');
        toast.error("An error occurred while sending verification code.");
      } finally {
        setDisable(false);
      }
    } else {
      toast.error("Invalid phone number. Please check and try again.");
    }
  };

  // Handle verification completion
  const handleVerify = async (otpCode) => {
    try {
      setDisable(true);
      const response = await dispatch(verifyOtp(otpCode, phoneNumber));
      if (response.success) {
        setVerificationStatus('success');
        toast.success("Phone number verified successfully! You may now set a password if you don't have one.");
      } else {
        setVerificationStatus('failed');
        toast.error(response.message || "Verification failed. Please try again.");
      }
    } catch (error) {
      console.error(error);
      setVerificationStatus('failed');
      toast.error("An error occurred during verification.");
    } finally {
      setDisable(false);
      setVerifyModal(false);
    }
  };

  // Handle skipping verification
  const handleSkipVerification = () => {
    toast.info("Phone verification skipped.");
  };

  // Password strength checker
  const checkPasswordStrength = (pw) => {
    const lengthCriteria = pw.length >= 8;
    const hasUpperCase = /[A-Z]/.test(pw);
    const hasLowerCase = /[a-z]/.test(pw);
    const hasNumber = /\d/.test(pw);
    const hasSpecial = /[!@#$%^&*(),.?":{}|<>]/.test(pw);

    const passedCriteria = [lengthCriteria, hasUpperCase, hasLowerCase, hasNumber, hasSpecial].filter(Boolean).length;

    let strengthLabel;
    let strengthColor;

    if (!pw) {
      strengthLabel = '';
      strengthColor = '';
    } else if (passedCriteria <= 2) {
      strengthLabel = 'Weak';
      strengthColor = 'text-red-500';
    } else if (passedCriteria === 3 || passedCriteria === 4) {
      strengthLabel = 'Medium';
      strengthColor = 'text-yellow-500';
    } else if (passedCriteria === 5) {
      strengthLabel = 'Strong';
      strengthColor = 'text-green-500';
    }

    return { strengthLabel, strengthColor, lengthCriteria, hasUpperCase, hasLowerCase, hasNumber, hasSpecial };
  }

  const handlePasswordChange = (e) => {
    const newPw = e.target.value;
    setPassword(newPw);
    const checked = checkPasswordStrength(newPw);
    setPasswordStrength(checked);
  }

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  }

  // Verification modal
  const verificationModal = verificationStatus === 'pending' && verifyModal
    ? createPortal(
      <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
        <PhoneVerificationModal
          resendCode={() => !resendTimed && setResendTimed(true)}
          waitingEnded={() => resendTimed && setResendTimed(false)}
          resendTimed={resendTimed}
          phoneNumber={phoneNumber}
          removeModal={() => verifyModal === true && setVerifyModal(false)}
          handleVerify={handleVerify}
        />
      </div>,
      document.getElementById("modal")
    )
    : null;

  return (
    <>
      <div className='flex justify-center items-center min-h-screen'>
        <div className="max-w-[900px] xtraxl:max-w-calculate py-5 w-full bg-white bg-opacity-80 flex-col justify-start items-start gap-5 inline-flex">
          <div className="w-full flex flex-col gap-4">
            <p className="text-sm text-gray-600 mt-2">
              After verifying your phone number, you can set a password for your account if you don't have one already.
              A password helps secure your account.
            </p>
            <div className="flex flex-col md:flex-row gap-y-2 flex gap-x-3">
              <div className="w-full flex gap-3">
                <CustomSelect
                  countryCodeCountry={countryCodeCountry}
                  hanldeSetCountryCodeCountry={handleSetCountryCodeCountry}
                  handleSelectCountryCode={handleSelectCountryCode}
                  countryCodes={countryCodes}
                  countryCode={countryCode}
                />
                <input
                  type="text"
                  className="py-2 px-4 w-full rounded-lg border border-indigo-400 border-opacity-30"
                  placeholder="+123 456 7890"
                  id="phoneNumber"
                  name="phone_number"
                  value={phoneNumber}
                  onChange={handleInputChange}
                  disabled={disable || verificationStatus === 'success'}
                />
              </div>

              {/* Verify Phone Button */}
              {!userInfo.user?.phone_number_verified &&
                p_profile.message === null && (
                  <button
                    type="button"
                    className="px-4 py-2 bg-orange-500 rounded-[4px] shadow justify-center items-center inline-flex cursor-pointer"
                    onClick={handleSendVerification}
                    disabled={disable || verificationStatus === 'pending'}
                  >
                    <span className="text-center text-white text-sm sm:text-base font-normal whitespace-nowrap">
                      Verify Phone
                    </span>
                  </button>
                )
              }
            </div>

            {/* Warning Bar */}
            {p_profile.message === null && (
              <div className="w-full bg-orange-500 flex justify-start items-start gap-2 inline-flex">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="ml-3"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1 21H23L12 2L1 21ZM13 18H11V16H13V18ZM13 14H11V10H13V14Z"
                    fill="#FF6633"
                  />
                </svg>
                <p className="text-[#1B1B1B] text-xs sm:text-base font-normal font-['Poppins'] py-2 text-white leading-normal">
                  {!userInfo.user?.phone_number_verified
                    ? "You have to verify your phone number to continue from here."
                    : "Phone number has been verified. You can set a password now."}
                </p>
              </div>
            )}

            {/* Password Fields After Verification */}
            {(userInfo.user?.phone_number_verified && userInfo.user?.authType === "OAUTH") && (
              <div className="flex flex-col gap-2 mt-4">
                <div className="relative  flex flex-col gap-y-2">
                  <label className="font-poppins text-xs font-medium leading-4 tracking-normal text-left" htmlFor="password">
                    Password
                  </label>
                  <div className={`border-solid border-indigo-400 w-full border-opacity-50 border ${focus ? "ring-2 ring-indigo-400" : ""} rounded-[6px] flex gap-2 w-full items-center px-3`}>
                    <input
                      type={showPassword ? "text" : "password"}
                      id="password"
                      class="w-full border-none outline-none focus:border-transparent focus:outline-transparent focus:ring-0"
                      placeholder="Enter your password"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    {
                      showPassword ?
                        <svg onClick={() => setShowPassword(!showPassword)} xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 cursor-pointer" viewBox="0 0 512 512"><path d="M432 448a15.92 15.92 0 0 1-11.31-4.69l-352-352a16 16 0 0 1 22.62-22.62l352 352A16 16 0 0 1 432 448z" fill="currentColor"></path><path d="M255.66 384c-41.49 0-81.5-12.28-118.92-36.5c-34.07-22-64.74-53.51-88.7-91v-.08c19.94-28.57 41.78-52.73 65.24-72.21a2 2 0 0 0 .14-2.94L93.5 161.38a2 2 0 0 0-2.71-.12c-24.92 21-48.05 46.76-69.08 76.92a31.92 31.92 0 0 0-.64 35.54c26.41 41.33 60.4 76.14 98.28 100.65C162 402 207.9 416 255.66 416a239.13 239.13 0 0 0 75.8-12.58a2 2 0 0 0 .77-3.31l-21.58-21.58a4 4 0 0 0-3.83-1a204.8 204.8 0 0 1-51.16 6.47z" fill="currentColor"></path><path d="M490.84 238.6c-26.46-40.92-60.79-75.68-99.27-100.53C349 110.55 302 96 255.66 96a227.34 227.34 0 0 0-74.89 12.83a2 2 0 0 0-.75 3.31l21.55 21.55a4 4 0 0 0 3.88 1a192.82 192.82 0 0 1 50.21-6.69c40.69 0 80.58 12.43 118.55 37c34.71 22.4 65.74 53.88 89.76 91a.13.13 0 0 1 0 .16a310.72 310.72 0 0 1-64.12 72.73a2 2 0 0 0-.15 2.95l19.9 19.89a2 2 0 0 0 2.7.13a343.49 343.49 0 0 0 68.64-78.48a32.2 32.2 0 0 0-.1-34.78z" fill="currentColor"></path><path d="M256 160a95.88 95.88 0 0 0-21.37 2.4a2 2 0 0 0-1 3.38l112.59 112.56a2 2 0 0 0 3.38-1A96 96 0 0 0 256 160z" fill="currentColor"></path><path d="M165.78 233.66a2 2 0 0 0-3.38 1a96 96 0 0 0 115 115a2 2 0 0 0 1-3.38z" fill="currentColor"></path></svg>
                        :
                        <svg onClick={() => setShowPassword(!showPassword)} xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 cursor-pointer" viewBox="0 0 512 512"><path d="M255.66 112c-77.94 0-157.89 45.11-220.83 135.33a16 16 0 0 0-.27 17.77C82.92 340.8 161.8 400 255.66 400c92.84 0 173.34-59.38 221.79-135.25a16.14 16.14 0 0 0 0-17.47C428.89 172.28 347.8 112 255.66 112z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"></path><circle cx="256" cy="256" r="80" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"></circle></svg>

                    }
                  </div>

                  {password && passwordStrength && (
                    <>
                      <p className={`text-sm ${passwordStrength.strengthColor} mt-1`}>
                        Strength: {passwordStrength.strengthLabel}
                      </p>
                      <ul className="text-xs text-gray-600 mt-1">
                        <li className={passwordStrength.lengthCriteria ? "text-green-600" : "text-red-500"}>
                          At least 8 characters
                        </li>
                        <li className={passwordStrength.hasUpperCase ? "text-green-600" : "text-red-500"}>
                          At least one uppercase letter
                        </li>
                        <li className={passwordStrength.hasLowerCase ? "text-green-600" : "text-red-500"}>
                          At least one lowercase letter
                        </li>
                        <li className={passwordStrength.hasNumber ? "text-green-600" : "text-red-500"}>
                          At least one number
                        </li>
                        <li className={passwordStrength.hasSpecial ? "text-green-600" : "text-red-500"}>
                          At least one special character
                        </li>
                      </ul>
                    </>
                  )}
                </div>
                <div className='flex flex-col gap-y-2'>
                  <label className="font-poppins text-xs font-medium leading-4 tracking-normal text-left" htmlFor="confirm_password">
                    Confirm Password
                  </label>
                  <div className={`border-solid border-indigo-400 w-full border-opacity-50 border ${focus ? "ring-2 ring-indigo-400" : ""} rounded-[6px] flex gap-2 w-full items-center px-3`}>
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      id="confirm_password"
                      c class="w-full border-none outline-none focus:border-transparent focus:outline-transparent focus:ring-0"
                      placeholder="Confirm your password"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                    />
                    {
                      showConfirmPassword ?
                        <svg onClick={() => setShowConfirmPassword(!showConfirmPassword)} xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 cursor-pointer" viewBox="0 0 512 512"><path d="M432 448a15.92 15.92 0 0 1-11.31-4.69l-352-352a16 16 0 0 1 22.62-22.62l352 352A16 16 0 0 1 432 448z" fill="currentColor"></path><path d="M255.66 384c-41.49 0-81.5-12.28-118.92-36.5c-34.07-22-64.74-53.51-88.7-91v-.08c19.94-28.57 41.78-52.73 65.24-72.21a2 2 0 0 0 .14-2.94L93.5 161.38a2 2 0 0 0-2.71-.12c-24.92 21-48.05 46.76-69.08 76.92a31.92 31.92 0 0 0-.64 35.54c26.41 41.33 60.4 76.14 98.28 100.65C162 402 207.9 416 255.66 416a239.13 239.13 0 0 0 75.8-12.58a2 2 0 0 0 .77-3.31l-21.58-21.58a4 4 0 0 0-3.83-1a204.8 204.8 0 0 1-51.16 6.47z" fill="currentColor"></path><path d="M490.84 238.6c-26.46-40.92-60.79-75.68-99.27-100.53C349 110.55 302 96 255.66 96a227.34 227.34 0 0 0-74.89 12.83a2 2 0 0 0-.75 3.31l21.55 21.55a4 4 0 0 0 3.88 1a192.82 192.82 0 0 1 50.21-6.69c40.69 0 80.58 12.43 118.55 37c34.71 22.4 65.74 53.88 89.76 91a.13.13 0 0 1 0 .16a310.72 310.72 0 0 1-64.12 72.73a2 2 0 0 0-.15 2.95l19.9 19.89a2 2 0 0 0 2.7.13a343.49 343.49 0 0 0 68.64-78.48a32.2 32.2 0 0 0-.1-34.78z" fill="currentColor"></path><path d="M256 160a95.88 95.88 0 0 0-21.37 2.4a2 2 0 0 0-1 3.38l112.59 112.56a2 2 0 0 0 3.38-1A96 96 0 0 0 256 160z" fill="currentColor"></path><path d="M165.78 233.66a2 2 0 0 0-3.38 1a96 96 0 0 0 115 115a2 2 0 0 0 1-3.38z" fill="currentColor"></path></svg>
                        :
                        <svg onClick={() => setShowConfirmPassword(!showConfirmPassword)} xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 cursor-pointer" viewBox="0 0 512 512"><path d="M255.66 112c-77.94 0-157.89 45.11-220.83 135.33a16 16 0 0 0-.27 17.77C82.92 340.8 161.8 400 255.66 400c92.84 0 173.34-59.38 221.79-135.25a16.14 16.14 0 0 0 0-17.47C428.89 172.28 347.8 112 255.66 112z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"></path><circle cx="256" cy="256" r="80" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"></circle></svg>

                    }
                  </div>

                  {confirmPassword && confirmPassword !== password && (
                    <p className="text-red-500 text-xs mt-1">Passwords do not match.</p>
                  )}
                </div>

                <div className='flex justify-end'>
                  <button
                    onClick={() => {
                      console.log(passwordStrength);
                      const {
                        lengthCriteria,
                        hasUpperCase,
                        hasLowerCase,
                        hasNumber,
                        hasSpecial,
                      } = passwordStrength || {};

                      if (lengthCriteria && hasUpperCase && hasLowerCase && hasNumber && hasSpecial && confirmPassword && confirmPassword === password) {
                        updatePassword(password);
                      } else {
                        toast.error("Your password does not meet all the criteria");
                      }
                    }}
                    className="px-5 mt-4 py-3 text-white bg-indigo-700 rounded-lg self-end shadow justify-center items-center gap-2.5 flex w-full max-w-[238px]"
                  >
                    Update Authentication
                  </button>

                </div>
              </div>
            )}

            {/* Verification Modal */}
            {verificationModal}

            {(userInfo.user?.phone_number_verified && userInfo.user?.authType === "PASSWORD") && <div className='flex justify-end'>
              <button
                onClick={() => navigate('/user/dashboard')}
                className="px-5 mt-4 py-3 text-white bg-indigo-700 rounded-lg self-end shadow justify-center items-center gap-2.5 flex w-full max-w-[238px]"
              >
                Update Authentication
              </button>

            </div>}

          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default PhoneVerification;
