import React, { useState, useEffect, useRef } from "react";
import Tab from "../tab/tab";
import TopBar from "../../topbar/Top-Bar.jsx";
import SideBar from "../../sidebar/sidebar.jsx";
import { TextArea } from "../textarea/textarea";
import TextInput from "../text_input/text_input";
import AddedItem from "../added_item/added_item";
import AddMore from "../add_more/add_more";
import Months from "../../common/month";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { dispatchActivites, generate_experience, cleanEX, ActivitiesDetails_loading, updateExperience, generate_my_cv, saveExperience, resume_step_jump_function, returnHome, existing_resume, save_secret } from "../../../Redux/ActionCreator";
import { convertExperience } from "../../../Redux/convertResume.js";
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useNavigate } from "react-router-dom";
import Radio from "../radio/radio";
import LoaderModal from '../../common/LoaderModal';
import dayjs from "dayjs";
import HelmetProvide from "../../common/helmet";
import { handleDeleteValue } from "../utils/handleDelete";
import { saveIntoServerCache, getOneFromServerCache } from "../../../utils/saveIntoCache";
import ViewProp from "../view_prop/view.jsx";
import { baseURL } from "../../../Redux/baseURL";
import { AdjustExperienceByDate } from "../../../utils/pdfUtil/Adjust_gptRes.js"
import ExperienceCompleteGamifiedModal from "../../gamified_modals/experience_gamified_complete_modal/index.jsx";
import dateJson from "../../../utils/date_object.js"
import BasicSelect from "../select/select"


export default function UserWorkExperience(props) {
  const profile = useSelector(e => e.profile);
  const dispatch = useDispatch();
  const exp = useSelector(exp => exp.activities);
  const generated = useSelector(e => e.generateCV);
  const secret = useSelector(prev => prev.secret);
  const cv = useSelector(prev => prev.cvId);
  const AIexp = useSelector(ai => ai.exp);
  const existing_cv_data = useSelector((prev) => prev.existingCV);
  const message = existing_cv_data.message;
  const profile1 = useSelector(e => e.login);
  const userInfo = useSelector(e => e.userInfo);
  let stat = profile1.profile_status.profile_status;
  const [isRedux, setIsredux] = useState(false);
  let [work, setwork] = useState(props.Experience);
  let [chips, setChips] = useState(props.Experience);
  const [activity, setActivities] = useState([]);
  const [experience, setExperience] = useState("")
  const [truty, setTruty] = useState(false);
  let [incr_defaultEdu, setIncre_defaultEdu] = useState();
  const [expStart, setExpStart] = useState("");
  const [expEnd, setExpEnd] = useState("");
  const [expStart1, setExpStart1] = useState("");
  const [expEnd1, setExpEnd1] = useState("");
  const navigate = useNavigate();
  const expe = AIexp.exp;
  const [newwork, setNewWork] = useState([]);
  const [visibility, setVisibility] = useState(false);
  const [color, setColor] = useState(false);
  const [e_present, setE_Present] = useState(false);
  const [obj, setObj] = useState()
  const [Language, setLanguage] = useState("");
  const [saving, setSaving] = useState(false);
  const [workedAllowed, setworkedAllowed] = useState(4);
  const experienceRef = useRef(null);

  const searchParams = new URLSearchParams(window.location.search);
  const process = searchParams.get("create profile");
  const [forty, setFourty] = useState(false);
  const [reviewedChips, setReviewedChips] = useState([]);

  const scrollToExperienceFields = () => {
    experienceRef.current?.scrollIntoView({ behavior: 'smooth' });
    experienceRef.current?.focus();
  }

  useEffect(() => {
    let SelectedLanguage = getOneFromServerCache("Language");
    SelectedLanguage.then(resp => resp.json())
      .then(resp => {
        // console.log("SelectedLanguage", resp.data);
        if (resp.success === true) {
          if (resp.data == null) {
            if (window.location.pathname !== "/user/profile/update/experience" && profile.message !== null && profile.message?.CompletionPercentage >= 95) {
              setTimeout(() => {
                toast.error("You need to select the language you want your resume to be in.", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }, 50);
              saveIntoServerCache("step", 0);
              dispatch(resume_step_jump_function(0));
            }
          } else {
            setLanguage(JSON.parse(resp.data));
          }
        } else if (resp.success === false && resp.message == "Unauthorized") {
          dispatch(returnHome(resp.status));
        }
      }).catch(err => {
        // console.log("Language getting error", err);
      });

    let cacheInfo = getOneFromServerCache("experience")
    cacheInfo.then(res => res.json())
      .then(res => {
        // console.log("server cache value", res);
        if (res.success === true) {
          if (res.data !== null && res.data !== undefined) {
            let resObj = JSON.parse(res.data);
            // console.log("respObj", resObj);
            dispatch(dispatchActivites(resObj));
            setwork(resObj);
            setChips(resObj);
          }
          // console.log("Done");
        } else if (res.success === false && res.message == "Unauthorized") {
          dispatch(returnHome(res.status));
        }
      })
    // if (!window.location.pathname.includes("update")) {
    //   // console.log("was called")
    //   saveIntoServerCache("step", 2);
    // }
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/user/profile/update/experience" && profile.message !== null && profile.message?.CompletionPercentage >= 95) {
      setVisibility(true);
      for (let i = 0; i < profile.message?.Experience?.length; i++) {
        profile.message.Experience[i].Review = true;
      }
      setwork(profile.message.Experience);
      setChips(profile.message.Experience);
      let reviewExp = profile.message.Experience?.filter(e => e?.Review == true);
      setReviewedChips(reviewExp);
    } else if (window.location.pathname === `/form/${cv.id}/update`) {
      setwork(generated.message.Experience);
      setChips(generated.message.Experience);
      let reviewExp = generated.message?.Experience?.filter(e => e?.Review == true);
      setReviewedChips(reviewExp);
    } else {
      if (exp.activities !== null && exp.activities?.length > 0) {
        setwork(exp.activities);
        setChips(exp.activities);
        let reviewExp = exp?.activities?.filter(e => e?.Review == true);
        setReviewedChips(reviewExp);
      } else if (message !== null) {
        if (message?.Experience !== null && message?.Experience !== undefined) {
          let exp = new Array();
          message?.Experience.forEach(element => {
            if (element.hasOwnProperty("key")) {
              exp.push(element);
            } else {
              element.key = message?.Experience.indexOf(element);
              exp.push(element);
            }
          })
          setwork(exp);
          setChips(exp);
        } else {
          setwork([]);
          setChips([]);
        }
      } else {
        if (message == null) {
          const getExistingCv = async () => {
            fetch(baseURL + "/api/temp/getTempRes", {
              headers: {
                // "Authorization": `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
              credentials: "include"
            }).then(resp => resp.json())
              .then(resp => {
                if (resp.success === true) {
                  // console.log("Temp response", resp);
                  if (resp.data !== null) {
                    setIsredux(true);
                    let response_data = new Array();
                    resp.data?.Experience.forEach(e => {
                      if (e.hasOwnProperty("key")) {
                        response_data.push(e);
                      } else {
                        e.key = resp.data?.Experience.indexOf(e);
                        response_data.push(e);
                      }
                    });
                    dispatch(existing_resume(resp.data));
                    setwork(response_data);
                    setChips(response_data);
                    dispatch(save_secret(resp.data?.Secret));
                  }
                } else if (resp.success === false && resp.message == "Unauthorized") {
                  dispatch(returnHome(resp.status));
                } else {
                  setwork([]);
                  setChips([]);
                }

              }).catch(err => {
                // console.log("Error getting temp resume", err)
              })
          }
          getExistingCv();
        }
      }
    }
  }, []);

  useEffect(() => {
    if (obj !== undefined) {
      dispatch(generate_my_cv(obj));
    }
  }, [obj])

  const [twentyModal, setTwentyModal] = useState(false);
  const [fourtyModal, setfourtyModal] = useState(false);
  const [sixtyModal, setsixtyModal] = useState(false);
  const [eightyModal, seteightyModal] = useState(false);
  const [nintyFiveModal, setnintyFiveModal] = useState(false);
  const [hundredModal, sethundredModal] = useState(false);
  const [processModal, setProcessModal] = useState(false);
  const sidebar = useSelector(e => e.sidebarOpen.message);

  useEffect(() => {
    setSaving(false);
    // if (profile.message !== null && processModal == true) {
    //   if (profile.message?.CompletionPercentage >= 20 && profile.message?.CompletionPercentage < 40) {
    //     setTwentyModal(true)
    //   } else if (profile.message?.CompletionPercentage >= 40 && profile.message?.CompletionPercentage < 60) {
    //     setfourtyModal(true);
    //   } else if (profile.message?.CompletionPercentage >= 60 && profile.message?.CompletionPercentage < 80) {
    //     setsixtyModal(true);
    //   } else if (profile.message?.CompletionPercentage >= 80 && profile.message?.CompletionPercentage < 95) {
    //     seteightyModal(true);
    //   } else if (profile.message?.CompletionPercentage >= 95 && profile.message?.CompletionPercentage <= 100) {
    //     setnintyFiveModal(true);
    //   } else if (profile.message?.CompletionPercentage === 100 && profile.message?.ProfileStatus !== "Completed") {
    //     sethundredModal(true);
    //   }
    // }
    if (profile.message !== null && processModal == true) {
      setfourtyModal(true);
    }
  }, [profile.message]);


  useEffect(() => {
    if ($('[name="work_day_activities"]').val() === "") {
      setColor(false);
    } else {
      setColor(true);
    }
  }, [$('[name="work_day_activities"]').val()])

  useEffect(() => {
    $('input[name="present"]').on("click", () => {
      setE_Present(!e_present)
      if (e_present === true) {
        $('#present').prop('checked', false);
      } else {
        $('#present').prop('checked', true);
      }
    });
  }, [e_present]);
  // console.log("Present", e_present);
  useEffect(() => {

    if (message !== null) {
      setIsredux(true);
    } else {
      setIsredux(false);
    }
    setExperience($('[name="work_day_activities"]').val())
    localStorage.setItem("job_role", $('[name="work_role"]').val())
    const filValue = (expe) => {
      if (message !== undefined && message !== null) {
        if (AIexp.exp !== null) {
          $('[name="work_day_activities"]').val(expe);
        } else {
          $('[name="work_day_activities"]').val(work[incr_defaultEdu]?.Day_to_day_work);
        }
      } else {
        if (AIexp.exp !== null) {
          $('[name="work_day_activities"]').val(expe);
        } else {
          $('[name="work_day_activities"]').val();
        }
      }
    }

    if (expe !== null) {
      filValue(expe);
    }


  }, [expe]);

  const StyledDatePicker = styled(DatePicker)({
    '.MuiPickersToolbar-root': {
      color: '#1565c0',
      borderRadius: '10px',
      borderWidth: '1px',
      borderColor: '#2196f3',
      border: '1px solid',
    },
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8px 16px',
      width: "100%"
    },
    ':-khtml-any-link.MuiStack-root': {
      width: "100%"
    },
  })
  const [loading, setLoading] = useState(false);
  const handleSaveAndContinue = async () => {
    if (window.location.pathname === "/user/profile/update/experience" && profile.message !== null && profile.message?.CompletionPercentage >= 95) {
      // do sth here
      if ($('[name="work_place"]').val() !== "" || $('[name="work_day_activities"]').val() !== "") {
        handleAdd();
      } else {
        dispatch(updateExperience(reviewedChips)); // work
        dispatch(dispatchActivites(reviewedChips));
        navigate(`/user/profile/:${userInfo.user.profile_id}`);
      }
    } else if (window.location.pathname === `/form/${cv.id}/update`) {
      if ($('[name="work_place"]').val() !== "") {
        handleAdd();
      }
      let generated_value = generated.message;
      generated_value.Experience = reviewedChips;
      setObj(generated_value);
      dispatch(dispatchActivites(reviewedChips));
      navigate("/allCv");
    } else {
      if (exp.activities !== null && exp.activities?.length === 0) {
        setTimeout(() => {
          toast.error("Add at least one experience", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }, 50);
      } else if ($('[name="work_place"]').val() !== "" || $('[name="work_day_activities"]').val() !== "") {
        setTimeout(() => {
          toast.error("Please, click on the add button to add your experience.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }, 50);
      } else {
        // let check if all chips has been reviewed.
        let unReviewedChip = work.filter(e => !(e.hasOwnProperty("Review")));
        if (unReviewedChip.length > 0) {
          // throw an error
          setTimeout(() => {
            toast.error("Please review the informations with red borders at the top.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }, 50);
        } else {
          if ((window.location.pathname == "/user/profile/update/experience" && profile.message !== null && profile.message?.CompletionPercentage < 95) || process !== null) {
            setSaving(true);
          }
          dispatch(dispatchActivites(reviewedChips));
          if (profile.message !== null && profile.message?.CompletionPercentage == 100) {
            dispatch(convertExperience({ Experience: reviewedChips, Lang: [{ lang: Language }] }, "resume"));
          }
          if (profile.message == null || (profile.message?.CompletionPercentage !== undefined && profile.message?.CompletionPercentage < 95)) {
            dispatch(saveExperience(reviewedChips, secret.secret));
            // save into server cache
            saveIntoServerCache("experience", reviewedChips); // take it out later
          }
          // save into server cache
          saveIntoServerCache("experience", reviewedChips);
          // saveIntoServerCache("step", 3)
          if ((window.location.pathname == "/user/profile/update/experience" && profile.message !== null && profile.message?.CompletionPercentage < 95) || process !== null) {
            // setFourty(true);
            setProcessModal(true);
          } else {
            props.handleStepChange();
          }
        }
      }
    }
  }

  let year = [];
  for (let i = 1990; i <= 2023; i++) {
    year.push(`${i}`);
  }

  // console.log("work", work)
  // console.log("chips", chips);

  const handleDelete = (chipToDelete) => {
    if (work.length == 0 && reviewedChips.length > 0) {
      setwork(reviewedChips);
    }
    if (window.location.pathname === "/user/profile/update/experience" && profile.message !== null && profile.message?.CompletionPercentage >= 95) {
      if (work.length === 1) {
        setTimeout(() => {
          toast.error("You cannot delete this experience. You must have at leat one experience", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }, 50);
      } else {
        const newWork = handleDeleteValue(chipToDelete?.Review !== undefined && chipToDelete?.Review == true ? reviewedChips : work, chipToDelete, "_id");
        if (chipToDelete?.Review !== undefined && chipToDelete?.Review == true) {
          if (newWork.length > 0 && newWork[0].Review !== undefined && newWork[0].Review == true) {
            setReviewedChips(newWork);
          }
          dispatch(dispatchActivites(newWork));
          dispatch(updateExperience(newWork))
        } else {
          setwork(newWork);
          setChips(newWork);
          dispatch(dispatchActivites(newWork));
        }
      }
    } else {
      // no need to setup a condition to test '/form/${cv.id}/update'

      const newWork = handleDeleteValue(chipToDelete?.Review !== undefined && chipToDelete?.Review == true ? reviewedChips : work, chipToDelete, "key");
      if (chipToDelete?.Review !== undefined && chipToDelete?.Review == true) {
        setReviewedChips(newWork);
        dispatch(dispatchActivites(newWork));
      } else {
        setChips(newWork);
        setwork(newWork)
        dispatch(dispatchActivites(newWork));
      }
    }
    // console.log("chips", chips);
    // console.log("delete work", work);
  }

  let month = [];
  for (let i = 0; i <= Months.length - 1; i++) {
    month.push(`${Months[i].value}`);
  }

  let mth = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"]
  let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

  const handleClickOperation = (desiredKey) => {
    if (window.location.pathname === "/user/profile/update/experience" && profile.message !== null && profile.message?.CompletionPercentage >= 95) {

      let click_work = reviewedChips;
      let desiredObject = click_work.find((item) => {
        // console.log("item.key", item.key)
        if (item.key === undefined) {
          return item._id === desiredKey._id
        } else {
          return item.key === desiredKey.key;
        }
      });
      // console.log("desiredObject", desiredObject);
      let w = click_work.filter(chip => {
        if (chip.key !== undefined) {
          return chip.key !== desiredObject.key;
        } else {
          return chip._id !== desiredObject._id
        }
      });

      $('[name="work_place"]').val(desiredObject.Where_i_worked);

      $('[name="work_role"]').val(desiredObject.My_job_title);
      localStorage.setItem("job_role", desiredObject.My_job_title);

      $('[name="work_day_activities"]').val(desiredObject.Day_to_day_work.join("\n"));
      setColor(true);
      setExperience(desiredObject.Day_to_day_work.join("\n"));
      // }

      let startMonth, startYear;
      if (desiredObject.Work_start_date !== null && desiredObject.Work_start_date !== undefined) {
        startMonth = desiredObject.Work_start_date.split(" ")[0];
        if ((isRedux == true && profile.message?.method !== "Manual" && profile.message.CompletionPercentage < 95)) {
          startYear = desiredObject.Work_start_date.split(" ")[1];
        } else {
          startYear = `${parseInt(desiredObject.Work_start_date.split(" ")[1], 10) + 1}`;
        }
        const firstMonth = new Date(startYear, mth.indexOf(startMonth));
        const firstYear = new Date(startYear);
        let i = firstMonth.getTime();
        let y = firstYear.getTime();
        // console.log("Milli", i);
        // console.log("Mil year", y);
        if ((isRedux == true && profile.message?.method !== "Manual" && profile.message.CompletionPercentage < 95)) {
          let monthIndex = mth.indexOf(startMonth);
          setExpStart(months[monthIndex]);
          setExpEnd(startYear);
        } else {
          setExpStart(i);
          setExpEnd(y);
        }

      }

      let endMonth, endYear;
      if (desiredObject.Work_end_date !== null && desiredObject.Work_end_date !== undefined) {
        if (desiredObject.Work_end_date === "Present") {
          $('#present').prop("checked", true);
          setE_Present(true);
        } else {
          $('#present').prop("checked", false);
          setE_Present(false);
          endMonth = desiredObject.Work_end_date.split(" ")[0];
          if ((isRedux == true && profile.message?.method !== "Manual" && profile.message.CompletionPercentage < 95)) {
            endYear = desiredObject.Work_end_date.split(" ")[1];
          } else {
            endYear = `${parseInt(desiredObject.Work_end_date.split(" ")[1], 10) + 1}`;
          }
          const secondMonth = new Date(endYear, mth.indexOf(endMonth));
          const secondYear = new Date(endYear);
          let sm = secondMonth.getTime();
          let sy = secondYear.getTime();
          // console.log("sm", sm);
          // console.log("sy", sy);
          if ((isRedux == true && profile.message?.method !== "Manual" && profile.message.CompletionPercentage < 95)) {
            let monthEndIndex = mth.indexOf(endMonth);
            setExpStart1(months[monthEndIndex]);
            setExpEnd1(endYear);
          } else {
            setExpStart1(sm);
            setExpEnd1(sy);
          }
        }
      }

      if ((isRedux == true && profile.message?.method !== "Manual" && profile.message.CompletionPercentage < 95)) {
        $('[name="work_start_date"]').val(desiredObject.Work_start_date);
        $('[name="work_end_date"]').val(desiredObject.Work_end_date);
      }

      // console.log("desired object", desiredObject);
      w.forEach((element) => {
        element.key = w.indexOf(element);
        // console.log("work key", w.indexOf(element));
      });
      setwork(w);
      // setChips(w);
      if (w[0]?.Review !== undefined && w[0]?.Review == true) {
        setReviewedChips(w);
      }
      desiredObject = [];

      // console.log("inner chips", chips);
      // console.log("inner work", w);
    } else {
      let click_work = work?.length > 0 ? work : reviewedChips;
      let desiredObject = click_work.find((item) => item.key === desiredKey.key);
      let w = click_work.filter(chip => chip.key !== desiredObject?.key);

      $('[name="work_place"]').val(desiredObject?.Where_i_worked);

      $('[name="work_role"]').val(desiredObject?.My_job_title);
      localStorage.setItem("job_role", desiredObject?.My_job_title);

      let startMonth, startYear;
      if (desiredObject.Work_start_date !== null && desiredObject.Work_start_date !== undefined) {
        startMonth = desiredObject.Work_start_date.split(" ")[0];
        startYear = `${parseInt(desiredObject.Work_start_date.split(" ")[1], 10) + 1}`;
        if ((isRedux == true && profile.message?.method !== "Manual" && profile.message.CompletionPercentage < 95)) {
          startYear = desiredObject.Work_start_date.split(" ")[1];
        } else {
          startYear = `${parseInt(desiredObject.Work_start_date.split(" ")[1], 10) + 1}`; // always add 1 because the date is always reduced by 0ne (1)
        }
        const firstMonth = new Date(startYear, mth.indexOf(startMonth));
        const firstYear = new Date(startYear);
        let i = firstMonth.getTime();
        let y = firstYear.getTime();
        // console.log("Milli", i);
        // console.log("Mil year", y);
        if ((isRedux == true && profile.message?.method !== "Manual" && profile.message.CompletionPercentage < 95)) {
          let monthIndex = mth.indexOf(startMonth);
          setExpStart(months[monthIndex]);
          setExpEnd(startYear);
        } else {
          setExpStart(i);
          setExpEnd(y);
        }
      }

      let endMonth, endYear;
      if (desiredObject.Work_end_date !== null && desiredObject.Work_end_date !== undefined) {
        // console.log("desiredObject.Work_end_date", desiredObject.Work_end_date)
        if (desiredObject.Work_end_date === "Present") {
          $('#present').prop("checked", true);
          setE_Present(true);
        } else {
          $('#present').prop("checked", false);
          setE_Present(false);
          endMonth = desiredObject.Work_end_date.split(" ")[0];
          if ((isRedux == true && profile.message?.method !== "Manual" && profile.message.CompletionPercentage < 95)) {
            endYear = desiredObject.Work_end_date.split(" ")[1];
          } else {
            endYear = `${parseInt(desiredObject.Work_end_date.split(" ")[1], 10) + 1}`;
          }
          const secondMonth = new Date(endYear, mth.indexOf(endMonth));
          const secondYear = new Date(endYear);
          let sm = secondMonth.getTime();
          let sy = secondYear.getTime();
          // console.log("sm", sm);
          // console.log("sy", sy);
          if ((isRedux == true && profile.message?.method !== "Manual" && profile.message.CompletionPercentage < 95)) {
            let monthEndIndex = mth.indexOf(endMonth);
            setExpStart1(months[monthEndIndex]);
            setExpEnd1(endYear);
          } else {
            setExpStart1(sm);
            setExpEnd1(sy);
          }
        }
      }
      if (Array.isArray(desiredObject.Day_to_day_work) == true) {
        $('[name="work_day_activities"]').val(desiredObject.Day_to_day_work.join("\n"));
        setExperience(desiredObject.Day_to_day_work.join("\n"));
      } else {
        $('[name="work_day_activities"]').val(desiredObject.Day_to_day_work);
        setExperience(desiredObject.Day_to_day_work);
      }
      // }

      if ((isRedux == true && profile.message?.method !== "Manual" && profile.message.CompletionPercentage < 95)) {
        $('[name="work_start_date"]').val(desiredObject.Work_start_date);
        $('[name="work_end_date"]').val(desiredObject.Work_end_date);
      }

      // console.log("desired object", desiredObject);
      w.forEach((element) => {
        element.key = w.indexOf(element);
        // console.log("work key", w.indexOf(element));
      });
      setwork(w);
      setChips(w);
      if (w[0]?.Review !== undefined && w[0]?.Review == true) {
        setReviewedChips(w);
      }
      desiredObject = [];
      // console.log("inner chips", chips);
      // console.log("inner work", w);
    }
  }

  // handleClick function
  const handleClick = (desiredKey) => {
    const Where_i_worked = $('[name="work_place"]').val();
    const day_day_work = $('[name="work_day_activities"]').val()
    if (Where_i_worked !== "" && day_day_work !== "") {
      handleAdd(); // add already mapped values back to the work array if the client/user forget to click add, this is so, so as to save users experience.
      localStorage.removeItem("count");
      handleClickOperation(desiredKey);
    } else {
      setColor(true)
      localStorage.removeItem("count");
      handleClickOperation(desiredKey);
    }
  }

  // console.log("new!!", newwork)

  // handle add function
  const handleAdd = () => {
    if (($('[name="work_place"]').val() == "" || $('[name="work_start_date"]').val() == "" || $('[name="work_end_date"]').val() == "" || $('[name="work_day_activities"]').val() == "") && e_present !== true) {
      setTimeout(() => {
        toast.error("Please fill form field before you can add.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
    } else if ((((isRedux == true && profile.message?.method !== "Manual" && profile.message.CompletionPercentage < 95) && expStart == "") || ((isRedux == true && profile.message.CompletionPercentage >= 95) && isNaN(expStart) == true) || ((isRedux == true && profile.message?.method !== "Manual" && profile.message.CompletionPercentage < 95) && expEnd == "") || ((isRedux == true && profile.message.CompletionPercentage >= 95) && isNaN(expEnd) == true) || ((isRedux == true && profile.message?.method !== "Manual" && profile.message.CompletionPercentage < 95) && expStart1 == "") || ((isRedux == true && profile.message.CompletionPercentage >= 95) && isNaN(expStart1) == true) || ((isRedux == true && profile.message?.method !== "Manual" && profile.message.CompletionPercentage < 95) && expEnd1 == "") || ((isRedux == true && profile.message.CompletionPercentage >= 95) && isNaN(expEnd1) == true)) && e_present !== true) {
      setTimeout(() => {
        toast.error("Please dates cannot be empty.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
    } else if ((((isRedux == false && profile.message.CompletionPercentage >= 95) && expStart == "") || ((isRedux == false && profile.message.CompletionPercentage >= 95) && expEnd == "") || ((isRedux == false && profile.message.CompletionPercentage >= 95) && expStart1 == "") || ((isRedux == false && profile.message.CompletionPercentage >= 95) && expEnd1 == "")) && e_present !== true) {
      setTimeout(() => {
        toast.error("Please dates cannot be empty.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
    } else {
      // console.log("current work length", work.length);
      dispatch(ActivitiesDetails_loading(true));


      const date1 = new Date(expStart);
      const date2 = new Date(expEnd);

      const date3 = new Date(expStart1);
      const date4 = new Date(expEnd1);

      const month1 = date1.toLocaleDateString('en-US', { month: 'short' })
      const year1 = date2.getFullYear();

      const month2 = date3.toLocaleDateString('en-US', { month: 'short' })
      const year2 = date4.getFullYear();

      let newWork = reviewedChips; // work;
      const Where_i_worked = $('[name="work_place"]').val();
      var Work_start_date;
      var Work_end_date;

      if ((isRedux == true && profile.message?.method !== "Manual" && profile.message.CompletionPercentage < 95)) {
        Work_start_date = $('[name="work_start_date"]').val() != "" && $('[name="work_start_date"]').val() != undefined ? $('[name="work_start_date"]').val() != "" : expStart + " " + expEnd;
        // Work_end_date = $('[name="work_end_date"]').val() == undefined ? expStart1 + " " + expEnd1 : $('[name="work_end_date"]').val();
        if ($('[name="work_end_date"]').val() == undefined && e_present !== true) {
          Work_end_date = expStart1 + " " + expEnd1
        } else if ($('[name="work_end_date"]').val() == undefined && e_present == true) {
          Work_end_date = "Present";
        } else {
          Work_end_date = $('[name="work_end_date"]').val();
        }
      } else {
        Work_start_date = month1 + ' ' + year1;
        if (e_present == true) {
          Work_end_date = "Present";
        } else {
          Work_end_date = month2 + ' ' + year2;
        }
      }

      let exp_value;
      if ($('[name="work_day_activities"]').val() !== "") {
        if ($('[name="work_day_activities"]').val().includes("\n")) {
          exp_value = $('[name="work_day_activities"]').val().split("\n");
        } else {
          exp_value = $('[name="work_day_activities"]').val().split(".");
        }
      }
      const k = work.length + 1;
      const data = {
        key: k,
        Where_i_worked: $('[name="work_place"]').val(),
        Where_i_worked_location: $('[name="work_place"]').val(),
        My_job_title: $('[name="work_role"]').val(),
        Work_start_date: Work_start_date,
        Work_end_date: Work_end_date,
        Day_to_day_work: exp_value,
        Review: true
      }
      if (Where_i_worked == '') {
        return;
      }
      // console.log("Add func chips", chips);
      newWork.push(data);
      newWork.forEach(element => {
        element.key = newWork.indexOf(element);
      });
      // console.log("new work", newWork);
      if (window.location.pathname === "/user/profile/update/experience" && profile.message !== null && profile.message?.CompletionPercentage >= 95) {
        setNewWork(newWork);
        // setwork(newWork);
        setActivities(newWork);
        setReviewedChips(newWork);
        // setChips(newWork);
        dispatch(dispatchActivites(newWork));
      } else {
        // console.log("newWork", newWork)
        // setwork(newWork);
        setReviewedChips(newWork);
        // setChips(newWork);
        setActivities(newWork);
        dispatch(dispatchActivites(newWork));
        // save into server cache
        saveIntoServerCache("experience", newWork);
      }

      setExpStart("");
      setExpEnd("");
      setExpStart1("");
      setExpEnd1("");
      $('#present').prop('checked', false);
      setE_Present(false);
      $('[name="work_place"]').val('');
      $('[name="work_role"]').val('');
      $('[name="work_day_activities"]').val('');
      if ((isRedux == true && profile.message?.method !== "Manual" && profile.message.CompletionPercentage < 95)) {
        Work_start_date = $('[name="work_start_date"]').val("");
        Work_end_date = $('[name="work_end_date"]').val("");
        setExpStart("");
        setExpEnd("");
        setExpStart1("");
        setExpEnd1("");
      }

      // clear the job role stored in localStorage
      localStorage.removeItem("count");
      localStorage.removeItem("job_role");
      dispatch(cleanEX());
      setExperience("");
    }
  }
  // console.log("expStart", expStart, typeof expStart);
  // console.log("expEnd", expEnd);
  // console.log("expStart1", expStart1);
  // console.log("expEnd1", expEnd1);
  const generateExperience = () => {
    dispatch(cleanEX());
    if ($('[name="work_place"]').val() === "") {
      setTimeout(() => {
        toast.error("Please enter the experience job title", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
      return false;
    } else if ($('[name="work_place"]').val() !== "") {

      if (message !== undefined && message !== null) {
        // console.log("experience", experience);
        if (work.length !== 0) {

        }
        if (Array.isArray(experience)) {
          setTruty(true);
          const cred = {
            Experience: experience,
            Language: Language,
            CvType: $('[name="work_role"]').val(),
            workPlace: $('[name="work_place"]').val(),
          }
          let count = localStorage.getItem("count") !== null ? parseInt(JSON.parse(localStorage.getItem("count")), 10) : 0;
          localStorage.setItem("count", count + 1);
          dispatch(generate_experience(cred));
        } else if (typeof experience === "string") {
          if (experience !== "" && $('[name="work_place"]').val() !== null) {
            setTruty(true);
            const cred = {
              Experience: experience,
              Language: Language,
              CvType: $('[name="work_role"]').val(),
              workPlace: $('[name="work_place"]').val(),
            }
            let count = localStorage.getItem("count") !== null ? parseInt(JSON.parse(localStorage.getItem("count")), 10) : 0;
            localStorage.setItem("count", count + 1);
            dispatch(generate_experience(cred));
          } else {
            let text
            if ($('[name="work_place"]').val() === "") {
              text = "Please enter the experience job title";
            }
            if (experience.split(" ").length < 10) {
              text = "In at least 20 words, breifly describe your daily activity"
            }
            setTimeout(() => {
              toast.error(text, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }, 50);
            return false;
          }
        }
      } else {
        // console.log("experience", experience);
        if (Array.isArray(experience)) {
          setTruty(true);
          const cred = {
            Experience: experience,
            Language: Language,
            CvType: $('[name="work_role"]').val(),
            workPlace: $('[name="work_place"]').val()
          }
          let count = localStorage.getItem("count") !== null ? parseInt(JSON.parse(localStorage.getItem("count")), 10) : 0;
          localStorage.setItem("count", count + 1);
          dispatch(generate_experience(cred));
        } else if (typeof experience === "string") {
          if ($('[name="work_place"]').val() === "") {
            setTimeout(() => {
              toast.error("Please enter the experience job title", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }, 50);
          } else if (experience.split(" ").length < 20) {
            setTimeout(() => {
              toast.error("In at least 20 words, breifly describe your daily activity", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }, 50);
          } else if ((experience !== "" && experience.split(" ").length >= 20) && $('[name="work_place"]').val() !== null) {
            setTruty(true);
            const cred = {
              Experience: experience,
              Language: Language,
              CvType: $('[name="work_role"]').val(),
              workPlace: $('[name="work_place"]').val()
            }
            let count = localStorage.getItem("count") !== null ? parseInt(JSON.parse(localStorage.getItem("count")), 10) : 0;
            localStorage.setItem("count", count + 1);
            dispatch(generate_experience(cred));
          } else {

            return false;
          }
        }
      }
    }

    return;
  }

  const handleBack = () => {
    if ($('[name="work_place"]').val() !== "" || $('[name="work_day_activities"]').val() !== "") {
      setTimeout(() => {
        toast.error("Add the experience you just filled.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
    } else {
      if (window.location.pathname === "/user/profile/update/experience" && profile.message !== null && profile.message?.CompletionPercentage < 95) {
        navigate("/user/profile/update/personalInfo")
      } else {
        props.handleStepChangeBack();
      }
    }
  }

  let text
  if (AIexp.errMess === null) {
    text = "Our AI tool is generating a standard experience description for you. Kindly wait a minute...";
  } else {
    // text = `${language["error"]}`;
  }

  // const twenty = !saving && twentyModal ? <TwentyPercentConfirmationGamifiedModal profileLink={`/user/profile/:${userInfo.user.profile_id}`} handleStepChange={props.handleStepChange} /> : null;
  const fourty = !saving && fourtyModal ? <ExperienceCompleteGamifiedModal profileLink={`/user/profile/:${userInfo.user.profile_id}`} handleStepChange={props.handleStepChange} /> : null;
  // const sixty = !saving && sixtyModal ? <SixtyPercentConfirmationGamifiedModal profileLink={`/user/profile/:${userInfo.user.profile_id}`} handleStepChange={props.handleStepChange} /> : null;
  // const eighty = !saving && eightyModal ? <EightyPercentConfirmationGamifiedModal profileLink={`/user/profile/:${userInfo.user.profile_id}`} handleStepChange={props.handleStepChange} /> : null;
  // const nintyFive = !saving && nintyFiveModal ? <NinetyFivePercentConfirmationGamifiedModal profileLink={`/user/profile/:${userInfo.user.profile_id}`} handleStepChange={props.handleStepChange} /> : null;
  // const hundred = hundredModal ? <CompleteConfirmationGamifiedModal handleClose={() => sethundredModal(false)} handleContinue={sethundredModal} /> : null;
  const savingloader = saving ? <LoaderModal text="Saving your information" /> : null;


  // let Acknowledge = forty ? <FortyPercentConfirmationGamifiedModal  profileLink={`/user/profile/:${userInfo.user.profile_id}`} handleStepChange={props.handleStepChange} /> : null;

  // console.log("reviewedChips", reviewedChips);

  return (
    <>
      <HelmetProvide
        title="Moil | Create Your Resume"
        description="Build your professional resume with Moil's easy-to-use resume creation form. Showcase your skills, experience, and qualifications to potential employers."
        className="notranslate"
      />
      <div className={`grid grid-cols-auto md:grid-cols-smdash ${sidebar ? 'cont:grid-cols-dash' : ''} grid-rows-dash`}>
        <TopBar topBarShowMenu={true} />
        <SideBar />
        <div className="bg-[#FAFAFA] px-3 lg:pl-6 py-8 w-full overflow-y-scroll ScrollOnly h-calc-70 flex justify-center">
          <div className="w-full xtraxl:max-w-calculate flex flex-col items-center">

            <p className="max-w-[900px] xtraxl:max-w-calculate w-full text-[24px] font-semibold leading-9 text-left text-black text-opacity-87">
              Complete your profile
            </p>

            <div className="max-w-[900px] xtraxl:max-w-calculate w-full py-5 rounded-2xl flex-col justify-start items-start gap-5 inline-flex">
              <div className="tabarea justify-start items-center gap-6 inline-flex w-full flex-shrink-0 max-w-[calc(100vw-88px)] overflow-hidden">
                <div className="hidden md:block">
                  <Tab
                    // label="Personal Information"
                    label="Personal Information"
                    current={false}
                    completed={true}
                    num="1"
                  />
                </div>
                <Tab num="2" current={true} label="Experience" completed={true} />
                <Tab num="3" current={false} label="Education" completed={false} />
                <Tab num="4" current={false} label="Skill/Language" completed={false} />
                <Tab num="5" current={false} label="Professional Summary" completed={false} />
              </div>

              <p class="text-base font-medium leading-6 text-left text-[#0D0D0D]">
                Fill this form to let employers know about your work experience.
              </p>
              {/* <div className="sm:mt-2 mb-4 py-2 px-4 sm:mb-2 sm:py-0">
                <h6 className="top-2 text-sm sm:text-[18px] lg:text-[24px] text-pry-920 leading-normal">
                  In this page, please tell us about your previous work experience, use your own words, do not worry about spelling and punctuation, we got you covered!!
                </h6>
              </div> */}
              <div className="flex-col justify-start items-start gap-4 flex w-full rounded-[16px] bg-white resume_page py-6 px-4">

                <p class="text-xl font-semibold leading-8 text-left text-[#5843BD]">
                  Work Experience
                </p>

                {chips?.length > 0 ? <h1 className="text-black text-base font-medium">Please update your pre loaded existing work experience added in the buttons below!</h1> : null}
                <span className="text-orange-500 text-base font-medium">
                  Your added experience will show here:
                </span>
                <div className="flex-col justify-start items-start gap-3 flex w-full">
                  <div className="flex flex-wrap gap-3">
                    {chips?.map((exp, key) => (
                      <>{exp?.Review === undefined ?
                        <AddedItem
                          item={exp.Where_i_worked}
                          key={key}
                          color={exp?.Review}
                          onClick={() => handleClick(exp)}
                          delete={() => handleDelete(exp)}
                        /> : null}</>
                    ))}
                  </div>
                </div>


                <div className="flex items-center flex-col md:flex-row gap-y-4  w-full gap-x-8">
                  <div className="flex-col justify-start items-start gap-2 flex w-full">
                    <div className="flex gap-3 items-center">
                      <div className="p-[3px] w-1 h-1 rounded-full bg-black"></div>
                      <label
                        className="text-black text-base font-medium"
                        for="companyWorked"
                      >
                        Company name?{/* {language["where_work"]} */}
                      </label>
                    </div>
                    <TextInput
                      placeholder="Type the name of your workplace here e.g Julius Berger, Mcdonard"
                      id="companyWorked"
                      name="work_place"
                      onChange={(event) =>
                        localStorage.setItem("Where_i_worked", event.target.value)
                      }
                      reff={experienceRef}
                    />
                  </div>
                  <div className="flex-col justify-start items-start gap-2 flex w-full">
                    <div className="flex gap-3 items-center">
                      <div className="p-[3px] w-1 h-1 rounded-full bg-black"></div>
                      <label
                        className="text-black text-base font-medium"
                        for="jobTitle"
                      >
                        What was your job title or role?
                      </label>
                    </div>
                    <TextInput
                      placeholder="Enter your job title here e.g Construction Worker "
                      id="jobTitle"
                      name="work_role"
                      onChange={(event) =>
                        localStorage.setItem("job_role", event.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="flex-col justify-start items-start gap-2 flex w-full">
                  <div className="flex gap-3 items-center">
                    <div className="p-[3px] w-1 h-1 rounded-full bg-black"></div>
                    <label
                      className="text-black text-base font-medium"
                      for="durationOfStudy"
                    >
                      Are you still working at here
                    </label>
                  </div>

                  <div className="mt-3">
                    <Radio name="present" label="Present (I still work here)" id="present" opacity={true} />
                  </div>
                </div>


                <div className="flex-col justify-start items-start gap-2 flex w-full">
                  {/* <div className="w-full flex flex-col sm:flex-row gap-x-8">
                    <div className="flex gap-3 items-center">
                      <div className="p-[3px] w-1 h-1 rounded-full bg-black"></div>
                      <label
                        className="text-black text-base font-medium"
                        for="durationOfStudy"
                      >
                        When did you start this job?
                      </label>
                    </div>
                    <div className="flex gap-3 items-center">
                      <div className="p-[3px] w-1 h-1 rounded-full bg-black"></div>
                      <label
                        className="text-black text-base font-medium"
                        for="durationOfStudy"
                      >
                        When did you end this job?
                      </label>
                    </div>
                  </div> */}
                  {/* {isRedux == true ? null : (
                  <p className="text-zinc-400 text-base font-medium">
                    When did you start and end this job?
                  </p>
                )} */}
                  <div className="w-full flex-col flex gap-3">
                    {(isRedux == true && profile.message?.method !== "Manual" && profile.message.CompletionPercentage < 95) ? (
                      <div className="w-full flex flex-col md:flex-row gap-x-8 gap-y-4 items-center">
                        <div className="flex-col justify-start items-start gap-2 flex w-full">
                          <div className="flex gap-3 items-center">
                            <div className="p-[3px] w-1 h-1 rounded-full bg-black"></div>
                            <label
                              className="text-black text-base font-medium"
                              for="durationOfStudy"
                            >
                              When did you start this job?
                            </label>
                          </div>
                          <div className={`${e_present ? "flex-row" : "flex-col"} justify-start items-start gap-2 flex w-full`}>

                            <BasicSelect
                              placeholder="Month"
                              labelId="start_month"
                              selectMenu="start_month"
                              value={expStart}
                              onChange={(event) => setExpStart(event.target.value)}
                              id="startMonth"
                              menuItems={[...dateJson.months]}
                            />

                            <BasicSelect
                              placeholder="Year"
                              labelId="start_year"
                              selectMenu="start_year"
                              value={expEnd}
                              onChange={(e) => setExpEnd(e.target.value)}
                              id="startYear"
                              menuItems={[...dateJson.years]}
                            />
                          </div>
                        </div>
                        {!e_present && <div className="flex-col justify-start items-start gap-2 flex w-full">
                          <div className="flex gap-3 items-center">
                            <div className="p-[3px] w-1 h-1 rounded-full bg-black"></div>
                            <label
                              className="text-black text-base font-medium"
                              for="durationOfStudy"
                            >
                              When did you end this job?
                            </label>
                          </div>
                          <div className="flex-col justify-start items-start gap-2 flex w-full">

                            <BasicSelect
                              placeholder="Month"
                              labelId="end_month"
                              selectMenu="end_month"
                              value={expStart1}
                              onChange={(event) => setExpStart1(event.target.value)}
                              id="endMonth"
                              menuItems={[...dateJson.months]}
                            />

                            <BasicSelect
                              placeholder="Year"
                              labelId="end_year"
                              selectMenu="end_year"
                              value={expEnd1}
                              onChange={(event) => setExpEnd1(event.target.value)}
                              id="endYear"
                              menuItems={[...dateJson.years]}
                            />
                          </div>
                        </div>}
                      </div>
                    ) : (
                      <div>
                        <div className="flex gap-3 items-center">
                          <div className="p-[3px] w-1 h-1 rounded-full bg-black"></div>
                          <label
                            className="text-black text-base font-medium"
                            for="durationOfStudy"
                          >
                            When did you start this job?
                          </label>
                        </div>
                        <div className="w-full flex flex-col sm:flex-row gap-x-8">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              components={[
                                "DatePicker",
                                "DatePicker",
                                "DatePicker",
                              ]}
                            >
                              <StyledDatePicker
                                label="Month"
                                views={["month"]}
                                value={dayjs(expStart)}
                                onChange={(event) => setExpStart(event)}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              components={[
                                "DatePicker",
                                "DatePicker",
                                "DatePicker",
                              ]}
                            >
                              <StyledDatePicker
                                label="Year"
                                name="startYear"
                                views={["year"]}
                                value={dayjs(expEnd)}
                                onChange={(endValue) => setExpEnd(endValue)}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {!e_present && <div className="flex-col justify-start items-start gap-2 flex w-full">
                  {/* <div className="flex gap-3 items-center">
                    <div className="p-[3px] w-1 h-1 rounded-full bg-black"></div>
                    <label
                      className="text-black text-base font-medium"
                      for="durationOfStudy"
                    >
                      When did you end this job?
                    </label>
                  </div> */}
                  <div className="w-full flex-col flex gap-3">
                    {(isRedux == true && profile.message?.method !== "Manual" && profile.message.CompletionPercentage < 95) ? (
                      <></>
                      // <div className="flex-col justify-start items-start gap-2 flex w-full">
                      //   <div className="flex gap-3 items-center"></div>
                      //   <TextInput
                      //     placeholder="Month Year"
                      //     id="jobTitle"
                      //     name="work_end_date"
                      //   />
                      // </div>
                    ) : (
                      <div>
                        <div className="flex gap-3 items-center">
                          <div className="p-[3px] w-1 h-1 rounded-full bg-black"></div>
                          <label
                            className="text-black text-base font-medium"
                            for="durationOfStudy"
                          >
                            When did you end this job?
                          </label>
                        </div>
                        <div className="w-full flex flex-col sm:flex-row gap-x-8">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              components={[
                                "DatePicker",
                                "DatePicker",
                                "DatePicker",
                              ]}
                            >
                              <StyledDatePicker
                                label="Month"
                                views={["month"]}
                                name="endMonth"
                                value={dayjs(expStart1)}
                                onChange={(event) => setExpStart1(event)}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              components={[
                                "DatePicker",
                                "DatePicker",
                                "DatePicker",
                              ]}
                            >
                              <StyledDatePicker
                                label="Year"
                                name="endYear"
                                views={["year"]}
                                value={dayjs(expEnd1)}
                                onChange={(endValue) => setExpEnd1(endValue)}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>
                      </div>
                    )}
                  </div>
                </div>}

                <div className="w-full">
                  <div className="flex flex-col gap-3">
                    <div className="flex gap-3 items-center">
                      <div className="p-[3px] w-1 h-1 rounded-full bg-black"></div>
                      <label
                        className="text-black text-base font-medium"
                        for="firstName"
                      >
                        In your own words, tell us about your responsibilities and achievements. When completed click Generate Experience and our AI will do its Magic to make you shine
                      </label>
                    </div>

                    <p class="text-xs font-medium leading-4.5 text-left text-[#FF6633]">
                      Describe your work activity OR click on the 'Generate Summary' button so that our AI can help with a better summary.
                    </p>

                    <div className="">
                      <TextArea
                        placeholder="Worked on various AC repairs and replacement ..."
                        className="h-fit"
                        name="work_day_activities"
                        onChangeHandler={(event) => setExperience(event.target.value)}
                      />
                    </div>
                    <button
                      className={`max-w-max px-5 py-3 ${color === true ? `bg-[#FF6633]` : `bg-pry-100`
                        } rounded-3xl border justify-start items-start gap-2.5 inline-flex`}
                      onClick={() => {
                        if (experience !== "") {
                          generateExperience()
                        }
                      }}
                    >
                      <span className="text-center text-white text-base font-medium">
                        Generate Experience
                      </span>
                    </button>
                    {/*                   <span className="text-orange-500 text-base font-medium">
                    Add, delete or edit the text created by our AI and generate again for an updated response.
                  </span> */}
                  </div>
                </div>
                <ul>
                  <li className="text-[#FF6633] text-base font-medium list-disc ml-4">Add, delete or edit the text created by our AI and generate again for an updated response.</li>
                  <li className="text-[#FF6633] text-base font-medium list-disc ml-4">When completed click “Add Experience”</li>
                  {/* <li className="text-orange-500 text-base font-medium list-disc ml-4">{chips?.length == 0 ? "No experience yet." : "You can add more experience"}</li> */}
                  <li className="text-[#FF6633] text-base font-medium list-disc ml-4">To add more, start at the top or save and continue to go to the next page.</li>
                </ul>
                <div className="flex-col justify-start items-start gap-3 flex w-full">
                  {/*                 <h6 className="text-red-500 text-base font-medium">When completed click “Add Experience” and add more or continue to the next page</h6> */}
                  <AddMore text="experience" onClick={handleAdd} />

                  <p className={`${reviewedChips?.length == 0 ? "text-[#FF6633]" : "text-black"} text-[#FF6633] text-base font-medium`}>
                    {reviewedChips?.length == 0 ? "No experience added yet." : "You have added"}
                  </p>
                  {/*                 <p className="text-red-500 text-base font-medium">
                  {chips?.length == 0 ? "No experience yet." : "You can add more experience"}
                </p> */}
                  <div className="flex-col justify-start items-start gap-3 flex w-full">
                    <div className="flex flex-wrap gap-3">
                      {reviewedChips?.slice(0, workedAllowed)?.map((exp, key) => (
                        <>{exp?.Review !== undefined ?
                          <AddedItem
                            item={exp?.Where_i_worked}
                            key={key}
                            color={exp?.Review}
                            onClick={() => handleClick(exp)}
                            delete={() => handleDelete(exp)}
                          /> : null}</>
                      ))}
                    </div>
                  </div>

                  {reviewedChips?.length > 4 && <ViewProp length={workedAllowed} normalLength={reviewedChips?.length} setAllowed={setworkedAllowed} />}

                  <button onClick={scrollToExperienceFields} class="text-base font-medium leading-6 text-center px-5 py-2.5 gap-2.5 rounded-lg text-[#FF6633] flex gap-1 items-center">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 17V7" stroke="#FF6633" stroke-width="1.5" stroke-linecap="round" />
                      <path d="M7 12L17 12" stroke="#FF6633" stroke-width="1.5" stroke-linecap="round" />
                    </svg>
                    <>Add another experience</>
                  </button>
                </div>
              </div>
              <div className="self-stretch justify-between items-start gap-4 inline-flex">
                {visibility == true ? null : (
                  <button
                    className="pr-2 md:pr-0 md:px-5 py-3 rounded-lg self-end justify-center items-center gap-1 md:gap-2.5 flex w-max max-w-[238px]"
                    onClick={handleBack}

                  >
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16 8L17.43 9.393L11.85 15H24V17H11.85L17.43 22.573L16 24L8 16L16 8Z" fill="#FF6633" />
                      <path d="M16 30C18.7689 30 21.4757 29.1789 23.778 27.6406C26.0803 26.1022 27.8747 23.9157 28.9343 21.3576C29.9939 18.7994 30.2712 15.9845 29.731 13.2687C29.1908 10.553 27.8574 8.05845 25.8995 6.10051C23.9416 4.14258 21.447 2.80921 18.7313 2.26901C16.0155 1.72882 13.2006 2.00607 10.6424 3.06569C8.08426 4.12532 5.89776 5.91973 4.35942 8.22202C2.82108 10.5243 1.99999 13.2311 1.99999 16C2.00423 19.7117 3.48059 23.2702 6.10518 25.8948C8.72977 28.5194 12.2883 29.9958 16 30ZM16 4.00001C18.3734 4.00001 20.6934 4.70379 22.6668 6.02237C24.6402 7.34095 26.1783 9.21509 27.0865 11.4078C27.9948 13.6005 28.2324 16.0133 27.7694 18.3411C27.3064 20.6689 26.1635 22.8071 24.4853 24.4853C22.807 26.1635 20.6688 27.3064 18.3411 27.7694C16.0133 28.2325 13.6005 27.9948 11.4078 27.0866C9.21508 26.1783 7.34094 24.6402 6.02236 22.6668C4.70378 20.6935 3.99999 18.3734 3.99999 16C4.0037 12.8185 5.26917 9.76845 7.5188 7.51882C9.76844 5.26918 12.8185 4.00371 16 4.00001Z" fill="#FF6633" />
                    </svg>


                    <span className="text-center text-[#FF6633] text-sm md:text-base font-medium whitespace-nowrap">
                      Previous
                    </span>
                  </button>
                )}
                <button
                  className="px-3 md:px-5 py-3 bg-indigo-700 rounded-lg self-end shadow justify-center items-center gap-1 md:gap-2.5 flex max-w-[238px]"
                  onClick={handleSaveAndContinue}

                >
                  {!visibility ? <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 8L14.57 9.393L20.15 15H8V17H20.15L14.57 22.573L16 24L24 16L16 8Z" fill="white" />
                    <path d="M16 30C13.2311 30 10.5243 29.1789 8.22202 27.6406C5.91973 26.1022 4.12532 23.9157 3.06569 21.3576C2.00607 18.7994 1.72882 15.9845 2.26901 13.2687C2.80921 10.553 4.14258 8.05845 6.10051 6.10051C8.05845 4.14258 10.553 2.80921 13.2687 2.26901C15.9845 1.72882 18.7994 2.00607 21.3576 3.06569C23.9157 4.12532 26.1022 5.91973 27.6406 8.22202C29.1789 10.5243 30 13.2311 30 16C29.9958 19.7117 28.5194 23.2702 25.8948 25.8948C23.2702 28.5194 19.7117 29.9958 16 30ZM16 4.00001C13.6266 4.00001 11.3066 4.70379 9.33316 6.02237C7.35977 7.34095 5.8217 9.21509 4.91345 11.4078C4.0052 13.6005 3.76756 16.0133 4.23058 18.3411C4.69361 20.6689 5.83649 22.8071 7.51472 24.4853C9.19296 26.1635 11.3312 27.3064 13.6589 27.7694C15.9867 28.2325 18.3995 27.9948 20.5922 27.0866C22.7849 26.1783 24.6591 24.6402 25.9776 22.6668C27.2962 20.6935 28 18.3734 28 16C27.9963 12.8185 26.7308 9.76845 24.4812 7.51882C22.2316 5.26918 19.1815 4.00371 16 4.00001Z" fill="white" />
                  </svg> : <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 4V16C18 16.55 17.8043 17.021 17.413 17.413C17.0217 17.805 16.5507 18.0007 16 18H2C1.45 18 0.979333 17.8043 0.588 17.413C0.196667 17.0217 0.000666667 16.5507 0 16V2C0 1.45 0.196 0.979333 0.588 0.588C0.98 0.196667 1.45067 0.000666667 2 0H14L18 4ZM16 4.85L13.15 2H2V16H16V4.85ZM9 15C9.83333 15 10.5417 14.7083 11.125 14.125C11.7083 13.5417 12 12.8333 12 12C12 11.1667 11.7083 10.4583 11.125 9.875C10.5417 9.29167 9.83333 9 9 9C8.16667 9 7.45833 9.29167 6.875 9.875C6.29167 10.4583 6 11.1667 6 12C6 12.8333 6.29167 13.5417 6.875 14.125C7.45833 14.7083 8.16667 15 9 15ZM3 7H12V3H3V7ZM2 4.85V16V2V4.85Z" fill="white" />
                  </svg>
                  }


                  <span className="text-center text-white text-sm md:text-base font-medium whitespace-nowrap">
                    {!visibility ? 'Save and Continue' : 'save to profile'}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        {truty === true ? (
          AIexp.exp !== null ? (
            setTruty(false)
          ) : AIexp.errMess === null ? (
            <div>
              <LoaderModal text={text} />
            </div>
          ) : null
        ) : null}
        {/* {Acknowledge} */}
        {/* {twenty} */}
        {fourty}
        {/* {sixty}
        {eighty}
        {nintyFive} */}
        {savingloader}
        <ToastContainer />
        {loading ? <LoaderModal text="Updating your information" /> : null}
      </div>
    </>
  );
}