import React, { useState, useEffect, useMemo, useCallback } from "react";
import DashboardOptionLink from "../common/dashboard_options_link";
import DashboardQueryItem from "../common/dashboard_query_item";
import { AddIcon } from "../common/svgs/svgs";
import {
  getAllMyCv,
  getSavedJobs,
  getJobsApplied,
  getChatInformation,
  personal_loading,
  existing_resume_loading,
  summaryUpdateCountLoading,
  available_jobs_success,
  available_jobs_success_category,
  total_page,
  search_success,
  search_failed,
  search_loading,
  resume_step_jump_function,
  search_total_page,
  ActivitiesDetails_loading,
  p_skillsDetails_loading,
  skillsDetails_loading,
  EducationDetails_loading,
  logOut
} from "../../Redux/ActionCreator";
import { useSelector, useDispatch } from "react-redux/es";
import { useNavigate } from "react-router-dom";
import HelmetProvide from "../common/helmet";
import HorizontalNFilterIcon from "../common/HorizontalNFilterIcon";
import SearchBarsOnTop from "../common/SearchBars/SearchBarsOnTop";
import { baseURL, baseURL1 } from ".././../Redux/baseURL";
import PaginationBtn from "../common/SearchBars/paginationBtn";
import LoaderModal from "../common/LoaderModal";
import { getRecommendedJobsByJobTitle, getRecommendedJobsByCategory, getRecommendedJobsByProfileTags } from "../../utils/recommendationAlgo";
import { ToastContainer, toast } from 'react-toastify';
// import { detectLang } from "../../utils/helper/languageDetector";
// import { checkSpelling } from "../../utils/helper/spellCheck"
import CompleteConfirmationGamifiedModal from "../gamified_modals/complete_confirm";
import ExperienceGamifiedModal from "../gamified_modals/experience_gamified_modal";
import EducationGamifiedModal from "../gamified_modals/education_gamified_modal";
import SkillsGamifiedModal from "../gamified_modals/skills_gamified_modal";
import SummaryGamifiedModal from "../gamified_modals/summary_gamified_modal";
import ProfilePicsGamifiedModal from "../gamified_modals/profile_pics_gamified_modal";
import AddMore from "../Resume/add_more/add_more";


export default function MoilUserDashboard() {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const userInfo = useSelector(e => e.userInfo.user);
  // getting all available jobs from the reux store
  const availble_jobs = useSelector((e) => e.available_jobs);
  const available_jobs_category = useSelector(e => e.available_jobs_category);
  const jobs = availble_jobs.message;
  const jobs_catgory = available_jobs_category.message;
  const total_page1 = useSelector((e) => e.total_page);
  const seachTotalPage = useSelector(e => e.seach_total_page.message);
  const ourtotalpage = total_page1.message;
  const [currentDashboardPage, setCurrentDashboardPage] = useState(1)
  const [totalJobs, setTotalJobs] = useState(0);
  const searchedJob = useSelector(e => e.searchResult.message);
  let [suggestion, setSuggestion] = useState([]);

  /* getting the user profile from the redux store so that the user dashboard 
  can be populated based on the information obtained */
  const profile = useSelector((e) => e.profile);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(summaryUpdateCountLoading(true));
    dispatch(existing_resume_loading());
  }, [])
  // get the available job as long as the user has created a profile within the application
  let [tabSwitch, setTabSwitch] = useState("title");
  let [tabColor, setTabColor] = useState(null);
  let [load, setLoad] = useState(false);

  const [experienceModal, setExperienceModal] = useState(false);
  const [educationModal, setEducationModal] = useState(false);
  const [skillsModal, setSkillsModal] = useState(false);
  const [summaryModal, setSummaryModal] = useState(false);
  // const [profilePics, setProfilePics] = useState(false);
  const [hundredModal, sethundredModal] = useState(false);
  const [updateText, setUpdateText] = useState("");
  const [urlLink, setUrlLink] = useState("");

  const memoizedFetchData = useMemo(() => {
    const fetchData = () => {
      if (userInfo !== null && userInfo !== undefined) {
        dispatch(getChatInformation(userInfo?.profile_id));
      }
      if (profile.message !== null) {
        const token = localStorage.getItem("token");
        dispatch(getAllMyCv(token));
        // dispatch(getAvailablejobs(currentDashboardPage)); // pass in the page state number;
        dispatch(getSavedJobs());
        dispatch(getJobsApplied(1));
      }
    }
    return fetchData;
  }, [dispatch])

  useEffect(() => {
    if (profile.message === null) {
      if (userInfo !== null) {
        setName(userInfo.name);
      }
    } else {
      if (profile?.message !== null) {
        const profileName = profile.message?.FirstName;// ?.split(" ")[0].toLowerCase();
        // let f_name = my_name?.charAt(0).toUpperCase() + my_name?.slice(1, my_name?.length);
        setName(profileName);
      }
    }
  }, [profile.message, userInfo])


  // const recommendationMemoization = useMemo(() => {
  //   const fetchRecommendationData = () => {
  //     if (profile.message !== null) {
  //       if (tabSwitch === "title") {
  //         // setLoad(true);
  //         let recommendation = getRecommendedJobsByJobTitle(currentDashboardPage);
  //         recommendation.then(resp => resp.json())
  //         .then(resp => {
  //           console.log("resp.data", resp);
  //           if (resp.success === true) {
  //             setLoad(false);
  //             console.log("resp.data", resp?.data);
  //             if (resp?.data?.returned_value !== null && resp?.data?.returned_value.length !== 0) {
  //               dispatch(available_jobs_success_category(resp?.data?.returned_value));
  //               dispatch(total_page(resp?.data?.page_num));
  //             } else {
  //               setLoad(false);
  //               setTabSwitch("category");
  //             }
  //           } else {
  //             if (resp?.title === "Not Found") {
  //               // it means it cant find last resume with category.
  //               setTabSwitch("category");
  //             }
  //           }
  //         }).catch(err => {
  //           console.log("An error occured", err);
  //           setLoad(false);
  //           setTimeout(() => {
  //             toast.error("An Error occured. Please check your network and try again.", {
  //               position: toast.POSITION.TOP_RIGHT,
  //             });
  //           }, 50);
  //         });
  //       } 
  //       if (tabSwitch === "category") {
  //         // now fallback to recommendation by category.
  //         setLoad(true);
  //         let recommendationByCat = getRecommendedJobsByCategory(currentDashboardPage);
  //         recommendationByCat.then(resp => resp.json())
  //         .then(resp => {
  //           // setLoad(false);
  //           console.log("resp.data", resp?.data);
  //           if (resp?.data?.returned_value !== null && resp?.data?.returned_value.length !== 0) {
  //             dispatch(available_jobs_success(resp?.data?.returned_value));
  //             dispatch(total_page(resp?.data?.page_num));
  //           } else {
  //             dispatch(available_jobs_success([]));
  //           }
  //         }).catch(err => {
  //           console.log("An error occured", err);
  //           setLoad(false);
  //           setTimeout(() => {
  //             toast.error("An Error occured. Please check your network and try again.", {
  //               position: toast.POSITION.TOP_RIGHT,
  //             });
  //           }, 50)
  //         });
  //       }
  //     }
  //   }

  //   return fetchRecommendationData;
  // }, [dispatch]);

  useEffect(() => {
    dispatch(personal_loading(true));
    dispatch(p_skillsDetails_loading(true));
    dispatch(skillsDetails_loading(true));
    dispatch(EducationDetails_loading(true));
    dispatch(ActivitiesDetails_loading(true));
    dispatch(resume_step_jump_function(0));
    memoizedFetchData();
    // recommendationMemoization();
  }, [memoizedFetchData, profile.message, currentDashboardPage, tabSwitch]);
  const searchParams = new URLSearchParams(location.search);
  // console.log("searchParams", searchParams);
  let title = searchParams.get("title");
  let loc = searchParams.get("location");
  let page = searchParams.get("page");

  useEffect(() => {
    if (profile.message !== null) {
      if (tabSwitch === "title") {
        if (jobs?.length == 0) {
          setLoad(true);
        }
        let recommendation = getRecommendedJobsByJobTitle(currentDashboardPage);
        recommendation.then(resp => resp.json())
          .then(resp => {
           // console.log("resp.data", resp);
            if (resp.success === true) {
              setLoad(false);
              // console.log("resp.data", resp?.data);
              if (resp?.data?.returned_value !== null && resp?.data?.returned_value.length !== 0) {
                dispatch(available_jobs_success(resp?.data?.returned_value));
                dispatch(total_page(resp?.data?.page_num));
              } else {
                setLoad(false);
                setTabSwitch("category");
              }
            } else {
              if (resp?.title === "Not Found") {
                // it means it cant find last resume with category.
                setTabSwitch("category");
              } else if (resp.message == "Unauthorized" || resp.message == "No token provided") {
                dispatch(logOut());
                setTimeout(() => {
                  toast.error("Session expired!", {
                    position: toast.POSITION.TOP_CENTER,
                  });
                  window.location.assign(baseURL1);
                }, 50);
              }
            }
          }).catch(err => {
            // console.log("An error occured", err);
            setLoad(false);
            setTimeout(() => {
              toast.error("An Error occured. Please check your network and try again.", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }, 50);
          });
      }
      if (tabSwitch === "category") {
        // now fallback to recommendation by category.
        if (jobs_catgory?.length == 0) {
          setLoad(true);
        }
        let recommendationByCat = getRecommendedJobsByProfileTags(currentDashboardPage) // getRecommendedJobsByCategory(currentDashboardPage);
        recommendationByCat.then(resp => resp.json())
          .then(resp => {
            setLoad(false);
            // console.log("resp.data category", resp?.data);
            if (resp?.data?.returned_value !== null && resp?.data?.returned_value.length !== 0) {
              dispatch(available_jobs_success_category(resp?.data?.returned_value));
              dispatch(total_page(resp?.data?.page_num));
            } else {
              dispatch(available_jobs_success_category([]));
            }
          }).catch(err => {
            // console.log("An error occured", err);
            setLoad(false);
            setTimeout(() => {
              toast.error("An Error occured. Please check your network and try again.", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }, 50)
          });
      }
    }
  }, [profile.message, currentDashboardPage, tabSwitch])

  // console.log("currentDashboardPage", currentDashboardPage);
  // console.log("Available Jobs", availble_jobs.message);

  // two recommendation tabs
  let getJobsByTitle = useCallback(
    async () => {
    if (profile.message?.JobInterests !== undefined && profile.message?.JobInterests !== null && profile.message?.JobInterests?.length !== 0) {
      // setLoad(true)
      setTabColor("title");
      setTabSwitch(null);
      setCurrentDashboardPage(1);

        let recommendationByTitle = getRecommendedJobsByJobTitle(currentDashboardPage);
        recommendationByTitle.then(resp => resp.json())
          .then(resp => {
            setLoad(false);
            if (resp?.success === true) {
              if (resp.data !== null) {
                dispatch(available_jobs_success(resp?.data?.returned_value));
              } else {
                dispatch(available_jobs_success([]));
              }
            } else {
              if (resp?.title === "Not Found") {
                // it means it cant find last resume with category.
                dispatch(available_jobs_success([]));
              } else if (resp.message == "Unauthorized" || resp.message == "No token provided") {
                dispatch(logOut());
                setTimeout(() => {
                  toast.error("Session expired!", {
                    position: toast.POSITION.TOP_CENTER,
                  });
                  window.location.assign(baseURL1);
                }, 50);
              }
            }
        }).catch(err => {
          // console.log("An error occured", err)
        })
      } 
    }, [dispatch]
  )

  let getJobsByCategory = useCallback(
    async () => {
      if (profile.message?.JobInterests !== undefined && profile.message?.JobInterests !== null && profile.message?.JobInterests?.length !== 0) {
        setTabColor("category");
        setTabSwitch(null);
        // setLoad(true);
        setCurrentDashboardPage(1);

        let recommendationByCategory = getRecommendedJobsByProfileTags(currentDashboardPage) //  getRecommendedJobsByCategory(currentDashboardPage);
        recommendationByCategory.then(resp => resp.json())
          .then(resp => {
            setLoad(false);
            if (resp?.success === true) {
              if (resp.data !== null) {
                dispatch(available_jobs_success_category(resp?.data?.returned_value));
              } else {
                dispatch(available_jobs_success_category([]));
              }
            } else {
              if (resp?.title === "Not Found") {
                // it means it cant find last resume with category.
                dispatch(available_jobs_success_category([]));
              } else if (resp.message == "Unauthorized" || resp.message == "No token provided") {
                dispatch(logOut());
                setTimeout(() => {
                  toast.error("Session expired!", {
                    position: toast.POSITION.TOP_CENTER,
                  });
                  window.location.assign(baseURL1);
                }, 50);
              }
            }
          }).catch(err => {
            // console.log("An error occured", err)
          });
      }
    }, [dispatch]
  )

  // BigSam
  const [hideSearchResults, setHideSearchResults] = useState(false);
  const [OurSearchLoading, setOurSearchLoading] = useState(false);
  const [searchJob, setSearchJob] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const [JobListData, setJobListData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [initialSearchDone, setInitialSearchDone] = useState(false);
  const [ourError, setOurError] = useState("");

  // useEffect(() => {
  //   // check the language type
  //   let getLangTpe = async () => {
  //     try {
  //       const langType = await detectLang(searchJob);
  //       console.log("langType", langType);
  //       // after getting language type, get suggestions in either es-ES or en-US
  //       let type;
  //       if (langType == "english") {
  //         type = "en-US";
  //       } else {
  //         type = "es-ES";
  //       }

  //       if (type !== undefined) {
  //         let sug = await checkSpelling(type, searchJob);
  //         setSuggestion(sug);
  //       }

  //     } catch (error) {
  //       console.log("language getting type error", error);
  //     }
  //   }
  //   return getLangTpe;
  // }, [ searchJob ]);

  // console.log("suggestion", suggestion);

  // Job Search
  function HandleCancelJobSearch() {
    setSearchJob("");
    HandleOverAllSearch();
    setHideSearchResults(false);
  }

  //    Location Search
  function HandleCancelLocationSearch() {
    setSearchLocation("");
    HandleOverAllSearch();
    setHideSearchResults(false);
  }

  const setQueryParams = (params) => {
    const searchParams = new URLSearchParams(location.search);

    Object.keys(params).forEach(key => {
      searchParams.set(key, params[key]);
    });
    navigate(location.pathname + "?" + searchParams.toString());
  };


  const HandleOverAllSearch = async () => {

    if (loc) {
      setSearchLocation(loc);
    }
    if (title) {
      setSearchJob(title);
    }
    if (searchJob || searchLocation !== "") {
      setHideSearchResults(true);
      setInitialSearchDone(true);
      setOurSearchLoading(true);
      setOurError("");

      const queryParams = [];
      if (searchJob) {
        queryParams.push(`title=${encodeURIComponent(searchJob.toLowerCase().trim())}`);
      }
      if (searchLocation) {
        queryParams.push(
          `location=${encodeURIComponent(searchLocation.toLowerCase().trim())}`
        );
      }
      if (currentPage) {
        queryParams.push(`limit=10&page=${currentPage}&flag=Client`);
      }
      const queryString = queryParams.join("&");
      setQueryParams({ title: searchJob, location: searchLocation, page: currentPage });
      try {
        await fetch(
          baseURL + `/api/job/search_job_posts?${queryString}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              // Authorization: "Bearer " + localStorage.getItem("token"),
            },
            credentials: "include"
          }
        )
          .then((res) => res.json())
          .then((data) => {
            if (data.success) {
              const respJson = data;
              setOurSearchLoading(false);
              setJobListData(respJson?.data["Job posts"]);
              dispatch(search_success(respJson?.data["Job posts"]));
              dispatch(search_total_page(respJson?.data.number_of_pages));
              setTotalPages(respJson?.data.number_of_pages);
              setTotalJobs(respJson?.data?.number_of_matches);
              // console.log("OurJobListData 🚀🚀🚀🚀", JobListData);
              // console.log("RespJsonUserSearch 🚀🚀🚀🚀", respJson);
            } else {
              setOurError("No records match this search criteria");
              setOurSearchLoading(false);
            }
          });
      } catch (error) {
        // console.error("Error fetching data:", error);
        setOurSearchLoading(false);
      }
    }
  };

  useEffect(() => {
    if (experienceModal) {
      setUrlLink("/user/profile/update/experience");
      setUpdateText("Experience");
    } else if (educationModal) {
      setUrlLink("/user/profile/update/education");
      setUpdateText("Education");
    } else if (skillsModal) {
      setUrlLink("/user/profile/update/skills");
      setUpdateText("Skills/Language");
    } else if (summaryModal) {
      setUrlLink("/user/profile/update/about");
      setUpdateText("Summary");
    }
    // else if (profilePics) {
    //   setUpdateText("Profile pics");
    // }
  }, [experienceModal, educationModal, skillsModal, summaryModal]);

  const handleResume = () => {
    if (profile.message !== null) {
      if (profile.message?.Experience?.length === 0 || !profile.message?.Experience) {
        setExperienceModal(true);
      } else if (profile.message?.Education?.length === 0 || !profile.message?.Education) {
        setEducationModal(true);
      } else if (profile.message?.Skills?.length === 0 || !profile.message?.Skills || profile.message?.Soft_skills?.length === 0 || !profile.message?.Soft_skills || profile.message?.Language?.length === 0 || !profile.message?.Language) {
        setSkillsModal(true);
      } else if (!profile.message?.About) {
        setSummaryModal(true);
      } else {
        navigate("/upload");
      }
    }
  }

  const handleAddInterest = () => {
    navigate("/user/profile/update/personalInfo");
  }

  const experience = experienceModal ? <ExperienceGamifiedModal updateText={updateText} handleClose={() => setExperienceModal(false)} handleContinue={() => navigate(urlLink)} /> : null;
  const education = educationModal ? <EducationGamifiedModal updateText={updateText} handleClose={() => setEducationModal(false)} handleContinue={() => navigate(urlLink)} /> : null;
  const skills = skillsModal ? <SkillsGamifiedModal updateText={updateText} handleClose={() => setSkillsModal(false)} handleContinue={() => navigate(urlLink)} /> : null;
  const summary = summaryModal ? <SummaryGamifiedModal updateText={updateText} handleClose={() => setSummaryModal(false)} handleContinue={() => navigate(urlLink)} /> : null;
  // const profile_pics = profilePics ? <ProfilePicsGamifiedModal updateText={updateText} handleClose={() => setProfilePics(false)} handleContinue={() => setProfilePics(false)} /> : null;
  const hundred = hundredModal ? <CompleteConfirmationGamifiedModal updateText={updateText} handleClose={() => sethundredModal(false)} handleContinue={sethundredModal} /> : null;
  // const debouncedSearch = debounce(HandleOverAllSearch, 300); 

  useEffect(() => {
    // console.log("currentPage", currentPage);
    // console.log("initialSearchDone", initialSearchDone);
    if (initialSearchDone) {
      HandleOverAllSearch();
      // debouncedSearch();
    }
  }, [currentPage, initialSearchDone]);
  // console.log("currentPage", currentPage);

  return (
    <>
      <HelmetProvide
        title="Moil | Browse jobs"
        description="Explore your user dashboard on Moil. Manage your profile, view job opportunities, and connect with recruiters in your field."
        className="notranslate"
      />
      <div className="flex justify-center overflow-y-scroll h-calc-70 pt-8 overflow-x-hidden">
        <div className="Dashboard mx-4 sm:mx-6 xl:mx-0 relative max-[500px]:w-full md:w-full lg:max-w-[950px] xllarge:max-w-[1000px] ">
          <div className="pb-4 border-b border-indigo-300">
            <p className="WelcomeBackMessage text-black text-opacity-60 text-2xl font-medium ipad:text-4xl">Welcome, <span clasName="notranslate">{`${name}!`}</span></p>
          </div>
          <div className="pb-6">
            <div className="First Section">
              <p className="Features text-black text-opacity-60 text-[28px] font-medium mb-3 mt-6">
                Features
              </p>
              <div className="flex flex-col gap-6">
                <div className="OptionsLinks grid grid-cols-1 gap-4 tablet:grid-cols-2 desktop:grid-cols-3 mb-4">
                  <DashboardOptionLink
                    header="Generate Resume"
                    onClick={() => handleResume()}
                    description={`with an AI powered technology, get the edge over others`}
                    ellipseStyle="bg-white left-[60%] top-[10%]  bg-opacity-80 rounded-full blur-[77.64px] ipad:left-[181px] top-[-100px]"
                    leftBordStyle="bg-orange-500"
                    bgBack="bg-indigo-500"
                  />
                  <DashboardOptionLink
                    header="My Job Interest"
                    onClick={() => navigate("/user/jobs")}
                    description="Check the jobs that you recently saved"
                    ellipseStyle="bg-white left-[65%] top-[15%] bg-opacity-80 rounded-full blur-[77.64px] ipad:left-[181px] top-[-100px]"
                    leftBordStyle="bg-white"
                    bgBack="bg-orange-500"
                  />
                  <DashboardOptionLink
                    header="Refer a Friend"
                    description="Refer someone  with your referral code and get paid"
                    ellipseStyle="bg-sky-300 left-[70%] top-[20%]  bg-opacity-95 rounded-full blur-[77.64px] ipad:left-[181px] top-[-100px]"
                    leftBordStyle="bg-orange-500"
                    bgBack="bg-indigo-500"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* bigDaddySam */}
          <div
            className={` my-5 ${hideSearchResults ? "" : "mx-4 px-4 desktop:px-8 shadow-md"
              } py-[45px] rounded-2xl flex flex-col items-center gap-4`}
          >
            {hideSearchResults ? (
              <h1 className=" tablet:font-medium text-xl font-normal tablet:text-[28px] text-black text-opacity-60 w-full">
                Filter your preferences
              </h1>
            ) : (
              <p className=" font-semibold text-base text-black">
                Search for jobs around you
              </p>
            )}

            <div className="w-full">
              <SearchBarsOnTop
                JobListData={JobListData}
                searchJob={searchJob}
                setSearchJob={setSearchJob}
                searchLocation={searchLocation}
                setSearchLocation={setSearchLocation}
                HandleCancelJobSearch={HandleCancelJobSearch}
                HandleCancelLocationSearch={HandleCancelLocationSearch}
                HandleOverAllSearch={HandleOverAllSearch}
                OurSearchLoading={OurSearchLoading}
                suggestion={suggestion}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />

              {title !== null || loc !== null || page !== null ? (
                <>
                  <div>
                    <HorizontalNFilterIcon />
                    <p className=" mt-[8px] pl-[26px] text-base font-normal text-black text-opacity-60">
                      Filter result
                    </p>
                  </div>

                  <p className=" mt-4 text-xl font-normal tablet:text-[28px] tablet:font-medium text-black text-opacity-60">
                    <span>{searchedJob?.length} {totalJobs !== 0 ? `out of ${totalJobs}` : null}</span> results found
                  </p>

                  <div className=" mt-4 grid grid-cols-1 ipad:grid-cols-2 tablet:gap-x-4 xtraxl:grid-cols-3 place-items-start gap-y-6">
                    {searchedJob?.map((items, index) => (
                      <DashboardQueryItem
                        experienceLevel=""
                        jobPosition={items.jobTitle}
                        profilePics={items?.logo}
                        employer={items?.companyName}
                        jobType={items?.jobType}
                        recruitmentDuration={items?.applicationDeadline}
                        JobLocation={`${items?.location?.city}, ${items?.location?.state}`}
                        key={index}
                        indeed={items?.indeed}
                        jobUrl={`/jobs/${items.custom_name}`}
                      />
                    ))}
                  </div>
                  <PaginationBtn
                    totalPages={seachTotalPage}
                    currentPage={page}
                    onNextPage={() => {
                      if (currentPage < seachTotalPage) {
                        setCurrentPage((prev) => prev + 1);
                        setInitialSearchDone(true);
                      }
                    }}
                    onPrevPage={() => {
                      if (currentPage > 1) {
                        setCurrentPage((prev) => prev - 1);
                        setInitialSearchDone(true);
                      }
                    }}
                    onSelectPage={(selectedPage) => {
                      setCurrentPage(selectedPage);
                      setInitialSearchDone(true);
                    }}
                  />
                </>
              ) : (
                <>
                  {ourError ? (<h1 className="mt-4 tablet:font-medium text-xl font-normal tablet:text-[28px] text-black text-opacity-60 w-full">
                    {ourError}
                  </h1>) :
                    <div className={`${hideSearchResults ? "block" : "hidden"} mt-8`}>
                      {OurSearchLoading ? (
                        // "Loading..."
                        <LoaderModal text="Loading..." />
                      ) : (
                        <>
                          <div>
                            <HorizontalNFilterIcon />
                            <p className=" mt-[8px] pl-[26px] text-base font-normal text-black text-opacity-60">
                              Filter result
                            </p>
                          </div>

                          <p className=" mt-4 text-xl font-normal tablet:text-[28px] tablet:font-medium text-black text-opacity-60">
                            <span>{JobListData?.length} {totalJobs !== 0 ? `out of ${totalJobs}` : null}</span> results found
                          </p>

                          <div className=" mt-4 grid grid-cols-1 gap-y-6 ipad:grid-cols-2 tablet:gap-4 xtraxl:grid-cols-3 place-items-start xllarge:gap-6">
                            {searchedJob?.map((items, index) => (
                              <DashboardQueryItem
                                experienceLevel=""
                                jobPosition={items.jobTitle}
                                profilePics={items?.logo}
                                employer={items?.companyName}
                                jobType={items?.jobType}
                                recruitmentDuration={items?.applicationDeadline}
                                JobLocation={`${items?.location?.city}, ${items?.location?.state}`}
                                key={index}
                                indeed={items?.indeed}
                                jobUrl={`/jobs/${items.custom_name}`}
                              />
                            ))}
                          </div>
                          <PaginationBtn
                            totalPages={seachTotalPage}
                            currentPage={currentPage}
                            onNextPage={() => {
                              if (currentPage < seachTotalPage) {
                                setCurrentPage((prev) => prev + 1);
                              }
                            }}
                            onPrevPage={() => {
                              if (currentPage > 1) {
                                setCurrentPage((prev) => prev - 1);
                              }
                            }}
                            onSelectPage={(selectedPage) => {
                              setCurrentPage(selectedPage);
                            }}
                          />
                        </>
                      )}
                    </div>}
                </>
              )}
            </div>
          </div>

          <div className="pb-8">
            <p className="text-black text-opacity-60 text-[28px] font-medium mb-3">
              Recommended Jobs
            </p>
            <div className="flex items-center px-2 mb-3">
              <button className={`w-full flex items-center justify-center p-2 ${tabSwitch == "title" || tabColor == "title" ? "bg-[#CDE0FF]" : "bg-[#edf0f9]"}`} onClick={getJobsByTitle}><span className="md:text-md vsm:text-sm text-black font-medium">Job Matches</span></button>
              <button className={`w-full flex items-center justify-center p-2 ${tabSwitch == "category" || tabColor === "category" ? "bg-[#CDE0FF]" : "bg-[#edf0f9]"}`} onClick={getJobsByCategory}><span className="md:text-md vsm:text-sm text-black font-medium">Industry Matches</span></button>
            </div>
            {
                profile.message?.JobInterests == undefined || profile.message.JobInterests == null || profile.message.JobInterests.length == 0 ? 
                <div className="mb-4">
                  <p className=" mt-4 font-normal text-black text-opacity-60 text-[28px] w-full mb-4">
                    Your profile does not have the require information to view recommended jobs. Kindly fill in your job interest.
                  </p>
                  <AddMore text="Job Interests" onClick={handleAddInterest} />
                </div> :
                <>
                <div className="QueryOptions grid grid-cols-1 gap-4 xllarge:gap-6 tablet:grid-cols-2 desktop:grid-cols-3">
               
                <>{tabSwitch == "title" || tabColor === "title" ?
                  <>{
                    jobs?.length !== 0 ?
                      <>
                        {jobs?.map((data, key) => (
                          <DashboardQueryItem
                            experienceLevel=""
                            jobPosition={data.jobTitle}
                            profilePics={data?.logo}
                            employer={data.companyName}
                            jobType={data.jobType}
                            recruitmentDuration={data.applicationDeadline}
                            JobLocation={`${data.location.city}, ${data.location.state}`}
                            key={key}
                            indeed={data?.indeed}
                            jobUrl={`/jobs/${data.custom_name}`}
                          />
                        ))}
                      </> : <p className=" mt-4 text-xl font-normal tablet:text-[28px] tablet:font-medium text-black text-opacity-60">
                        No results found!!
                      </p>
                  }</> :
                  <>{
                    jobs_catgory?.length !== 0 ?
                      <>
                        {jobs_catgory?.map((data, key) => (
                          <DashboardQueryItem
                            experienceLevel=""
                            jobPosition={data?.jobTitle}
                            profilePics={data?.logo}
                            employer={data?.companyName}
                            jobType={data?.jobType}
                            recruitmentDuration={data?.applicationDeadline}
                            JobLocation={`${data.location?.city}, ${data.location?.state}`}
                            key={key}
                            indeed={data?.indeed}
                            jobUrl={`/jobs/${data?.custom_name}`}
                          />
                        ))}
                      </> : <p className=" mt-4 text-xl font-normal tablet:text-[28px] tablet:font-medium text-black text-opacity-60">
                        No results found!!
                      </p>
                  }</>
                }</>
              </div>
              <PaginationBtn
                totalPages={ourtotalpage}
                currentPage={currentDashboardPage}
                onNextPage={() => {
                  if (currentDashboardPage < ourtotalpage) {
                    setCurrentDashboardPage((prev) => prev + 1);
                    setLoad(true);
                    if (tabColor == "title") {
                      setTabSwitch("title");
                    } else if (tabColor == "category") {
                      setTabSwitch("category");
                    }
                  }
                }}
                onPrevPage={() => {
                  if (currentDashboardPage > 1) {
                    setCurrentDashboardPage((prev) => prev - 1);
                    setLoad(true);
                  }
                }}
                onSelectPage={(selectedPage) => {
                  setCurrentDashboardPage(selectedPage);
                }}
              />
            </>
          }
          </div>
          <div className="absolute bottom-[0px] right-[0%]">
            <AddIcon />
          </div>
        </div>
      </div>
      <ToastContainer />
      {experience}
      {education}
      {skills}
      {summary}
      {/* {profile_pics} */}
      {hundred}
      {load === true ? <LoaderModal text="Getting job matches" /> : null}
    </>
  );
}
