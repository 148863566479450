import React from "react";
import QuestionItem from "../question_item/question_item";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState, useContext } from 'react';
import LoaderModal from "../../common/LoaderModal";
import { useDispatch, useSelector } from 'react-redux';
import UploadModal from "../../common/uploadModal";
import TopBar from "../../topbar/Top-Bar";

import {
	userName_from_db,
  existing_resume_features,
} from '../../../Redux/ActionCreator';
import HelmetProvide from "../../common/helmet";

export default function UserResume() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
	const [stat, setStat] = useState(false);
  
  const [showUploadModal, setShowUploadModal] = useState(false);
	const userName = useSelector((prev) => prev.userInfo);
	const my_name = userName.user;
	const profile = useSelector(prev => prev.profile);
	const params = new URLSearchParams(window.location.search).get('token');
	const profile_stat = new URLSearchParams(window.location.search).get('profile_status')
	// console.log('params', params);
	// console.log('profile status', profile_stat);

	const uploadInputRef = useRef();
	const myCvs = useSelector((prev) => prev.saved_cv);
	// initialize useSelector and useDispatch
	const dispatch = useDispatch();
	// console.log('params', params);

	let text = "Extracting CV text, this can take a couple of minutes..."

	useEffect(() => {
		const token = localStorage.getItem('token');
		if (userName.user === null) {
			dispatch(userName_from_db(token));
		}
	}, []);


  const handleClick = () => {
    navigate("/generateCV")
  }
	const upload_status = useSelector((prev) => prev.existingCV);
  
	const formSubmissionHandle = (e) => {
		e.preventDefault();
		//Sending the file to the backend here
		const file = uploadInputRef.current?.files[0];
		// console.log("file", file)
		const formData = new FormData();
		formData.append('uploadPDF', file);
		if (file !== undefined) {
			setStat(true);
			dispatch(existing_resume_features(formData));
		}
	};


  return (
    <>
    <HelmetProvide
        title="Moil | Create Your Resume"
        description="Build your professional resume with Moil's easy-to-use resume creation form. Showcase your skills, experience, and qualifications to potential employers."
        className="notranslate"
      />
      <TopBar />
      <div className="flex flex-col gap-4 justify-center items-center mt-8 ">
        <div className="w-full ml-0 xl:ml-5 items-center md:items-start max-w-[1028px] ">
          <div className="flex items-center">
            <p className="pl-2 text-black text-[32px] font-semibold">
              Welcome to 
            </p>
            <img src='https://res.cloudinary.com/drlcisipo/image/upload/v1705704139/Website%20images/logo_buekar.png' className="ml-2 w-[70px] h-[30px] object-cover" />
          </div>
          <p className="self-start pl-2 text-black text-base font-semibold">
            Continue your journey to upload or tell us about you, your experience and create a profile.
          </p>
        </div>
        <div className="ml-8 w-full max-w-[1028px]">
          <p className="text-black text-xl md:text-2xl font-semibold">
            Upload / Generate resume
          </p>
        </div>
        <div
          className="w-full max-w-[1028px] md:h-[70vh] bg-white bg-opacity-80 rounded-2xl shadow border border-indigo-400 border-opacity-30 px-6 pt-10 
      flex md:justify-between flex-col md:flex-row items-center md:items-start gap-4 md:gap-0 pb-10 md:pb-2"
        >
          <div className="md:basis-[45%] w-[80%]">
            <QuestionItem question="Upload CV/Resume" />
            <form onSubmit={formSubmissionHandle}>
              <div className="mt-[5rem] pl-[31px] flex flex-col gap-16">
                <div className="flex flex-col gap-4">
                  <div className="text-zinc-500 text-base font-normal">
                    In order for us to create a profile/resume for you, <br /> please upload an existing resume or Continue with a new form.
                  </div>
                </div>
                <button
                  className="w-[125px] h-[47px] px-5 py-2.5 bg-indigo-700 rounded-3xl shadow justify-start items-start gap-2.5 inline-flex"
                  onClick={() => setShowUploadModal(true)}
                >
                  <span className="text-center text-white text-lg font-semibold">
                    Upload
                  </span>
                </button>
                {showUploadModal && <UploadModal />}
              </div>
            </form>
          </div>
          <div className="md:basis-[45%] w-[90%]">
            <QuestionItem question="Generate CV/Resume" />
            <div className="mt-[5rem] pl-[31px] flex flex-col gap-16">
              <div className="flex flex-col gap-4">
                <div className="text-zinc-500 text-base font-normal">
                  Generate a nice resume from scratch <br />{" "}
                  with our AI powered technology
                </div>
                <div></div>
              </div>
              <button
                className="w-[125px] h-[47px] px-5 py-2.5 bg-orange-500 rounded-3xl shadow justify-start items-start gap-2.5 inline-flex"
                onClick={handleClick}
              >
                <span className="text-center text-white text-lg font-semibold">
                  Continue
                </span>
              </button>
            </div>
            {stat === true ? (
              upload_status.errMess === null ? (
                <LoaderModal text={text} />
              ) : null
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
