import React, { useState, useEffect } from "react";
import MessageBox from "./component/message_box";
import MessageVerticalItem from "./component/message_vertical_item";
import { getEndToEndChatInformation, end_to_end_chat_loading, message_notification } from "../../Redux/ActionCreator";
import { useSelector, useDispatch } from "react-redux";
import timeResolver from "../Job/timeresolver";
const { io } = require("socket.io-client");

export default function UserMessagesUser() {
  const dispatch = useDispatch();
  const userInfo = useSelector(e => e.userInfo.user);
  const login_stat = useSelector(e => e.login);
  const AcctType = login_stat?.profile_status?.AcctType;
  const profile = useSelector((e) => e.profile);
  const chat_info = useSelector(e => e.chat_info.message);
  const employee = useSelector(e => e.profile);
  const [socket, setSocket] = useState(null);
  const [onlineUser, setOnlineUser] = useState([]);
  const newMessage = useSelector(e => e.end_to_end.message);
  const [newMsg, setNewMsg] = useState(newMessage?.message);
  const [notification, setNotification] = useState([])
  const index = new URLSearchParams(window.location.search).get("i");

  // return the notification back to 0 since it has been read
  // useEffect(() => {
  //   chat_info[index].Notification = 0;
  // }, [])


  useEffect(() => {
    dispatch(end_to_end_chat_loading(true));
    if (AcctType === "Client") {
      dispatch(getEndToEndChatInformation(chat_info[index]?.chatIds[0]?.employerId?._id, chat_info[index]?.chatIds[0]?.employeeId));
    }
  }, [index]);

  // console.log("newMessafe", newMessage);

  return (
    <div className="Inbox px-3 sm:px-6 pb-2 h-calc-70">
      <p className="text-black text-opacity-50 text-[32px] font-normal font-['Poppins'] mb-2">Messages</p>
      <div className="flex ipad:gap-[0.5rem] h-calc-chatarea">
        <div className="w-full max-w bg-white bg-opacity-80 rounded-xl shadow border border-indigo-300 border-opacity-30 hidden xl:block xl:max-w-[320px] xtraxl:max-w-[340px]">
          <div className="ChatMessage h-full overflow-y-scroll px-4 pb-4">
            {chat_info !== null && (
              <>{chat_info?.map((data, key) => (
                <MessageVerticalItem
                  name={data?.chatIds[0]?.employerId?.name}
                  position={data?.chatIds[0]?.jobTitle}
                  team="Design Team"
                  messageAge={timeResolver(data?.updatedChatTime)}
                  messageNo={data?.Notification}
                  avatar={data?.chatIds[0]?.employerId?.photo}
                  avatarStyle="bg-rose-300"
                  receiver={data?.chatIds[0]?.employerId?.name}
                  userType="employee"
                  index={chat_info?.indexOf(data)}
                  key={key}
                />
              ))}
              </>
            )}
          </div>
        </div>
        {chat_info !== null && (
          <MessageBox userType="employee" index={index} avatar={chat_info[index]?.chatIds[0]?.employerId?.photo} newMessage={newMessage} />
        )}
      </div>
    </div>
  );
}