export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGOUT_LOADING = "LOGOUT_LOADING";
export const LOG_OUT = "LOG_OUT";
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_FAILED = "SIGNUP_FAILED";
export const SIGNUP_LOADING = "SIGNUP_LOADING";

// cv prototype
export const CV_LOADING = "CV_LOADING";
export const GENERATING_CV_FAILED = "GENERATING_CV_FAILED";
export const CV_GENERATED_SUCCESSFULLY = "CV_GENERATED_SUCCESSFULLY"

// existing cv
export const EXISTING_CV_LOADING = "EXISTING_CV_LOADING";
export const GENERATING_EXISTING_CV_FAILED = "GENERATING_EXISTING_CV_FAILED";
export const EXISTING_CV_GENERATED_SUCCESSFULLY = "EXISTING_CV_GENERATED_SUCCESSFULLY"

// cv id
export const CV_ID = 'CV_Id';
export const CV_ID_LOADING = "CV_ID_LOADING";
export const CV_PICS = 'CV_PICS';

// user's info
export const USER_NAME_DB = "USER_NAME_DB";
export const LOADING_USER_NAME = "LOADING_USER_NAME"

// Saving Cv details prototype
export const GETTING_CV_LOADING = "GETTING_CV_LOADING"
export const GETTING_CV_FAILED = "GETTING_CV_FAILED";
export const GETTING_CV_SUCCESS = "GETTING_CV_SUCCESS";

// creating user profile
export const GET_PROFILE_LOADING = "GET_PROFILE_LOADING"
export const GET_PROFILE_FAILED = "GET_PROFILE_FAILED";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";

// employee public account view
export const GET_PUBLIC_PROFILE_LOADING = "GET_PUBLIC_PROFILE_LOADING"
export const GET_PUBLIC_PROFILE_FAILED = "GET_PUBLIC_PROFILE_FAILED";
export const GET_PUBLIC_PROFILE_SUCCESS = "GET_PUBLIC_PROFILE_SUCCESS";


// creating user profile
export const GET_EMPLOYER_PROFILE_LOADING = "GET_EMPLOYER_PROFILE_LOADING"
export const GET_EMPLOYER_PROFILE_FAILED = "GET_EMPLOYER_PROFILE_FAILED";
export const GET_EMPLOYER_PROFILE_SUCCESS = "GET_EMPLOYER_PROFILE_SUCCESS";


// verify mail
export const V_MAIL_LOADING = "V_MAIL_LOADING"
export const V_MAIL_FAILED = "V_MAIL_FAILED"
export const V_MAIL_SUCCESS = "V_MAIL_SUCCESS"

//  reset password
export const R_SET_LOADING = "V_MAIL_LOADING"
export const R_SET_FAILED = "R_SET_FAILED"
export const R_SET_SUCCESS = "R_SET_SUCCESS"

export const ACTIVITIES = "ACTIVITIES";
export const ACTIVITIES_LOADING = "ACTIVITIES_LOADING";

export const SKILLS = "SKILLS";
export const SKILLS_LOADING = "SKILLS_LOADING";

export const JOBTITLE = "JOBTITLE";
export const JOBTITLE_LOADING = "JOBTITLE_LOADING";

export const PROFESSIONAL_SUMMMARY = "PROFESSIONAL_SUMMMARY";
export const PROFESSIONAL_SUMMMARY_FAILED = "PROFESSIONAL_SUMMMARY_FAILED";
export const PROFESSIONAL_SUMMMARY_LOADING = "PROFESSIONAL_SUMMMARY_LOADING";

export const EDUCATION = "EDUCATION";
export const EDUCATION_LOADING = "EDUCATION_LOADING";

export const PERSONAL_SKILLS = "PERSONAL_SKILLS";
export const PERSONAL_SKILLS_LOADING = "PERSONAL_SKILLS_LOADING";

export const VOLUNTARY = "VOLUNTARY";
export const VOLUNTARY_LOADING = "VOLUNTARY_LOADING";


export const AWARD = "AWARD";
export const AWARD_LOADING = "AWARD_LOADING";

export const JOBDETAILS = "JOBDETAILS";
export const JOBDETAILS_LOADING = "JOBDETAILS_LOADING";

export const REFERENCE = "REFERENCE";
export const REFERENCE_LOADING = "REFERENCE_LOADING";

export const LANGUAGE = "LANGUAGE";
export const LANGUAGE_LOADING = "LANGUAGE_LOADING";

export const INDIVIDUAL_EXPERIENCE = "INDIVIDUAL_EXPERIENCE";
export const INDIVIDUAL_EXPERIENCE_LOADING = "INDIVIDUAL_EXPERIENCE_LOADING";
export const INDIVIDUAL_EXPERIENCE_ERROR = "INDIVIDUAL_EXPERIENCE_ERROR";

export const LANGUAGE_TRANSLATION = "LANGUAGE_TRANSLATION";
export const LANGUAGE_TRANSLATION_LOADING = "LANGUAGE_TRANSLATION_LOADING";

export const PERSONAL_INFO = "PERSONAL_INFO";
export const PERSONAL_INFO_LOADING = "PERSONAL_INFO_LOADING";
export const PERSONAL_INFO_FAILED = "PERSONAL_INFO_FAILED";

export const CERT = "CERT";
export const CERT_LOADING = "CERT_LOADING";

export const LANG = "LANG";
export const LANG_LOADING = "LANG_LOADING";

export const RESUME_STEP_ADD = "RESUME_STEP_ADD";
export const RESUME_STEP_SUB = "RESUME_STEP_SUB";
export const RESUME_STEP_JUMP = "RESUME_STEP_JUMP";

export const SPANISH = "SPANISH";
export const SPANISH_LOADING = "SPANISH_LOADING";

export const LANG_TYPE = "LANG_TYPE";
export const LANG_TYPE_S = "LANG_TYPE_S";

export const LAST_RESUME = "LAST_RESUME";
export const LAST_RESUME_LOADING = "LAST_RESUME_LOADING";

export const SECRET = "SECRET";
export const SECRET_LOADING = "SECRET_LOADING";

export const SAVE_LINK = "SAVE_LINK"
export const SAVE_LINK_LOADING = "SAVE_LINK_LOADING"


export const EMPLOYEE_SPANISH_PROFILE = "EMPLOYEE_SPANISH_PROFILE";
export const EMPLOYEE_SPANISH_PROFILE_LOADING = "EMPLOYEE_SPANISH_PROFILE_LOADING";
export const EMPLOYEE_SPANISH_PROFILE_FAILED = "EMPLOYEE_SPANISH_PROFILE_FAILED"

export const AVAILABLE_JOBS = "AVAILABLE_JOBS";
export const AVAILABLE_JOBS_FAILED = "AVAILABLE_JOBS_FAILED";
export const AVAILABLE_JOBS_LOADING = "AVAILABLE_JOBS_LOADING";

export const AVAILABLE_JOBS_CATEGORY = "AVAILABLE_JOBS_CATEGORY";
export const AVAILABLE_JOBS_FAILED_CATEGORY = "AVAILABLE_JOBS_FAILED_CATEGORY";
export const AVAILABLE_JOBS_LOADING_CATEGORY = "AVAILABLE_JOBS_LOADING_CATEGORY";

export const APPLICATION_LOADING = "APPLICATION_LOADING";
export const APPLICATION_SUCCESSFUL = "APPLICATION_SUCCESSFUL";
export const APPLICATION_FAILED = "APPLICATION_FAILED";

export const JOBS_APPLIED = "JOBS_APPLIED";
export const JOBS_APPLIED_LOADING = "JOBS_APPLIED_LOADING";
export const JOBS_APPLIED_FAILED = "JOBS_APPLIED_FAILED";

export const CHAT_INFO = "CHAT_INFO";
export const CHAT_INFO_LOADING = "CHAT_INFO_LOADING";
export const CHAT_INFO_FAILED = "CHAT_INFO_FAILED";

export const END_TO_END_CHAT = "END_TO_END_CHAT";
export const END_TO_END_CHAT_LOADING = "END_TO_END_CHAT_LOADING";
export const END_TO_END_CHAT_FAILED = "END_TO_END_CHAT_FAILED";

export const SEND_MESSAGE = "SEND_MESSAGE";
export const SEND_MESSAGE_LOADING = "SEND_MESSAGE_LOADING";
export const SEND_MESSAGE_FAILED = "SEND_MESSAGE_FAILED";

export const SAVE_JOB = "SAVE_JOB";
export const SAVE_JOB_LOADING = "SAVE_JOB_LOADING";
export const SAVE_JOB_FAILED = "SAVE_JOB_FAILED";

export const GET_SAVED_JOBS = "GET_SAVED_JOBS";
export const GET_SAVED_JOBS_LOADING = "GET_SAVED_JOBS_LOADING";
export const GET_SAVED_JOBS_FAILED = "GET_SAVED_JOBS_FAILED";


export const DECISION_CARD = "DECISION_CARD";
export const DECISION_CARD_LOADING = "DECISION_CARD_LOADING";
export const DECISION_CARD_FAILED = "DECISION_CARD_FAILED";


export const NOTIFICATION = "NOTIFICATION";
export const NOTIFICATION_LOADING = "NOTIFICATION_LOADING";

export const MESSAGE_NOTIFICATION = "MESSAGE_NOTIFICATION";
export const MESSAGE_NOTIFICATION_LOADING = "MESSAGE_NOTIFICATION_LOADING";

export const PHONE_VERIFICATION = "PHONE_VERIFICATION";
export const PHONE_VERIFICATION_LOADING = "PHONE_VERIFICATION_LOADING";
export const PHONE_VERIFICATION_FAILED = "PHONE_VERIFICATION_FAILED";

export const SEND_OTP = "SEND_OTP";
export const SEND_OTP_LOADING = "SEND_OTP_LOADING";
export const SEND_OTP_FAILED = "SEND_OTP_FAILED";

export const TOTAL_PAGE = "TOTAL_PAGE";
export const TOTAL_PAGE_LOADING = "TOTAL_PAGE_LOADING";

export const GET_A_JOB_LOADING = "GET_A_JOB_LOADING";
export const GET_A_JOB_FAILED = "GET_A_JOB_FAILED";
export const GET_A_JOB = "GET_A_JOB";

// last page visited
export const GET_LAST_PAGE_VISITED = "GET_LAST_PAGE_VISITED";
export const GET_LAST_PAGE_VISITED_LOADING = "GET_LAST_PAGE_VISITED_LOADING";
export const GET_LAST_PAGE_VISITED_ERROR = "GET_LAST_PAGE_VISITED_ERROR"


export const COUNT = "COUNT";
export const COUNT_LOADING = "COUNT_LOADING";

export const ERROR = "ERROR";
export const ERROR_LOADING = "ERROR_LOADING"

export const CLICK = "CLICK";
export const CLICK_LOADING = "CLICK_LOADING"

export const APPLICATION_PUBLIC_LOADING = "APPLICATION_PUBLIC_LOADING";
export const APPLICATION_PUBLIC_SUCCESSFUL = "APPLICATION_PUBLIC_SUCCESSFUL";
export const APPLICATION_PUBLIC_FAILED = "APPLICATION_PUBLIC_FAILED";

export const NEW_JOB_POST_LOADING = "NEW_JOB_POST_LOADING";
export const NEW_JOB_POST_SUCCESSFUL = "NEW_JOB_POST_SUCCESSFUL";
export const NEW_JOB_POST_FAILED = "NEW_JOB_POST_FAILED";

export const CREATE_REFERRAL_LINK_LOADING = 'CREATE_REFERRAL_LINK_LOADING';
export const CREATE_REFERRAL_LINK_SUCCESS = 'CREATE_REFERRAL_LINK_SUCCESS';
export const CREATE_REFERRAL_LINK_FAILED = 'CREATE_REFERRAL_LINK_FAILED';

export const REFERRER_LINK = "REFERRER_LINK";
export const REFERRER_LINK_LOADING = "REFERRER_LINK_LOADING";

export const EMPLOYER_EMPLOYEEINFO = "EMPLOYER_EMPLOYEEINFO";
export const EMPLOYER_EMPLOYEEINFO_LOADING = "EMPLOYER_EMPLOYEEINFO_LOADING";

export const SOCKET = "SOCKET";
export const SOCKET_LOADING = "SOCKET_LOADING";

export const ONLINE = "ONLINE";
export const ONLINE_LOADING = "ONLINE_LOADING";

export const SEARCH = "SEARCH";
export const SEARCH_LOADING = "SEARCH_LOADING";
export const SEARCH_FAILED = "SEARCH_FAILED";

export const SEARCH_TOTAL_PAGE = "SEARCH_TOTAL_PAGE";
export const SEARCH_TOTAL_PAGE_LOADING = "SEARCH_TOTAL_PAGE_LOADING";

export const SET_DASHBOARD_ONLY = "SET_DASHBOARD_ONLY";
export const SET_DASHBOARD_ONLY_LOADING = "SET_DASHBOARD_ONLY_LOADING";

export const SIDEBAR = "SIDEBAR";

export const search_total_page_loading = () => ({
    type: prototype.SEARCH_TOTAL_PAGE_LOADING,
});

// login failed dispatching function
export const search_total_page = (response) => ({
type: prototype.SEARCH_TOTAL_PAGE,
payload: response,
});
  
