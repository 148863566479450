import React, { useEffect, useState } from "react"
import {
  Document,
  Page,
  Text,
  View,
  Link,
  StyleSheet,
  Line,
  Svg,
  Path,
  Image,
  PDFViewer
} from "@react-pdf/renderer";

import font from "./font/poppins-v20-latin-600.ttf";
import font1 from "./font/poppins-v20-latin-300.ttf";
import { Font } from '@react-pdf/renderer'

Font.register({ family: 'Poppins', src: font1});
Font.register({ family: 'Poppins-bold', src: font});



// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FCFAF7",
    color: "#2F2F2F",
    fontFamily: "Poppins",
    display: "flex",
    flexDirection: "row",
    paddingBottom: 20,
    paddingTop: 30
  },
  text_h4: {
    fontSize: 30,
    fontFamily: "Poppins-bold",
    width: "auto",
    left: 40,
    top: -82,
  },
  viewer: {
    width: '55vw', height: '100vh',
    '@media maxWidth: 500' : {
      width: "40vw", height: "80vh"
    }
  },
  left_side : {
    width: "35vw",
    color: "#2F2F2F",/* 
    backgroundColor: "#FFFFFF", */
    background: "#F7EFE5",
    height: "auto",
    paddingLeft: 10,
    marginLeft: 0,
    paddingTop: 20,
  },
  right_side: {
    width: "65vw",
    color: "#2F2F2F",
    paddingBottom: 30,
    paddingTop: 60,
    marginRight: 0,
    paddingRight: 30,
    marginLeft: 30,
    background: "#FFFFFF",
  }
});


function BlackShark(props) {

  let details;
  details = [props.details]

  const [ wrap_stat, setWrap_stat ] = useState(false);
  useEffect(() => {
    if (details[0].Experience.length >= 4) {
      setWrap_stat(true);
    } else {
      setWrap_stat(false);
    }
  }, [])

  let my_interest;
  if (details[0] !== null) {
    if (Array.isArray(details[0]?.Interest)) {
      my_interest = details[0]?.Interest;
    } else if (typeof details[0]?.Interest === "string") {
      my_interest = details[0]?.Interest.split(", ");
    }
  }
  if (Array.isArray(details[0]?.Technical_skills) === false ) {
    if (typeof details[0]?.Technical_skills === "string") {
      details[0].Technical_skills = details[0]?.Technical_skills.split(", ");
    }
   }

   if (Array.isArray(details[0]?.Personal_skills) === false ) {
    if (typeof details[0]?.Personal_skills === "string") {
      details[0].Personal_skills = details[0]?.Personal_skills.split(", ");
    }
   }
  
  // console.log("my_interest", my_interest);


  const chunkSubstr = (str, size) => {
    const numChunks = Math.ceil(str.length / size);
    const chunks = new Array(numChunks);

    for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
      chunks[i] = str.substr(o, size);
    }

    return chunks;
  };

  Font.registerHyphenationCallback((word) => { // to avoid breaking of words.
    if (word.length > 16) {
      return chunkSubstr(word, 14);
    } else {
      return [word];
    }
  });


  return (

    <Document>
    <Page size="A4" style={styles.page} wrap={wrap_stat}>
          {
            details?.map((data, key) => (
                  <View style={{ display: "flex", flexDirection: "row" }} key={key}>
                  {/* left side start */}
                    <View style={styles.left_side}>
                    <View style={{width: "180px", marginLeft: 15}}>
                      <View style={{ backgroundColor: "#CBC6BF", opacity: 0.9, borderRadius: 10, margin: "0px"}}>
                        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center", width: 150, marginLeft: 15, }}>
                          <Text style={{fontSize: 15, marginBottom: 5, fontFamily: "Poppins-bold", textAlign: "center", color: "#000000"}}>{data.Fullname} </Text>
                        </View>
                        <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                          <View style={{top: 0, left: 5}}>
                            <Svg  height="2" width="30">
                              <Line
                                x1="0"
                                y1="0"
                                x2="380"
                                y2="0"
                                strokeWidth={2}
                                stroke="#C5C5C5"
                              />
                            </Svg>
                          </View>
                          {data.CvType === null || data.CvType === undefined ? null : 
                          <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center", width: 200,  }}>
                            <Text style={{fontFamily: "Poppins-bold", fontSize: 12,  color: "black"}}>{data.CvType} </Text>
                          </View>
                        }
                          <View style={{top: 0, left: -15 }}>
                            <Svg  height="2" width="30">
                              <Line
                                x1="0"
                                y1="0"
                                x2="380"
                                y2="0"
                                strokeWidth={2}
                                stroke="#C5C5C5"
                              />
                            </Svg>
                          </View>
                        </View>
                      </View>
                    </View>

                    {/* soft skills start */}
                    { data.Personal_skills?.length === 0 ? null :
                      <View style={{ marginTop: 10}}>
                      {
                      data.Lang[0]?.lang === 'English' ? <Text style={{fontSize: 12, marginLeft: 15,fontFamily: "Poppins-bold"}}>Soft Skills</Text> : <Text style={{fontSize: 12, marginLeft: 15,fontFamily: "Poppins-bold"}}>Habilidades Blandas</Text> 
                      }
                      <View style={{display: "flex", flexDirection: "row", marginLeft: 10, flexWrap: "wrap", width: 150, }}>
                        { data.Personal_skills?.map((P_data, key) => (
                          <View style={{  marginLeft: 5, marginRight: 2, marginTop: 2 }} key={key}>
                            <Text style={{ color: "#2F2F2F", fontSize: 8, fontFamily: "Poppins",}}>{P_data.value !== undefined ? <Text style={{ color: "#000000",fontSize: 8, fontFamily: "Poppins",}}>{P_data.value}</Text> : <Text style={{ color: "#000000",fontSize: 8, fontFamily: "Poppins",}}>{P_data}</Text>}</Text>
                          </View>
                        ))}
                      </View>
                    </View>
                    }
                    {/* soft skills end */}

                    {/* Hard skills start */}
                    { data.Technical_skills?.length === 0 ? null :
                    <View style={{ marginTop: 10}}>
                      {
                      data.Lang[0]?.lang === 'English' ? <Text style={{fontSize: 12, marginLeft: 12,fontFamily: "Poppins-bold"}}>Technical Skills</Text> : <Text style={{fontSize: 12, marginLeft: 12,fontFamily: "Poppins-bold"}}> Habilidades Técnicas </Text> 
                      }
                    <View style={{display: "flex", flexDirection: "row", marginLeft: 10, flexWrap: "wrap", width: 150, }}>
                      { data.Technical_skills?.map((T_data, key) => (
                        <View style={{ marginLeft: 5, marginRight: 2, marginTop: 2 }} key={key}>
                          {T_data.value !== undefined ? <Text style={{ color: "#000000",fontSize: 8, fontFamily: "Poppins",}}>{T_data.value}</Text> : <Text style={{ color: "#000000",fontSize: 8, fontFamily: "Poppins",}}>{T_data}</Text>}
                        </View>
                      ))}
                    </View>
                  </View>
                  }
                    {/* soft skills end */}

                    {/* keep in touch start */}
                    <View style={{marginTop: 10}}>
                      {
                      data.Lang[0]?.lang === 'English' ? <Text style={{fontSize: 12, marginLeft: 15, marginBottom: 5, fontFamily: "Poppins-bold"}}>Contact</Text> : <Text style={{fontSize: 12, marginLeft: 15, marginBottom: 5, fontFamily: "Poppins-bold"}}>Contacto </Text> 
                      }
                      <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginLeft: 15, marginTop: 5}}>
                        <Image src="https://res.cloudinary.com/drlcisipo/image/upload/v1705704128/Website%20images/icons8-phone-64_tflbkk.png" style={{width: 10, height: 10}} />
                        <Text style={{marginLeft: 3, fontSize: 8, fontFamily: 'Poppins'}}>{data.Phone_number}</Text>
                      </View>
                      <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginLeft: 15, marginTop: 5}}>
                        <Image src="https://res.cloudinary.com/drlcisipo/image/upload/v1705704124/Website%20images/icons8-mail-50_w5lu0x.png" style={{width: 10, height: 10}} />
                        <Text style={{marginLeft: 3, fontSize: 8, fontFamily: 'Poppins'}}>{data.Email}</Text>
                      </View>
                    </View>
                    {/* keep in touch end */}

                    {/* Award start */}
                      { data.Award?.length !== 0 && data.Award[0]?.Award_name.length !== 0 ?  
                        <View style={{ marginTop: 10}}>
                          {
                          data.Lang[0]?.lang === 'English' ? 
                          <Text style={{fontSize: 12, marginLeft: 15, marginTop: 5, marginBottom: 5, fontFamily: "Poppins-bold"}}>Award</Text> : <Text style={{fontSize: 12, marginLeft: 15, marginTop: 5, marginBottom: 5, fontFamily: "Poppins-bold"}}>Premio</Text> 
                          }
                          { data.Award?.map((H_data, key) => (
                          <View style={{ display: "flex", flexDirection: "row", width: 180, marginLeft: 15, flexWrap: "wrap"}} key={key}>
                            <View style={{display: "flex", flexDirection: "row", width: 185, justifyContent: "space-between"}}>
                              <View><Text style={{ fontFamily: "Poppins-bold", fontSize: "8px", marginLeft: 0}}>{H_data.Award_name}</Text></View>
                              <View style={{marginLeft: "auto", fontFamily: "Poppins", fontSize: "8px"}}><Text>{H_data.Award_year}</Text></View>
                            </View>
                            <View style={{marginTop: 2}}>
                              <Text style={{marginLeft: 0, fontFamily: "Poppins", fontSize: "8px", width: 180}}>{H_data.Award_giver}</Text>
                            </View>
                          </View>
                        ))}
                      </View> : null
                      }
                    {/* Award end */}
                    {/* Language section start */}
                      { data.Language?.length === 0 ? null : 
                      <View style={{marginTop: 10}}>
                      {
                      data.Lang[0]?.lang === 'English' ? 
                      <Text style={{fontFamily: "Poppins-bold", fontSize: 12, marginLeft: 15}}>Languages</Text> : <Text style={{fontFamily: "Poppins-bold", fontSize: 12, marginLeft: 15}}>Idiomas</Text> 
                      }
                        <View>
                        { data.Language?.map((L_data, key) => (
                          <View style={{display: "flex", flexDirection: 'row', width: 150}} key={key}>
                            <Text style={{fontFamily: "Poppins", fontSize: 8, marginTop: 2, marginRight: 3, marginLeft: 15}}>{L_data.language} -</Text>
                            <Text style={{fontFamily: "Poppins", fontSize: 8, marginTop: 2,}}>{L_data.rate}</Text>
                          </View>
                        ))}
                        </View>
                      </View>
                      }
                  {/* Language section end */}
                  </View>
                  {/* left side end */}

                  {/* right side start */}
                  <View style={styles.right_side}>
                    {
                    data.Lang[0]?.lang === 'English' ?
                    <Text style={{fontFamily: "Poppins-bold", fontSize: 12, marginTop: -40,  marginLeft: 15}}>About me</Text> : <Text style={{fontFamily: "Poppins-bold", fontSize: 12, marginTop: -40,  marginLeft: 15}}>Sobre mí </Text> 
                    }
                    <View style={{width: 300, marginTop: 10, display: "flex", flexDirection: "row", flexWrap: "wrap", marginLeft: 15}}>
                      <Text style={{fontFamily: "Poppins", fontSize: 10, }} >{data.Professional_summary} </Text>
                    </View>
                    <View style={{top: 2, left: 15}}>
                      <Svg  height="2" width="340">
                        <Line
                          x1="0"
                          y1="0"
                          x2="320"
                          y2="0"
                          strokeWidth={2}
                          stroke="#C5C5C5"
                        />
                      </Svg>
                    </View>

                    {/* Experience start */}
                    { data.Experience?.length === 0 ? null : 
                      <View style={{marginTop: 20}}>
                      <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginLeft: 0}}>
                        <View style={{borderRadius: "50%", border: "1px solid #606366", height: 30, width: 30, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginLeft: 15}}>
                          <Image src="https://res.cloudinary.com/drlcisipo/image/upload/v1705704124/Website%20images/icons8-mail-50_w5lu0x.png" style={{width: 20, height: 20}} />
                        </View>
                        {
                          data.Lang[0]?.lang === 'English' ?
                          <Text style={{marginLeft: 12, fontFamily: "Poppins-bold", fontSize: 12}}>Work Experience</Text> : <Text style={{marginLeft: 12, fontFamily: "Poppins-bold", fontSize: 12}}>Experiencia Laboral</Text>
                        }
                      </View>
                      {/* vertical Line */}
                      {/*                       
                        <View style={{top: 30, left: 102, position: "absolute"}}>
                        <Svg  height="850" width="25">
                          <Line
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="750"
                            strokeWidth={2}
                            stroke="#FF6633"
                          />
                        </Svg>
                      </View> */}
                      {data.Experience?.map((E_data, key) => (
                        <View style={{ width: 370}}>
                          <View style={{ display: "block", marginTop: 10}}>
                              {/* year */}
                              <View style={{width: 320, display: "flex", flexDirection: "row"}}>
                                <Text style={{marginLeft: 58, fontFamily: "Poppins", fontSize: 10}}>{E_data.Work_start_date} -</Text>
                                <Text style={{marginLeft: 0, fontFamily: "Poppins", fontSize: 10}}> {E_data.Work_end_date}</Text>
                              </View>

                              
                            </View>
                            <View style={{ display: "flex", flexDirection: "row", alignItems: "center", width: 280}}>
                              {/* circle */}
                              <View style={{borderRadius: "50%", backgroundColor: "#606366", height: 20, width: 20, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginLeft: 20, marginTop: -30 }}>
                                <View style={{borderRadius: "50%", backgroundColor: "#FF6633", height: 10, width: 10, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center",}}></View>
                              </View>
                              <View style={{marginTop: 0}}>
                                <View style={{  display: "flex", flexDirection: "row", flexWrap: "wrap", marginLeft: 18, width: 200 }}>
                                  <Text style={{ fontFamily: "Poppins-bold", fontSize: 10, }} >{E_data.My_job_title} </Text>
                                </View>
                                <View style={{  display: "flex", flexDirection: "row", flexWrap: "wrap", marginLeft: 18, width: 200 }}>
                                  <Text style={{ fontFamily: "Poppins", fontSize: 10}} >{E_data.Where_i_worked} </Text>
                                </View>
                              </View>
                            </View>
                            { Array.isArray(E_data.Day_to_day_work) === true ? 
                            <View  style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", marginTop: 5, width: 270, marginLeft: 55 }}>
                              {E_data.Day_to_day_work?.map((ex_data, key) => (
                                <View style={{ display: "flex", flexDirection: "row", }} key={key}>
                                      <View>
                                        <Text style={{ marginRight: 8, marginTop: -5 }}>•</Text>
                                      </View>
                                      {ex_data[0] === "-" || ex_data[0] == "•" || ex_data[0] == "," ? <Text style={{ fontFamily: "Poppins", fontSize: 10, }} >{ex_data.slice(1, ex_data.length).trim()}</Text>: <Text style={{ fontFamily: "Poppins", fontSize: 10, }} >{ex_data.replace(/^(\d[^\w\s]*|[^\w\s])+/g, '').trim()}</Text>}
                                </View>
                            ))}
                            </View> : 
                            <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", marginLeft: 15, marginTop: 5, width: 270 }}>
                              <Text style={{marginLeft: 45, fontFamily: "Poppins", fontSize: 10, }} key={key}>{E_data.Day_to_day_work.replace(/^(\d[^\w\s]*|[^\w\s])+/g, '').trim()} </Text>
                            </View>
                            }
                          </View>
                      ))}
                      </View>
                      }
                    {/* Experience end */}

                    {/* Education start */}
                    { data.Education[0]?.Degree === "No Formal Education" ? null : 
                      <View style={{marginTop: 10}}>
                      <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginLeft: 0}}>
                        <View style={{borderRadius: "50%", border: "1px solid #606366", height: 30, width: 30, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginLeft: 15, backgroundColor: "#FFFFFF"}}>
                          <View style={{borderRadius: "50%", backgroundColor: "#FF6633", height: 20, width: 20, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                            <Image src="https://res.cloudinary.com/drlcisipo/image/upload/v1705704125/Website%20images/icons8-museum-50_1_v8bbsa.png" style={{width: 10, height: 10}} />
                          </View>
                        </View>
                        {
                          data.Lang[0]?.lang === 'English' ?
                          <Text style={{marginLeft: 15, fontFamily: "Poppins-bold", fontSize: 15}}>Education</Text> : <Text style={{marginLeft: 15, fontFamily: "Poppins-bold", fontSize: 15}}> Educación </Text>
                        }
                      </View>
                      {
                        data.Education?.map((Ed_data, key) => (
                          <View key={key}>
                            <View style={{ display: "block", marginTop: 5}}>
                              {/* year */}
                              <View style={{width: 280, display: "flex", flexDirection: "row" }}>
                                <Text style={{marginLeft: 60, fontFamily: "Poppins", fontSize: 10}}>{Ed_data.End_date}</Text>
                              </View>

                              <View style={{ display:"flex", flexDirection: "row", alignItems: "center" }}>
                                {/* circle */}
                                <View style={{borderRadius: "50%", backgroundColor: "#606366", height: 20, width: 20, display: "flex", flexDirection: "row", justifyContent: "center",  alignItems: "center", marginLeft: 20}}>
                                  <View style={{borderRadius: "50%", backgroundColor: "#FF6633", height: 10, width: 10, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center",}}></View>
                                </View>
                                <View style={{marginTop: 10}}>
                                  <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: 250, marginLeft: 20, }} >
                                      <Text style={{ fontFamily: "Poppins-bold", fontSize: 12, }} >{Ed_data.School} </Text>
                                  </View>
                                  <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: 250, marginLeft: 20, }}>
                                    <Text style={{ fontFamily: "Poppins", fontSize: 10 }} >{Ed_data.Degree} {Ed_data.Course} </Text>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                        ))
                      }
                    </View>
                    }
                    {/* Education end */}

                    {/* Volunteer start */}
                    {/* Voluteer end */}
                  </View>
                  {/* right side end */}
                  </View>
            ))
          }
          </Page>
        </Document>
  )
}

export default BlackShark
