import NewProfileHeader from "./profile_header_for_employerView_of_Employee_profile";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getEmployerProfilePublicView } from "../../../Redux/ActionCreator";
import LoaderModal from "../../common/LoaderModal";

function EmployerWorkerViewOriginal() {
  const dispatch = useDispatch();
  const employer = useSelector((e) => e.employer);
  const profile = employer.message;
  const employerProfileId = window.location.pathname.split("/")[4];
  // console.log(employerProfileId);
  // console.log("This is your profile!!!!", profile);

  useEffect(() => {
    dispatch(getEmployerProfilePublicView(employerProfileId));
  }, []);

  return (
    <>
      {profile === null ? (
        <LoaderModal text="Fetching employers profile" />
      ) : (
        <div className=" mt-8 px-[2.5%] w-full h-full mx-auto">
          {/* Pre text  */}
          <div className=" hidden tablet:flex flex-col tablet:flex-row gap-4 tablet:gap-0 justify-between">
            {/* left side  */}
            <div>
              <h1 className=" font-medium text-xl tablet:text-4xl text-[#626060]">
                {profile?.name}
              </h1>
              <p className=" font-medium text-base text-[#7A7878]">
                {profile?.type_of_business}
                <span className=" text-[#5843BD]">
                  {" "}
                  in {profile?.location.state}
                </span>
              </p>
            </div>
          </div>

          <div className="my-4 w-full h-full   rounded-2xl flex flex-col items-center">
            <NewProfileHeader />

            {/* bottom white content of page  */}
            <div className="w-[80%] flex flex-col items-center my-9 gap-9">
              {/* Divider line */}
              <hr className=" bg-[#E9E9FB] w-full" />

              {/* Your skills  */}
              {/* LHS content  */}
              <div className="w-[90%]">
                <h1 className="font-semibold text-base text-[#5843BD]">
                  Opening Hours
                </h1>

                <div className=" mt-4 tablet:flex flex-col gap-4 tablet:flex-row tablet:gap-6">
                  <p>
                    Opens:{" "}
                    <span className=" font-semibold text-base text-[#FF6633]">
                      {profile?.hours.opening}
                    </span>
                  </p>
                  <p>
                    Closes:{" "}
                    <span className=" font-semibold text-base text-[#FF6633]">
                      {profile?.hours.closing}
                    </span>
                  </p>
                </div>
              </div>

              {/* Divider line */}
              <hr className=" bg-[#E9E9FB] w-full " />

              {/* Hiring type  */}
              {/* LHS content  */}
              {/* <div className="w-[90%]">
          <h1 className="font-semibold text-base text-[#5843BD]">
            Pay Structure
          </h1>

          <p className="font-medium text-base text-black mt-4">
            {profile?.pay_structure}
          </p>
        </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default EmployerWorkerViewOriginal;