const Personal = [
    {
      value: 'Time Management',
      label: 'Time Management',
    },
    {
      value: 'Teamwork',
      label: 'Teamwork',
    },
    {
      value: 'Communication',
      label: 'Communication',
    },
    {
      value: 'Problem Solving',
      label: 'Problem Solving',
    },
    {
      value: 'Adaptability',
      label: 'Adaptability',
    },
    {
      value: 'Attention to Detail',
      label: 'Attention to Detail',
    },
    {
      value: 'Physical Stamina',
      label: 'Physical Stamina',
    },
    {
      value: 'Critical Thinking',
      label: 'Critical Thinking',
    },
    {
      value: 'Mechanical Skills',
      label: 'Mechanical Skills',
    },
    {
      value: 'Organization',
      label: 'Organization',
    },
    {
      value: 'Decision Making',
      label: 'Decision Making',
    },
    {
      value: 'Leadership',
      label: 'Leadership',
    },
    {
      value: 'Analytical Thinking',
      label: 'Analytical Thinking',
    },
    {
      value: 'Problem Analysis',
      label: 'Problem Analysis',
    },
    {
      value: 'Interpersonal Skills',
      label: 'Interpersonal Skills',
    },
    {
      value: 'Self-Motivation',
      label: 'Self-Motivation',
    },
    {
      value: 'Team Leadership',
      label: 'Team Leadership',
    },
    {
      value: 'Initiative',
      label: 'Initiative',
    },
    {
      value: 'Customer Service',
      label: 'Customer Service',
    },
    {
      value: 'Flexibility',
      label: 'Flexibility',
    },
    {
      value: 'Safety Consciousness',
      label: 'Safety Consciousness',
    },
    {
      value: 'Detail Orientation',
      label: 'Detail Orientation',
    },
    {
      value: 'Physical Dexterity',
      label: 'Physical Dexterity',
    },
    {
      value: 'Troubleshooting',
      label: 'Troubleshooting',
    },
    {
      value: 'Cooperation',
      label: 'Cooperation',
    },
    {
      value: 'Perseverance',
      label: 'Perseverance',
    },
    {
      value: 'Conflict Resolution',
      label: 'Conflict Resolution',
    },
    {
      value: 'Decision Making',
      label: 'Decision Making',
    },
    {
      value: 'Planning',
      label: 'Planning',
    },
    {
      value: 'Goal Orientation',
      label: 'Goal Orientation',
    },
    {
      value: 'Team Building',
      label: 'Team Building',
    },
    {
      value: 'Listening Skills',
      label: 'Listening Skills',
    },
    {
      value: 'Initiative',
      label: 'Initiative',
    },
    {
      value: 'Problem-Solving',
      label: 'Problem-Solving',
    },
    {
      value: 'Attention to Detail',
      label: 'Attention to Detail',
    },
    {
      value: 'Customer Focus',
      label: 'Customer Focus',
    },
    {
      value: 'Positive Attitude',
      label: 'Positive Attitude',
    },
    {
      value: 'Work Ethic',
      label: 'Work Ethic',
    },
    {
      value: 'Adaptability',
      label: 'Adaptability',
    },
    {
      value: 'Dependability',
      label: 'Dependability',
    },
    {
      value: 'Integrity',
      label: 'Integrity',
    },
    {
      value: 'Resilience',
      label: 'Resilience',
    },
    {
      value: 'Patience',
      label: 'Patience',
    },
    {
      value: 'Stress Management',
      label: 'Stress Management',
    },
    {
      value: 'Empathy',
      label: 'Empathy',
    },
    {
      value: 'Negotiation',
      label: 'Negotiation',
    },
    {
      value: 'Conflict Management',
      label: 'Conflict Management',
    },
    {
      value: 'Leadership',
      label: 'Leadership',
    },
    {
      value: 'Motivation',
      label: 'Motivation',
    },
    {
      value: 'Decision-Making',
      label: 'Decision-Making',
    },
    {
      value: 'Communication Skills',
      label: 'Communication Skills',
    },
    {
      value: 'Creativity',
      label: 'Creativity',
    },
    {
      value: 'Emotional Intelligence',
      label: 'Emotional Intelligence',
    },
    {
      value: 'Time Management',
      label: 'Time Management',
    },
    {
      value: 'Responsible',
      label: 'Responsible',
    },
    {
      value: 'Active Listening',
      label: 'Active Listening',
    },
    {
      value: 'Calm Under Pressure',
      label: 'Calm Under Pressure',
    },
    {
      value: 'Self-Confidence',
      label: 'Self-Confidence',
    },
    {
      value: 'Team Collaboration',
      label: 'Team Collaboration',
    },
    {
      value: 'Positive Attitude',
      label: 'Positive Attitude',
    },
    {
      value: 'Work Ethic',
      label: 'Work Ethic',
    },
    {
      value: 'Creativity',
      label: 'Creativity',
    },
    {
      value: 'Conflict Resolution',
      label: 'Conflict Resolution',
    },
    {
      value: 'Problem Solving',
      label: 'Problem Solving',
    },
    {
      value: 'Adaptability',
      label: 'Adaptability',
    },
    {
      value: 'Flexibility',
      label: 'Flexibility',
    },
    {
      value: 'Time Management',
      label: 'Time Management',
    },
    {
      value: 'Leadership',
      label: 'Leadership',
    },
    {
      value: 'Communication',
      label: 'Communication',
    },
    {
      value: 'Decision Making',
      label: 'Decision Making',
    },
    {
      value: 'Critical Thinking',
      label: 'Critical Thinking',
    },
    {
      value: 'Problem Analysis',
      label: 'Problem Analysis',
    },
    {
      value: 'Interpersonal Skills',
      label: 'Interpersonal Skills',
    },
    {
      value: 'Customer Service',
      label: 'Customer Service',
    },
    {
      value: 'Initiative',
      label: 'Initiative',
    },
    {
      value: 'Attention to Detail',
      label: 'Attention to Detail',
    },
    {
      value: 'Teamwork',
      label: 'Teamwork',
    },
    {
      value: 'Positive Attitude',
      label: 'Positive Attitude',
    },
    {
      value: 'Adaptability',
      label: 'Adaptability',
    },
    {
      value: 'Dependability',
      label: 'Dependability',
    }
];


export default Personal;

