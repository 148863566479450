import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

export default function MessageVerticalItem(props) {
  const navigate = useNavigate();
  const chat_info = useSelector(e => e.chat_info.message);
  
  const handleClick = () => {
    chat_info[props.index].Notification = 0;
    navigate(
      `/${
        props.userType === "employer" ? "employer/inbox" : "user/messages"
      }/${props.receiver}?i=${props.index}`
    )
  }
  return (
    <button
      className={`flex justify-between ${props.index !== 0 ? "border-t border-indigo-300" : ""} py-3 w-full focus:bg-transparent`}
      onClick={handleClick}
    >
      <span className="flex gap-x-3 items-center">
        <span className="block relative w-9 h-9">
          <span
            className={`AvatarP w-9 h-9 ${props.avatarStyle} rounded-[50%] justify-center items-center flex`}
          >
            <img
              className="Avatar w-[75%] h-[75%] rounded-full"
              src={props.avatar}
              alt={`${props.name} avatar`}
            />
          </span>
        </span>
        <span className="block text-start">
          <span className="SandraMara text-black block text-base font-normal">
            {props.name}
          </span>
          <span className="block text-red-500 text-[10px] font-normal">
            {props.position}
          </span>
          <span className="block text-black text-opacity-50 text-[10px] font-normal">
            {/* {props.team} */}
          </span>
        </span>
      </span>
      <span className="flex flex-col items-center gap-2">
        <span className="text-black text-opacity-50 text-[10px] font-normal">
          {props.messageAge}
        </span>
        <span className="w-4 h-4 bg-indigo-700 rounded-full flex justify-center items-center">
          <span className="self-end text-white text-[10px] font-normal">
            {props.messageNo}
          </span>
        </span>
      </span>
    </button>
  );
}
