import React, { useState, useEffect, useContext } from "react";
import HelmetProvide from "../../common/helmet";
import TopBar from "../../topbar/Top-Bar";
import SideBar from "../../sidebar/sidebar";
import Tab from "../tab/tab";
import Months from "../../common/month";
import { dispatchVoluntary, generate_my_cv } from "../../../Redux/ActionCreator";
import { useSelector, useDispatch } from "react-redux";
import AddedItem from "../added_item/added_item";
import AddMore from "../add_more/add_more";
import $ from "jquery";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { handleDeleteValue } from "../utils/handleDelete";

export default function UserVolunteerExperience(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector(e => e.profile);
  const voluntary = useSelector((vol) => vol.voluntary);
  const [chips, setChips] = useState(props.volunteer);
  const generated = useSelector((e) => e.generateCV);
  const profile1 = useSelector((e) => e.login);
  let stat = profile1.profile_status.profile_status;
  const cv = useSelector((prev) => prev.cvId);
  const [volunteer, setVolunteer] = useState([]);
  const [volStartDate, setVolStartDate] = useState("");
  const [volEndDate, setVolEndDate] = useState("");
  const [obj, setObj] = useState();

  let year = [];
  for (let i = 1990; i <= 2023; i++) {
    year.push(`${i}`);
  }
  let month = [];
  for (let i = 0; i <= Months.length - 1; i++) {
    month.push(`${Months[i].value}`);
  }

  useEffect(() => {
    if (obj !== undefined) {
      dispatch(generate_my_cv(obj));
    }
  }, [obj]);

  useEffect(() => {
    if (voluntary.voluntary !== null) {
      setChips(voluntary.voluntary);
      setVolunteer(voluntary.voluntary);
    }
  }, [voluntary.voluntary]);
  // console.log("volunteer", props);

  const handleAdd = () => {
    const date1 = new Date(volStartDate);
    const date2 = new Date(volEndDate);

    const month1 = date1.toLocaleDateString("en-US", { month: "short" });
    const year1 = date2.getFullYear();

    const Volunteer_Org = $('[name="vol"]').val();
    const Volunteer_join_date = month1 + " " + year1;
    let newVol = volunteer;
    let k = volunteer?.length;
    const data = {
      key: k,
      Volunteer_Org: Volunteer_Org,
      Volunteer_join_date: Volunteer_join_date,
    };
    newVol.push(data);
    setVolunteer(newVol);
    setChips(newVol);
    dispatch(dispatchVoluntary(newVol));
    $('[name="vol"]').val("");
    setVolStartDate("");
    setVolEndDate("");
    $('[name="volMonth"]').val("");
    $('[name="volYear"]').val("");
  };

  let mth = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const handleClickOperation = (desiredKey) => {
    let v = volunteer;
    let desiredObject = v.find((item) => item.key === desiredKey.key);
    let w = v.filter((chip) => chip.key !== desiredObject.key);

    $('[name="vol"]').val(desiredObject.Volunteer_Org);
    const startMonth = desiredObject.Volunteer_join_date.split(" ")[0];
    const startYear = desiredObject.Volunteer_join_date.split(" ")[1];

    const firstMonth = new Date(startYear, mth.indexOf(startMonth));
    const firstYear = new Date(startYear);
    let i = firstMonth.getTime();
    let y = firstYear.getTime();
    // console.log("Milli", i);
    // console.log("Mil year", y);
    setVolStartDate(i);
    setVolEndDate(y);

    // console.log("desired object", desiredObject);
    w.forEach((element) => {
      element.key = w.indexOf(element);
      // console.log("vol key", w.indexOf(element));
    });
    setVolunteer(w);
    setChips(w);
    dispatch(dispatchVoluntary(w));
    desiredObject = [];

    // console.log("inner chips", chips);
    // console.log("inner volunteer", w);
  };

  // handleClick function
  const handleClick = (desiredKey) => {
    const volun = $('[name="vol"]').val();
    if (volun !== "") {
      handleAdd(); // add already mapped values back to the work array if the client/user forget to click add, this is so, so as to save users experience.
      handleClickOperation(desiredKey);
    } else {
      handleClickOperation(desiredKey);
    }
  };

  const handleDelete = (chipToDelete) => {
    const newvol = handleDeleteValue(volunteer, chipToDelete, "key");
    setVolunteer(newvol);
    setChips(newvol);
    dispatch(dispatchVoluntary(newvol));
    // console.log("chips", chips);
    // console.log("delete cert", volunteer);
  };

  const handleBackBtn = () => {
    if ($('[name="vol"]').val() !== "") {
      setTimeout(() => {
        toast.error("Add the volunteer you just filled.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
    } else {
      props.handlejump(4);
    }
  };

  const handleNextBtn = () => {
    if (window.location.pathname === `/form/${cv.id}/update`) {
      if ($('[name="vol"]').val() !== "") {
        handleAdd();
      }
      let generated_value = generated.message;
      generated_value.Volunteer = volunteer;
      setObj(generated_value);
      navigate("/allCv");
    } else {
      if ($('[name="vol"]').val() !== "") {
        setTimeout(() => {
          toast.error("Add the volunteer you just filled.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }, 50);
      } else {
        props.handlejump(4);
      }
    }
  };

  return (
    <>
    <HelmetProvide
        title="Moil | Create Your Resume"
        description="Build your professional resume with Moil's easy-to-use resume creation form. Showcase your skills, experience, and qualifications to potential employers."
        className="notranslate"
      />
    <div className="grid grid-cols-auto md:grid-cols-smdash cont:grid-cols-dash grid-rows-dash">
      <TopBar topBarShowMenu={true} />
      <SideBar />
      <div className="px-3 lg:pl-6 py-8 w-full overflow-y-scroll ScrollOnly h-calc-70">
        <div className="w-full xtraxl:max-w-calculate">
          <div className="px-3 py-8 w-full pl-3 sm:pl-6 lg:pl-9 xl:pl-12 max-w-[800px] xtraxl:max-w-calculate">
            <div className="w-full max-w-[793px] xtraxl:max-w-calculate h-[54px] bg-white bg-opacity-50 rounded-[40px] shadow border border-indigo-400 border-opacity-30 flex flex-col justify-center">
              <p className="text-stone-950 text-base md:text-[28px] font-semibold ml-12 max-[300px]:ml-6">
                Resume Text Capturing Form
              </p>
            </div>
            <p className="w-full max-w-[741px] xtraxl:max-w-calculate mt-2 px-3 lg:pl-6">
              <span className="text-black text-opacity-50 text-[14px] md:text-base font-normal px-3 lg:pl-6">
                Hey there! We’re excited to help you create an awesome resume. Don’t worry about formal language - just tell us about your experience and skills in your own words. We’ll take care of the rest!
              </span>
            </p>
            <div className="w-full mt-8 p-8 bg-white bg-opacity-80 rounded-2xl shadow border border-indigo-400 border-opacity-30 flex-col justify-start items-start gap-16 inline-flex">
              <div className="tabarea justify-start items-center gap-6 inline-flex w-full flex-shrink-0 max-w-[calc(100vw-88px)] overflow-hidden">
                <Tab
                  num="1"
                  current={false}
                  completed={true}
                  className="max-[420px]:hidden"
                />
                <Tab
                  num="2"
                  current={false}
                  completed={true}
                  className="max-[420px]:hidden"
                />
                <Tab
                  num="3"
                  current={false}
                  completed={true}
                  className="max-[350px]:hidden"
                />
                <Tab num="4" current={false} completed={true} />
                <Tab num="5" current={false} completed={true} />
                <Tab
                  // label="Volunteer Experience"
                  label="Volunteer Experience"
                  current={true}
                  completed={false}
                />
                <Tab num="7" current={false} completed={false} />
                <Tab num="8" current={false} completed={false} />
              </div>
              <div className="flex-col justify-start items-start gap-12 flex w-full">
                <div className="flex-col justify-start items-start gap-2 flex w-full">
                  <div className="flex gap-3 items-center">
                    <div className="px-1 w-2 h-2 rounded-full bg-black"></div>
                    <label
                      className="text-black text-base font-medium"
                      for="volunteerOrg"
                    >
                      At what organization did you volunteer?
                    </label>
                  </div>
                  <input
                    type="text"
                    className="p-4 w-full rounded-lg border border-indigo-400 border-opacity-30"
                    placeholder="Enter organization here"
                    id="volunteerOrg"
                    name="vol"
                  />
                </div>
                <div className="flex-col justify-start items-start gap-2 flex w-full">
                  <div className="flex gap-3 items-center">
                    <div className="px-1 w-2 h-2 rounded-full bg-black"></div>
                    <label
                      className="text-black text-base font-medium"
                      for="volunteerStartDate"
                    >
                      When did you volunteer?
                    </label>
                  </div>
                  <div className="w-full flex flex-col sm:flex-row gap-2">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker", "DatePicker", "DatePicker"]}
                      >
                        <DatePicker
                          label="Month"
                          views={["month"]}
                          value={dayjs(volStartDate)}
                          onChange={(event) => setVolStartDate(event)}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker", "DatePicker", "DatePicker"]}
                      >
                        <DatePicker
                          label="Year"
                          name="startYear"
                          views={["year"]}
                          value={dayjs(volEndDate)}
                          onChange={(endValue) => setVolEndDate(endValue)}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                  <div className="flex-col justify-start items-start gap-3 flex w-full">
                    <AddMore text="volunteer" onClick={handleAdd} />
                    <p className="text-red-500 text-base font-medium">
                      {chips?.length == 0 ? "No volunteer experience yet." : "You can add more volunteer experience"}
                    </p>
                    <div className="flex flex-wrap gap-3">
                      {chips?.map((vol, key) => (
                        <AddedItem
                          item={vol.Volunteer_Org}
                          key={key}
                          onClick={() => handleClick(vol)}
                          delete={() => handleDelete(vol)}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <div className="self-stretch flex-col sm:flex-row justify-between items-start gap-4 inline-flex">
                  <button
                    className="px-5 py-3 rounded-lg shadow border border-orange-500 justify-center items-start gap-2.5 flex w-full sm:max-w-[200px]"
                    onClick={handleBackBtn}
                  >
                    <span className="text-center text-orange-500 text-base font-semibold">
                      Previous
                    </span>
                  </button>
                  <button
                    className="px-5 py-3 bg-indigo-700 rounded-lg shadow justify-center items-start gap-2.5 flex w-full sm:max-w-[200px]"
                    onClick={handleNextBtn}
                  >
                    <span className="text-center text-white text-base font-semibold whitespace-nowrap">
                      Save and Continue
                    </span>
                  </button>
                </div>
                <ToastContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
