import React, { useState, useEffect } from "react";
import JobPostItem from "../common/job_posts_item";
import { baseURL } from "../../Redux/baseURL";
import { useSelector, useDispatch } from "react-redux";
import { JobDetailed, returnHome, } from "../../Redux/ActionCreator";
import { Link } from "react-router-dom";

function EmployeesJobCreated() {
  const dispatch = useDispatch();
  const [jobs, setJobs] = useState(null);
  let altEmployerId = useSelector(e => e.employer_employee_info.message?.userId);
  const employerId = useSelector(e => e.employer.message?.userId) || altEmployerId;
  const pid = new URLSearchParams(window.location.search).get("id");
  const jid = new URLSearchParams(window.location.search).get("jid");
  const hideBreadCrumb = new URLSearchParams(window.location.search).get("d");
  const url = `${baseURL}/api/job/employee/get_employer_jobpost?employerId=${employerId}&page=1`; // take note this will also receive page no query, handle the page state change.

  useEffect(() => {
    let token = localStorage.getItem("token");

    const headers = {
      // Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
      credentials: "include"
    })
      .then((resp) => resp.json())
      .then((resp) => {
        // console.log(resp);
        if (resp.success === true) {
          // console.log("====>", resp);
          let ourResp = resp.data;
          setJobs(ourResp);
          dispatch(JobDetailed(ourResp));
          // console.log("jobs ===>", jobs);
        } else if (resp.success === false && resp.message == "Unauthorized") {
          dispatch(returnHome(resp.status));
        }
      });
  }, []);

  // console.log("new jobs", jobs);

  return (
    <div className="h-calc-70 xsm:px-4 py-8 overflow-y-scroll flex justify-center">
      <div className="w-full flex flex-col gap-8 max-w-[800px] xtraxl:max-w-[1200px]">
        {hideBreadCrumb !== null ? null :
          <div className="flex flex-wrap items-center gap-3 pb-4">
            <Link to="/user/jobs" className="text-[#5145CD]">Applied jobs</Link> /
            <Link to={`/jobs/${jid}`} className="text-[#5145CD]">Job details</Link> /
            <Link to={`/user/employer/profile/${pid}?job=${jid}`} className="text-[#5145CD]">Employer profile</Link> /
            <Link to={`/user/available_jobs?id=${pid}&jid=${jid}`} className="text-[#5145CD]">Employer jobs</Link>
          </div>}
        {jobs && jobs?.jobPosts
          .length > 0 && (
            <p className="mx-4 xsm:mx-0 text-black text-opacity-50 text-xl font-semibold font-['Poppins']">
              These are the jobs published by {jobs.jobPosts[0].companyName}
            </p>
          )}
        <div className="w-full px-4 sm:p-8 bg-white bg-opacity-80 rounded-xl shadow border border-indigo-400 border-opacity-30 flex-col justify-start items-start inline-flex">
          {jobs !== null ? (
            jobs?.jobPosts?.map((job) => {
              return (
                <div
                  key={job?._id}
                  className="w-full py-4 border-b border-indigo-400 border-opacity-30"
                >
                  <JobPostItem
                    jobPosition={job?.jobTitle}
                    postAge={job?.createdAt}
                    logo={job?.logo}
                    jobId={job._id}
                    whereWeGoin={`/user/job_preview/${job?.custom_name}?apply="job"`}
                    isEmployee
                  />
                </div>
              );
            })
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EmployeesJobCreated;
