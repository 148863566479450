import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendOtp, verifyOtp, verify_otp_loading } from "../../../Redux/ActionCreator";
import { ToastContainer, toast } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';

export default function PhoneVerificationModal({
  waitingEnded,
  resendCode,
  resendTimed,
  phoneNumber,
  removeModal,
  handleVerify
}) {
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  const [verificationCode, setVerificationCode] = useState([]);
  const [time, setTime] = useState(120);
  const [expireTime, setExpireTime] = useState(1800);
  const otp = useSelector((state) => state.otp);
  const send_otp = useSelector((state) => state.send_otp);
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (time >= 1) {
        setTime((prev) => prev - 1);
      } else {
        waitingEnded();
      }
    }, 1000);

    const expireTimer = setTimeout(() => {
      if (expireTime >= 1) {
        setExpireTime((prev) => prev - 1);
      }
    }, 1000);

    // Clear the timeout to avoid memory leaks

    // Clear the timeout to avoid memory leaks
    return () => {
      clearTimeout(timer);
      clearTimeout(expireTimer);
    };
  }, [time, waitingEnded, expireTime, otp.errMess]);

  useEffect(() => {
    if (otp.message !== null) {
      handleCloseModal();
    }
  }, [otp.message])

  useEffect(() => {
    if (otp.errMess !== null || otp.errMess !== undefined) {
      setTimeout(() => {
        toast.error(otp.errMess, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);

      dispatch(verify_otp_loading());
    } else {
      handleCloseModal();
    }
  }, [otp.errMess])

  const handleCloseModal = () => {
    // console.log("Called")
    removeModal();
  }

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""
      }${seconds}`;
  };

  const handleResend = () => {
    setTime(120);
    setExpireTime(1800);
    resendCode();
    dispatch(sendOtp(phoneNumber));
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && index > 0) {
      verificationCode.pop();
      e.preventDefault();
      inputRefs[index].current.value = "";
      inputRefs[index - 1].current.focus();
    }
  };

  const handleChange = (index, e) => {
    const value = e.target.value;
    setVerificationCode((prev) => {
      const newCode = [...prev];
      newCode[index] = /^[0-9]$/.test(value) ? value : "";
      return newCode;
    });
    if (
      ["", null, undefined].includes(value) === false &&
      index < inputRefs.length - 1 &&
      /^[0-9]$/.test(value)
    ) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleVerification = (code) => {
    if (verificationCode.length === 4) {
      dispatch(verifyOtp(code, phoneNumber));
    } else {
      toast.error("Incomplete code", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  return (
    <div className="w-[90%] lg:w-full py-6 sm:py-10 bg-white rounded-3xl shadow border border-indigo-400 border-opacity-30 max-w-[500px] flex justify-center items-center">
      <div className="w-[80%] flex flex-col justify-center gap-y-4 sm:gap-y-6 pb-6">
        <button className="ml-auto cursor-pointer"
          onClick={() => handleCloseModal()}><CloseIcon /></button>
        <div>
          <p className="text-black text-center text-md xsm:text-xl sm:text-2xl font-medium font-['Poppins'] leading-normal mb-2">
            Verify your phone number
          </p>
          <p className="text-center text-zinc-800 text-xs sm:text-sm font-normal font-['Poppins'] leading-[16.80px]">
            We have sent a 4-digit Code to your phone number, enter the code
            below.
          </p>
        </div>
        <div className="flex w-full items-center justify-center gap-x-4 xsm:gap-x-6 sm:gap-x-8">
          {inputRefs.map((inputRef, index) => (
            <input
              key={index}
              type="tel"
              id={`phone-${index}`}
              name={`phone-${index}`}
              className="bg-purple-600 bg-opacity-5 rounded-[5px] max-w-[40px] text-center sm:max-w-[55px] aspect-[1/1] border border-transparent"
              autocorrect="off"
              autoComplete="new-password0"
              maxLength={1}
              placeholder="-"
              pattern="\d{1}"
              required
              onKeyDown={(e) => handleKeyDown(index, e)}
              onChange={(e) => handleChange(index, e)}
              ref={inputRef}
              value={verificationCode?.[index]}
            />
          ))}
        </div>

        {expireTime > 0 ? (
          <p className="text-zinc-800 text-center text-sm font-normal font-['Inter'] leading-snug">
            OTP will expire in{" "}
            <span className="text-zinc-800  text-sm font-semibold font-['Inter'] leading-snug">
              {formatTime(expireTime)} mins
            </span>
          </p>
        ) : (
          <p className="text-zinc-800 text-center text-sm font-normal font-['Inter'] leading-snug">
            OTP has expired. Request for another one
          </p>
        )}
        <button
          className="max-w-[186px] self-center h-12 px-9 py-3 bg-orange-500 rounded-3xl justify-center items-center gap-3 inline-flex"
          onClick={() => {
            const code = verificationCode.join("");
            if (typeof handleVerify === "function") {
              handleVerify(code);
            } else {
              handleVerification(code);
            }
          }}
        >
          <span className="text-center text-white text-sm sm:text-base font-semibold font-['Poppins']">
            Confirm Code
          </span>
        </button>
        {resendTimed ? (
          <p className="text-indigo-700 text-sm text-center font-semibold font-['Inter'] leading-snug">
            {`Token expires in 2 mins`} {/* ${formatTime(time)} */}
          </p>
        ) : (
          <button
            className="text-indigo-700 text-sm font-semibold font-['Inter'] leading-snug"
            onClick={handleResend}
          >
            Resend Code
          </button>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}
