import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { baseURL1 } from '../../Redux/baseURL';
import {
	googleSuccess,
} from '../../Redux/ActionCreator';

const ProfileProtected = (props) => {
  // initialize useSelector hook
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuth = useSelector(prev => prev.login);
  const token = localStorage.getItem("token");
	const profile_stat = new URLSearchParams(window.location.search).get('profile_status');
	const acctType = new URLSearchParams(window.location.search).get('AcctType');
  // console.log("Parameter", token);

  useEffect(() => {
		// const token = localStorage.getItem('token');
		if (token === null) {
			dispatch(googleSuccess());
		}
	}, []);

  const handleRedirect = () => {
    if (token !== null) {
      return props.children;
		} else {
      // console.log("I called this component")
      if (window.location.pathname.includes("jobs")) {
        window.open(baseURL1 + `/login?link=${window.location.pathname}&open=true`, "_self");
      } else {
        window.open(baseURL1 + `/Home?link=${window.location.pathname}&open=true`, "_self");
      }
    }
  }

  return (
    <>
      {token !== null ? <>
      { isAuth.isAuthenticated === true ? props.children : handleRedirect() }
      </> : null} 
    </>
  )
}

export default ProfileProtected;

