import React from 'react';
import { useState, useRef, useContext } from 'react';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import {
	existing_resume_features,
	user_CV_state_back_to_null,
} from '../../../Redux/ActionCreator';
import { createPortal } from 'react-dom';
import LoaderModal from '../LoaderModal';
import BackButton from '../back_button';

import { useAnalytics } from '../../../context/AnalyticsContext';

const UploadModal = (props) => {
  const analytics = useAnalytics();
	const [stat, setStat] = useState(false);
	const [showWarningModal, setShowWarningModal] = useState(true);
	const dispatch = useDispatch();
	const [userFile, setUserFile] = useState('');
	const navigate = useNavigate();
	const uploadInputRef = useRef();
	let labelClasses =
		'flex items-center text-sm font-semibold text-slate-700 ml-[24px]';

	const upload_status = useSelector((prev) => prev.existingCV);
	const user = useSelector(e => e.userInfo);

	const savedResume = useSelector((e) => e.saved_cv);
  	const details = savedResume.message;

	let [ select, setSelect ] = useState(false);
	let [ uploadPc, setUploadPc ] = useState(false);

	const handleUploadPc = () => {
		if (select == true) {
			setSelect(false);
		}
		setUploadPc(true);
	}

	const handleSelect = () => {
		if (uploadPc == true) {
			setUploadPc(false);
		}
		setSelect(true);
	}

	// improvise job
	let job = {
		indeed: false
	};

	const formSubmissionHandle = (e) => {
		e.preventDefault();
		//Sending the file to the backend here
		const file = uploadInputRef.current?.files[0];
		// console.log("file", file);
		if (file === undefined || file === null) {
			// console.log("file", file)
			setTimeout(() => {
				toast.error("Select a file to upload", {
					position: toast.POSITION.TOP_RIGHT,
				});
			}, 50);
		} else {
			const formData = new FormData();
			formData.append('uploadPDF', file);
			setStat(true);
			dispatch(existing_resume_features(formData));
		}
	};

	const triggerUploadFile = (e) => {
		e.preventDefault();
		uploadInputRef.current.click();
	};
	const fileChangeHandle = (e) => {
		e.preventDefault();
		// console.log(1);
		// console.log("target", e.target.value)
		// console.log("uploadInputRef.current?.files[0]", uploadInputRef.current?.files[0])
		let enteredFileName = uploadInputRef.current?.files[0]?.name;
		if (enteredFileName) {
			setUserFile(enteredFileName);
		}
	};

	const skipHandle = (e) => {
		navigate('/generateCV');
	};

	const handleClick = () => {
		navigate("/user/dashboard");
	}

	const handleContinue = () => {
		analytics.track("Resume upload continue btn", {
      Type: "Employee",
      time: new Date(),
      Email: user.user?.email,
			userId: user.user?._id,
    });
		dispatch(user_CV_state_back_to_null())
	}

	let text = "Extracting CV text, this can take a couple of minutes..."
	
	if (details.length == 0) {
		return (
			<div>
				{showWarningModal &&
					createPortal(
						<div className="fixed top-0 left-0 bottom-0 right-0 z-10 bg-tet-600 overflow-y-scroll min-h-screen py-7 backdrop-blur-[2px] flex justify-center items-center">
							<section className="bg-white sm:max-w-[500px] min-w-[350px] w-[80%] m-auto rounded-3xl sm:px-10 p-3">
								<BackButton onClick={handleClick} />
								<form
									onSubmit={formSubmissionHandle}
									className="bg-white px-10 py-5 rounded-xl">
									<div className="flex flex-col md:gap-3 justify-between mb-4">
										<label className="block mb-6 flex-2 relative">
											<span className={labelClasses}>Upload Resume</span>

											<span className="hr-span"></span>
											<div className="w-[200px] h-[200px] mx-auto border-dashed border-[2px] rounded-2xl border-sec-300 cursor-pointer relative my-8">
												<input
													ref={uploadInputRef}
													onInput={fileChangeHandle}
													type="file"
													accept=".doc, .docx, .pdf"
													className="opacity-0 absolute -z-[1] w-full h-full"
													name="uploadPDF"
												/>
												{!userFile && (
													<button
														type="button"
														onClick={triggerUploadFile}
														className="py-[10px] px-[20px] bg-sec-500 border border-sec-300 drop-shadow-btn text-pry-600 rounded-3xl center pointer-events-none">
														Select
													</button>
												)}
												{userFile && (
													<article className="absolute top-[50%] left-[50%] transform -translate-y-[50%] text-center -translate-x-[50%]">
														<AttachmentIcon />
														<span>{userFile}</span>
													</article>
												)}
											</div>
											<p className="text-base text-center my-4">
												Reduce your time for CV generation by uploading an existing Resume
											</p>
											{/* <button
												type="button"
												onClick={triggerUploadFile}
												className="py-[10px] px-[20px] bg-pry-800 drop-shadow-btn text-white font-semibold rounded-3xl block mx-auto">
												{language["Upload_btn_text"]}
											</button> */}
										</label>
									</div>
									{upload_status.errMess !== null ? (
										<h6
											style={{
												color: 'red',
												marginTop: '5px',
												textAlign: 'center',
												fontSize: "10px"
											}}>
											{upload_status.errMess}
										</h6>
									) : null}
									<div className="flex justify-center items-center gap-4">
										<button
											type="text"
											onClick={skipHandle}
											className="py-2 px-6 rounded-full block bg-sec-300 border border-sec-400 dropshadow-btn text-pry-200 font-semibold">
											Skip
										</button>
										<button
											type="submit"
											className="py-2 px-6 rounded-full block bg-sec-300 border border-sec-400 dropshadow-btn text-pry-200 font-semibold"
											onClick={handleContinue}>
											Continue
										</button>
										<ToastContainer />
										{stat === true ? (
											upload_status.errMess === null ? <LoaderModal text={text}/> : null
										) : null}
									</div>
								</form>
							</section>
						</div>,
						document.getElementById('modal')
					)}
			</div>
		);
	}
};

export default UploadModal;
