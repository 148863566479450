import React, { useState, useContext, useEffect } from "react";
import Tab from "../tab/tab";
import TopBar from "../../topbar/Top-Bar";
import SideBar from "../../sidebar/sidebar";
import Skills from "../skills";
import Skills_sp from "../skills/spSkill";
import Personal from "../personal/personal";
import Sp_personal from "../personal/sp_personal";
import { Box, Chip } from "@mui/material";
import $ from "jquery";
import { useSelector, useDispatch } from "react-redux";
import {
  dispatchSkills,
  dispatchPskills,
  cert_function,
  updateSkills,
  updateSoft_skills,
  generate_my_cv,
  saveSkills,
  returnHome,
  existing_resume,
  save_secret,
  updateProfileLanguage,
  resume_step_jump_function
} from "../../../Redux/ActionCreator";
import { convertSkills } from "../../../Redux/convertResume";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HelmetProvide from "../../common/helmet";
import UserLanguage from "./moil_user_language";
import { saveIntoServerCache, getTwoFromServerCache, getOneFromServerCache } from "../../../utils/saveIntoCache";
import { baseURL } from "../../../Redux/baseURL";
import SelectLanguage from "../../common/resumeLangSelection/selectLanguage";
import ViewProp from "../view_prop/view";
import TextInput from "../text_input/text_input";
import AddedItem from "../added_item/added_item";
import AddMore from "../add_more/add_more";
import LoaderModal from "../../common/LoaderModal/index.jsx";
import SkillsCompleteGamifiedModal from "../../gamified_modals/skills_gamified_complete_modal/index.jsx";


export default function UserSKillsAndLicensing(props) {
  const t_lang = useSelector((lg) => lg.t_lang);
  const profile = useSelector((e) => e.profile);
  const generated = useSelector((e) => e.generateCV);
  const Tech_skills = useSelector((e) => e.skills);
  const Personal_skills = useSelector((e) => e.p_skills);
  const existing_cv_data = useSelector((prev) => prev.existingCV);
  const secret = useSelector((prev) => prev.secret);
  const profile1 = useSelector((e) => e.login);
  const userInfo = useSelector((e) => e.userInfo);
  let stat = profile1.profile_status.profile_status;
  const message = existing_cv_data.message;
  const cv = useSelector((prev) => prev.cvId);
  const navigate = useNavigate();
  let sk = Skills;;
  // if (t_lang.selected === "sp") {
  //   sk = Skills_sp;
  // } else if (t_lang.selected === "en") {
  //   sk = Skills;
  // }

  let pk = Personal;;
  // if (t_lang.selected === "sp") {
  //   pk = Sp_personal;
  // } else if (t_lang.selected === "en") {
  //   pk = Personal;
  // }

  let [s, setS] = useState(props.skills !== undefined ? props.skill : []);
  let [p, setP] = useState(props.personal_skill !== undefined ? props.personal_skill : []);
  const [skill, setskill] = useState(sk);
  const [chips, setChips] = useState([]);
  const [other, setOther] = useState("none");
  const [currentArray, setCurrentArray] = useState([]);
  const [cert, setCert] = useState([]);
  const [path, setPath] = useState(false);
  const sidebar = useSelector(e => e.sidebarOpen.message);
  const [technicalSkill, setTechnicalSkill] = useState(4);
  const [softSkill, setSoftSkill] = useState(4);
  const [saving, setSaving] = useState(false);

  const [ proplang, setPropLang ] = useState("");
  const [ proplangRate, setPropLangRate ] = useState("");

  let resumeLanguage = useSelector(e => e.language.lang);

  const [selectedOptions, setSelectedOptions] = useState(sk);
  const [selectedOptionsp, setSelectedOptionsp] = useState(pk);

  // initialize useSelector and useDispatch hook
  const existing_activities = useSelector((prev) => prev.existingCV);
  const dispatch = useDispatch();
  const certMess = useSelector((msg) => msg.cert);
  // const technicalSkill = useSelector(sk => sk.skills);
  // const personalSkill = useSelector(pk => pk.p_skills);

  const searchParams = new URLSearchParams(window.location.search);
  const process = searchParams.get("create profile");
  // const [ eighty, setEighty ] = useState(false)

  const [language, setLanguage] = useState("");
  let [showLanguageModal, setShowLanguageModal] = useState(false);

  useEffect(() => {
    let SelectedLanguage = getOneFromServerCache("Language");
    SelectedLanguage.then(resp => resp.json())
      .then(resp => {
        // console.log("SelectedLanguage", resp.data);
        if (resp.success == true) {
          if (resp.data == null) {
            if (window.location.pathname !== "/user/profile/update/skills" && profile.message !== null && profile.message?.CompletionPercentage == 100) {
              setTimeout(() => {
                toast.error("You need to select the language you want your resume to be in.", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }, 50);
              saveIntoServerCache("step", 0);
              dispatch(resume_step_jump_function(0));
            }
          } else {
            setLanguage(JSON.parse(resp.data));
          }
        }
        //  else if (resp.success === false && resp.message == "Unauthorized") {
        //   dispatch(returnHome(resp.status));
        // }
      }).catch(err => {
        // console.log("Language getting error", err);
      });
  }, []);

  // console.log("language", language);

  useEffect(() => {
    if (Tech_skills.skills === null && Personal_skills.p_skills == null) {
      // console.log("skillsss", props.skills)
      let cacheInfo = getTwoFromServerCache("technicalSkills", "personalSkills")
      cacheInfo.then(res => res.json())
        .then(res => {
          // console.log("server cache value", res);
          if (res.success == true) {
            if (res.data.value1 !== null && res.data.value1 !== undefined) {
              let resObj = JSON.parse(res.data.value1);
              // console.log("value 1", resObj);
              dispatch(dispatchSkills([... new Set(resObj)]));
              setS(resObj);
            }
            if (res.data.value2 !== null && res.data.value2 !== undefined) {
              let resObj = JSON.parse(res.data.value2);
              // console.log("value 2", resObj);
              dispatch(dispatchPskills([... new Set(resObj)]));
              setP(resObj);
            }
            // dispatch(saveSkills(res.data.value1, res.data.value2, secret.secret));
            // console.log("Done");
          } else if (res.success === false && res.message == "Unauthorized") {
            dispatch(returnHome(res.status));
          }
        })
    }
    // if (!window.location.pathname.includes("update")) {
    //   // console.log("was called")
    //   saveIntoServerCache("step", 3);
    // }
  }, []);

  // console.log("s", s, "p", p);


  useEffect(() => {
    // console.log("location", window.location.pathname);
    if (
      window.location.pathname === "/user/profile/update/skills" &&
      profile.message !== null && profile.message?.CompletionPercentage >= 95
    ) {
      // console.log("profile.message.Skills", profile.message.Skills)
      setPath(true);
      let T_skill = [];
      if (Array.isArray(profile.message?.Skills) === true) {
        for (let i = 0; i <= profile.message?.Skills?.length - 1; i++) {
          if (profile.message.Skills[i]?.value !== undefined) {
            T_skill.push(profile.message.Skills[i]?.value)
          } else {
            T_skill.push(profile.message.Skills[i])
          }
        }
        T_skill = T_skill.map(e => e.toLowerCase());
        T_skill = [... new Set(T_skill)];
        T_skill = T_skill.map(e => capitalizeFirstChar(e));
        setS([... new Set(T_skill)]);
      }
      let P_skill = [];
      if (Array.isArray(profile.message?.Soft_skills) === true) {
        for (let i = 0; i <= profile.message?.Soft_skills?.length - 1; i++) {
          if (profile.message.Soft_skills[i]?.value !== undefined) {
            P_skill.push(profile.message.Soft_skills[i]?.value)
          } else {
            P_skill.push(profile.message.Soft_skills[i])
          }
        }
        P_skill = P_skill.map(e => e.toLowerCase());
        P_skill = [... new Set(P_skill)];
        P_skill = P_skill.map(e => capitalizeFirstChar(e));
        setP([... new Set(P_skill)]);
      }
      // console.log("t_skills", s);
      // console.log("p_skills", p);
    } else if (window.location.pathname === "/user/profile/update/skills" &&
      profile.message !== null && profile.message?.CompletionPercentage < 95) {
      if (message === null) {
        const getExistingCv = async () => {
          fetch(baseURL + "/api/temp/getTempRes", {
            headers: {
              // "Authorization": `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
            credentials: "include"
          }).then(resp => resp.json())
            .then(resp => {
              if (resp.success === true) {
                // console.log("Temp response", resp);
                if (resp.data !== null) {
                  dispatch(existing_resume(resp.data));
                  dispatch(save_secret(resp.data?.Secret))
                  let T_skill = [];
                  if (Array.isArray(resp.data?.Technical_skills) === true) {
                    for (let i = 0; i <= resp.data?.Technical_skills?.length - 1; i++) {
                      if (resp.data?.Technical_skills[i]?.value !== undefined) {
                        T_skill.push(resp.data?.Technical_skills[i]?.value)
                      } else {
                        T_skill.push(resp.data?.Technical_skills[i])
                      }
                    }
                    T_skill = T_skill.map(e => e.toLowerCase());
                    T_skill = [... new Set(T_skill)];
                    T_skill = T_skill.map(e => capitalizeFirstChar(e));
                    setS([... new Set(T_skill)]);
                  }
                  let P_skill = [];
                  if (Array.isArray(resp.data?.Personal_skills) === true) {
                    for (let i = 0; i <= resp.data?.Personal_skills?.length - 1; i++) {
                      if (resp.data?.Personal_skills[i]?.value !== undefined) {
                        P_skill.push(resp.data?.Personal_skills[i]?.value)
                      } else {
                        P_skill.push(resp.data?.Personal_skills[i])
                      }
                    }
                    P_skill = P_skill.map(e => e.toLowerCase());
                    P_skill = [... new Set(P_skill)];
                    P_skill = P_skill.map(e => capitalizeFirstChar(e));
                    setP([... new Set(P_skill)]);
                  }
                }
              } else if (resp.success === false && resp.message == "Unauthorized") {
                dispatch(returnHome(resp.status));
              }

            }).catch(err => {
              // console.log("Error getting temp resume", err)
            })
        }
        getExistingCv();
      } else {
        if (Personal_skills.p_skills !== null) {
          setP(Personal_skills.p_skills);
        } else {
          if (Personal_skills.p_skills == null) {
            setP(message.Personal_skills);
            dispatch(dispatchPskills(message.Personal_skills));
          }
        }
        if (Tech_skills.skills !== null) {
          setS(Tech_skills.skills);
        } else {
          if (Tech_skills.skills === null) {
            setS(message.Technical_skills);
            dispatch(dispatchSkills(message.Technical_skills));
          }
        }
        if (
          Tech_skills.skills === null &&
          Personal_skills.p_skills === null &&
          message === null
        ) {
          setS([]);
          setP([]);
        }
      }
    } else {
      if (Personal_skills.p_skills !== null) {
        setP(Personal_skills.p_skills);
      }
      if (Tech_skills.skills !== null) {
        setS(Tech_skills.skills);
      }
      if (
        Tech_skills.skills === null &&
        Personal_skills.p_skills === null &&
        message === null
      ) {
        setS([]);
        setP([]);
      }
    }
  }, []);

  const [twentyModal, setTwentyModal] = useState(false);
  const [fourtyModal, setfourtyModal] = useState(false);
  const [sixtyModal, setsixtyModal] = useState(false);
  const [eightyModal, seteightyModal] = useState(false);
  const [nintyFiveModal, setnintyFiveModal] = useState(false);
  const [hundredModal, sethundredModal] = useState(false);
  const [ processModal, setProcessModal ] = useState(false);

  useEffect(() => {
    setSaving(false);
    if (profile.message !== null && processModal == true) {
      // if (profile.message?.CompletionPercentage >= 20 && profile.message?.CompletionPercentage < 40) {
      //   setTwentyModal(true)
      // } else if (profile.message?.CompletionPercentage  >= 40 && profile.message?.CompletionPercentage < 60) {
      //   setfourtyModal(true);
      // } else if (profile.message?.CompletionPercentage  >= 60 && profile.message?.CompletionPercentage < 80) {
      //   setsixtyModal(true);
      // } else if (profile.message?.CompletionPercentage  >= 80 && profile.message?.CompletionPercentage < 95) {
      //   seteightyModal(true);
      // } else if (profile.message?.CompletionPercentage  >= 95 && profile.message?.CompletionPercentage <= 100) {
      //   setnintyFiveModal(true);
      // } else if (profile.message?.CompletionPercentage === 100 && profile.message?.ProfileStatus !== "Completed") {
      //   sethundredModal(true);
      // }
      seteightyModal(true);
    }
  }, [profile.message]);

  // Function to capitalize the first character of a string
  function capitalizeFirstChar(text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  useEffect(() => {
    if (certMess.cert !== null) {
      setChips(certMess.cert);
      setCert(certMess.cert);
    }

    if (t_lang.selected === "sp") {
      setSelectedOptions(Skills_sp); /* 
      sk = Skills_sp; */
    } else if (t_lang.selected === "en") {
      setSelectedOptions(Skills); /* 
      sk = Skills; */
    }

    if (t_lang.selected === "sp") {
      setSelectedOptionsp(Sp_personal); /* 
      pk = Sp_personal; */
    } else if (t_lang.selected === "en") {
      setSelectedOptionsp(Personal); /* 
      pk = Personal; */
    }
  }, [t_lang.selected, Personal, Sp_personal, Skills, Skills_sp]);

  const handlesets = (event, value) => {
    if (
      window.location.pathname === "/user/profile/update/skills" &&
      profile.message !== null
    ) {
      // update skills here
      dispatch(updateSkills(value));
    } else {
      setS(value);
      dispatch(dispatchSkills(value));
      // save into server cache
      saveIntoServerCache("technicalSkills", value);
    }
  };

  const handlesetp = (event, value) => {
    if (
      window.location.pathname === "/user/profile/update/skills" &&
      profile.message !== null && profile.message?.CompletionPercentage >= 95
    ) {
      // update soft dkills here
      dispatch(updateSoft_skills(value));
    } else {
      setP(value);
      dispatch(dispatchPskills(value));
      saveIntoServerCache("personalSkills", value);
    }
  };
  // console.log("Tech skill", s);
  const handleInputChange = (event, value) => {
    if (!value.includes(",")) {
      return false;
    }
    const newOptions = value
      .split(",")
      .map((item) => item.trim())
      .filter((item) => item !== "")
      .filter((item) => !selectedOptions.includes(item));
    // console.log("new option", newOptions);
    if (newOptions.length === 0) {
      return false;
    }

    const newArray = [...newOptions, ...selectedOptions];
    setSelectedOptions(newArray);
  };

  const handleInputChangep = (event, value) => {
    if (!value.includes(",")) {
      return false;
    }
    const newOptions = value
      .split(",")
      .map((item) => item.trim())
      .filter((item) => item !== "")
      .filter((item) => !selectedOptionsp.includes(item));
    // console.log(newOptions);
    if (newOptions.length === 0) {
      return false;
    }
    const newArray = [...newOptions, ...selectedOptionsp];
    setSelectedOptionsp(newArray);
  };

  let instruction;
  if (window.outerWidth <= 450) {
    instruction = `Delete or add by typing the new skills you want to add and click "Endter or Go" on your mobile keyboard;`;
  } else {
    instruction = `Delete or add by typing the new skills you want to add and press "Enter" key`;
  }

  const handleNextStep = () => {
    if (window.location.pathname === "/user/profile/update/skills" &&
      profile.message !== null && profile.message?.CompletionPercentage >= 95) {
        // console.log("proplang", proplang, "proplangRate", proplangRate)
      if (proplang !== "" || proplangRate !== "") {
        setTimeout(() => {
          toast.error("Please click the 'Add language' button before you proceed", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }, 50);
      } else {
        dispatch(updateSkills(s))
        dispatch(updateSoft_skills(p));
        dispatch(updateProfileLanguage(resumeLanguage));
        navigate(`/user/profile/:${userInfo.user.profile_id}`);
      }
    } else if (window.location.pathname === `/form/${cv.id}/update`) {
      if (generated.message !== null) {
        let newV = generated.message;
        let t = s;
        let pS = p;
        newV.Technical_skills = s;
        newV.Personal_skills = p;
        dispatch(generate_my_cv(newV));
        navigate("/allCv");
      } else {
        // as you update the skills, the redux store & cache are receiving it, becase upon getting to the allCv route, you get all cache infor from server.
        navigate("/allCv");
      }
    } else {
      if (profile.message == null || (profile.message?.CompletionPercentage !== undefined && profile.message?.CompletionPercentage < 95)) {
        // console.log("p", p);
        if ((s === null || s?.length === 0) || (p === null || p?.length === 0) || (resumeLanguage === null || resumeLanguage.length === 0)) {
          setTimeout(() => {
            toast.error("Please fill all fields (Skills and language)", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }, 50);
        } else {
          if (proplang !== "" || proplangRate !== "") {
            setTimeout(() => {
              toast.error("Please click the 'Add language' button before you proceed", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }, 50);
          } else {
            if ((window.location.pathname === "/user/profile/update/skills" && profile.message !== null && profile.message?.CompletionPercentage < 95) || process !== null) {
              setSaving(true);
            }
            dispatch(saveSkills(s, p, secret.secret, resumeLanguage));
            // save into server cache
            saveIntoServerCache("technicalSkills", s);
            saveIntoServerCache("personalSkills", p);
            if ((window.location.pathname === "/user/profile/update/skills" && profile.message !== null && profile.message?.CompletionPercentage < 95) || process !== null) {
              // setEighty(true);
              setProcessModal(true);
            } else {
              props.handleStepChange();
            }
          }
        }
      } else {
        if ((s === null || s?.length === 0) || (p === null || p?.length === 0)) {
          setTimeout(() => {
            toast.error("Please fill the technical and personal skills.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }, 50);
        } else {
          if (proplang !== "" || proplangRate !== "") {
            setTimeout(() => {
              toast.error("Please click the 'Add language' button before you proceed", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }, 50);
          } else {
            dispatch(convertSkills({ Technical_skills: s, Personal_skills: p, Lang: [{ lang: language }] }, "resume"));
            // saveIntoServerCache("step", 4)
            if ((window.location.pathname === "/user/profile/update/skills" && profile.message !== null && profile.message?.CompletionPercentage < 95) || process !== null) {
              // setEighty(true);
              setProcessModal(true);
            } else {
              props.handleStepChange();
            }
          }
        }
      }
      // save into server cache
      // saveIntoServerCache("technicalSkills", s);
      // saveIntoServerCache("personalSkills", p);

      // navigate(`/user/profile/:${userInfo.user.profile_id}`);
    }
  };

  const handleDelete = (chipToDelete) => {
    let newS = new Array();
    if (s?.length > 1) {
      newS.push(...s.filter((element) => {
        if (element !== chipToDelete) return element;
      }));
      // console.log("new arr", newS);
      setS(newS);
      dispatch(dispatchSkills(newS));
      saveIntoServerCache("technicalSkills", newS);
      if (window.location.pathname === "/user/profile/update/skills" &&
        profile.message !== null && profile.message?.CompletionPercentage >= 95) {
        dispatch(updateSkills(newS))
      }
    } else {
      setTimeout(() => {
        toast.error("You cannot delete all your skills", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
    }
  };

  const handleDeleteP = (chipToDelete) => {
    let newP = new Array();
    if (p?.length > 1) {
      newP.push(...p.filter((element) => {
        if (element !== chipToDelete) return element;
      }));
      // console.log("new arr", newP);
      setP(newP);
      dispatch(dispatchPskills(newP));
      saveIntoServerCache("personalSkills", newP);
      if (window.location.pathname === "/user/profile/update/skills" &&
        profile.message !== null && profile.message?.CompletionPercentage >= 95) {
        dispatch(updateSoft_skills(newP));
      }
    } else {
      setTimeout(() => {
        toast.error("You cannot delete all your soft skills", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
    }
  };

  // const [ reviewedS, setReviewedS ] = useState([]);
  const handleKeyEnter = (e) => {
    if (e.key == "Enter") {
      e.preventDefault();
      // console.log("event", e.target.value)
      // console.log("useffect s", s)
      let sFilter = s.map(e => {
        if (e.value !== undefined) {
          return e.value
        } else {
          return e
        }
      })
      // console.log("sFilter ", sFilter)
      // console.log("before s", s)
      setS(s => [...s, e.target.value]);
      dispatch(dispatchSkills([...s, e.target.value]));
      if (window.location.pathname === "/user/profile/update/skills" &&
        profile.message !== null && profile.message?.CompletionPercentage >= 95) {
        dispatch(updateSkills([...s, e.target.value]))
      }
      // save into server cache
      saveIntoServerCache("technicalSkills", [...s, e.target.value]);
      // console.log("after s", s)
      $('[name="tech"]').val("")
    } else {
      let mainValue = $('[name="technicalSkills"]').val();
      // console.log("useffect s", s)
      let sFilter = s?.map(e => {
        if (e.value !== undefined) {
          return e.value
        } else {
          return e
        }
      })
      // console.log("sFilter ", sFilter)
      // console.log("before s", s);
      let sValue;
      if (mainValue !== "") {
        sValue = [ ...sFilter, mainValue ];
      }
      // console.log("svalue", sValue);
      sValue = sValue?.map(e => e.toLowerCase());
      sValue = [... new Set(sValue)];
      sValue = sValue?.map(e => capitalizeFirstChar(e));
      setS([...sValue]);
      dispatch(dispatchSkills([...sValue]));
      if (window.location.pathname === "/user/profile/update/skills" &&
        profile.message !== null && profile.message?.CompletionPercentage >= 95) {
        dispatch(updateSkills([...sValue]))
      }
      // save into server cache
      saveIntoServerCache("technicalSkills", [...sValue]);
      // console.log("after s", s)
      $('[name="technicalSkills"]').val("");
    }
  }

  const handleKeyEnterP = (e) => {
    if (e.key == "Enter") {
      e.preventDefault();
      // console.log("event", e.target.value)
      // console.log("useffect s", p)
      let pFilter = p.map(e => {
        if (e.value !== undefined ) {
          return e.value
        } else {
          return e
        }
      })
      // console.log("pFilter ", pFilter)
      // console.log("before p", p);
      setP(p => [...p, e.target.value]);
      dispatch(dispatchPskills([...p, e.target.value]));
      if (window.location.pathname === "/user/profile/update/skills" &&
        profile.message !== null && profile.message?.CompletionPercentage >= 95) {
        dispatch(updateSoft_skills([...p, e.target.value]));
      }
      saveIntoServerCache("personalSkills", [...p, e.target.value]);
      // console.log("after p", p)
      $('[name="personal_skills"]').val("")
    } else {
      let mainValue = $('[name="softSkills"]').val();
      let pFilter = p.map(e => {
        if (e.value !== undefined) {
          return e.value
        } else {
          return e
        }
      });

      // console.log("pFilter ", pFilter);
      // console.log("before p", p);
      let pValue;
      if (mainValue !== "") {
        pValue = [ ...pFilter, mainValue ];
      }
      pValue = pValue?.map(e => e.toLowerCase());
      pValue = [... new Set(pValue)];
      pValue = pValue?.map(e => capitalizeFirstChar(e));
      setP([...pValue]);
      dispatch(dispatchPskills([...pValue]));
      if (window.location.pathname === "/user/profile/update/skills" &&
        profile.message !== null && profile.message?.CompletionPercentage >= 95) {
        dispatch(updateSoft_skills([...pValue]));
      }
      saveIntoServerCache("personalSkills", [...pValue]);
      // console.log("after p", p)
      $('[name="softSkills"]').val("")
    }
  }

  let showModal = showLanguageModal ? createPortal(
    <div className="top-0 left-0 fixed bottom-0 right-0 z-10 h-screen flex justify-center items-center md:bg-black md:bg-opacity-70 backdrop-blur-[2px] sm:bg-white xsm:bg-white">
      <SelectLanguage handleClick={() => setShowLanguageModal(false)} back="/generateCV" />
    </div>, document.getElementById("Language")
  ) : null;

  // const twenty = !saving && twentyModal ? <TwentyPercentConfirmationGamifiedModal profileLink={`/user/profile/:${userInfo.user.profile_id}`} handleStepChange={props.handleStepChange} /> : null;
  // const fourty = !saving && fourtyModal ? <FortyPercentConfirmationGamifiedModal profileLink={`/user/profile/:${userInfo.user.profile_id}`} handleStepChange={props.handleStepChange} /> : null;
  // const sixty = !saving && sixtyModal ? <SixtyPercentConfirmationGamifiedModal profileLink={`/user/profile/:${userInfo.user.profile_id}`} handleStepChange={props.handleStepChange} /> : null;
  const eighty = !saving && eightyModal ? <SkillsCompleteGamifiedModal profileLink={`/user/profile/:${userInfo.user.profile_id}`} handleStepChange={props.handleStepChange} /> : null;
  // const nintyFive = !saving && nintyFiveModal ? <NinetyFivePercentConfirmationGamifiedModal profileLink={`/user/profile/:${userInfo.user.profile_id}`} handleStepChange={props.handleStepChange} /> : null;
  const savingloader = saving ? <LoaderModal text="Saving your information" /> : null;

  // let Acknowledge = eighty ? <EightyPercentConfirmationGamifiedModal  profileLink={`/user/profile/:${userInfo.user.profile_id}`} handleStepChange={props.handleStepChange} /> : null;

  return (
    <>
      <HelmetProvide
        title="Moil | Create Your Resume"
        description="Build your professional resume with Moil's easy-to-use resume creation form. Showcase your skills, experience, and qualifications to potential employers."
        className="notranslate"
      />
      <div className={`grid grid-cols-auto md:grid-cols-smdash ${sidebar ? 'cont:grid-cols-dash' : ''} grid-rows-dash`}>
        <TopBar topBarShowMenu={true} />
        <SideBar />
        <div className="bg-[#FAFAFA] px-3 lg:pl-6 py-8 w-full overflow-y-scroll ScrollOnly h-calc-70 flex justify-center">
          <div className="w-full xtraxl:max-w-calculate flex flex-col items-center">

            <p class="max-w-[900px] xtraxl:max-w-calculate w-full text-[24px] font-semibold leading-9 text-left text-black text-opacity-87">
              Complete your profile
            </p>
            <div className="max-w-[900px] xtraxl:max-w-calculate w-full py-5 rounded-2xl flex-col justify-start items-start gap-5 inline-flex">
              <div className="tabarea justify-start items-center gap-6 inline-flex w-full flex-shrink-0 max-w-[calc(100vw-88px)] overflow-hidden">
                <Tab
                  // label="Personal Information"
                  label="Personal Information"
                  current={false}
                  completed={true}
                  num="1"
                />
                <Tab num="2" current={false} label="Experience" completed={true} />
                <Tab num="3" current={false} label="Education" completed={true} />
                <Tab num="4" current={true} label="Skill/Language" completed={false} />
                <Tab num="5" current={false} label="Professional Summary" completed={false} />
              </div>

              <p class="text-base font-medium leading-6 text-left text-[#0D0D0D]">
                Tell potential employers about your skills and languages you speak<br />
                Put your best foot forward so that employers can easily consider you.
              </p>
              {/* <div className="sm:mt-2 mb-4 py-2 px-4 sm:mb-2 sm:py-0">
                <h6 className="top-2 text-sm sm:text-[18px] lg:text-[24px] text-pry-920 leading-normal">
                  {instruction}
                </h6>
              </div> */}
              <div className="flex-col justify-start items-start gap-4 flex w-full rounded-[16px] bg-white resume_page py-6 px-4 ">
                <p class="text-lg font-semibold leading-8 text-center text-[#5843BD]">
                  We have added skills based on your experience, please review and continue to language.
                </p>
                <p class="text-xl font-semibold leading-8 text-left text-[#5843BD]">
                  Technical Skills
                </p>

                <div className="flex-col justify-start items-start gap-12 flex w-full">
                  <div className="flex-col justify-start items-start gap-3 flex w-full">
                    <div className="flex gap-3 items-center">
                      <div className="p-[3px] w-1 h-1 rounded-full bg-black"></div>
                      <label
                        className="text-black text-base font-medium"
                        for="technicalSkill"
                      >
                        What are some technical or job-specific skills you have
                      </label>
                    </div>

                    <TextInput
                      placeholder="Type a skill here"
                      id="technicalSkills"
                      name="technicalSkills"

                    />

                    <AddMore text="Skill" onClick={handleKeyEnter} />

                    <div className="flex gap-y-4 flex-col">
                      <p class="text-black text-base font-medium leading-normal text-left">
                        You have added
                      </p>

                      <div className="flex flex-wrap gap-3">
                        {s?.slice(0, technicalSkill)?.map(e => (
                          <AddedItem
                            item={e.value !== undefined ? e.value : e}
                            plain={true}
                            // color={exp?.Review}
                            // onClick={() => handleClick(e)}
                            delete={() => handleDelete(e)}
                          />
                        ))}
                      </div>

                      {s?.length > 4 && <ViewProp length={technicalSkill} normalLength={s?.length} setAllowed={setTechnicalSkill} />}
                    </div>

                  </div>
                  <div className="flex-col justify-start items-start gap-12 flex w-full">
                    <div className="flex-col justify-start items-start gap-3 flex w-full">
                      <div className="flex gap-3 items-center">
                        <div className="p-[3px] w-1 h-1 rounded-full bg-black"></div>
                        <label
                          className="text-black text-base font-medium"
                          for="softSkill"
                        >
                          What are some soft skills you’re proud of?
                        </label>
                      </div>

                      <TextInput
                        placeholder="Type a skill here"
                        id="softSkills"
                        name="softSkills"

                      />

                      <AddMore text="Skill" onClick={handleKeyEnterP} />

                      <div className="flex gap-y-4 flex-col">
                        <p class="text-black text-base font-medium leading-normal text-left">
                          You have added
                        </p>

                        <div className="flex flex-wrap gap-3">
                        {p?.slice(0, softSkill)?.map(e => (
                          <AddedItem
                            item={e.value !== undefined ? e.value : e}
                            plain={true}
                            // color={exp?.Review}
                            // onClick={() => handleClick(e)}
                            delete={() => handleDeleteP(e)}
                          />
                        ))}
                        </div>

                        {p?.length > 4 && <ViewProp length={softSkill} normalLength={p?.length} setAllowed={setSoftSkill} />}
                      </div>

                    </div>
                  </div>
                </div>
                {/* <h6 className="top-2 text-sm sm:text-[18px] lg:text-[24px] text-pry-920 leading-normal">
                  Next please select your language and proficiency level and click “add language.
                </h6> */}


              </div>
              <UserLanguage setPropLang={setPropLang} setPropLangRate={setPropLangRate} />

              <div className="self-stretch  justify-between items-start gap-4 inline-flex">
                {path === true ? null : (
                  <button
                    className="pr-2 md:pr-0 md:px-5 py-3 rounded-lg self-end justify-center items-center gap-1 md:gap-2.5 flex w-max max-w-[238px]"
                    onClick={props.handleStepChangeBack}

                  >
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16 8L17.43 9.393L11.85 15H24V17H11.85L17.43 22.573L16 24L8 16L16 8Z" fill="#FF6633" />
                      <path d="M16 30C18.7689 30 21.4757 29.1789 23.778 27.6406C26.0803 26.1022 27.8747 23.9157 28.9343 21.3576C29.9939 18.7994 30.2712 15.9845 29.731 13.2687C29.1908 10.553 27.8574 8.05845 25.8995 6.10051C23.9416 4.14258 21.447 2.80921 18.7313 2.26901C16.0155 1.72882 13.2006 2.00607 10.6424 3.06569C8.08426 4.12532 5.89776 5.91973 4.35942 8.22202C2.82108 10.5243 1.99999 13.2311 1.99999 16C2.00423 19.7117 3.48059 23.2702 6.10518 25.8948C8.72977 28.5194 12.2883 29.9958 16 30ZM16 4.00001C18.3734 4.00001 20.6934 4.70379 22.6668 6.02237C24.6402 7.34095 26.1783 9.21509 27.0865 11.4078C27.9948 13.6005 28.2324 16.0133 27.7694 18.3411C27.3064 20.6689 26.1635 22.8071 24.4853 24.4853C22.807 26.1635 20.6688 27.3064 18.3411 27.7694C16.0133 28.2325 13.6005 27.9948 11.4078 27.0866C9.21508 26.1783 7.34094 24.6402 6.02236 22.6668C4.70378 20.6935 3.99999 18.3734 3.99999 16C4.0037 12.8185 5.26917 9.76845 7.5188 7.51882C9.76844 5.26918 12.8185 4.00371 16 4.00001Z" fill="#FF6633" />
                    </svg>


                    <span className="text-center text-sm md:text-base text-[#FF6633] text-base font-medium whitespace-nowrap">
                      Previous
                    </span>
                  </button>
                )}
                <button
                  className="px-3 md:px-5 py-3 bg-indigo-700 rounded-lg self-end shadow justify-center items-center gap-2 md:gap-2.5 flex max-w-[238px]"
                  onClick={handleNextStep}

                >
                  {!path ? <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 8L14.57 9.393L20.15 15H8V17H20.15L14.57 22.573L16 24L24 16L16 8Z" fill="white" />
                    <path d="M16 30C13.2311 30 10.5243 29.1789 8.22202 27.6406C5.91973 26.1022 4.12532 23.9157 3.06569 21.3576C2.00607 18.7994 1.72882 15.9845 2.26901 13.2687C2.80921 10.553 4.14258 8.05845 6.10051 6.10051C8.05845 4.14258 10.553 2.80921 13.2687 2.26901C15.9845 1.72882 18.7994 2.00607 21.3576 3.06569C23.9157 4.12532 26.1022 5.91973 27.6406 8.22202C29.1789 10.5243 30 13.2311 30 16C29.9958 19.7117 28.5194 23.2702 25.8948 25.8948C23.2702 28.5194 19.7117 29.9958 16 30ZM16 4.00001C13.6266 4.00001 11.3066 4.70379 9.33316 6.02237C7.35977 7.34095 5.8217 9.21509 4.91345 11.4078C4.0052 13.6005 3.76756 16.0133 4.23058 18.3411C4.69361 20.6689 5.83649 22.8071 7.51472 24.4853C9.19296 26.1635 11.3312 27.3064 13.6589 27.7694C15.9867 28.2325 18.3995 27.9948 20.5922 27.0866C22.7849 26.1783 24.6591 24.6402 25.9776 22.6668C27.2962 20.6935 28 18.3734 28 16C27.9963 12.8185 26.7308 9.76845 24.4812 7.51882C22.2316 5.26918 19.1815 4.00371 16 4.00001Z" fill="white" />
                  </svg> : <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 4V16C18 16.55 17.8043 17.021 17.413 17.413C17.0217 17.805 16.5507 18.0007 16 18H2C1.45 18 0.979333 17.8043 0.588 17.413C0.196667 17.0217 0.000666667 16.5507 0 16V2C0 1.45 0.196 0.979333 0.588 0.588C0.98 0.196667 1.45067 0.000666667 2 0H14L18 4ZM16 4.85L13.15 2H2V16H16V4.85ZM9 15C9.83333 15 10.5417 14.7083 11.125 14.125C11.7083 13.5417 12 12.8333 12 12C12 11.1667 11.7083 10.4583 11.125 9.875C10.5417 9.29167 9.83333 9 9 9C8.16667 9 7.45833 9.29167 6.875 9.875C6.29167 10.4583 6 11.1667 6 12C6 12.8333 6.29167 13.5417 6.875 14.125C7.45833 14.7083 8.16667 15 9 15ZM3 7H12V3H3V7ZM2 4.85V16V2V4.85Z" fill="white" />
                  </svg>
                  }


                  <span className="text-center text-white text-sm md:text-base font-medium whitespace-nowrap">
                    {!path ? 'Save and Continue' : 'save to profile'}
                  </span>
                </button>
              </div>
              {/* {Acknowledge} */}
              {/* {twenty}
              {fourty}
              {sixty} */}
              {eighty}
              {/* {nintyFive} */}
              {savingloader}
              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
