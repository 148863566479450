import React from 'react';
import { useState, useRef, useContext } from 'react';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import {
  existing_resume_features,
  user_CV_state_back_to_null,
} from '../../../Redux/ActionCreator';
import { createPortal } from 'react-dom';
import LoaderModal from '../LoaderModal';
import BackButton from '../back_button';

import { useAnalytics } from '../../../context/AnalyticsContext';

const UploadResumeModal = (props) => {
  const analytics = useAnalytics();
  const [stat, setStat] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(true);
  const dispatch = useDispatch();
  const [userFile, setUserFile] = useState('');
  const navigate = useNavigate();
  const uploadInputRef = useRef();
  let labelClasses =
    'flex items-center text-base font-semibold text-slate-700 ml-[24px]';

  const upload_status = useSelector((prev) => prev.existingCV);
  const user = useSelector(e => e.userInfo);

  const savedResume = useSelector((e) => e.saved_cv);
  const details = savedResume.message;

  let [select, setSelect] = useState(false);
  let [uploadPc, setUploadPc] = useState(false);

  const handleUploadPc = () => {
    if (select == true) {
      setSelect(false);
    }
    setUploadPc(true);
  }

  const handleSelect = () => {
    if (uploadPc == true) {
      setUploadPc(false);
    }
    setSelect(true);
  }

  // improvise job
  let job = {
    indeed: false
  };

  const formSubmissionHandle = (e) => {
    e.preventDefault();
    //Sending the file to the backend here
    const file = uploadInputRef.current?.files[0];
    // console.log("file", file);
    if (file === undefined) {
      // console.log("file", file)
      setTimeout(() => {
        toast.error("Select a file to upload", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
    } else {
      const formData = new FormData();
      formData.append('uploadPDF', file);
      setStat(true);
      dispatch(existing_resume_features(formData));
    }
  };

  const triggerUploadFile = (e) => {
    e.preventDefault();
    uploadInputRef.current.click();
  };
  const fileChangeHandle = (e) => {
    e.preventDefault();
    // console.log(1);
		// console.log("target", e.target.value)
		// console.log("uploadInputRef.current?.files[0]", uploadInputRef.current?.files[0])
    let enteredFileName = uploadInputRef.current?.files[0]?.name;
    if (enteredFileName) {
      setUserFile(enteredFileName);
    }
  };

  const skipHandle = (e) => {
    navigate('/generateCV');
  };

  const handleClick = () => {
    navigate("/user/dashboard");
  }

  const handleContinue = () => {
    analytics.track("Resume upload continue btn", {
      Type: "Employee",
      time: new Date(),
      Email: user.user?.email,
      userId: user.user?._id,
    });
    dispatch(user_CV_state_back_to_null());
  }

  let text = "Extracting CV text, this can take a couple of minutes..."

  if (details.length == 0) {
    return (
      <div>
        {showWarningModal &&
          createPortal(
            <div onClick={props.handleClose} className="fixed top-0 left-0 bottom-0 right-0 z-10 bg-tet-600 overflow-y-scroll min-h-screen py-7 backdrop-blur-[2px] flex justify-center items-center">
              <section onClick={(e) => e.stopPropagation()} className="bg-white sm:max-w-[500px] min-w-[350px] w-[80%] m-auto rounded-lg p-10">
                <form
                  onSubmit={formSubmissionHandle}
                  className="bg-white rounded-xl">
                  <div className="flex flex-col gap-y-5 justify-between mb-4">
                    <div>
                      <p className={labelClasses}>Upload Resume</p>
                      <span className="hr-span"></span>
                    </div>

                    <p class="font-sans text-xl font-semibold leading-6 text-left text-black">
                      Upload your existing resume?
                    </p>

                    <p class="font-sans text-base font-normal leading-6 text-left text-[#898989]">
                      In order for us to create a profile/resume for you, please upload an existing resume.
                    </p>

                    <div>
                      {userFile && (
                        <p class="p-2 rounded-[4px] w-max min-w-[200px] max-w-full text-sm font-medium italic leading-5 text-left text-[#393939] bg-[#DCDCDC80]">
                          {userFile}
                        </p>
                      )}
                    </div>

                  </div>
                  {upload_status.errMess !== null ? (
                    <h6
                      style={{
                        color: 'red',
                        marginTop: '5px',
                        textAlign: 'center',
                        fontSize: "10px"
                      }}>
                      {upload_status.errMess}
                    </h6>
                  ) : null}
                  <div className="flex flex-col sm:flex-row gap-y-4 items-center justify-between">
                    <label className="w-full sm:w-max block flex-2 relative">
                      <div className="">
                        <input
                          ref={uploadInputRef}
                          onInput={fileChangeHandle}
                          type="file"
                          accept=".doc, .docx, .pdf"
                          className="opacity-0 absolute -z-[1] w-full h-full"
                          name="uploadPDF"
                        />
                        {(
                          <button
                            type="button"
                            onClick={triggerUploadFile}
                            className="px-5 w-full sm:w-max py-3 gap-2.5 rounded-lg justify-center border border-[#5843BD] bg-[#5843BD1A] flex gap-1 items-center">
                            <span className='rounded-[2px] bg-[#5843BD]'>
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 19V5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M19 12L12 5L5 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                            </span>
                            <span class="text-base font-medium leading-6 text-center text-[#5843BD]">
                              Upload Resume
                            </span>
                          </button>
                        )}

                      </div>
                    </label>
                    <button
                      type="submit"
                      className="w-full sm:w-max px-5 py-2.5 gap-2.5 rounded-lg bg-[#5843BD] animate-none transition-all duration-300 text-white ease-out"
                      onClick={handleContinue}>
                      Continue
                    </button>
                  </div>


                  {/* Error component */}

                  <div>
                    <ToastContainer />
                    {stat === true ? (
                      upload_status.errMess === null ? <LoaderModal text={text} /> : null
                    ) : null}
                  </div>
                </form>
              </section>
            </div>,
            document.getElementById('modal')
          )}
      </div>
    );
  }
};

export default UploadResumeModal;
