import React, { useState, useEffect, useContext } from "react";
import BasicSelect from "../select/select";
import TopBar from "../../topbar/Top-Bar";
import SideBar from "../../sidebar/sidebar";
import Tab from "../tab/tab";
import { useDispatch, useSelector } from "react-redux";
import { dispatchAward, saveAward } from "../../../Redux/ActionCreator";
import AddedItem from "../added_item/added_item";
import AddMore from "../add_more/add_more";
import $ from "jquery";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import HelmetProvide from "../../common/helmet";
import { handleDeleteValue } from "../utils/handleDelete";
import { getOneFromServerCache, saveIntoServerCache } from "../../../utils/saveIntoCache";
import { generate_my_cv } from "../../../Redux/ActionCreator";

export default function UserAwardAndHonor(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector(e => e.profile);
  const existingCv = useSelector((e) => e.existingCV);
  const message = existingCv.message;
  const award_data = useSelector((awd) => awd.award);
  let [chips, setChips] = useState([]);
  const generated = useSelector((e) => e.generateCV);
  const profile1 = useSelector((e) => e.login);
  let stat = profile1.profile_status.profile_status;
  const secret = useSelector((prev) => prev.secret);
  const cv = useSelector((prev) => prev.cvId);
  const userInfo = useSelector((e) => e.userInfo);
  let [award, setaward] = useState([]);
  const [awardYear, setAwardYear] = useState("");
  const [obj, setObj] = useState();

  useEffect(() => {
    if (typeof props.award === "string") {
      setChips([props.award]);
      setaward([props.award]);
    }
  }, []);

  useEffect(() => {
    let cacheInfo =  getOneFromServerCache("award")
    cacheInfo.then(res => res.json())
    .then(res => {
     // console.log("server cache value", res);
      if (res.data !== null && res.data !== undefined) {
        let resObj = JSON.parse(res.data);
       // console.log("respObj", resObj);
        dispatch(dispatchAward(resObj));
        setChips(resObj);
        setaward(resObj);
      }
     // console.log("Done");
    })
    if (!window.location.pathname.includes("update")) {
    //  console.log("was called")
      saveIntoServerCache("step", 7);
    }
  }, []);


  useEffect(() => {
    if (window.location.pathname === `/form/${cv.id}/update`) {
     // console.log("got here");
      if (generated.message?.Award !== null) {
        setChips(generated.message?.Award);
        setaward(generated.message?.Award);
      }
    } else {
      if (award_data.award !== null) {
        setChips(award_data.award);
        setaward(award_data.award);
      } else if (message !== null) {
        if (message.Award === "" || message.Award == null) {
          chips = [];
          award = [];
         // console.log("WOWWWWWW");
        } else if (message.Award !== "") {
          setChips(message.Award);
          setaward(message.Award);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (obj !== undefined) {
      dispatch(generate_my_cv(obj));
    }
  }, [obj]);
  const handleClickOperation = (desiredKey) => {
    let a = award;
    let desiredObject = a.find((item) => item.key === desiredKey.key);
    let w = a.filter((chip) => chip.key !== desiredObject.key);

    $('[name="award_name"]').val(desiredObject.Award_name);
    $('[name="award_giver"]').val(desiredObject.Award_giver);
    $('[name="r_year"]').val(desiredObject.Award_year);

    const startYear = desiredObject.Award_year;
    const firstYear = new Date(startYear);
    let y = firstYear.getTime();
   // console.log("Mil year", y);
    setAwardYear(y);

   // console.log("desired object", desiredObject);
    w.forEach((element) => {
      element.key = w.indexOf(element);
    // console.log("award key", w.indexOf(element));
    });
    setaward(w);
    setChips(w);
    dispatch(dispatchAward(w));
    desiredObject = [];

   // console.log("inner chips", chips);
   // console.log("inner award", w);
  };

  // handleClick function
  const handleClick = (desiredKey) => {
    const awrd = $('[name="award_name"]').val();
    if (awrd !== "") {
      handleAdd(); // add already mapped values back to the work array if the client/user forget to click add, this is so, so as to save users experience.
      handleClickOperation(desiredKey);
    } else {
      handleClickOperation(desiredKey);
    }
  };

  const handleDelete = (chipToDelete) => {
    const newawd = handleDeleteValue(award, chipToDelete, "key")
    setaward(newawd);
    setChips(newawd);
    dispatch(dispatchAward(newawd));
    // console.log("chips", chips);
    // console.log("delete cert", award);
  };

  const handleAdd = () => {
    if ($('[name="award_name"]').val() === "") {
      setTimeout(() => {
        toast.success("Add award", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
    } else {
      const date2 = new Date(awardYear);

      const year1 = date2.getFullYear();

      const newAwd = award;
      let k = award.length;
      const data = {
        key: k,
        Award_name: $('[name="award_name"]').val(),
        Award_giver: $('[name="award_giver"]').val(),
        Award_year: year1,
        Review: true
      };
      newAwd.push(data);
      setaward(newAwd);
      setChips(newAwd);
      dispatch(dispatchAward(newAwd));
      $('[name="award_name"]').val("");
      $('[name="award_giver"]').val("");
      setAwardYear("");
    }
  };

  const handleNextBtn = () => {
    if (window.location.pathname === `/form/${cv.id}/update`) {
      if ($('[name="award_name"]').val() !== "") {
        handleAdd();
      }
      let generated_value = generated.message;
      generated_value.Award = award;
      setObj(generated_value);
      navigate("/allCv");
    } else {
      if ($('[name="award_name"]').val() !== "") {
        setTimeout(() => {
          toast.error("Add the award you just filled.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }, 50);
      } else {
        if (award === "") {
          setaward([]);
        }
        if (profile.message == null || (profile.message?.CompletionPercentage !== undefined && profile.message?.CompletionPercentage < 95)) {
          if (award == "") {
            dispatch(saveAward([], secret.secret));
          } else {
            dispatch(saveAward(award, secret.secret));
            saveIntoServerCache("award", award);
          }
        }
        if (award.length > 0) {
          let reviewInfo = award.filter(e => !e.hasOwnProperty("Review"));
          if (reviewInfo.length > 0) {
            // throw an error 
            setTimeout(() => {
              toast.error("Please review the informations with red borders at the top.", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }, 50);
          } else {
            saveIntoServerCache("award", award);
            props.handlejump(4);
          }
        } else {
          props.handlejump(4);
        }
      }
    }
  };

  const handleBackBtn = () => {
    if ($('[name="award_name"]').val() !== "") {
      setTimeout(() => {
        toast.error("Add the award you just filled.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
    } else {
        props.handlejump(4);
    }
  };

  return (
    <>
      <HelmetProvide
        title="Moil | Create Your Resume"
        description="Build your professional resume with Moil's easy-to-use resume creation form. Showcase your skills, experience, and qualifications to potential employers."
        className="notranslate"
      />
      <div className="grid grid-cols-auto md:grid-cols-smdash cont:grid-cols-dash grid-rows-dash">
        <TopBar topBarShowMenu={true} />
        <SideBar />
        <div className="px-3 lg:pl-6 py-8 w-full overflow-y-scroll ScrollOnly h-calc-70 flex justify-center">
          <div className="w-full xtraxl:max-w-calculate">
            <div className="w-full max-w-[793px] xtraxl:max-w-calculate h-[54px] bg-white bg-opacity-50 rounded-[40px] shadow border border-indigo-400 border-opacity-30 flex flex-col justify-center">
              <p className="text-stone-950 text-base md:text-[28px] font-semibold ml-12 max-[300px]:ml-6">
                Resume Text Capturing Form
              </p>
            </div>
            <p className="w-full max-w-[741px] xtraxl:max-w-calculate mt-2 px-3 lg:pl-6">
              <span className="text-black text-opacity-50 text-[14px] md:text-base font-normal ">
                Complete this page with any awards you have received in the past. If you have no awards, click on{" "}
                <span className="text-orange-500 text-[14px] md:text-base font-medium">
                  'Skip this page'{" "}
                </span>
                at the bottom of the page.
              </span>
            </p>
            <div className="max-w-[800px]  xtraxl:max-w-calculate w-full mt-8 p-8 bg-white bg-opacity-80 rounded-2xl shadow border border-indigo-400 border-opacity-30 flex-col justify-start items-start gap-16 inline-flex">
              <div className="tabarea justify-start items-center gap-6 inline-flex w-full flex-shrink-0 max-w-[calc(100vw-88px)] overflow-hidden">
                <Tab
                  num="1"
                  current={false}
                  completed={true}
                  className="max-[420px]:hidden"
                />
                <Tab
                  num="2"
                  current={false}
                  completed={true}
                  className="max-[420px]:hidden"
                />
                <Tab
                  num="3"
                  current={false}
                  completed={true}
                  className="max-[360px]:hidden"
                />
                <Tab
                  num="4"
                  current={false}
                  completed={true}
                  className="max-[2800px]:hidden"
                />
                <Tab num="5" current={false} completed={true} />
                <Tab num="6" current={false} completed={true} />
                <Tab
                  // label="Awards and Honors"
                  label="Awards and Honors"
                  current={true}
                  completed={false}
                />
                <Tab num="8" current={false} completed={false} />
              </div>
              <div className="flex-col justify-start items-start gap-12 flex w-full">

                <div className="flex-col justify-start items-start gap-3 flex w-full">
                    <div className="flex flex-wrap gap-3">
                      {Array.isArray(chips) === true ? (
                        <>
                          {chips?.map((awd, key) => (
                            <AddedItem
                              item={awd.Award_name}
                              key={key}
                              color={awd.Review}
                              onClick={() => handleClick(awd)}
                              delete={() => handleDelete(awd)}
                            />
                          ))}
                        </>
                      ) : null}
                    </div>
                  </div>

                <div className="flex-col justify-start items-start gap-2 flex w-full">
                  <div className="flex gap-3 items-center">
                    <div className="px-1 w-2 h-2 rounded-full bg-black"></div>
                    <label
                      className="text-black text-base font-medium"
                      for="awardTitle"
                    >
                      Title of the Award received
                    </label>
                  </div>
                  <input
                    type="text"
                    className="p-4 w-full rounded-lg border border-indigo-400 border-opacity-30"
                    placeholder="Award title"
                    id="awardTitle"
                    name="award_name"
                  />
                </div>
                <div className="flex-col justify-start items-start gap-2 flex w-full">
                  <div className="flex gap-3 items-center">
                    <div className="px-1 w-2 h-2 rounded-full bg-black"></div>
                    <label
                      className="text-black text-base font-medium"
                      for="awardPresenter"
                    >
                      Who gave it to you ?
                    </label>
                  </div>
                  <input
                    type="text"
                    className="p-4 w-full rounded-lg border border-indigo-400 border-opacity-30"
                    placeholder="Enter name here"
                    id="awardPresenter"
                    name="award_giver"
                  />
                </div>

                <div className="flex-col justify-start items-start gap-2 flex w-full">
                  <div className="flex gap-3 items-center">
                    <div className="px-1 w-2 h-2 rounded-full bg-black"></div>
                    <label
                      className="text-black text-base font-medium"
                      for="lastName"
                    >
                      When did you receive it ?
                    </label>
                  </div>
                  <div className="w-full flex flex-col sm:flex-row gap-2">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker", "DatePicker", "DatePicker"]}
                      >
                        <DatePicker
                          label="Year"
                          views={["year"]}
                          value={dayjs(awardYear)}
                          onChange={(event) => setAwardYear(event)}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                  <div className="flex-col justify-start items-start gap-3 flex w-full">
                    <AddMore text="award" onClick={handleAdd} />
                    <p className="text-red-500 text-base font-medium">
                      {chips?.length == 0 ? "No award yet." : "You can add more award"}
                    </p>
                    {/* <div className="flex flex-wrap gap-3">
                      {Array.isArray(chips) === true ? (
                        <>
                          {chips?.map((awd, key) => (
                            <AddedItem
                              item={awd.Award_name}
                              key={key}
                              onClick={() => handleClick(awd)}
                              delete={() => handleDelete(awd)}
                            />
                          ))}
                        </>
                      ) : null}
                    </div> */}
                  </div>
                </div>
                <div className="self-stretch flex-col sm:flex-row justify-between items-start gap-4 inline-flex">
                  <button
                    className="px-5 py-3 rounded-lg shadow border border-orange-500 justify-center items-start gap-2.5 flex w-full sm:max-w-[200px]"
                    onClick={handleBackBtn}
                  >
                    <span className="text-center text-orange-500 text-base font-semibold">
                      Previous
                    </span>
                  </button>
                  <button
                    className="px-5 py-3 bg-indigo-700 rounded-lg shadow justify-center items-start gap-2.5 flex w-full sm:max-w-[200px]"
                    onClick={handleNextBtn}
                  >
                    <span className="text-center text-white text-base font-semibold whitespace-nowrap">
                      Save and Continue
                    </span>
                  </button>
                </div>
              </div>
              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
