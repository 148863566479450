import React, {useState} from 'react';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import MyDocument from './cvTemplate1';
import BlackEagle from './BlackEagle';
import BlueShark from './BlueShark';
import BlueTalent from './BlueTalent';
import SamCv from './SamCv';
import Designer from './Designer';
import BlackShark from './BlackShark';
// import CustomMoil from './customMoil';
import NewCustom from './newCustom';
import { useSelector } from 'react-redux';
import reference from '../common/reference';

import font from "./font/poppins-v20-latin-600.ttf";
import font1 from "./font/poppins-v20-latin-300.ttf";

Font.register({ family: 'Poppins', src: font1});
Font.register({ family: 'Poppins-bold', src: font});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  download: {
    fontFamily: "poppins",
    fontSize: "1em",
    padding: "6px 12px",
    backgroundColor: "#5843BD",
    color: "white",
    borderRadius: 10,

  }
});


const MyDoc = ({details, id}) => {
  // console.log("Mydoc id and details", details)
  // console.log("Mydoc id ", id)
  if (details?.pdfId == "6" || id == "6") {
    return (
      <MyDocument details={details} />
    )
  } else if (details.pdfId == "7" || id == "7") {
    return (
      <BlackEagle details={details} />
    )
  } else if (details.pdfId == "8" || id == "8") {
    return (
      <BlueShark details={details} />
    )
  } else if (details.pdfId == "9" || id == "9") {
    return (
      <BlackShark details={details} />
    )
  } else if (details.pdfId == "10" || id == "10") {
    return (
      <BlueTalent details={details} />
    )
  } else if (details.pdfId == "11" || id == "11") {
    return (
      <SamCv details={details} />
    )
  } else if (details.pdfId == "12" || id == "12") {
    return (
      <Designer details={details} />
    )
  }
}

function App1(props) {
  const cv_id = useSelector(prev => prev.cvId);
  const urlPath = window.location.pathname;
  const [isOptionsVisible, setOptionsVisible] = useState(true);
  // console.log("{`${props?.details?.Fullname}.pdf`}", `${props?.details?.Fullname}.pdf`)
  // console.log(props?.text)
  if (urlPath.includes("jobs")) {
    return (
      <div className="px-4 py-2 cursor-pointer" onClick={props.onClick}  >
        <PDFDownloadLink document={<MyDoc details={props?.details} id={props?.id}/>} fileName={`${props?.details?.Fullname}.pdf`}>
        {({ blob, url, loading, error }) => (loading ? <Text style={{color: "white"}}>'Loading document...'</Text > : 
        <View className="flex gap-[6px] hover:bg-[#5843BD] hover:bg-opacity-30">
          <img
            src={reference?.previewcvicon}
            alt="preview cv icon"
          />
          <View>
            <Text className=" font-semibold text-sm text-black">
              {props.details?.CvType}
            </Text>
            <Text className=" text-xs text-[#1C1C1C] text-opacity-60 font-normal">
              Resume created on Moil.
            </Text>
          </View>
        </View>
      )}
      </PDFDownloadLink>
      </div>
    )
  } else if (urlPath == "/resume") {
    return (
      <div className="" onClick={props.onClick}>
      <PDFDownloadLink document={<MyDoc details={props.details} id={props.id}/>} fileName={`${props.details?.Fullname}.pdf`} >
        <View className="flex justify-center items-center inline-block gap-x-1">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 12L3 7L4.4 5.55L7 8.15V0L9 0V8.15L11.6 5.55L13 7L8 12ZM2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196666 15.0217 0.000666667 14.5507 0 14L0 11H2L2 14H14V11H16V14C16 14.55 15.8043 15.021 15.413 15.413C15.0217 15.805 14.5507 16.0007 14 16H2Z" fill="#FF6633" />
          </svg>
          <span className="hidden zsm:inline text-base leading-normal font-normal text-[#FF6633]">Download</span>
        </View>
      </PDFDownloadLink>
    </div>
    );
  } else if (urlPath == "/generateCV") {
    return (
      <div className={`h-11 ${props.gamified ? 'px-8' : 'px-4 sm:px-8'} py-2.5 rounded-3xl justify-start items-center gap-2 sm:gap-2.5 inline-flex bg-orange-500 cursor-pointer`} onClick={props.onClick}>
        <PDFDownloadLink document={<MyDoc details={props.details} id={props.id}/>} fileName={`${props.details?.Fullname}.pdf`} >
          <View className="flex justify-center">
            <Text className=" font-semibold text-sm text-white">
              Download
            </Text>
          </View>
        </PDFDownloadLink>
      </div>
    );
  } else {
    return (
      <div className="px-5 py-3 bg-indigo-700 rounded-lg shadow justify-center items-start gap-2.5 flex lg:flex sm:max-w-[200px] w-full" onClick={props?.onClick}>
        <PDFDownloadLink document={<MyDoc details={props?.details} id={props?.id}/>} fileName={`${props?.details?.Fullname}.pdf`} >
        {({ blob, url, loading, error }) => (loading ? <Text style={{color: "white"}}>'Loading document...'</Text > : 
        <Text style={{color: "white", display:"flex"}}>{props?.text}</Text>)}
      </PDFDownloadLink>
      </div>
    );
  }
}

export default App1;
