import App1 from "../../../pdf/DownloadableRresume";

export default function ResumeItemMobile(props) {
  const dateString = props.createdAt;
  const date = new Date(dateString);

  const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${String(date.getFullYear()).slice(-2)}`;


  return (
    <div className={`${props.bordered && 'border-b border-indigo-300'} flex items-center justify-between`}>
      <div className={`flex items-center py-5 gap-x-2 pl-4`}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.5415 21.8699C19.5415 23.0159 18.6831 23.944 17.623 23.944H5.39236C4.33336 23.944 3.47461 23.0155 3.47461 21.8699V3.16753C3.47461 2.02041 4.33373 1.09229 5.39236 1.09229H17.623C18.6831 1.09229 19.5415 2.02041 19.5415 3.16753V21.8699Z" fill="#F4AE7F" />
          <path d="M11.587 3.94088C11.587 4.15875 11.1527 4.335 10.6195 4.335H4.44286C3.90811 4.335 3.47461 4.15875 3.47461 3.94088V0.393375C3.47461 0.17625 3.90773 0 4.44286 0H10.6195C11.1527 0 11.587 0.17625 11.587 0.393375V3.94088Z" fill="#F4AE7F" />
          <path d="M20.4985 21.0001C20.4985 21.9725 19.6315 22.7589 18.5609 22.7589H6.2013C5.13217 22.7589 4.26367 21.9721 4.26367 21.0001V5.14889C4.26367 4.17726 5.13217 3.39014 6.2013 3.39014H18.5609C19.6315 3.39014 20.4985 4.17764 20.4985 5.14889V21.0001Z" fill="#D0D2D3" />
          <path d="M20.4985 19.7601C20.4985 20.7325 19.6315 21.5189 18.5609 21.5189H6.2013C5.13217 21.5189 4.26367 20.7321 4.26367 19.7601V3.90888C4.26367 2.93725 5.13217 2.15088 6.2013 2.15088H18.5609C19.6315 2.15088 20.4985 2.93763 20.4985 3.90888V19.7601Z" fill="white" />
          <path d="M16.1625 3.10481C16.1625 3.22181 15.5857 3.31706 14.8762 3.31706H6.66373C5.95273 3.31706 5.37598 3.22181 5.37598 3.10481V1.19418C5.37598 1.07606 5.95273 0.981934 6.66373 0.981934H14.8762C15.5857 0.981934 16.1625 1.07606 16.1625 1.19418V3.10481Z" fill="#D0D2D3" />
          <path d="M7.5259 6.76104H18.2366C18.9574 6.76104 18.9574 5.74854 18.2366 5.74854H7.5259C6.80777 5.74854 6.80777 6.76104 7.5259 6.76104ZM7.5259 8.81791H18.2366C18.9574 8.81791 18.9574 7.80616 18.2366 7.80616H7.5259C6.80777 7.80616 6.80777 8.81791 7.5259 8.81791ZM7.5259 10.9198H18.2366C18.9574 10.9198 18.9574 9.90729 18.2366 9.90729H7.5259C6.80777 9.90729 6.80777 10.9198 7.5259 10.9198ZM7.5259 12.9748H18.2366C18.9574 12.9748 18.9574 11.963 18.2366 11.963H7.5259C6.80777 11.963 6.80777 12.9748 7.5259 12.9748ZM7.5259 16.9423H12.4564C13.1749 16.9423 13.1749 15.9302 12.4564 15.9302H7.5259C6.80777 15.9298 6.80777 16.9423 7.5259 16.9423Z" fill="#35494D" />
        </svg>

        <div>
          <p className="text-base leading-normal font-medium text-[#000000CC]">{props.CvType}</p>
          <p className="text-xs leading-normal font-normal text-[#5843BD]">{props.pdfId !== "0" ? "Generated on Moil" : "Resume Uploaded" }
            <span className="leading-normal py-2 font-normal text-[#000000CC]"> | {props.createdAt}</span>
          </p>
        </div>
      </div>

      <div className="flex items-center gap-x-8 lg:justify-between pr-4 py-2 lg:max-w-[350px] h-full">
        {
          props.pdfId >= 6 ?
            <App1
              details={props.data}
              id={props.pdfId}
            /> :
            <a className="flex items-center inline-block gap-x-1" href={props.aws_link} download={props.CvType}>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 12L3 7L4.4 5.55L7 8.15V0L9 0V8.15L11.6 5.55L13 7L8 12ZM2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196666 15.0217 0.000666667 14.5507 0 14L0 11H2L2 14H14V11H16V14C16 14.55 15.8043 15.021 15.413 15.413C15.0217 15.805 14.5507 16.0007 14 16H2Z" fill="#FF6633" />
              </svg>

              <span className="hidden zsm:inline text-base leading-normal font-normal text-[#FF6633]">Download</span>
            </a>
        }

      </div>
    </div>
  )
}