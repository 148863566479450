import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { createPortal } from "react-dom";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux/es";
import {
  JobDurationSvg,
  JobTitleSvg,
  JobTypeSvg,
  LocationSvg,
} from "../common/svgs/svgs";
import DashboardQueryItem from "../common/dashboard_query_item";
import HelmetProvide from "../common/helmet";
import timeResolver from "./timeresolver";
import PreviewButton from "../common/preview_components/preview_button";
import {
  application_loading,
  getJobsApplied,
  save_job_loading,
  save_link_loading,
  getProfile,
  getAllMyCv,
  userName_from_db,
  returnHome,
  saveJob
} from "../../Redux/ActionCreator";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
// import JobAppicationModal from "./application_modal";
import InterviewModal from "../common/interviewModal";
import { Link } from "react-router-dom";
import LoaderModal from "../common/LoaderModal";
import { baseURL } from "../../Redux/baseURL";
import BackButton from "../common/back_button";
import ReactHtmlParser from 'react-html-parser';
import { saveIntoServerCache } from "../../utils/saveIntoCache"
import PublicJobDetails from "./publicJobDetails";
import CompleteConfirmationGamifiedModal from "../gamified_modals/complete_confirm";
import ExperienceGamifiedModal from "../gamified_modals/experience_gamified_modal";
import EducationGamifiedModal from "../gamified_modals/education_gamified_modal";
import SkillsGamifiedModal from "../gamified_modals/skills_gamified_modal";
import SummaryGamifiedModal from "../gamified_modals/summary_gamified_modal";
import ProfilePicsGamifiedModal from "../gamified_modals/profile_pics_gamified_modal";
import NoResumeModal from "../gamified_modals/no_resume_modal";

export default function UserJobDetails(props) {
  // getting all available jobs from the redux store
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const availble_jobs = useSelector((e) => e.available_jobs);
  const login_stat = useSelector(e => e.login);
  const profile = useSelector((e) => e.profile);
  const application_stat = useSelector((e) => e.apply);
  let employer_JobInfo = useSelector((e) => e.jobDetails);
  let [hide, setHide] = useState(false);
  const savedJobsState = useSelector((e) => e.saved_jobs);
  const [fillIcon, setFillIcon] = useState(false);
  // const [ fill, setFill ] = useState(false);
  let appliedJobs = useSelector(e => e.jobsApplied);
  let [applied, setApplied] = useState(false);
  let [takeTest, setTakeTest] = useState(false);
  let [job, setJob] = useState();
  let query = new URLSearchParams(window.location.search).get("apply");
  const get_A_job = useSelector((e) => e.get_a_job);
  let [jobArray, setJobArray] = useState([]);
  let token = localStorage.getItem("token");
  let AcctType = login_stat.profile_status.AcctType;
  let profile_status = login_stat.profile_status.profile_status;
  const userName = useSelector((prev) => prev.userInfo);
  const my_name = userName.user;
  const available_jobs_category = useSelector(e => e.available_jobs_category);
  const jobs_catgory = available_jobs_category.message;

  // getting the jobs the user has applied for
  const save_job = useSelector((e) => e.save_job);
  const save_cv = useSelector((e) => e.saved_cv?.message);
  const params = useParams();
  // console.log(params);

  // state to control modal to submit application
  // const [modal, setModal] = useState(false);
  const [OpenInterviewModal, setOpenInterviewModal] = useState(false);
  const [experienceModal, setExperienceModal] = useState(false);
  const [educationModal, setEducationModal] = useState(false);
  const [skillsModal, setSkillsModal] = useState(false);
  const [summaryModal, setSummaryModal] = useState(false);
  // const [profilePics, setProfilePics] = useState(false);
  const [hundredModal, sethundredModal] = useState(false);
  const [updateText, setUpdateText] = useState("");
  const [urlLink, setUrlLink] = useState("");

  // only when path includes search

  const memoizeData = useMemo(() => {
    const fetchedData = async () => {
      if (my_name === null) {
        dispatch(userName_from_db());
      }
      if (profile.message == null) {
        dispatch(getProfile(token));
      }
      if (save_cv.message == null) {
        dispatch(getAllMyCv(token));
      }
    }
    return fetchedData;
  }, [])

  const memoizeDataForJobs = useMemo(() => {
    const fetchedData = async () => {
      dispatch(save_link_loading(true));
      // dispatch(getting_employer_profile_loading(true));
      if (profile.message !== null) {
        if (appliedJobs.message === null) {
          dispatch(getJobsApplied());
        }
        // if (availble_jobs.message === null) {
        //   dispatch(getAvailablejobs());
        // }
      }
    }
    return fetchedData;
  }, []);

  useEffect(() => {
    memoizeData();
    memoizeDataForJobs();
  }, [memoizeData, memoizeDataForJobs]);

  // useEffect(() => {
  //   if (token !== null) {
  //   } 
  //   // else {
  //   //   if (profile.message === null || profile.message === undefined) {
  //   //     dispatch(getProfile(token));
  //   //   }
  //   // }
  // }, [window.location.pathname.includes("search")]);


  // this get possible new jobs posted by an employer when a user is active on this page.

  useEffect(() => {
    axios.patch(`${baseURL}/api/job/clicks/${params.jobName}`)
      .then(response => {
        // console.log('Data:', response.data);
      })
      .catch(error => {
        // console.error('Error:', error);
      });
  }, [])


  useEffect(() => {
    if (window.location.pathname.includes("job_preview")) {
      hide = true;
      jobArray = employer_JobInfo?.jobDetails?.jobPosts?.filter(
        (item) => item.custom_name === params.jobName
      );
      if (jobArray !== undefined) {
        setJobArray(jobArray);
        setJob(jobArray[0]?.JobId !== undefined ? jobArray[0]?.JobId : jobArray[0]);
      }
    } else if (query !== null) {
      appliedJobs.message?.Jobs?.forEach(element => {
        if (element?.JobId?.custom_name == params.jobName) {
          // console.log("Applied jobs", element.JobId)
          setApplied(true);
          let application = element?.JobId?.applications.filter(application => application.applicant === my_name._id);
           (application?.[0]?.testScore <= 0 && Array.isArray(application?.[0]?.answers) && application[0].answers.length === 0)
           ? setTakeTest(true)
           : setTakeTest(false);
         
          if (jobArray !== undefined) {
            setJobArray(jobArray);
            setJob(jobArray[0]?.JobId !== undefined ? jobArray[0]?.JobId : jobArray[0]);
          }
        }
      });
      // console.log("inner applied", applied)
    } else {
      if (appliedJobs.message !== null) {
        let job_filter = appliedJobs.message?.Jobs?.filter(element => element?.JobId?.custom_name == params.jobName);
        // console.log("Job filter", job_filter)
        if (job_filter.length !== 0) {
          setApplied(true);
          let application = job_filter[0]?.JobId?.applications.filter(application => application.applicant == my_name._id);
          (application?.[0]?.testScore <= 0 && Array.isArray(application?.[0]?.answers) && application[0].answers.length === 0)
          ? setTakeTest(true)
          : setTakeTest(false);
          // setJobArray(job_filter);
          setJob(job_filter[0]?.JobId !== undefined ? job_filter[0]?.JobId : job_filter[0]);
          // return job_filter back to empty array;
          job_filter = [];
        } else {
          jobArray = availble_jobs.message?.filter(
            (item) => item.custom_name === params.jobName
          );
          // console.log("Indeed Jobarray", jobArray);
          if (jobArray !== undefined) {
            setApplied(false);
            setJobArray(jobArray);
            setJob(jobArray[0]?.JobId !== undefined ? jobArray[0]?.JobId : jobArray[0]);
          } else {
            // check category state/store
            // console.log("check category state/store")
            jobArray = jobs_catgory?.filter(
              (item) => item.custom_name === params.jobName
            );
            // console.log("Jobarray", jobArray);
            if (jobArray !== undefined) {
              setApplied(false);
              setJobArray(jobArray);
              setJob(jobArray[0]?.JobId !== undefined ? jobArray[0]?.JobId : jobArray[0]);
            }
          }
        }
      } else {
        // console.log("Get here")
        jobArray = availble_jobs.message?.filter(
          (item) => item.custom_name == params.jobName
        );
        // console.log("my jobArray", jobArray)
        if (jobArray !== undefined) {
          setApplied(false);
          setJobArray(jobArray);
          setJob(jobArray[0]?.JobId !== undefined ? jobArray[0]?.JobId : jobArray[0]);
        } else {
          // check category state/store
          // console.log("check category state/store")
          jobArray = jobs_catgory?.filter(
            (item) => item.custom_name === params.jobName
          )
          if (jobArray !== undefined) {
            setApplied(false);
            setJobArray(jobArray);
            setJob(jobArray[0]?.JobId !== undefined ? jobArray[0]?.JobId : jobArray[0]);
          }
        }
      }
    }
  }, [window.location.pathname]);

  // console.log("jobArray", jobArray);
  // console.log("job", job);
  // let job;
  const [snull, setNull] = useState(false);

  const getJob = (arg) => {
    fetch(baseURL + `/api/job/getASecurejobNoAuth/${arg}`, {
      headers: {
        "Content-Type": "application/json",
      }
    })
      .then((resp) => resp.json())
      .then(resp => {
        // console.log("resp", resp);
        // setJobArray([resp.data]);
        if (resp.success == true && resp.statusCode == 200) {
          setJob(resp.data);
          return;
        } else {
          if (resp.title == "Not Found") {
            setTimeout(() => {
              toast.error("Job not found.", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }, 50);
            navigate("/user/dashboard");
          }
        }
      }).catch(err => console.log(err))
  }

  useEffect(() => {
    if (jobArray !== undefined && jobArray.length !== 0) {
      // console.log("And am feelinh, good!!")
      job = jobArray[0]?.JobId !== undefined ? jobArray[0]?.JobId : jobArray[0];
    } else {
      if (token) {
        // console.log(params.jobName);
        fetch(baseURL + `/api/job/getASecurejob/${params.jobName}`, {
          headers: {
            "Content-Type": "application/json",
            // Authorization: "Bearer " + localStorage.getItem("token"),
          },
          credentials: "include"
        })
          .then((resp) => resp.json())
          .then(async resp => {
            // console.log("resp", resp);
            // setJobArray([resp.data]);
            if (resp.success == true && resp.statusCode == 200) {
              setJob(resp.data);
              // add resp.data to list of available jobs
              await saveIntoServerCache("JobOwnerId", resp.data); //save the job into cache
              return;
            } else if (resp.success === false && resp.message == "Unauthorized") {
              // dispatch(returnHome(resp.status));
              localStorage.removeItem("token");
              window.location.assign(window.location.pathname);
              // getJob(params.jobName);
            } else {
              if (resp.title == "Not Found") {
                setTimeout(() => {
                  toast.error("Job not found.", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }, 50);
                navigate("/user/dashboard");
              }
            }
          }).catch(err => console.log("getting job error", err))
      } else {
        getJob(params.jobName);
      }
    }
  }, [])


  const jobs = availble_jobs.message?.filter(
    (item) => item.custom_name !== params.jobName
  );

  useEffect(() => {
    // check if this jobId already exist within the saved jobs so you can update the save heart to full
    let present = savedJobsState.message?.joblist?.some(
      (element) => element?.custom_name === params.jobName
    );
    if (present) {
      setFillIcon(true);
    } else {
      setFillIcon(false);
    }

  }, [savedJobsState.message, /* appliedJobs */])
  // console.log("job", job)

  useEffect(() => {
    if (application_stat.message !== null) {
      setApplied(true);
      setTimeout(() => {
        toast.success(application_stat.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
      dispatch(application_loading(true));
    }
    if (application_stat.errMess !== null) {
      setTimeout(() => {
        toast.error(application_stat.errMess, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
      dispatch(application_loading(true));
    }

    if (save_job.message !== null) {
      // setTimeout(() => {
      //   toast.success(save_job.message, {
      //     position: toast.POSITION.TOP_RIGHT,
      //   });
      // }, 50);
      // dispatch( save_job_loading());
    }
    if (save_job.errMess !== null) {
      setTimeout(() => {
        toast.error(save_job.errMess, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
      dispatch(save_job_loading());
    }
  }, [application_stat, save_job]);

  useEffect(() => {
    let noJob = new URLSearchParams(window.location.search).get("noResume");
    if (noJob !== null) {
      setOpenInterviewModal(true);
    }
  }, []);

  // This handler opens the modal for us
  // const handleOpenModal = () => {
  //   setModal(true);
  // };

  // This handler save a job
  const handleSaveJob = () => {
    if (profile.message !== null) {
      if (fillIcon === true) {
        setFillIcon(!fillIcon);
        // throw an error
        handleUnsaveJob();
        setTimeout(() => {
          toast.info("Job post has been removed from saved jobs", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }, 50);
      } else {
        dispatch(saveJob(job._id));
        setFillIcon(true);
        setTimeout(() => {
          toast.success("Job post has been saved successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }, 50);
      }
    }
  };

  const handleUnsaveJob = () => {
    fetch(baseURL + `/api/post/remove_post?jobId=${job._id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + localStorage.getItem("token"),
      },
      credentials: "include"
    }).then((resp) => resp.json())
  }

  // console.log("applied", applied);

  useEffect(() => {
    if (experienceModal) {
      setUrlLink("/user/profile/update/experience");
      setUpdateText("Experience");
    } else if (educationModal) {
      setUrlLink("/user/profile/update/education");
      setUpdateText("Education");
    } else if (skillsModal) {
      setUrlLink("/user/profile/update/skills");
      setUpdateText("Skills/Language");
    } else if (summaryModal) {
      setUrlLink("/user/profile/update/about");
      setUpdateText("Summary");
    }
    // else if (profilePics) {
    //   setUpdateText("Profile pics");
    // }
  }, [experienceModal, educationModal, skillsModal, summaryModal]);

  const handleApply = () => {
    // now consider jobs with skill test.

    if (profile.message !== null) {
      if (profile.message?.Experience?.length === 0 || !profile.message?.Experience) {
        setExperienceModal(true);
      } else if (profile.message?.Education?.length === 0 || !profile.message?.Education) {
        setEducationModal(true);
      } else if (profile.message?.Skills?.length === 0 || !profile.message?.Skills || profile.message?.Soft_skills?.length === 0 || !profile.message?.Soft_skills || profile.message?.Language?.length === 0 || !profile.message?.Language) {
        setSkillsModal(true);
      } else if (!profile.message?.About) {
        setSummaryModal(true);
      } else {
        if (applied) {
          // do sth

        } else {
          setOpenInterviewModal(!OpenInterviewModal);
        }
      }
    }
  }

  const handleEmployerProfileView = () => {
    if (profile.message !== null) {
      if (profile.message?.Experience?.length === 0 || !profile.message?.Experience) {
        setExperienceModal(true);
      } else if (profile.message?.Education?.length === 0 || !profile.message?.Education) {
        setEducationModal(true);
      } else if (profile.message?.Skills?.length === 0 || !profile.message?.Skills || profile.message?.Soft_skills?.length === 0 || !profile.message?.Soft_skills || profile.message?.Language?.length === 0 || !profile.message?.Language) {
        setSkillsModal(true);
      } else if (!profile.message?.About) {
        setSummaryModal(true);
      } else {
        navigate(`/user/employer/profile/${job?.profile_id}?job=${job.custom_name}`)
      }
    }
  }

  const experience = experienceModal ? <ExperienceGamifiedModal updateText={updateText} handleClose={() => setExperienceModal(false)} handleContinue={() => navigate(urlLink)} /> : null;
  const education = educationModal ? <EducationGamifiedModal updateText={updateText} handleClose={() => setEducationModal(false)} handleContinue={() => navigate(urlLink)} /> : null;
  const skills = skillsModal ? <SkillsGamifiedModal updateText={updateText} handleClose={() => setSkillsModal(false)} handleContinue={() => navigate(urlLink)} /> : null;
  const summary = summaryModal ? <SummaryGamifiedModal updateText={updateText} handleClose={() => setSummaryModal(false)} handleContinue={() => navigate(urlLink)} /> : null;
  // const profile_pics = profilePics ? <ProfilePicsGamifiedModal updateText={updateText} handleClose={() => setProfilePics(false)} handleContinue={() => setProfilePics(false)} /> : null;
  const hundred = hundredModal ? <CompleteConfirmationGamifiedModal updateText={updateText} handleClose={() => sethundredModal(false)} handleContinue={sethundredModal} /> : null;


  if (token !== null) {
    return (
      <>
        <HelmetProvide
          title={`Moil | ${job?.jobTitle} at ${job?.companyName}`}
          description={`Explore and apply for the ${job?.jobTitle} position  at ${job?.companyName}.`}
          image={job?.logo}
          className="notranslate"
        />
        {job === undefined ? <LoaderModal text="Getting information" /> :
          <div className="px-3 lg:pl-6 py-8 w-full flex justify-center h-calc-70 overflow-y-scroll">
            <div className="flex flex-col">
              {window.location.pathname.includes("search") ? <BackButton onClick={() => navigate(`/user/dashboard`)} /> :
                <div className="flex items-center gap-3 pb-4">
                  <BackButton onClick={() => window.history.back()} />
                  <Link to="/user/jobs" className="text-[#5145CD]">Applied jobs</Link> /
                  <Link to="#" className="text-[#5145CD]">Job details</Link>
                </div>
              }
              <div className="flex flex-col w-full gap-y-10 xtraxl:max-w-calculate max-w-[800px] pb-10 border-b border-indigo-400 border-opacity-30">
                <div className="bg-white bg-opacity-80 rounded-lg shadow border border-indigo-400 border-opacity-30 py-8 px-4 sm:px-12 gap-y-8 flex flex-col">
                  <p>
                    <span className="text-gray-600 text-base font-medium">
                      Posted{" "}
                    </span>
                    <span className="text-indigo-700 text-base font-medium">
                      {job?.indeed == true ? <>{job?.posted_on}</> : <>{timeResolver(job?.createdAt)}</>}
                    </span>
                    <span className="text-gray-600 text-base font-medium"> </span>
                  </p>
                  <div className="flex flex-col gap-4">
                    <div className="text-indigo-700 text-2xl font-semibold">
                      {`${job?.jobTitle}`}
                    </div>
                    <div className="w-full justify-start items-start gap-4 flex flex-wrap">
                      <div className="flex gap-x-2 flex-none">
                        <JobTitleSvg />
                        <p className="text-slate-800 text-opacity-80 text-sm font-medium">
                          {`${job?.jobTitle}`}
                        </p>
                      </div>
                      <div className="flex gap-x-2 flex-none">
                        <JobTypeSvg />
                        <p className="text-slate-800 text-opacity-80 text-sm font-medium">
                          {job?.jobType}
                        </p>
                      </div>
                      <div className="flex gap-x-2 flex-none">
                        <LocationSvg />
                        <p className="text-slate-800 text-opacity-80 text-sm font-medium">
                          {`${job?.location?.city}, ${job?.location?.state}.`}
                        </p>
                      </div>
                      {job?.applicationDeadline !== undefined ?
                        <div className="flex gap-x-2">
                          <JobDurationSvg />
                          <p className="whitespace-normal">
                            <span className="text-slate-800 text-opacity-80 text-sm font-medium">
                              Recruiting until{" "}
                            </span>
                            <span className="text-indigo-700 text-sm font-semibold">
                              {job?.applicationDeadline}
                            </span>
                          </p>
                        </div> : null}
                      {job?.verify !== "" ?
                        <div className="flex gap-x-2 flex-none">
                          <p className="text-slate-800 text-opacity-80 text-sm font-medium">
                            Verification type: {`${job?.verify}.`}
                          </p>
                        </div> : null}
                    </div>
                  </div>
                  <div className="w-full flex-col gap-y-4 md:flex-row justify-between items-center flex">
                    <div className="flex flex-col w-full md:flex-row items-center gap-4">
                      <button
                        className={`px-5 py-3 w-full md:w-max ${applied ? 'bg-slate-400' : 'bg-indigo-700 focus:bg-indigo-700'} rounded-lg shadow justify-center items-start gap-2.5 flex`}
                        disabled={applied}
                        // onClick={() => dispatch(ApplyForAJob(params.jobId))}
                        onClick={() => handleApply()}
                      >
                        <span className="text-center text-white text-base font-semibold">
                          {applied ? <>Applied</> : <>Apply Now</>}
                        </span>
                      </button>

                      {(job?.skillTest && applied) && <button onClick={() => navigate(`/user/job/skillTest/${params.jobName}`)}
                        disabled={!takeTest}
                        className={`px-5 py-3 w-full md:w-max ${takeTest ? 'bg-green-500 focus:bg-green-500' : 'bg-slate-400'} rounded-lg shadow justify-center items-start gap-2.5 flex`}
                      >
                        <span className="text-center text-white text-base font-semibold">
                          {takeTest ? "Take skill test" : "Skill Test completed"}
                        </span>
                      </button>}
                    </div>
                    {save_cv?.length === 0 ? <NoResumeModal OpenInterviewModal={OpenInterviewModal}
                      Closemodal={() => setOpenInterviewModal(false)} profileLink={`/user/profile/:${my_name?.profile_id}`} /> :
                      <InterviewModal
                        OpenInterviewModal={OpenInterviewModal}
                        Closemodal={() => setOpenInterviewModal(false)}
                        indeed={job}
                        purpose="job"
                        setTakeTest={setTakeTest}
                      />}
                    <button
                      className="justify-center w-full md:w-max items-center py-3 px-5 rounded-lg focus:bg-transparent gap-2.5 flex"
                      onClick={handleSaveJob}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {fillIcon ? (
                          <path
                            fill="#FF6633"
                            d="M5.301 3.002c-.889-.047-1.759.247-2.404.893-1.29 1.292-1.175 3.49.26 4.926l.515.515L8.332 14l4.659-4.664.515-.515c1.435-1.437 1.55-3.634.26-4.926-1.29-1.292-3.483-1.175-4.918.262l-.516.517-.517-.517C7.098 3.438 6.19 3.049 5.3 3.002z"
                            stroke="#FF6633"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        ) : (
                          <path
                            d="M6.24935 3.33301C3.7181 3.33301 1.66602 5.38509 1.66602 7.91634C1.66602 12.4997 7.08268 16.6663 9.99935 17.6355C12.916 16.6663 18.3327 12.4997 18.3327 7.91634C18.3327 5.38509 16.2806 3.33301 13.7493 3.33301C12.1993 3.33301 10.8285 4.10259 9.99935 5.28051C9.57672 4.67851 9.01525 4.18721 8.36249 3.84821C7.70973 3.50921 6.98489 3.33249 6.24935 3.33301Z"
                            stroke="#FF6633"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        )}
                      </svg>

                      <span className="text-center truncate text-orange-500 text-base font-semibold">
                        Save Job
                      </span>
                    </button>
                  </div>
                </div>
                {job?.companyAbout !== undefined ? (
                  <div className="bg-white bg-opacity-80 rounded-lg shadow border border-indigo-400 border-opacity-30 py-8 px-4 sm:px-12 gap-y-8 flex flex-col">
                    <p className="text-gray-600 text-base font-semibold">
                      About this business
                    </p>
                    <div className="flex flex-col md:flex-row justify-between gap-x-8 gap-y-4 max-w-[1280px]">
                      <div className="flex gap-2 min-w-[150px]">
                        <img src={job?.logo} className="w-5 h-5" />
                        <div>
                          <p className="text-indigo-700 text-sm font-semibold font-['Poppins'] notranslate">
                            {`${job?.companyName}`}
                          </p>
                          <ul className="">
                            <li>{job?.companyBuinsessType}</li>
                            <li>{`${job?.location.city}, ${job?.location.state}.`}</li>
                            <li>{`${job?.companyEmployeeRange} employees`}</li>
                          </ul>
                        </div>
                      </div>
                      <div className="flex flex-col gap-6">
                        <div className="w-full lg:max-w-[400px] xtraxl:max-w-[1024px] text-gray-600 word-spacing-2 text-sm font-medium">
                          {ReactHtmlParser(job?.companyAbout)}
                          {/* {job?.companyAbout} */}
                        </div>
                        <button
                          className="max-w-[210px] px-5 py-3 bg-indigo-700 rounded-lg shadow justify-start items-start gap-2.5 inline-flex"
                          onClick={() =>
                            handleEmployerProfileView()
                          }
                        >
                          <span className="text-center text-white text-base font-semibold font-['Poppins']">
                            View Business Profile
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="px-4 sm:px-12 py-8 bg-white bg-opacity-80 rounded-lg shadow border border-indigo-400 border-opacity-30 flex-col justify-start items-start gap-8 inline-flex">
                  <p className="text-indigo-700 text-2xl font-semibold font-['Poppins']">
                    Job Details
                  </p>
                  <div className="justify-start items-start inline-flex">
                    <div className="flex-col justify-start items-start gap-2 inline-flex">
                      <p className="text-indigo-700 text-sm font-semibold font-['Poppins']">
                        Job Description
                      </p>
                      <p className="text-gray-600 text-sm font-medium word-spacing-2 font-['Poppins']">
                        {ReactHtmlParser(job?.jobDescription)}
                        {/* {`${job?.jobDescription}`} */}
                      </p>
                    </div>
                  </div>
                  {job?.indeed == "true" || job?.indeed == true ? null :
                    <div className="justify-start items-start inline-flex">
                      <div className="flex-col justify-start items-start gap-2 inline-flex">
                        <div className="text-indigo-700 text-sm font-semibold font-['Poppins']">
                          Key Responsibilities
                        </div>
                        <ul className="flex flex-col gap-2 text-gray-600 text-sm font-medium font-['Poppins'] list-disc  word-spacing-2">
                          {job?.responsibilities?.map((responsibility, key) => {
                            return <li className="ml-4" key={key}>
                              {ReactHtmlParser(responsibility)}</li>;
                          })}
                        </ul>
                      </div>
                    </div>
                  }
                  {job?.indeed == "true" || job?.indeed == true ? null :
                    <div className="justify-start items-start inline-flex">
                      <div className="flex-col justify-start items-start gap-4 inline-flex">
                        <div className="text-indigo-700 text-sm font-semibold">
                          Required Skills
                        </div>
                        <div className="items-center gap-3 inline-flex w-full flex-wrap">
                          {job?.skills?.map((data, key) => (
                            <>
                              {data.value ? (
                                <PreviewButton text={data.value} key={key} />
                              ) : (
                                <PreviewButton text={data} key={key} />
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                  }
                </div>
                <div className="w-full px-4 sm:px-12  py-8 bg-white bg-opacity-80 rounded-lg shadow border border-indigo-400 border-opacity-30 flex-col justify-start items-start gap-8 flex">
                  <div className="justify-start items-center inline-flex">
                    <div className="self-stretch flex-col justify-start items-start gap-4 inline-flex">
                      <p className="text-indigo-700 text-xl sm:text-2xl font-semibold font-['Poppins']">
                        Payment Details
                      </p>
                    </div>
                  </div>
                  <div className="self-stretch justify-start items-start inline-flex">
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
                      <p className="text-indigo-700 text-sm font-semibold font-['Poppins']">
                        Payment Plan
                      </p>
                      <div className="px-4 py-2 bg-white bg-opacity-50 rounded-lg border border-indigo-400 border-opacity-30 justify-center items-center gap-2 inline-flex">
                        <div className="w-4 h-4 bg-red-500 rounded-full border border-indigo-400 border-opacity-30" />
                        <p className="text-black text-base font-normal lowercase">
                          {job?.paymentPlan}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                    <p className="text-indigo-700 text-sm font-semibold font-['Poppins']">
                      Compensation
                    </p>
                    <p className="word-spacing-2 text-gray-600 text-sm font-medium font-['Poppins']">
                      This is the expected salary range for this job.
                    </p>
                    <p className="text-black text-base font-medium font-['Poppins']">
                      {job?.compensation}
                    </p>
                  </div>
                </div>
                <div className="max-w-[840px]">
                  <span className="text-gray-600 text-sm font-medium font-['Poppins']">
                    Click on{" "}
                  </span>
                  <span className="text-indigo-700 text-sm font-bold font-['Poppins']">
                    ‘Apply Now’
                  </span>
                  <span className="text-gray-600 text-sm font-medium font-['Poppins']">
                    {" "}
                    if you are in this job,
                    <br />
                    or click on{" "}
                  </span>
                  <span className="text-orange-500 text-sm font-bold font-['Poppins']">
                    ‘Save Job’
                  </span>
                  <span className="text-gray-600 text-sm font-medium font-['Poppins']">
                    {" "}
                    to add it to your job interests.
                  </span>
                </div>
                <div className="w-full justify-between items-center flex">
                  <button
                    className={`px-5 py-3 ${applied ? 'bg-slate-400' : 'bg-indigo-700 focus:bg-indigo-700'} rounded-lg shadow justify-start items-start gap-2.5 flex`}
                    disabled={applied}
                    onClick={handleApply}
                  >
                    <span className="text-center text-white text-base font-semibold">
                      {applied ? <>Applied</> : <>Apply Now</>}
                    </span>
                  </button>
                  <button
                    className="justify-start items-center gap-2.5 flex rounded-lg shadow border border-orange-500 px-5 py-3 focus:bg-transparent"
                    onClick={handleSaveJob}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      {fillIcon ? (
                        <path
                          fill="#FF6633"
                          d="M5.301 3.002c-.889-.047-1.759.247-2.404.893-1.29 1.292-1.175 3.49.26 4.926l.515.515L8.332 14l4.659-4.664.515-.515c1.435-1.437 1.55-3.634.26-4.926-1.29-1.292-3.483-1.175-4.918.262l-.516.517-.517-.517C7.098 3.438 6.19 3.049 5.3 3.002z"
                          stroke="#FF6633"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      ) : (
                        <path
                          d="M6.24935 3.33301C3.7181 3.33301 1.66602 5.38509 1.66602 7.91634C1.66602 12.4997 7.08268 16.6663 9.99935 17.6355C12.916 16.6663 18.3327 12.4997 18.3327 7.91634C18.3327 5.38509 16.2806 3.33301 13.7493 3.33301C12.1993 3.33301 10.8285 4.10259 9.99935 5.28051C9.57672 4.67851 9.01525 4.18721 8.36249 3.84821C7.70973 3.50921 6.98489 3.33249 6.24935 3.33301Z"
                          stroke="#FF6633"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      )}
                    </svg>
                    <span className="text-center text-orange-500 text-base font-semibold">
                      Save Job
                    </span>
                  </button>
                </div>
              </div>
              {hide ? null : (
                <div>
                  <p className="text-black text-opacity-60 text-base font-medium mt-10 mb-5">
                    Here are some similar jobs you might be interested in:
                  </p>
                  <div className="grid grid-cols-1 gap-4 tablet:grid-cols-2 desktop:grid-cols-3 mb-8">
                    {jobs?.map((data, key) => (
                      <DashboardQueryItem
                        experienceLevel=""
                        jobPosition={data.jobTitle}
                        profilePics={data?.logo}
                        employer={data.companyName}
                        jobType={data.jobType}
                        recruitmentDuration={data.applicationDeadline}
                        JobLocation={`${data.location.city}, ${data.location.state}`}
                        key={key}
                        indeed={data?.indeed}
                        jobUrl={`/jobs/${data.custom_name}`}
                      />
                    ))}
                  </div>
                </div>
              )}
              {/* {applicationModal} */}
            </div>
            <ToastContainer />
            {experience}
            {education}
            {skills}
            {summary}
            {/* {profile_pics} */}
            {hundred}
          </div>
        }
      </>
    );
  } else {
    return (
      <PublicJobDetails />
    )
  }
}
