import React, { useRef, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { nanoid } from "nanoid";
import MessageItem from "../message_item";
import { CopyNumberSvg } from "../../../common/svgs/svg";
import reference from "../../../common/reference";
import { BusinessCenter } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sendMessage, chat_info_set, dispatch_employer_employee_info, end_to_end_chat, socketio } from "../../../../Redux/ActionCreator";
import { useParams } from "react-router-dom";
import MessageBoxHeader from "../message_box_header";
import NewMessageItem from "../new_message_item";
const { io } = require("socket.io-client");
import { baseURL } from "../../../../Redux/baseURL";

export default function MessageBox(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const newMessage = useSelector(e => e.end_to_end.message);
  const socket = useSelector(e => e.socket.message);
  const employer = useSelector((e) => e.employer);
  const employee = useSelector((e) => e.profile);
  const login_stat = useSelector((e) => e.login);
  const userInfo = useSelector(e => e.userInfo.user);
  let AcctType = login_stat.profile_status?.AcctType;
  const chat_info = useSelector((e) => e.chat_info.message);
  const lastMessageRef = useRef(null);
  const messageContainerRef = useRef(null);
  const [message, setMessage] = useState(null);
  const [messages, setMessages] = useState([]);
  const [decisonCard, setDecisionCard] = useState();
  const past = useSelector((e) => e.end_to_end);
  const pastMsg = past?.message;
  const [sender, setSender] = useState(false);
  const [senderImage, setSenderImage] = useState();
  let index = new URLSearchParams(window.location.search).get("i");
  const params = useParams().receiverId;
  const regexPattern = /^Decison card implementation cardId:.+&secret:.+$/;
  const [alertModal, setAlertModal] = useState({
    hasCheck: false,
    hasOpen: false,
  });
  const decisionCard = useSelector((e) => e.decisionCard);
  const profile_stat = useSelector((prev) => prev.login);
  let Acct_profile = useSelector((e) => e.profile);

  useEffect(() => {
    if (props.newMessage !== undefined) {
      // console.log("newMsg useEffect", props.newMessage);
      setMessages(props.newMessage);
    }
  }, [props.newMessage]);

  useEffect(() => {
    if (props.userType === "employee") {
      dispatch(dispatch_employer_employee_info(chat_info[props.index]?.chatIds[0]?.employerId));
    }
  }, [])

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    if (messages?.length > 0) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSendMessage = (message) => {
    if (["", null, undefined].includes(message) === false) {
      if (messages?.length == 0) {
        let newMsg = pastMsg;
        // newMsg.push({senderId: Acct_profile.message._id, text: message})
        // dispatch(end_to_end_chat(newMsg))
        // setMessages({ text: message });
      } else {
        let newMsg = pastMsg;
        // newMsg.push({senderId: Acct_profile.message._id, text: message})
        // dispatch(end_to_end_chat(newMsg))
        // setMessages((prev) => [...prev, { text: message }]);
      }
      if (AcctType === "Client") {
        setSenderImage(employee.message?.photo);
      }
      if (props.userType === "employee") {
       // console.log(userInfo.profile_id);
        // console.log(employee.message?._id);
        if (messages !== null && messages !== undefined) {
          dispatch(end_to_end_chat([...messages, {text: message, senderId: employee.message?._id}]));
        } else {
          dispatch(end_to_end_chat([{text: message, senderId: employee.message?._id}]));
        }
        dispatch(
          sendMessage(
            chat_info[props.index]?._id,
            employee.message?._id,
            message,
            userInfo.profile_id
          )
        );
        // console.log("messages", messages);
        if (socket === null || socket.connected == false) {
          const newSocket = io(baseURL, 
            {
              path: "/chat",
              cors: {
                origin: ["https://employee-beta.moilapp.com", 'https://employer-beta.moilapp.com' ], //  "http://localhost:3000", "http://localhost:3001"
              }
            }
          );
          // socketClient.connect();
          // setSocket(newSocket);
          dispatch(socketio(newSocket));
        }
        // console.log("socket", socket)
        if (chat_info !== null) {
          if (messages !== null && messages !== undefined) {
            socket?.emit("sendMessage", { message: [ ...messages, {text: message, senderId: employee.message?._id}], otherId: chat_info[index]?.chatIds[0]?.employerId?._id, AcctType: AcctType, name: Acct_profile.message?.Fullname, chatId: chat_info[index]?._id });
          } else {
            socket?.emit("sendMessage", { message: [ {text: message, senderId: employee.message?._id}], otherId: chat_info[index]?.chatIds[0]?.employerId?._id, AcctType: AcctType, name: Acct_profile.message?.Fullname, chatId: chat_info[index]?._id });
          }

          // console.log("messages", messages);
          // if (newMessage?.message !== null) {
          //   dispatch(end_to_end_chat(newMessage?.message));
          // }
        }
      }
    }
    let data;
    if (chat_info) {
      data = [...chat_info];
      data[index] = {
        ...data[index],
        updatedChatTime: new Date().toISOString()
      };
      data?.sort((a, b) => new Date(b?.updatedChatTime) - new Date(a?.updatedChatTime));
    }
    // let currentUrl = window.location.href;
    // var receiver = currentUrl?.split('/user/messages/')[1].split('?')[0];
    dispatch(
      chat_info_set(data)
    );

    // Create a new URLSearchParams object from the current query string
    const searchParams = new URLSearchParams(window.location.search);
    
    // Set a new or update existing parameter
    searchParams.set('i', '0');

    // Use history to update the URL without reloading the page
    navigate(`${window.location.pathname}?${searchParams.toString()}`);

    setMessage("");
    setHeightBack();
    setSender(true);
  };

  const openModal = () => {
    setAlertModal((prev) => {
      return { ...prev, hasOpen: true };
    });
  };

  // const phoneNumberModal = alertModal.hasOpen
  //   ? createPortal(
  //     <div className="top-0 left-0 fixed bottom-0 right-0 z-10 h-screen flex justify-center items-center bg-black bg-opacity-70 backdrop-blur-[2px] ">
  //       <PhoneNumberCopyModal
  //         calledNumber={() =>
  //           setAlertModal((prev) => {
  //             return { hasCheck: true, hasOpen: false };
  //           })
  //         }
  //         phoneNumber={chat_info[props.index]?.chatIds[0]?.employeeId?.Phone_number}
  //         employeeName={params.toLocaleLowerCase()}
  //       />
  //     </div>,
  //     document.getElementById("modal")
  //   )
  //   : null;

    // console.log(employee?.message?.photo);
    // console.log(sender);

  const handleSendDecisionCard = (message) => {
    if (["", null, undefined].includes(message) === false) {
      if (messages?.length == 0) {
        setMessages({ text: message });
      } else {
        setMessages((prev) => [...prev, { text: message }]);
      }
      if (AcctType === "Client") {
        setSenderImage(employee.message?.photo);
      }
      if (props.userType === "employee") {
        dispatch(
          sendMessage(
            chat_info[props.index]?._id,
            employee.message?._id,
            message
          )
        );
      }
    }
    setHeightBack();
  };

  const handleTypeMessage = (e) => {
    const { name, value } = e.target;
    setMessage(value);
    adjustTextareaHeight();
  };

  // Function to auto-adjust textarea height based on content
  const adjustTextareaHeight = () => {
    const textarea = document.getElementById("messageTextarea");
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
  };

  // Function to auto-adjust textarea height based on content
  const setHeightBack = () => {
    const textarea = document.getElementById("messageTextarea");
    textarea.style.height = "auto";
  };

  const handleResumeView = () => {
    if (props.userType === "employee") {
      // /user/available_jobs?id=Tolulope_Adeleke
      navigate("/user/available_jobs?d=fls");
    }
  };

  const handleBack = () => {
    if (props.userType === "employee") {
      navigate("/user/messages");
    }
  };

  // to be called by useEffect.
  const sendDecisonCard = (cardId) => {
    // console.log("sent mest");
    // console.log("decisioncard", decisonCard);
    // if (["", null, undefined].includes(decisonCard) === false) {
    handleSendDecisionCard(
      `Decison card implementation cardId:${decisionCard.message?._id
      }&secret:${nanoid()}`
    );
    // }
  };

  function capitalizeAfterSpaceOrDash(str) {
    return str.replace(/(?:^|\s|-)([a-z])/g, (match, char) => match.toUpperCase());
  }


  const handleDecisionCard = () => {
    if (
      decisionCard.message !== null &&
      decisionCard.message?.employeeId ===
      chat_info[index]?.chatIds[0]?.employeeId?._id &&
      decisionCard.message?.JobId === chat_info[index]?.chatIds[0]?.jobId
    ) {
      return;
    } else {
      navigate(`/employer/inbox/${params}/decisionCard?index=${index}`);
      // if (messages.length < 10) {
      //   return ;
      // } else {

      // }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      // console.log(e.key);
      e.preventDefault(); // Prevents the default behavior of the Enter key (e.g., form submission)
      handleSendMessage(message);
    }
  };

  return (
    <div className="w-full xl:w-[80%] relative bg-white rounded-xl shadow border border-indigo-400 border-opacity-30 block">
      <div className="w-full flex flex-col justify-start sm:flex-row sm:items-center sm:justify-between absolute top-0 px-2 sm:px-6 py-3">
        <div className="flex gap-[8px] sm:gap-[1.63rem] items-center">
          <div className="flex items-center">
            <button onClick={handleBack} className="xl:hidden">
              <svg
                className="w-6 h-6"
                viewBox="0 0 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.65625 16.4062H29.5312C29.8213 16.4062 30.0995 16.5215 30.3046 16.7266C30.5098 16.9317 30.625 17.2099 30.625 17.5C30.625 17.7901 30.5098 18.0683 30.3046 18.2734C30.0995 18.4785 29.8213 18.5938 29.5312 18.5938H7.65625C7.36617 18.5938 7.08797 18.4785 6.88285 18.2734C6.67773 18.0683 6.5625 17.7901 6.5625 17.5C6.5625 17.2099 6.67773 16.9317 6.88285 16.7266C7.08797 16.5215 7.36617 16.4062 7.65625 16.4062Z"
                  fill="#5843BD"
                />
                <path
                  d="M8.10906 17.4994L17.1806 26.5688C17.386 26.7742 17.5014 27.0527 17.5014 27.3432C17.5014 27.6336 17.386 27.9122 17.1806 28.1176C16.9753 28.3229 16.6967 28.4383 16.4063 28.4383C16.1158 28.4383 15.8373 28.3229 15.6319 28.1176L5.78813 18.2738C5.68627 18.1722 5.60546 18.0515 5.55032 17.9186C5.49518 17.7857 5.4668 17.6433 5.4668 17.4994C5.4668 17.3556 5.49518 17.2131 5.55032 17.0802C5.60546 16.9474 5.68627 16.8267 5.78813 16.7251L15.6319 6.8813C15.8373 6.67593 16.1158 6.56055 16.4063 6.56055C16.6967 6.56055 16.9753 6.67593 17.1806 6.8813C17.386 7.08668 17.5014 7.36523 17.5014 7.65568C17.5014 7.94613 17.386 8.22468 17.1806 8.43005L8.10906 17.4994Z"
                  fill="#5843BD"
                />
              </svg>
            </button>

            <div className="w-8 h-8 bg-violet-200 rounded-full justify-center items-center inline-flex">
              <img className="w-7 h-7 rounded-full" src={props.avatar} />
            </div>
          </div>
          <div className="text-neutral-950 text-sm xsm:text-base font-normal">
            {chat_info[props.index]?.chatIds[0]?.employerId?.name}{" "}
            {/* name */}
          </div>
        </div>
        <div className="px-2 py-1 bg-white rounded-lg justify-start items-center gap-4 inline-flex">
          <button
            className="rounded-lg justify-start items-center gap-2 flex focus:bg-white"
            onClick={handleResumeView}
          >
            <span className="w-6 h-6 block">
              {props.userType === "employee" ? (
                <BusinessCenter />
              ) : (
                null
              )}
            </span>
            <span className="rounded-lg flex-col justify-center items-start inline-flex">
              <span className="text-zinc-900 text-xs sm:text-sm font-semibold font-['Poppins'] block leading-[12px] sm:leading-[18px]">
                View Jobs
              </span>
              <span className="self-stretch text-zinc-900 text-opacity-60 block text-[8px] xsm:text-xs font-normal font-['Poppins'] leading-[12px] sm:leading-[18px]">
                Jobs created on Moil.
              </span>
            </span>
          </button>
          {messages?.length === 0 && <div></div>}
        </div>
      </div>
      <div className="absolute top-[84px] sm:top-[54px] w-full flex flex-col gap-y-2 mt-2">
        {AcctType === "Client" ? (
          <MessageBoxHeader isAllowedToHire={false} />
        ) : (
          null
        )}
        {/* {alertModal.hasCheck === false & AcctType === "Client" && (
          <MessageBoxAlert handleShowAlertModal={handleShowAlertModal} />
        )} */}
      </div>
      {messages?.length > 0 ? (
        <div
          ref={messageContainerRef}
          className={`h-max ${AcctType === "Client" ? "max-h-calc-200  sm:max-h-calc-220" : "max-h-calc-200  sm:max-h-calc-220"} absolute bottom-[94px] w-full overflow-y-scroll overflow-x-hidden`}
        >
          {messages?.[messages.length - 1].senderId === employer.message?._id ||
            messages?.[messages.length - 1].senderId === employee.message?._id ? (
            <>
              {messages?.map((message, index) =>
                index !== messages.length - 1 ? (
                  <MessageItem
                    message={message?.text}
                    isUser={message?.senderId}
                    lastMessageRef={lastMessageRef}
                    key={index}
                    sender={sender}
                    senderImage={senderImage}
                    receiverImage={props.avatar}
                    isDecisionCard={
                      "decision" ? regexPattern.test(message?.text) : "plain"
                    }
                    neededName={capitalizeAfterSpaceOrDash(params.toLowerCase())}
                    chat_information={chat_info}
                  />
                ) : (
                  <NewMessageItem
                    message={message?.text}
                    isUser={message?.senderId}
                    lastMessageRef={lastMessageRef}
                    key={index}
                    sender={sender}
                    senderImage={senderImage}
                    receiverImage={props.avatar}
                    isDecisionCard={regexPattern.test(message?.text)}
                    neededName={capitalizeAfterSpaceOrDash(params.toLowerCase())}
                    chat_information={chat_info}
                  />
                )
              )}
            </>
          ) : (
            messages?.map((message, index) => (
              <MessageItem
                message={message?.text}
                isUser={message?.senderId}
                lastMessageRef={lastMessageRef}
                key={index}
                sender={sender}
                senderImage={senderImage}
                receiverImage={props.avatar}
                isDecisionCard={
                  "decision" ? regexPattern.test(message?.text) : "plain"
                }
                neededName={capitalizeAfterSpaceOrDash(params.toLowerCase())}
                chat_information={chat_info}
              />
            ))
          )}
        </div>
      ) : (
        // <div className="h-full max-h-calc-94 absolute bottom-[94px] w-full flex justify-center items-center">
        //   <img src='https://res.cloudinary.com/drlcisipo/image/upload/v1705704285/Website%20images/Emails-cuate_1_bi6b7h.png' />
        // </div>
        <></>
      )}
      <div className="w-full absolute bottom-[71px] px-6 my-3">
        <hr className="w-full h-[1px] bg-indigo-400" />
      </div>
      <div className="bottom-0 absolute w-full h-max max-h-[200px] px-2 sm:px-6 pb-3 flex justify-between gap-2 sm:gap-4">
        <div className="w-full bg-white h-max relative chatInboxbox whitespace-pre-wrap">
          <textarea
            className="w-[85%] left-[45px] overflow-hidden h-max py-3 px-2 pl-[14px] sm:px-3 sm:pl-[20px] border border-transparent inputborder focus:ring-transparent focus:border-transparent resize-none"
            onChange={handleTypeMessage}
            value={message}
            rows="1"
            id="messageTextarea"
            placeholder="type here"
            onKeyDown={handleKeyDown}
          ></textarea>
          <button
            className="w-[60%] absolute right-[15px] bg-indigo-700 w-max p-2 rounded-[8px] bottom-[12px]"
            onClick={() => handleSendMessage(message)}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.05588 4.96147L5.97054 8.13284C5.74415 8.36632 5.38841 8.4182 5.10381 8.26255L1.37812 6.21964C0.828321 5.92132 0.893019 5.11712 1.47516 4.90311L12.0248 1.04428C12.6005 0.836749 13.1632 1.3945 12.9562 1.9717L9.19819 12.517C8.9912 13.0942 8.21501 13.172 7.89807 12.6532L6.49446 10.3509"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        {/* {AcctType === "Official" && (
          <>
            {window.innerWidth < 1024 ? (
              <a
                href={
                  `tel:${chat_info[props.index]?.chatIds[0]?.employeeId?.Phone_number
                  }`
                }
                className="self-end"
              >
                <CopyNumberSvg className="w-8 h-8 sm:w-full sm:h-full" />
              </a>
            ) : (
              <button onClick={openModal}>
                <CopyNumberSvg className="w-8 h-8 sm:w-full sm:h-full" />
              </button>
            )}
          </>
        )} */}
      </div>
      {/* {window.innerWidth > 1023 && phoneNumberModal} */}
    </div>
  );
}

