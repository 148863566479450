// import { Configuration, OpenAIApi } from 'openai';
import OpenAI from "openai"
import { cv_success, dispatchSpanish, spanish_profile_successful, dispatchJobDetail, SiteError, dispatchEducation, dispatchActivites, dispatchSkills, dispatchPskills } from './ActionCreator';
import { AdjustGPTResponse } from '../utils/pdfUtil/Adjust_gptRes';
import { baseURL1 } from './baseURL';
import { saveIntoServerCache } from "../utils/saveIntoCache"
import { store } from "./ConfigureStore";

//    " School, Degree, Course, Course should be a string inside of Education object array and not an array, Start_date, End_date, Interest, CvType, Award, Education, Experience, Technical_skills, Personal_skills"
// Education, Experience, Technical_skills, Personal_skills, Award should all be in an array.`


  //  const command = `Strictly examine and convert all values of this object ${userInformation} into ${cred.Lang[0].lang}. All keys should be in English while values should be changed to ${cred.Lang[0].lang}. Do not truncate "Experience" array or any array for brevity but strictly return all the experiences array, make sure to return everything.
  //   Return the response or result exactly in object-readable format following this key:
  //   "Professional_summary, School, Degree, Course, Course should be a string inside of Education object array and not an array, Start_date, End_date, CvType, Job_type, Award, Education, Experience, Technical_skills, Personal_skills"
  //   Education, Experience, Technical_skills, Personal_skills, Award should all be in an array.


export const convertOnce = (cred, flag, redirect, language, count = 0) => async (dispatch) => {
  const valueToBeMapped = { "Fullname": cred.Fullname, "Address": cred.Address, "Email": cred.Email, "Phone_number": cred.Phone_number, "Lang": cred.Lang, "Reference": cred.Reference, "Language": cred.Language, "Website": cred.Website, "Linkedin": cred.Linkedin, "year_of_experience": cred.year_of_experience };
  // console.log("type", cred.Lang[0].lang)
    const detailsToFilter = { ...cred};
    delete detailsToFilter.Fullname;
    delete detailsToFilter.Address;
    delete detailsToFilter.Email;
    delete detailsToFilter.Phone_number;
    delete detailsToFilter.Language;
    delete detailsToFilter.year_of_experience;
  
    const userInformation = `${JSON.stringify(detailsToFilter)}`;

    const command = `Strictly examine and convert all values of this object ${userInformation} into ${language}. All keys should be in English while values should be changed to ${language}. Do not truncate "Experience" array or any array for brevity but strictly return all the experiences array, make sure to return everything.
    Organize the output in JSON as follows::
    {
      "Professional_summary": "The summary goes here",    
      "CvType": "Specified job title",
      "Job_type": "Job_type",
      "Award": [Award],
      "Technical_skills": [Technical_skills],
      "Personal_skills": [Personal_skills],
      "Education": [{
        "School": "School",
        "Degree": "Degree",
        "Course": "Course",
        "End_date": "End_date",
      }, ...],
      "Experience": [{
        "Where_i_worked": "where_i_worked",
        "Where_i_worked_location": "where_i_worked_location",
        "My_job_title":  "Job title",
        "Work_start_date" : "Work_start_date",
        "Work_end_date" : "Work_end_date",
        "Day_to_day_work": [exp_value],
      }],
      "Reference": [{
        "Reference_person_name": "Reference_person_name",
        "How_do_i_know_this_person": "How_do_i_know_this_person",
        "Referencee_job_title": "Referencee_job_title",
        "Reference_email": "Reference_email",
        "Reference_phone_number": "Reference_phone_number",
      }]
    }`
     
  
    // const configuration = new Configuration({
    //   apiKey: "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",
    // });

    // delete configuration.baseOptions.headers['User-Agent'];
  const openai = new OpenAI({
    apiKey: "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",
    dangerouslyAllowBrowser: true
  });
    await openai.chat.completions.create({
      model: 'gpt-4o',
      messages: [{ role: 'user', content: command}],
      temperature: 0,
      response_format: { type: "json_object" }
    }).then(resp => {
      const result = resp.choices[0].message.content;
      // console.log("result", result);
      let r1 = result.replace('\n', "");
      // console.log("r1", r1)
      // console.log("Strip!!!", r1.slice(r1.indexOf('{'), r1.lastIndexOf('}') + 1));
      let r2 = r1.slice(r1.indexOf('{'), r1.lastIndexOf('}') + 1);
      try {
        r2 = JSON.parse(r2);
        // console.log("r2", r2);
      } catch (error) {
        if (error instanceof SyntaxError) {
         // console.log("count", count);
          // console.error('Invalid JSON format:', error.message);
          if (count <= 3) {
            // console.log("Calling the gpt review function again.")
            dispatch(convert(cred, flag, redirect, count += 1));
          } else {
            dispatch(SiteError("Please try again"));
          }
        } else {
          console.error('Unexpected error:', error.message);
        }
      }
      if (typeof r2 == "object") {
        const final_result = {...valueToBeMapped, ...r2};
        // console.log("keys after parsing", Object.keys(final_result));
    
        // console.log("final_result", final_result);
        if (flag === "resume" && redirect == "/generateCV?download=true") {
          dispatch(dispatchSpanish(final_result));
          dispatch(cv_success(final_result));
          // window.location.assign(baseURL1 + "/generateCV?download=true");
        } else if (flag === "resume" && redirect == "none") {
          dispatch(dispatchSpanish(final_result));
          dispatch(cv_success(final_result));
        }
      }
      // else if (flag === "profile") {
      //   const result = AdjustGPTResponse(final_result);
      //   dispatch(spanish_profile_successful(result));
      // }
    })
}



export const convert = (cred, flag, redirect, count = 0) => async (dispatch) => {
  const valueToBeMapped = { "Fullname": cred.Fullname, "Address": cred.Address, "Email": cred.Email, "Phone_number": cred.Phone_number, "Lang": cred.Lang, "Reference": cred.Reference, "Language": cred.Language, "year_of_experience": cred.year_of_experience,"Education": cred.Education, "Experience": cred.Experience, "Technical_skills": cred.Technical_skills, "Personal_skills": cred.Personal_skills, "Award": cred.Award };
  // console.log("type", cred.Lang[0].lang)
    const detailsToFilter = { ...cred};
    delete detailsToFilter.Fullname;
    delete detailsToFilter.Address;
    delete detailsToFilter.Email;
    delete detailsToFilter.Phone_number;
    delete detailsToFilter.Language;
    delete detailsToFilter.year_of_experience;
  
    const userInformation = `${JSON.stringify(detailsToFilter)}`;

    const command = `Strictly examine and convert all values of this object ${userInformation} into ${cred.Lang[0].lang}. All keys should be in English while values should be changed to ${cred.Lang[0].lang}. Do not truncate "Experience" array or any array for brevity but strictly return all the experiences array, make sure to return everything.
    Organize the output in JSON as follows::
    {
      "Professional_summary": "The summary goes here",    
      "CvType": "Specified job title",
      "Job_type": "Job_type",
    }`
     
  
    // const configuration = new Configuration({
    //   apiKey: "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",
    // });

    // delete configuration.baseOptions.headers['User-Agent'];
  const openai = new OpenAI({
    apiKey: "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",
    dangerouslyAllowBrowser: true
  });
    await openai.chat.completions.create({
      model: 'gpt-4o',
      messages: [{ role: 'user', content: command}],
      temperature: 0,
      response_format: { type: "json_object" }
    }).then(resp => {
      const result = resp.choices[0].message.content;
      // console.log("result", result);
      let r1 = result.replace('\n', "");
      // console.log("r1", r1)
      // console.log("Strip!!!", r1.slice(r1.indexOf('{'), r1.lastIndexOf('}') + 1));
      let r2 = r1.slice(r1.indexOf('{'), r1.lastIndexOf('}') + 1);
      try {
        r2 = JSON.parse(r2);
        // console.log("r2", r2);
      } catch (error) {
        if (error instanceof SyntaxError) {
          // console.log("count", count);
          // console.error('Invalid JSON format:', error.message);
          if (count <= 3) {
            // console.log("Calling the gpt review function again.")
            dispatch(convert(cred, flag, redirect, count += 1));
          } else {
            dispatch(SiteError("Please try again"));
          }
        } else {
          console.error('Unexpected error:', error.message);
        }
      }
      if (typeof r2 == "object") {
        const final_result = {...valueToBeMapped, ...r2};
        // console.log("keys after parsing", Object.keys(final_result));

        // console.log("final_result", final_result);
        if (flag === "resume" && redirect == "/allCv") {
          dispatch(dispatchSpanish(final_result));
          dispatch(cv_success(final_result));
          const store_value = store.getState();
          let profileId = store_value.userInfo.user.profile_id;
          window.location.assign(baseURL1 + "/allCv");
        } else if (flag === "resume" && redirect !== "/allCv") {
          dispatch(dispatchSpanish(final_result));
          dispatch(cv_success(final_result));
          const store_value = store.getState();
          let profileId = store_value.userInfo.user.profile_id;
          window.location.assign(baseURL1 + `${redirect}`)
        } else if (flag === "resume" && redirect == "none") {
          dispatch(dispatchSpanish(final_result));
          dispatch(cv_success(final_result));
        }
      }
      // else if (flag === "profile") {
      //   const result = AdjustGPTResponse(final_result);
      //   dispatch(spanish_profile_successful(result));
      // }
    })
}



export const convertEducation = (cred, flag, count = 0) => async (dispatch) => {
  // const valueToBeMapped = {"Language": cred.Language, };
  // console.log("cred", cred);
  if (cred !== undefined) {
    // console.log("type", cred.Lang[0].lang)
    const detailsToFilter = { ...cred};
  
    const userInformation = `${JSON.stringify(detailsToFilter)}`;

    const command = `Strictly examine and convert all values of this object ${userInformation} into ${cred.Lang[0].lang}. All keys should be in English while values should be changed to ${cred.Lang[0].lang}. Do not truncate "Education" array or any array for brevity but strictly return all the education array, make sure to return everything.
    Organize the output in JSON as follows::
    {
      "Education": [{
        "School": "School",
        "Degree": "Degree",
        "Course": "Course",
        "End_date": "End_date",
      }, ...],
    }`
     
  
    // const configuration = new Configuration({
    //   apiKey: "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",
    // });

    // delete configuration.baseOptions.headers['User-Agent'];
  const openai = new OpenAI({
    apiKey: "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",
    dangerouslyAllowBrowser: true
  });
    await openai.chat.completions.create({
      model: 'gpt-4o',
      messages: [{ role: 'user', content: command}],
      temperature: 0,
      response_format: { type: "json_object" }
    }).then(resp => {
      const result = resp.choices[0].message.content;
      // console.log("result", result);
      let r1 = result.replace('\n', "");
      // console.log("r1", r1)
      // console.log("Strip!!!", r1.slice(r1.indexOf('{'), r1.lastIndexOf('}') + 1));
      let r2 = r1.slice(r1.indexOf('{'), r1.lastIndexOf('}') + 1);
      try {
        r2 = JSON.parse(r2);
        // console.log("r2", r2);
      } catch (error) {
        if (error instanceof SyntaxError) {
          // console.log("count", count);
          // console.error('Invalid JSON format:', error.message);
          if (count <= 3) {
            // console.log("Calling the gpt review function again.")
            dispatch(convert(cred, flag, redirect, count += 1));
          } else {
            dispatch(SiteError("Please try again"));
          }
        } else {
          console.error('Unexpected error:', error.message);
        }
      }
      if (typeof r2 == "object") {
        const final_result = { ...r2 };
        // console.log("Eduction keys after parsing", Object.keys(final_result));
    
        // console.log("Education final result", final_result);
        if (flag === "resume") {
          // dispatch(dispatchSpanish(final_result));
          // dispatch(cv_success(final_result));
          dispatch(dispatchEducation(final_result.Education));
        }
      }
      // else if (flag === "profile") {
      //   const result = AdjustGPTResponse(final_result);
      //   dispatch(spanish_profile_successful(result));
      // }
    })
  }
}


export const convertExperience = (cred, flag, count = 0) => async (dispatch) => {
  // const valueToBeMapped = {"Language": cred.Language, };
  // console.log("cred", cred);
  if (cred !== undefined) {
    // console.log("type", cred.Lang[0].lang)
    const detailsToFilter = { ...cred};
  
    const userInformation = `${JSON.stringify(detailsToFilter)}`;

    const command = `Strictly examine and convert all values of this object ${userInformation} into ${cred.Lang[0].lang}. All keys should be in English while values should be changed to ${cred.Lang[0].lang}. Do not truncate "Experience" array or any array for brevity but strictly return all the experience array, make sure to return everything.
    Organize the output in JSON as follows::
    {
      "Experience": [{
        "Where_i_worked": "where_i_worked",
        "Where_i_worked_location": "where_i_worked_location",
        "My_job_title":  "Job title",
        "Work_start_date" : "Work_start_date",
        "Work_end_date" : "Work_end_date",
        "Day_to_day_work": [exp_value],
      }],
    }`
     
  
    // const configuration = new Configuration({
    //   apiKey: "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",
    // });

    // delete configuration.baseOptions.headers['User-Agent'];
  const openai = new OpenAI({
    apiKey: "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",
    dangerouslyAllowBrowser: true
  });
    await openai.chat.completions.create({
      model: 'gpt-4o',
      messages: [{ role: 'user', content: command}],
      temperature: 0,
      response_format: { type: "json_object" }
    }).then(resp => {
      const result = resp.choices[0].message.content;
      // console.log("result", result);
      let r1 = result.replace('\n', "");
      // console.log("r1", r1)
      // console.log("Strip!!!", r1.slice(r1.indexOf('{'), r1.lastIndexOf('}') + 1));
      let r2 = r1.slice(r1.indexOf('{'), r1.lastIndexOf('}') + 1);
      try {
        r2 = JSON.parse(r2);
        // console.log("r2", r2);
      } catch (error) {
        if (error instanceof SyntaxError) {
          // console.log("count", count);
          // console.error('Invalid JSON format:', error.message);
          if (count <= 3) {
            // console.log("Calling the gpt review function again.")
            dispatch(convert(cred, flag, redirect, count += 1));
          } else {
            dispatch(SiteError("Please try again"));
          }
        } else {
          console.error('Unexpected error:', error.message);
        }
      }
      if (typeof r2 == "object") {
        const final_result = { ...r2 };
        // console.log("Experience keys after parsing", Object.keys(final_result));
    
        // console.log("Experience final result", final_result);
        if (flag === "resume") {
          // dispatch(dispatchSpanish(final_result));
          // dispatch(cv_success(final_result));
          dispatch(dispatchActivites(r2.Experience));
        }
      }
      // else if (flag === "profile") {
      //   const result = AdjustGPTResponse(final_result);
      //   dispatch(spanish_profile_successful(result));
      // }
    })
  }
}


export const convertSkills = (cred, flag, count = 0) => async (dispatch) => {
  // const valueToBeMapped = {"Language": cred.Language, };
  // console.log("cred", cred);
  if (cred !== undefined) {
    // console.log("type", cred.Lang[0].lang)
    const detailsToFilter = { ...cred};
  
    const userInformation = `${JSON.stringify(detailsToFilter)}`;

    const command = `Strictly examine and convert all values of this object ${userInformation} into ${cred.Lang[0].lang}. All keys should be in English while values should be changed to ${cred.Lang[0].lang}. Do not truncate "Experience" array or any array for brevity but strictly return all the experience array, make sure to return everything.
    Organize the output in JSON as follows::
    {
      "Technical_skills": [Technical_skills],
      "Personal_skills": [Personal_skills],
    }`
     
  
    // const configuration = new Configuration({
    //   apiKey: "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",
    // });

    // delete configuration.baseOptions.headers['User-Agent'];
  const openai = new OpenAI({
    apiKey: "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",
    dangerouslyAllowBrowser: true
  });
    await openai.chat.completions.create({
      model: 'gpt-4o',
      messages: [{ role: 'user', content: command}],
      temperature: 0,
      response_format: { type: "json_object" }
    }).then(resp => {
      const result = resp.choices[0].message.content;
      // console.log("result", result);
      let r1 = result.replace('\n', "");
      // console.log("r1", r1)
      // console.log("Strip!!!", r1.slice(r1.indexOf('{'), r1.lastIndexOf('}') + 1));
      let r2 = r1.slice(r1.indexOf('{'), r1.lastIndexOf('}') + 1);
      try {
        r2 = JSON.parse(r2);
        // console.log("r2", r2);
      } catch (error) {
        if (error instanceof SyntaxError) {
          // console.log("count", count);
          // console.error('Invalid JSON format:', error.message);
          if (count <= 3) {
            // console.log("Calling the gpt review function again.")
            dispatch(convert(cred, flag, redirect, count += 1));
          } else {
            dispatch(SiteError("Please try again"));
          }
        } else {
          console.error('Unexpected error:', error.message);
        }
      }
      if (typeof r2 == "object") {
        const final_result = { ...r2 };
        // console.log("Experience keys after parsing", Object.keys(final_result));

        // console.log("Experience final result", final_result);
        if (flag === "resume") {
          // dispatch(dispatchSpanish(final_result));
          // dispatch(cv_success(final_result));
          dispatch(dispatchSkills(r2.Technical_skills));
          dispatch(dispatchPskills(r2.Personal_skills));
          saveIntoServerCache("technicalSkills", r2.Technical_skills);
          saveIntoServerCache("personalSkills", r2.Personal_skills);
        }
      }
      // else if (flag === "profile") {
      //   const result = AdjustGPTResponse(final_result);
      //   dispatch(spanish_profile_successful(result));
      // }
    })
  }
}


export const convert_employer_JobPost = (cred, flag, redirect, count) => async (dispatch) => {
  const valueToBeMapped = { "display": cred.display, "paymentPlan": cred.paymentPlan, "compensation": cred.compensation, "duration": cred.duration, "zipCode": cred.zipCode, "Category": cred.Category, "verify": cred.verify, "city": cred.city, "state": cred.state };

    const detailsToFilter = { ...cred};
    delete detailsToFilter.display;
    delete detailsToFilter.paymentPlan;
    delete detailsToFilter.compensation;
    delete detailsToFilter.duration;
    delete detailsToFilter.zipCode;
    delete detailsToFilter.Category;
    delete detailsToFilter.verify;
    delete detailsToFilter.city;
    delete detailsToFilter.state;
  
    const userInformation = `${JSON.stringify(detailsToFilter)}`;

    const command = `Strictly examine and convert all values of this object ${userInformation} into English. All keys should be in English while values should be changed to English. Do not truncate "Description" or any array for brevity but strictly return all the Description, make sure to return everything.
    Organize the output in JSON as follows:
    {
      "jobTitle": "jobTitle",,    
      "employmentType": "employmentType",
      "Description": "Description",
      "Responsibilities": [Responsibilities],
      "Skills": [Skills],
    }`
     
  
    // const configuration = new Configuration({
    //   apiKey: "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",
    // });

    // delete configuration.baseOptions.headers['User-Agent'];
  const openai = new OpenAI({
    apiKey: "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",
    dangerouslyAllowBrowser: true
  });
    await openai.chat.completions.create({
      model: 'gpt-4o',
      messages: [{ role: 'user', content: command}],
      temperature: 0,
      response_format: { type: "json_object" }
    }).then(resp => {
      const result = resp.choices[0].message.content;
      // console.log("result", result);
      let r1 = result.replace('\n', "");
      // console.log("r1", r1)
      // console.log("Strip!!!", r1.slice(r1.indexOf('{'), r1.lastIndexOf('}') + 1));
      let r2 = r1.slice(r1.indexOf('{'), r1.lastIndexOf('}') + 1);
      try {
        r2 = JSON.parse(r2);
        // console.log("r2", r2);
      } catch (error) {
        if (error instanceof SyntaxError) {
          console.error('Invalid JSON format:', error.message);
          // console.log("count", count);
          if (count <= 3) {
            // console.log("Calling the gpt review function again.")
            dispatch(convert_employer_JobPost(cred, flag, redirect, count += 1));
          } else {
            dispatch(SiteError("Please try again"));
          }
        } else {
          console.error('Unexpected error:', error.message);
        }
      }
      if (typeof r2 == "object") {
        const final_result = {...valueToBeMapped, ...r2};
    
        // console.log("final_result", final_result);
        if (flag === "job posting" && redirect == "/employer/create-job/preview") {
          dispatch(dispatchJobDetail(final_result));
          window.location.assign(baseURL1 + "/employer/create-job/preview")
        }
      }
    })
}




export const handleLastPageTranslation = (cred, flag) => async (dispatch) => {
  const valueToBeMapped = { "Fullname": cred.Fullname, "Address": cred.Address, "Email": cred.Email, "Phone_number": cred.Phone_number, "Lang": cred.Lang, "Reference": cred.Reference, "Language": cred.Language, "Website": cred.Website, "Linkedin": cred.Linkedin };
  // console.log("type", cred.Lang[0].lang)
    const detailsToFilter = { ...cred};
    delete detailsToFilter.Fullname;
    delete detailsToFilter.Address;
    delete detailsToFilter.Email;
    delete detailsToFilter.Phone_number;
    delete detailsToFilter.Language;
    delete detailsToFilter.Website;
    delete detailsToFilter.Linkedin;
  
    const userInformation = `${JSON.stringify(detailsToFilter)}`;

    const command = `Strictly examine and convert all values of this object ${userInformation} into ${cred.Lang[0].lang}. All keys should be in English while values should be changed to ${cred.Lang[0].lang}. Do not truncated "Experience" array or any array for brevity but strictly return all the experiences array, make sure to return everything.
    Return the response or result exactly in object-readable format following this key:
    "Professional_summary, School, Degree, Course, Course should be a string inside of Education object array and not an array, Start_date, End_date, Interest, CvType, Job_type, Award, Education, Experience, Technical_skills, Personal_skills"
    Education, Experience, Technical_skills, Personal_skills, Award should all be in an array.`
     
  
    // const configuration = new Configuration({
    //   apiKey: "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",
    // });

    // delete configuration.baseOptions.headers['User-Agent'];
  const openai = new OpenAI({
    apiKey: "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",
    dangerouslyAllowBrowser: true
  });
    await openai.chat.completions.create({
      model: 'gpt-4o',
      messages: [{ role: 'user', content: command}],
      temperature: 0
    }).then(resp => {
      const result = resp.choices[0].message.content;
      // console.log("result", result);
      let r1 = result.replace('\n', "");
      // cred.Lang[0].lang
      // console.log("r1", r1)
      // console.log("Strip!!!", r1.slice(r1.indexOf('{'), r1.lastIndexOf('}') + 1));
      const r2 = r1.slice(r1.indexOf('{'), r1.lastIndexOf('}') + 1);
      const final_result = {...valueToBeMapped, ...JSON.parse(r2)};
      // console.log("keys after parsing", Object.keys(final_result));
  
      // console.log("final_result", final_result);
      if (flag === "resume") {
        dispatch(dispatchSpanish(final_result));
        dispatch(cv_success(final_result));
      } else if (flag === "profile") {
        const result = AdjustGPTResponse(final_result);
        dispatch(spanish_profile_successful(result));
      }
    })
}
