import { createPortal } from "react-dom";
import { GamefiedVector } from "../../Resume/svgs/svgs";
import { useNavigate, Link } from "react-router-dom";
import { baseURL } from "../../../Redux/baseURL";
import { getting_profile_successful } from "../../../Redux/ActionCreator"
import { useDispatch } from "react-redux";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";

export default function CompleteConfirmationGamifiedModal(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAnyClick = async (location) => {
    if (location == "dashboard") {
      // update the profile to completed;
      fetch(baseURL + "/api/client/profileStatus", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Bearer " + localStorage.getItem("token"),
        },
        credentials: "include"
      }).then((resp) => resp.json())
        .then(resp => {
          // console.log(resp)
          if (resp.success == true && resp.data !== null) {
            // dispatch into profile store.
            dispatch(getting_profile_successful(resp.data));
            // then navigate to dashboard
            navigate("/user/dashboard");
          }
        }).catch(err => {
          // console.log("error", err);
        })
    } else if (location == "profile") {
      // update the profile to completed;
      fetch(baseURL + "/api/client/profileStatus", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Bearer " + localStorage.getItem("token"),
        },
        credentials: "include"
      }).then((resp) => resp.json())
        .then(resp => {
          // console.log(" '  '", resp)
          if (resp.success == true && resp.data !== null) {
            // dispatch into profile store.
            dispatch(getting_profile_successful(resp.data));
            // then hide the modal
            props.handleContinue(false);
          }
        }).catch(err => {
          // console.log("error", err);
        })
    }
  }

  return (
    <div>
      {
        createPortal(
          <div onClick={(e) => e.stopPropagation()} className="fixed top-0 left-0 bottom-0 right-0 z-10 bg-tet-600 overflow-y-scroll min-h-screen py-7 backdrop-blur-[2px] flex justify-center items-center">
            <section onClick={(e) => e.stopPropagation()} className="bg-white md:max-w-[550px] sm:max-w-[500px] min-w-[350px] w-[80%] z-100 m-auto rounded-[24px] pb-5 overflow-hidden relative">
              <div className="w-full max-w-[660px] p-4 flex justify-between items-center bg-[#5843BD] border-b-8 border-[#E9F1FF] rounded-tr-[24px] rounded-tl-[24px] ">
                <p className=" text-lg font-medium leading-normal text-left text-white">
                  Profile Completion
                </p>
                <div className="w-[64px] h-[64px] bg-[#F5F9FF] rounded-[50%] font-semibold">
                  <CircularProgressbarWithChildren
                    value={100}
                    styles={buildStyles({
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: 'round',
                      // How long animation takes to go from one percentage to another, in seconds
                      pathTransitionDuration: 0.5,
                      // Can specify path transition in more detail, or remove it entirely
                      // pathTransition: 'none',
                      // Colors
                      pathColor: 100 < 50 ? '#FF6633' : `#5843BD`,

                      trailColor: '#FFFFFF80',
                    })}
                  >
                    <p className="text-[20px] text-[#000000DE]">{100}%</p>
                  </CircularProgressbarWithChildren>
                </div>
              </div>

              <div className="absolute top-[-2%] -z-1 left-[-20%]">
                <GamefiedVector />
              </div>

              <div className="flex flex-col items-center relative z-[1000] justify-center gap-y-4">
                <div className="w-full">
                  <div className="px-4 py-2 w-full gap-x-4 flex justify-between items-center">
                    <img src="https://res.cloudinary.com/drlcisipo/image/upload/v1717676522/confetti.png" className="w-[70px] md:w-[90px]" />
                    <div className="incomplete-profile-image w-[64px] h-[64px] font-semibold flex justify-center items-center">
                      <img src="https://res.cloudinary.com/drlcisipo/image/upload/v1717677314/fourty_memoji.png" className="w-full rounded-[50%]" />
                    </div>
                    <img src="https://res.cloudinary.com/drlcisipo/image/upload/v1717676522/confetti.png" className="w-[70px] md:w-[90px]" />
                  </div>

                  <p className="text-[32px] font-semibold leading-none text-center text-[#5843BD]">
                    You have<br />
                    a complete profile!
                  </p>
                </div>


                <p className="text-base font-semibold rounded-[4px] leading-tight p-2 w-max text-center text-black bg-[#FF66331A]">
                  Your profile is <span className="text-[#FF6633]">100% complete</span>
                </p>

                <p className=" text-base font-normal leading-[1.2] text-center max-w-[280px] sm:max-w-[400px]">
                  You just got full access into Moil’s world of  <br className="hidden md:block" />opportunities. View your profile, download your <br className="hidden md:block" />
                  resume, search for and apply to jobs, and do <br className="hidden md:block" />much more! You’ve got this!

                </p>

                <div className="flex justify-between items-center justify-center flex-col gap-x-4 w-full px-4 md:px-8 sm:flex-row gap-y-4">
                  <button className="w-full md:w-max px-9 py-3 gap-3 rounded-3xl text-base font-semibold leading-normal text-center bg-[#5843BD] text-white" onClick={() => handleAnyClick("dashboard")}  >
                    Go to my Dashboard
                  </button>

                  <button className="w-full md:w-max items-center justify-center flex gap-x-2 border border-[#FF6633] px-9 py-3 gap-3 rounded-3xl text-base font-semibold leading-normal text-center text-[#FF6633]" onClick={() => handleAnyClick("profile")} >
                    View My Profile
                  </button>
                </div>

                <p className=" text-sm font-normal py-4 leading-[1.2] text-center text-[#5843BD33]" onClick={() => navigate("/generateCV?download=true")}>
                  Download my Resume
                </p>

              </div>

            </section>
          </div>, document.getElementById("modal")
        )
      }
    </div>
  )
}

