import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

export default function DecisionCard(props) {
  const navigate = useNavigate();

  const handleDecisionCardView = () => {
    // console.log("open", props.open);
    if (props.acctType === "Client" && props.open == true) {
      navigate(
        `/user/messages/${props.params}/decisionCard/${props.cardId}?index=${props.index}`
      );
    } else {
      setTimeout(() => {
        toast.error("The position is no longer available.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
    }
    // else if (props.acctType === "Official") {
    //   navigate(
    //     `/employer/inbox/${props.params}/decisionCard?index=${props.index}&view=true`
    //   );
    // }
  };

  return (
    <>
      {props.offerText === "Offer Accepted." ? (
        <div className="max-w-[270px] p-1 bg-rose-100 rounded border border-orange-500 justify-center items-center gap-2 inline-flex">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.274 6.71967L7.27398 0.719674C7.03398 0.479674 6.70065 0.333008 6.33398 0.333008H1.66732C0.933984 0.333008 0.333984 0.933008 0.333984 1.66634V6.33301C0.333984 6.69967 0.480651 7.03301 0.727318 7.27967L6.72732 13.2797C6.96732 13.5197 7.30065 13.6663 7.66732 13.6663C8.03398 13.6663 8.36732 13.5197 8.60732 13.273L13.274 8.60634C13.5207 8.36634 13.6673 8.03301 13.6673 7.66634C13.6673 7.29967 13.514 6.95967 13.274 6.71967ZM2.66732 3.66634C2.11398 3.66634 1.66732 3.21967 1.66732 2.66634C1.66732 2.11301 2.11398 1.66634 2.66732 1.66634C3.22065 1.66634 3.66732 2.11301 3.66732 2.66634C3.66732 3.21967 3.22065 3.66634 2.66732 3.66634Z"
              fill="#FF6633"
            />
          </svg>
          <div className="flex-col justify-center items-start gap-1 inline-flex">
            <div className="text-zinc-900 text-xs font-normal font-['Poppins'] leading-[18px]">
              {props.cardText}
            </div>
          </div>
        </div>
      ) : (
        <div className="pl-4 w-full max-w-[460px] sm:max-w-full pr-4 py-2 justify-start bg-rose-100 rounded-lg items-center gap-3.5 inline-flex relative">
          <div
            className={`w-1.5 h-full left-0 top-0 absolute bg-indigo-700 bg-opacity-80 rounded-tl-2xl rounded-bl-2xl`}
          />
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.9993 0.666992C6.63935 0.666992 0.666016 6.64033 0.666016 14.0003C0.666016 21.3603 6.63935 27.3337 13.9993 27.3337C21.3594 27.3337 27.3327 21.3603 27.3327 14.0003C27.3327 6.64033 21.3594 0.666992 13.9993 0.666992ZM15.3327 20.667H12.666V12.667H15.3327V20.667ZM15.3327 10.0003H12.666V7.33366H15.3327V10.0003Z"
              fill="url(#paint0_linear_2197_5849)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_2197_5849"
                x1="17.1993"
                y1="-10.4441"
                x2="-8.80791"
                y2="-8.55679"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#5843BD" />
                <stop offset="1" stop-color="#FF6633" />
              </linearGradient>
            </defs>
          </svg>

          <div className="grow shrink basis-0 justify-between flex-col sm:flex-row items-center gap-2 flex">
            <div className="w-full">
              <p className="text-zinc-900 text-base font-semibold font-['Poppins'] leading-tight">
                {props.offerText}
              </p>
              <p className="text-zinc-900 text-xs sm:text-sm font-normal font-['Poppins'] leading-[21px]">
                {props.cardText}
              </p>
            </div>
            <button
              className="w-full max-w-[120px] self-start sm:self-center text-white text-sm sm:text-base font-medium  px-3 py-2.5 bg-indigo-700 rounded-lg shadow border border-indigo-700"
              onClick={handleDecisionCardView}
            >
              View Card
            </button>
          </div>
          <ToastContainer/>
        </div>
      )}
    </>
  );
}
