import { Link, useNavigate } from "react-router-dom";
import timeResolver from "../timeresolver";


export default function JobDetailItemMobile(props) {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col rounded-[8px] gap-y-2 shadow gap-y-2 bg-white px-6 py-4">
      <div className="flex gap-x-2 h-full items-center">
        <div>
          <img className="w-[45px] h-[45px] rounded-[4px]" src={props.profilePics} />
        </div>
        <div>
          <p class="text-base font-medium leading-normal text-left text-[#5843BD]">
            {props.jobPosition}
          </p>
          <p class="text-[10px] text-[#4E5161] font-normal leading-[15px] text-left">
            Posted {`${timeResolver(props.createdAt)}`} by
            <span class="text-[#5843BD]"> {props.employer}</span>
          </p>
        </div>
      </div>

      <div className="flex items-center justify-between">
        <p className="text-base py-4 leading-normal font-normal text-[#000000CC]">{props.date}</p>

        {
          props.status === "Interview" ? <div className="bg-[#5843BD26] w-max rounded-[4px] py-2 px-3 text-base font-medium leading-normal text-left text-[#111111]">
            Interview
          </div> : props.status === "Pending" ? <div className="bg-[#FF663326] w-max rounded-[4px] py-2 px-3 text-base font-medium leading-normal text-left text-[#111111]">
            Pending
          </div> : props.status === "Hired" ? <div className="bg-[#5843BD80] w-max rounded-[4px] text-white py-2 px-3 text-base font-medium leading-normal text-left">
            Hired
          </div> : <div className="bg-[#FF6633] py-2 px-3 rounded-[4px] w-max text-base font-medium leading-normal text-left text-white">
            Closed
          </div>
        }
      </div>

      <button className="border border-[#5843BD] py-3 flex justify-center items-center rounded-[8px] text-base font-medium leading-normal text-left text-[#5843BD]" onClick={() => navigate(props.jobUrl)}>View Details</button>
    </div>
  )
}