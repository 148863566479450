import React from "react";
import { useSelector } from "react-redux";
import EmployerProfileComponent from "./profile_component_for_employer_profile";

function NewProfileHeader(props) {
  const employer = useSelector(e => e.employer);
  const profile = employer.message;

  let id, jobName;
  // if (props.employerProfileId !== null) {
  //   id = props.employerProfileId;
  //   jobId = props.id;
  // }
  jobName = new URLSearchParams(window.location.search).get("job");
  id = window.location.pathname.split("/")[4];
  // console.log("id", id);
  return (
    <>
      {/* top purple header and About of page  */}
      <div className="w-full  tablet:flex gap-6">
        <EmployerProfileComponent profileId={id} jobName={jobName} />
        {/* About page  */}
        <div className="flex flex-col gap-10 w-full tablet:w-[50%]">
          <div className="bg-white rounded-xl border border-[#D2D4F8] p-5 w-full h-fit flex justify-between">
            {/* content  */}
            <div className="w-[90%]">
              <h1 className="text-[#5843BD] font-semibold text-base">
                About
              </h1>
              <p className=" text-sm font-normal text-[#666666] w-fit h-fit">
                {profile?.about}
              </p>
            </div>
          </div>

          {/* info and Location  */}
          <div className="w-full flex justify-between gap-4">
            {/* location  */}
            {profile?.location !== null && profile?.location !== undefined ? (
              <div className="w-[40%] bg-white rounded-xl border border-[#D2D4F8] p-5">
                <h1 className="text-[#5843BD] font-semibold text-base">
                  Location
                </h1>
                <p className=" text-xs font-normal text-[#666666]">
                  {`${profile.location?.state}, ${profile.location?.country}.`}
                </p>
              </div>
            ) : null}

            {/* Employees  */}
            <div className="w-[40%] bg-white rounded-xl border border-[#D2D4F8] p-5">
              <h1 className="text-[#5843BD] font-semibold text-base">
                Employees
              </h1>
              <p className=" text-xs font-normal text-[#666666]">
                {profile?.no_of_employees}
              </p>
            </div>
          </div>

          {/* Button and review  */}
          <div className="w-full flex flex-col gap-4 tablet:flex-row tablet:gap-0 justify-between">
            {/* <button className=" bg-[#E1DCF9] border border-[#5843BD] hover:text-[#ffffff] hover:bg-[#5843BD] hover:border-[#ffffff] px-5 py-3 rounded-2xl text-[#5843BD] whitespace-nowrap font-semibold text-base">
              Send Message
            </button> */}
            {/* <button className=" bg-[#F5F4FC] border  hover:text-[#ffffff] hover:bg-[#5843BD] hover:border-[#ffffff] px-5 py-3 rounded-2xl text-[#5843BD] whitespace-nowrap font-semibold text-base">
              Request Review
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default NewProfileHeader;