import { createPortal } from "react-dom";
import { useState, useEffect } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { GamefiedVector } from "../../Resume/svgs/svgs";
import AddMore from "../../Resume/add_more/add_more";
import { useNavigate } from "react-router-dom";
import App1 from "../../pdf/DownloadableRresume";
import { saveCvDetails } from "../../../Redux/ActionCreator"
import { v4 as uuidv4 } from "uuid";
import { useSelector, useDispatch } from "react-redux";
import { convertOnce } from "../../../Redux/convertResume";
import { saveIntoServerCache } from "../../../utils/saveIntoCache";
import { ToastContainer, toast } from "react-toastify";

export default function ResumeDownloadModal(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [defaultLang, setDefaultLang] = useState("");
  const generated_cv = useSelector(e => e.generateCV.message);

  useEffect(() => {
    if (generated_cv !== null) {
      props.show(false);
    }
  }, [generated_cv]);

  const handleEnglishClick = async () => {
    // dispatch(convertOnce(props.details, "resume", "/generateCV?download=true", "English", 0));
    setDefaultLang("English");
    await saveIntoServerCache("Language", "English");
    // navigate("/generateCV?download=true");
  }

  const handleSpanishClick = async () => {
    dispatch(convertOnce(props.details, "resume", "/generateCV?download=true", "Spanish", 0));
    props.show(true);
    setDefaultLang("Spanish");
		await saveIntoServerCache("Language", "Spanish");
  }

  const handleClick = () => {
    if (props.details?.Secret === undefined) {
      const clientSecret = uuidv4();
      props.details.Secret = clientSecret;
      // post here
      // if (log_stat === "true" || log_stat === true) {
      dispatch(saveCvDetails(props.details, props.details?.pdfId, clientSecret, "English"));
    } else {
      dispatch(saveCvDetails(props.details, props.details?.pdfId, props.details?.Secret, "English"));
    }
  }

  const handleMoveClick = () => {
    if (defaultLang === "") {
      setTimeout(() => {
        toast.error("Please select a language.", {
          position: toast.POSITION.TOP_RIGHT,
        })
      }, 50)
    } else {
      navigate("/generateCV?download=true");
    }
  }


//  console.log("details", props.details);

  return (
    <div>
      {
        createPortal(
          <div className="fixed top-0 left-0 bottom-0 right-0 z-10 bg-tet-600 overflow-y-scroll min-h-screen py-7 backdrop-blur-[2px] flex justify-center items-center">
            <section className="bg-white md:max-w-[550px] sm:max-w-[500px] min-w-[350px] w-[80%] z-100 m-auto rounded-[24px] overflow-hidden relative">
              <div class="w-full max-w-[660px] p-4 flex justify-between items-center bg-[#5843BD] border-b-8 border-[#E9F1FF] rounded-tr-[24px] rounded-tl-[24px] ">
                <p class=" text-lg font-medium leading-normal text-left text-white">
                  Create Resume from profile
                </p>
              </div>

              <div className="absolute top-[-5%] hidden md:block z-10 left-[-20%]">
                <GamefiedVector />
              </div>

              <div className="flex flex-col items-center px-2 md:px-0 mt-4 justify-center relative z-[1000] gap-y-4">
                <div className="w-full">
                  <p className="text-[20px] font-semibold leading-[24px] text-[#5843BD] text-center">Select a language for your resume then <br className="hidden md:block" />click on ‘Download Resume’ to get started. </p>
                </div>

                {/* select section */}
                <div className="flex md:items-center md:px-8 md:justify-between w-full px-2 md:px-0 flex-col md:flex-row gap-y-4 gap-x-6">
                  <button
                    className={` border-2 ${defaultLang == "English" ? " border-[#FF6633]" : " border-[#DCDCDC]"} p-4 w-full  md:w-[250px] rounded-lg border text-gray-900 focus:ring-orange-400 focus:border-orange-400 font-['Poppins'] text-base flex items-center justify-between `} onClick={handleEnglishClick}
                  >
                    <div className="flex items-center">
                      <img src="https://res.cloudinary.com/drlcisipo/image/upload/v1705704286/Website%20images/English_uj5huz.jpg" alt="English icon" className="object-cover" />
                      <h1 className="text-base font-medium text-black ml-4">English</h1>
                    </div>
                    <CheckCircleIcon className={` ${defaultLang == "English" ? "text-[#FF6633]" : "text-[#DCDCDC]"}`} />
                  </button>

                  <button
                    className={`md:mt-0 border-2 ${defaultLang == "Spanish" ? " border-[#FF6633]" : "border-[#DCDCDC]"} p-4 w-full md:w-[250px] rounded-lg border text-gray-900 focus:ring-orange-400 focus:border-orange-400 font-['Poppins'] text-base flex items-center justify-between `} onClick={handleSpanishClick}
                  >
                    <div className="flex items-center">
                      <img src="https://res.cloudinary.com/drlcisipo/image/upload/v1705704281/Website%20images/Spain_shstdy.jpg" lt="spanish icon" />
                      <h1 className="text-base font-medium text-black ml-4">Spanish</h1>
                    </div>
                    <CheckCircleIcon className={` ${defaultLang == "Spanish" ? "text-[#FF6633]" : "text-[#DCDCDC]"}`} />
                  </button>
                </div>

                <div>
                  <p className="text-base leading-[2opx] text-center font-normal">If you want to create a new resume from scratch, <br className="hidden md:block" />click on the <span className="font-medium">‘Create New Resume’</span> button instead.</p>
                </div>

                <div className="flex flex-col w-full max-w-[90%] justify-between md:flex-row gap-y-4">
                  <button className="bg-[#5843BD] py-4 px-9 font-semibold rounded-[24px] text-base leading-normal text-white text-center" onClick={() => handleMoveClick()}>Download Resume</button>
                  <button className="border border-[#FF6633] font-semibold py-4 px-9 rounded-[24px] text-base leading-normal text-[#FF6633] text-center" onClick={() => navigate("/upload?frp=t")}>Create New Resume</button>
                </div>

                <button className="py-4 text-sm leading-normal text-[#5843BD80] text-center" onClick={() => navigate("/resume")}>View my Resumes</button>
              </div>
            </section>
          </div>, document.getElementById("modal")
        )
      }
    </div>
  )
}