import EditButton from "../edit_btn";
import { useNavigate } from "react-router-dom";

export default function EmptySection({ heading, subheading, data }) {
  const navigate = useNavigate();
  return (
    <div class="w-full  p-6 gap-4 rounded-lg bg-[#FFFFFFCC] flex flex-col gap-x-4 shadow-custom">
      <div className="flex justify-between">
        <p class="text-base font-semibold leading-normal text-[#5843BD]">
          {heading}
        </p>
        <EditButton handleClick={() => navigate("/user/profile/update/about")}/>
      </div>
      {
        data?.About !== undefined && data?.About !== null && data?.About !== "" ? null : ( 
        <p class="text-base font-medium leading-normal text-[#FF6633]">
          {subheading}
        </p>
        ) 
      }
      <p className=" text-sm font-normal text-[#666666]">
          {data === null ? null : (
            <>{data?.About}</>
          )}
        </p>
    </div>

  )
}