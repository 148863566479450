import React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { baseURL } from "../Redux/baseURL";

const logErrorToService = (error, errorInfo) => {
    fetch(baseURL + '/api/error/err', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        error: `error: ${error.toString()} \n error_info:${errorInfo.componentStack}`,
      })
    })
      .then(response => response.json())
      .then(data => {
        console.log('Error logged successfully:', data);
      })
      .catch((error) => {
        console.error('Error logging failed:', error);
      });
  }

const ErrorFallback = ({ error, resetErrorBoundary }) => {
    const handleGoToDashboard = () => {
        window.location.href = '/user/dashboard';
    };

    return (
        <div className="flex justify-center items-center w-full h-screen">
      <div className="flex flex-col justify-center items-center w-full h-full p-5 max-h-screen max-w-full overflow-hidden">
        <div className="p-5 text-center">
          <div className="max-w-[550px] flex flex-col gap-6">
            <div className="flex flex-col gap-2">
              <img
                src="https://res.cloudinary.com/drlcisipo/image/upload/v1721682674/Layer_2_mbkex5.png"
                alt="Maintenance"
                className="max-w-full h-auto"
              />
              <p className="text-center text-[#5843BD] text-[24px] font-[700] leading-[1.5]">
                Ooops!! We encountered a problem
              </p>
            </div>
            <p className="text-center text-[#2F2F2F] text-[16px] leading-[1.2]">
              We encountered a problem while loading your resources, our team as been notified and we will fix and notify you soon
            </p>
          </div>

          <div className="flex flex-row gap-4 w-full mt-4 justify-center">
            <button onClick={handleGoToDashboard} className="bg-[#FF6633] px-6 p-4 text-[16px] leading-[1.5] text-white rounded-[2rem] min-w-max">
              Go to Dahboard
            </button>
          </div>
        </div>
      </div>
    </div>
      );
};

const ErrorBoundary = ({ children }) => {
  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // Reset the state of your app so the error doesn't happen again
        console.log('Error boundary reset');
      }}
      onError={(error, errorInfo) => {
        logErrorToService(error, errorInfo);
      }}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
