import { configureStore } from "@reduxjs/toolkit";
import { combineReducers, applyMiddleware } from "redux";
import {
  Login,
  Sign_up,
  UserInfo,
  generate_cv,
  cv_id,
  cv_pics,
  existing_cv,
  get_me_my_cv,
  get_my_profile,
  verify_mail,
  password_reset,
  activities,
  skills,
  jobTitle,
  Professional_summary,
  education,
  p_skills,
  voluntary,
  award,
  reference,
  language,
  individual_experience,
  translate_lang,
  persomal_information,
  cert,
  lang,
  spanish,
  resumeStepChange,
  get_employer_profile,
  langType,
  last_resume,
  secret,
  save_link,
  JobDetails,
  get_my_profile_spanish,
  available_jobs,
  available_jobs_category,
  application_func,
  get_jobs_applied,
  saveJob,
  getSavedJobs,
  endToEndChatInformation,
  chatInformation,
  sendMessage,
  decisionCard,
  notification,
  OtpVerification,
  OtpSending,
  total_page,
  msg_notification,
  get_a_job,
  Last_page,
  count,
  errors,
  click,
  application_public_func,
  set_new_job_state,
  createReferral,
  referral_link,
  employer_employee_info,
  socket,
  get_public_profile,
  online,
  seach_total_page,
  searchResult,
  Dashboard_only_reducer,
  Sidebar_Open_reducer
} from "./Reducers";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import { logger } from "redux-logger";

const persistConfig = {
  key: "root",
  storage,
  // blacklist: ["saved_cv"]
};

const rootReducer = combineReducers({
  lastPage: Last_page,
  login: Login,
  signup: Sign_up,
  userInfo: UserInfo,
  profile: get_my_profile,
  verify_mail: verify_mail,
  password_reset: password_reset,
  saved_cv: get_me_my_cv,
  generateCV: generate_cv,
  existingCV: existing_cv,
  cvId: cv_id,
  cvpics: cv_pics,
  activities: activities,
  skills: skills,
  jobTitle: jobTitle,
  summary: Professional_summary,
  education: education,
  p_skills: p_skills,
  voluntary: voluntary,
  award: award,
  reference: reference,
  language: language,
  exp: individual_experience,
  t_lang: translate_lang,
  personal_info: persomal_information,
  cert: cert,
  lang: lang,
  resumeStep: resumeStepChange,
  spanish: spanish,
  employer: get_employer_profile,
  type: langType,
  last_resume: last_resume,
  secret: secret,
  userLink: save_link,
  jobDetails: JobDetails,
  spanish_profile: get_my_profile_spanish,
  available_jobs: available_jobs,
  available_jobs_category: available_jobs_category,
  apply: application_func,
  jobsApplied: get_jobs_applied,
  save_job: saveJob,
  saved_jobs: getSavedJobs,
  chat_info: chatInformation,
  end_to_end: endToEndChatInformation,
  send_message: sendMessage,
  decisionCard: decisionCard,
  notification: notification,
  msg_notification: msg_notification,
  otp: OtpVerification,
  send_otp: OtpSending,
  total_page: total_page,
  get_a_job: get_a_job,
  count: count,
  errors: errors,
  click: click,
  pubApplication: application_public_func,
  newjobpost: set_new_job_state,
  referralcretion: createReferral,
  referral_link: referral_link,
  employer_employee_info: employer_employee_info,
  socket: socket,
  public: get_public_profile,
  online: online,
  seach_total_page: seach_total_page,
  searchResult: searchResult,
  dashboardOnly: Dashboard_only_reducer,
  sidebarOpen: Sidebar_Open_reducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk, logger ], //logger
});

export const persistor = persistStore(store);
