
export default function RequestReset(props) {

    const goBack = () => {
        window.history.back();
      };

    return (
        <div className="border border-pry-900 border-opacity-10 border-[6px] w-full max-w-[523px] py-10 bg-white rounded-2xl border-pry-900 bg-opacity-100 border-opacity-10 px-8 sm:px-10 lg:px-16">
            <div className="flex flex-col gap-4">
                <div>
                    <img src="https://res.cloudinary.com/drlcisipo/image/upload/v1705704280/Website%20images/small_logo_sw9gv9.png" alt="Logo Image" />
                </div>
                
                <p class="font-poppins text-[24px] font-semibold leading-36 tracking-normal text-left">
                    Reset your password
                </p>

                <p class="font-poppins text-xs font-normal leading-18 tracking-normal text-left">
                    Enter your email address, we will send your a link.
                </p>

            </div>

            <div className="flex flex-col gap-4 mt-2">

                <div>
                    <label class="font-poppins text-xs font-medium leading-4 tracking-normal text-left" for="email">
                        Email address
                    </label>
                    <input type="text" id="email" class="border  rounded-[6px] border-solid border-indigo-400 w-full border-opacity-50 focus:border-none focus:outline-none focus:ring-2 focus:ring-indigo-400" ref={props.emailRef} />

                </div>


                <button className="submit-form-gradient w-full flex items-center justify-center py-4 rounded-[8px]" onClick={props.handle}>
                    <span class="font-poppins text-base font-medium leading-4 tracking-normal text-center text-white">
                        Send Link
                    </span>
                </button>

                <div className="w-full h-auto py-2 px-0 flex flex-col justify-center items-center gap-4">
                    <p className="font-poppins text-xs font-normal leading-3 text-left w-max">
                        Go back
                        <a href="#" onClick={goBack} className="ml-1 font-poppins text-xs italic font-semibold leading-3 text-left">sign in</a>
                    </p>
                </div>
            </div>
        </div>
    )
}