import React, { useState, useContext, useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate, useLocation } from "react-router-dom";
// require dispatch from react-redux;
import { useDispatch, useSelector } from "react-redux";
// import the signup function from the redux actioncreator file.
import {
  signUpFunction,
  userGoogleAuth,
  userLinkedinAuth,
  signup_loading
} from "../../Redux/ActionCreator";
import TranslateBtnDropdown from "../common/translateBTN";
import { baseURL1 } from "../../Redux/baseURL";
import HelmetProvide from "../common/helmet";
import SignupForm from "../common/signup_form";
import { ToastContainer, toast } from "react-toastify";
import SendMailModal from "../common/sentMailModal/sentMailModal";
import { createPortal } from "react-dom";
import HomeButton from "../common/HomeButton/homeButton";

const Register = () => {
  const navigate = useNavigate();
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [checked1, setChecked1] = React.useState(true);
  const [checked2, setChecked2] = React.useState(false);
  const [hide, setHide] = useState(true);
  const [hide1, setHide1] = useState(true);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [userCred, setUserCred] = React.useState({
    username: "",
    password: "",
    confirmPassword: "",
    AcctType: "Client",
    code: queryParams.get('ref'),
  });
  const dispatch = useDispatch();
  const signup_status = useSelector((stat) => stat.signup);

//  console.log("userCred", userCred);
  const [ show, setShow ] = useState(false);
  useEffect(() => {
    if (queryParams.get('ref') !== null) {
      localStorage.clear();
      localStorage.setItem("ref", queryParams.get('ref'));
    } else {
      let ref = localStorage.getItem("ref");
      localStorage.clear();
      localStorage.setItem("ref", ref);
    }
  }, []);
  useEffect(() => {
    if (signup_status.errMess !== null) {
      setTimeout(() => {
        toast.error(signup_status.errMess, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);

      dispatch(signup_loading());
    }
    if (signup_status.message !== null) {
      // setTimeout(() => {
      //   toast.success(signup_status.message?.status, {
      //     position: toast.POSITION.TOP_RIGHT,
      //   });
      // }, 50);
      setShow(true);

      dispatch(signup_loading());
    }
  }, [signup_status.errMess, signup_status.message]);

  /* This is the the handle submit function that is implemeted when the register form is submitted */

  const handleSubmit = () => {
    dispatch(signUpFunction(userCred));
    localStorage.removeItem("ref");
    setUserCred((prev) => ({
      ...prev,
      username: "",
      password: "",
      confirmPassword: "",
      AcctType: "Client",
      code: ""
    }));
    localStorage.removeItem("ref");
  };

  /* 
    Using Toast as error displayer. This display the error everytime the error changes.
    The toaster container is at the end of the apage
   */

  const screenheight = window.innerHeight;

  const heighting = screenheight >= 755 ? "md:top-[50%] md:translate-y-[-50%]" : "md:top-[35px]";

  const showModal = show == true ? createPortal(
    <div className="top-0 left-0 fixed bottom-0 right-0 z-10 h-screen flex justify-center items-center bg-black bg-opacity-70 backdrop-blur-[2px] ">
      <SendMailModal signup={true}/>
    </div>, document.getElementById("mail")
  ) : null

  return (
    <>
      <HelmetProvide
        title="Moil | Resume Builder Tool"
        description="At Moil, our goal is to help blue-collar workers build resumes and portfolios and connect them to recruiters on our platform."
        className="notranslate"
      />

      <div className="h-screen relative max-w-[100vw] overflow-x-hidden">
        <div className={`absolute z-10 ${heighting} w-full gap-x-4 gap-y-4 justify-center lg:max-w-[876px] xl:max-w-[1024px] left-[50%] translate-x-[-50%] top-[80px] flex lg:gap-x-0 lg:justify-between flex-col md:flex-row items-center px-4`}>
          <div className="w-full">
            <HomeButton className="bg-white p-4 vsm:mb-5 md:mb-0" path={`https://stagebeta.moilapp.com`} text="Hompage" />
            <div className="md:flex md:items-center md:justify-between w-full">
              <p className="text-white font-poppins text-[24px] md:text-[32px] font-semibold leading-[30px] md:leading-[36px] tracking-normal flex flex-col gap-y-10 max-w-[380px]">Free AI-powered resume
              creation and unlimited
              job matching for you.
                <span className="font-poppins text-[14px] md:text-[24px] font-[500] leading-[20px] md:leading-[28px] tracking-normal vsm:mb-4 md:mb-0"> Sign up now to start getting
                  your dream jobs.
                </span>
              </p>
              <SignupForm type="employee" googleSignup={() => dispatch(userGoogleAuth())} username={userCred.username} password={userCred.password} confirmPassword={userCred.confirmPassword} setUserCred={setUserCred} handleSubmit={handleSubmit} />
            </div>
          </div>
        </div>

        <div className={`w-full h-added-25 ${screenheight > 755 ? "md:h-full " : "md:h-added-35"} grid custom-grid`}>

          <img src="https://res.cloudinary.com/drlcisipo/image/upload/v1709257423/Website%20images/singup-employee_ksgz2b.svg" className="w-full md:max-h-max md:h-full object-cover" />  {/* max-h-[340px] */}


          <div className="relative h-full overflow-hidden -z-1000">
            <svg width="851" height="851" viewBox="0 0 1028 992" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute top-[-30%] right-[-30%] -z-10">
              <g opacity="0.1" filter="url(#filter0_f_3436_5351)">
                <circle cx="739.5" cy="252.5" r="425.5" fill="#777DEA" />
              </g>
              <defs>
                <filter id="filter0_f_3436_5351" x="0" y="-487" width="1479" height="1479" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                  <feGaussianBlur stdDeviation="157" result="effect1_foregroundBlur_3436_5351" />
                </filter>
              </defs>
            </svg>

            <svg width="1065" height="1065" viewBox="0 0 1201 1024" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute bottom-[-25%] right-[-20%] -z-10">
              <g opacity="0.1" filter="url(#filter0_f_3436_5429)">
                <circle cx="847.5" cy="695.5" r="533.5" fill="#FF6633" />
              </g>
              <defs>
                <filter id="filter0_f_3436_5429" x="0" y="-152" width="1695" height="1695" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                  <feGaussianBlur stdDeviation="157" result="effect1_foregroundBlur_3436_5429" />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
      </div>
      <ToastContainer />
      {showModal}
    </>
  );
};

export default Register;
