import { store } from "../../Redux/ConfigureStore";
import { skillsDetails, p_skillsDetails } from "../../Redux/ActionCreator";
import { saveIntoServerCache } from "../saveIntoCache"

// Function to capitalize the first character of a string
function capitalizeFirstChar(text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

export const GPTTechSkillServiceFilteringFromExp = (skills) => async (dispatch) => {
    const store_value = store.getState();
    const tSkills = store_value.skills.skills;
    const newMap = new Map();
    let arr = new Array();
    let T_arr = new Array();
    if (tSkills !== null) {
        tSkills.forEach(element => {
            if (element.value !== undefined) {
                T_arr.push(element.value);
            } else {
                T_arr.push(element);
            }
        })
    }
    if (!Array.isArray(skills)) {
        if (typeof skills == "string") {
            skills = skills.split(/,|\.\s|\t|\n/)
        }
    }
    skills.forEach(element => {
        if (!T_arr.includes(element)) {
            if (!newMap.has(element)) {
                newMap.set(element, true);
                arr.push(element);
            }
        }
    })
    if (arr.length != 0) {
        T_arr.forEach(element => {
            if (!newMap.has(element)) {
                newMap.set(element, true);
                arr.push(element);
            }
        })
    }
    arr = arr.map(e => e.toLowerCase());
    arr = [... new Set(arr)];
    arr = arr.map(e => capitalizeFirstChar(e));
    dispatch(skillsDetails([... new Set(arr)]));
    saveIntoServerCache("technicalSkills", [... new Set(arr)]);
}


export const GPTPersonalSkillServiceFilteringFromExp = (skills) => async (dispatch) => {
    const store_value = store.getState();
    const pSkills = store_value.p_skills.p_skills;
    const newMap = new Map();
    let arr = new Array();
    let P_arr = new Array();
    if (pSkills !== null) {
        pSkills.forEach(element => {
            if (element.value !== undefined) {
                P_arr.push(element.value);
            } else {
                P_arr.push(element);
            }
        })
    }
    if (!Array.isArray(skills)) {
        if (typeof skills == "string") {
            skills = skills.split(/,|\.\s|\t|\n/)
        }
    }
    skills.forEach(element => {
        if (!P_arr.includes(element)) {
            if (!newMap.has(element)) {
                newMap.set(element, true);
                arr.push(element);
            }
        }
    })
    if (arr.length != 0) {
        P_arr.forEach(element => {
            if (!newMap.has(element)) {
                newMap.set(element, true);
                arr.push(element);
            }
        })
    }
    arr = arr.map(e => e.toLowerCase());
    arr = [... new Set(arr)];
    arr = arr.map(e => capitalizeFirstChar(e));
    dispatch(p_skillsDetails([... new Set(arr)]));
    saveIntoServerCache("personalSkills", [... new Set(arr)]);
}
