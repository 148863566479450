import React, { useState } from "react";
import PurpleFillBtn from "../purplefillbtn/purplefillbtn";
import reference from "../reference";
import { useSelector, useDispatch } from "react-redux";
import { ApplyForAJob, existing_resume } from "../../../Redux/ActionCreator";
import { useParams } from "react-router-dom";
import App1 from "../../pdf/DownloadableRresume";
// import analytics from "analytics"
import { useAnalytics } from "../../../context/AnalyticsContext";
import { useNavigate } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify";
import { baseURL } from "../../../Redux/baseURL";
import { saveJobClick } from "../../../utils/helper/analytics";
import TakeSkillTestModal from "../skillTestModal/skilltest_msg_modal";

function InterviewModal({ OpenInterviewModal, Closemodal, indeed, purpose, testAnswer, setTakeTest }) {
  const analytics = useAnalytics();
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const socket = useSelector((e) => e.socket.message);
  const savedResume = useSelector((e) => e.saved_cv);
  const details = savedResume.message;
  const profile = useSelector((prev) => prev.profile);
  const login = useSelector(e => e.login);
  let log_stat = login.profile_status.profile_status;
  const [isOptionsVisible, setOptionsVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  // console.log("======>", savedResume);
  const user = useSelector(e => e.userInfo.user);
  const online = useSelector(e => e.online.message);
  const [takeSkillTest, setTakeSkillTest] = useState(false);

  async function HandlePurpleFillClick() {
    if (indeed.indeed == true || indeed?.indeed == "true") {
      // redirect to the indeed site
      // window.location.replace(indeed?.url);
      analytics.track("Indeed Job Application", {
        Type: "Employee",
        info: "Number of users that click apply on indeed jobs",
        time: new Date(),
        Email: profile?.message?.Email,
        _id: profile?.message?._id
      });
      window.open('https://www.indeed.com' + indeed?.job_url);
      await saveJobClick({ indeedLinks: `https://www.indeed.com${indeed?.job_url}`, type: "Indeed" });
      Closemodal();
    } else {
      if (purpose == "job" && selectedOption) {
        dispatch(ApplyForAJob(params.jobName, selectedOption?._id, testAnswer, "apply"));
        socket.emit("applyForJob", ({ JobId: indeed, senderId: profile.message }));
        await saveJobClick({ type: "MoilJobs" });
        if (indeed?.skillTest !== null && indeed?.skillTest !== undefined && indeed?.skillTest?.skillTestQuestions?.length !== 0 && indeed?.skillTest?.length !== 0) {
          setTakeSkillTest(true);
          Closemodal();
        } else {
          Closemodal();
        }
      } else {
        if (details.length > 0 && !selectedOption) {
          toast.error("Please select one of your resume to continue", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          // do something
          dispatch(existing_resume(selectedOption));
          navigate("/generateCV");
          Closemodal();
        }
      }
      analytics.track("Moil Job Application", {
        Type: "Employee",
        info: "Number of users that apply for moil jobs",
        time: new Date(),
        Email: profile?.message?.Email,
        _id: profile?.message?._id
      });
    }
  }

  const downloadFile = async (lastCv) => {
    const fileUrl = lastCv?.aws_link;
    let link = document.createElement('a');
    link.href = fileUrl;
    link.download = lastCv?.CvType;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setOptionsVisible(false);
  }

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setOptionsVisible(false);
  };

  const handleClose = () => {
    setTimeout(() => {
      setOptionsVisible(false);
    }, 50)
  }

  // console.log(`${takeSkillTest} roj3orj3f4o ojg042 fk024jt4lk3o1 fn3o2rknto`);

  let showTestModal = takeSkillTest ?
    <TakeSkillTestModal handleClose={() => {
      setTakeSkillTest(false);
      setTakeTest(true);
    }} indeed={indeed} /> :
    null;


  return (
    <div
      className={`min-w-screen w-[100vw] min-h-screen bg-tet-600 backdrop-blur-[2px] z-[9996] ${OpenInterviewModal
        ? "flex fixed top-0 left-0 justify-center items-center"
        : "hidden relative"
        }`}
    >
      <div
        className={`  z-[9999] m-4 bg-[#FFFFFF] rounded-3xl border-[2px] border-[#777DEA] border-opacity-[0.33] p-4 tablet:p-8 flex flex-col items-center justify-center max-w-[437px] max-h-[500px] relative`}
      >
        <div
          onClick={Closemodal}
          className=" w-8 h-8 p-3 rounded-full bg-[#5843BD] bg-opacity-10 absolute right-[2.5%] top-[2.5%] flex justify-center items-center font-bold text-[#1C1C1C] text-opacity-60 cursor-pointer"
        >
          x
        </div>
        <h1 className=" font-semibold text-xl text-[#5843BD]">
          Submit Application
        </h1>

        <hr className=" bg-[#5843BD] bg-opacity-30 w-full my-4" />

        {indeed?.indeed == "true" || indeed.indeed == true ?
          <p className=" font-normal text-sm text-[#0D0D0D] text-center">
            Click the "select your resume" button to download resume you want to use to apply on indeed
          </p> :
          <>{purpose == "job" ?
            <p className=" font-normal text-sm text-[#0D0D0D] text-center">
              This action will send your resume and profile to{" "}
              <span className=" font-medium text-[#5843BD]">Johnasah Inc. </span>{" "}
              your job application. They will contact you to begin an interview.
              Watch your inbox.
            </p> : <p>This resume details will be prefilled into the resume fields you ought to fill</p>}</>
        }

        <div>

          <div className="mt-4 relative">
            <div
              className="flex justify-center items-center gap-2 px-4 py-2 border border-[#5843BD] rounded-sm bg-[#5843BD] bg-opacity-10 cursor-pointer"
              onClick={() => setOptionsVisible(!isOptionsVisible)}
            >
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="24"
                  height="24"
                  rx="2"
                  transform="matrix(0 -1 -1 0 24.543 24)"
                  fill="#5843BD"
                />
                <path
                  d="M12.543 19V5"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19.543 12L12.543 5L5.54297 12"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span className="font-semibold text-xs text-[#5843BD]">
                {selectedOption ? selectedOption.CvType : "Select your resume"}
              </span>
            </div>


            {isOptionsVisible && (
              <div className={`absolute top-14 ltop-10 left-[50%] translate-x-[-50%] bg-white border border-[#5843BD] rounded-sm z-10 ${details.length === 0 ? "w-[320px]" : "w-[270px] max-h-[150px] overflow-y-scroll"} py-2 min-w-full`}>
                {details.map((option, index) => (
                  <>
                    {indeed?.indeed !== true && indeed?.indeed !== "true" ?
                      <div
                        key={option._id}
                        className="px-4 cursor-pointer  "
                        onClick={() => handleOptionSelect(option)}
                      >
                        <div className="py-2 flex gap-[6px] hover:bg-[#5843BD] hover:bg-opacity-30">
                          <img
                            src={reference?.previewcvicon}
                            alt="preview cv icon"
                          />
                          <div>
                            <h2 className=" font-semibold text-sm text-black">
                              {option.CvType}
                            </h2>
                            <p className=" text-xs text-[#1C1C1C] text-opacity-60 font-normal">
                              {/* {option.descript} */}
                              Resume created on Moil.
                            </p>
                          </div>
                        </div>
                        <hr
                          className={` ${details.length - 1 === index ? "hidden" : ""
                            } bg-[#5843BD] bg-opacity-30 w-full my-2`}
                        />
                      </div> :
                      <>
                        {
                          parseInt(option?.pdfId, 10) < 5 ?
                            // <a href={option?.aws_link} download>
                            <div className="flex gap-[6px] hover:bg-[#5843BD] hover:bg-opacity-30" onClick={() => downloadFile(option)}>
                              <img
                                src={reference?.previewcvicon}
                                alt="preview cv icon"
                              />
                              <div>
                                <h2 className=" font-semibold text-sm text-black">
                                  {option?.CvType}
                                </h2>
                                <p className=" text-xs text-[#1C1C1C] text-opacity-60 font-normal">
                                  {/* {option.descript} */}
                                  Resume created on Moil.
                                </p>
                              </div>
                            </div>
                            // </a>
                            :
                            <App1
                              details={option}
                              onClick={handleClose}
                            />
                        }
                      </>
                    }
                  </>

                ))}

                {details.length === 0 && <div className="flex w-full justify-center gap-y-4 flex-col px-4 py-3">
                  <p className=" font-normal text-sm text-[#0D0D0D] text-center">
                    Click the <span className="font-semibold">"View Profile"</span> button to navigate to profile and click create resume to generate a resume based on your profile
                  </p>

                  <button class="w-full md:w-max px-9 py-3 gap-3 self-center rounded-3xl text-base font-semibold leading-normal text-center bg-[#5843BD] text-white cursor-pointer" onClick={() => navigate(`/user/profile/:${user.profile_id}`)}>
                    View Profile
                  </button>
                </div>}

              </div>
            )}

            {selectedOption ? (
              <>
                <p className=" mt-4 font-normal text-sm text-[#5843BD] text-center">
                  {indeed.indeed == "true" || indeed?.indeed == true ? <>Selected resume</> : <>Uploaded resume</>}
                </p>

                <div className=" mt-4 max-w-[284px] w-full flex justify-center gap-[6px] rounded-lg bg-[#EFEDF9] py-[6px] px-[1rem] lpx-[50px]">
                  <img src={reference?.previewcvicon} alt="preview cv icon" />
                  <div>
                    <h2 className=" font-semibold text-sm text-black">
                      {selectedOption?.CvType}
                    </h2>
                    <p className=" text-xs text-[#1C1C1C] text-opacity-60 font-normal">
                      {/* {selectedOption.descript} */}
                      Resume created on Moil.
                    </p>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>

        <hr className=" bg-[#5843BD] bg-opacity-30 w-full my-4" />

        <PurpleFillBtn
          HandlePurpleFillClick={HandlePurpleFillClick}
          children={indeed?.indeed == true || indeed?.indeed == "true" ? "Apply on Company site" : <>{purpose == "job" ? "Submit" : "proceed"}</>}
        />
      </div>
      <ToastContainer />
      {showTestModal}
    </div>
  );
}

export default InterviewModal;
