import * as React from "react";
import Box from "@mui/material/Box";
import { TextareaAutosize } from "@mui/material";

export const TextArea = (props) => {

    const autoSize = () => {
      const textarea = document.getElementById(`${props.id}`);
      // textarea.style.height = 'auto';
      // textarea.style.height = `${textarea.scrollHeight}px`;
    };

  return (
    <div className="vsm:w-full">
      <textarea
        onInput={autoSize}
        placeholder={props.placeholder}
        rows={5} // Set to 1 to allow the textarea to grow initially
        style={{
          width: "100%",
          resize: "none",
          padding: "16px",
          borderColor: "#777dea54",
          borderWidth: "1px",
          height: "fit-content",
          outlineColor: "#777dea54",
          borderRadius: "8px",
          "&:focus": {
            borderColor: "#777dea54", // Border color on focus
          },
        }}
        onKeyDown={props.onKeyDown}
        onCopy={props.onCopy}
        onCut={props.onCut}
        name={props.name}
        value={props.value}
        onChange={props.onChangeHandler}
        id={props.id}
      />
    </div>
  );
};
