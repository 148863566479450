import { createPortal } from "react-dom";
import { GamefiedVector } from "../../Resume/svgs/svgs";
import AddMore from "../../Resume/add_more/add_more";
import { useNavigate } from "react-router-dom";
import App1 from "../../pdf/DownloadableRresume";
import { saveCvDetails } from "../../../Redux/ActionCreator"
import { v4 as uuidv4 } from "uuid";
import { useSelector, useDispatch } from "react-redux";

export default function DownloadResumeModal(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
    if (props.details?.Secret === undefined) {
      const clientSecret = uuidv4();
      props.details.Secret = clientSecret;
      // post here
      // if (log_stat === "true" || log_stat === true) {
        dispatch(saveCvDetails(props.details, props.details?.pdfId, clientSecret, "English"));
    } else {
      dispatch(saveCvDetails(props.details, props.details?.pdfId, props.details?.Secret, "English"));
    }
  }

  
 // console.log("details", props.details);

  return (
    <div>
      {
        createPortal(
          <div className="fixed top-0 left-0 bottom-0 right-0 z-10 bg-tet-600 overflow-y-scroll min-h-screen py-7 backdrop-blur-[2px] flex justify-center items-center">
            <section className="bg-white sm:max-w-[500px] min-w-[350px] w-[80%] z-100 m-auto rounded-[24px] pb-5 overflow-hidden relative">
              <div class="w-full max-w-[660px] p-4 flex justify-between items-center bg-[#5843BD] border-b-8 border-[#E9F1FF] rounded-tr-[24px] rounded-tl-[24px] ">
                <p class=" text-lg font-medium leading-normal text-left text-white">
                  Download Resume
                </p>
              </div>

              <div className="absolute top-[-8%] z-10 left-[-20%]">
                <GamefiedVector />
              </div>

              <div className="flex flex-col items-center justify-center relative z-[1000] gap-y-4">
                <div className="px-4 py-2 w-max gap-x-4 flex  items-center">
                  <img src="https://res.cloudinary.com/drlcisipo/image/upload/v1717534408/thumbup_c26edw.png" />
                  <p class=" text-2xl font-semibold leading-normal text-center text-[#5843BD]">
                    Download Your Resume!
                  </p>
                  <img src="https://res.cloudinary.com/drlcisipo/image/upload/v1717534408/thumbup_c26edw.png" />
                </div>

                <p class=" text-base font-semibold rounded-[4px] leading-tight p-2 w-max text-center text-[#5843BD] bg-[#FF66331A]">
                  Your resume is <span className="text-[#FF6633]">ready</span>
                </p>
                
                <p class=" text-base font-normal leading-[1.2] text-center max-w-[280px] sm:max-w-[400px]">
                  Click the button below to download your resume.
                </p>

                <App1
                  details={props?.details}
                  onClick={handleClick}
                  id={props.details?.pdfId}
                  gamified={true}
                />

                <p class=" text-sm font-normal py-4 leading-[1.2] text-center text-[#5843BD] cursor-pointer" onClick={() => props.hide(false)}>
                  Close
                </p>


              </div>

            </section>
          </div>, document.getElementById("modal")
        )
      }
    </div>
  )
}