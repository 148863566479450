import { baseURL } from "../../Redux/baseURL.jsx"

export const saveIntoServerCache = async (key, value) => {
    return fetch(baseURL + "/api/cache/saveIntoCache", {
        method: "POST",
        body: JSON.stringify({key: key, value: value}),
        headers: {
            "Content-Type": "application/json",
            // Authorization: "Bearer " + localStorage.getItem("token")
        },
        credentials: "include"
    })
}

export const getOneFromServerCache = async (key) => {
    return fetch(baseURL + "/api/cache/getOneValueFromCache", {
        method: "POST",
        body: JSON.stringify({key: key}),
        headers: {
            "Content-Type": "application/json",
            // Authorization: "Bearer " + localStorage.getItem("token")
        },
        credentials: "include"
    })
}

export const deleteAKey = async (key) => {
    return fetch(baseURL + "/api/cache/deleteKey", {
        method: "POST",
        body: JSON.stringify({key: key}),
        headers: {
            "Content-Type": "application/json",
            // Authorization: "Bearer " + localStorage.getItem("token")
        },
        credentials: "include"
    })
}

export const getTwoFromServerCache = async (key1, key2) => {
    return fetch(baseURL + "/api/cache/getTwoValueFromCache", {
        method: "POST",
        body: JSON.stringify({key1: key1, key2: key2}),
        headers: {
            "Content-Type": "application/json",
            // Authorization: "Bearer " + localStorage.getItem("token")
        },
        credentials: "include"
    })
}

export const getAllResumeDetailsInCache = async (personal, education, experience, technical_skills, personal_skills, language, award, reference) => {
    return fetch(baseURL + "/api/cache/getResumeDetails", {
        method: "POST",
        body: JSON.stringify({personal: "personalInfo", education: "education", experience: "experience", technical_skills: "technicalSkills", personal_skills: "personalSkills", language: "language", award: "award", reference: "reference", YOF: "YOF", summary: "summary", title: "title"}),
        headers: {
            "Content-Type": "application/json",
            // Authorization: "Bearer " + localStorage.getItem("token")
        },
        credentials: "include"
    })
}

