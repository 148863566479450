import React, { useState, useEffect, useContext } from "react";
import BasicSelect from "../select/select";
import AddedItem from "../added_item/added_item";
import AddMore from "../add_more/add_more";
import { handleDeleteValue } from "../utils/handleDelete";
import { useDispatch, useSelector } from "react-redux";
import {
  dispatchLanguage,
  saveLanguage,
  returnHome
} from "../../../Redux/ActionCreator";
import $ from "jquery";
import {
  Languages,
  Rate,
  Rate_sp,
  Langu,
} from "../languages";
import { ToastContainer, toast } from "react-toastify";
import HelmetProvide from "../../common/helmet";
import { saveIntoServerCache, getOneFromServerCache } from "../../../utils/saveIntoCache";
import TextInput from "../text_input/text_input";

export default function UserLanguage(props) {
  const dispatch = useDispatch();
  const language_data = useSelector((lan) => lan.language);
  const profile1 = useSelector((e) => e.login);
  const userInfo = useSelector((e) => e.userInfo);
  let stat = profile1.profile_status.profile_status;
  const secret = useSelector((prev) => prev.secret);
  const lang_data = useSelector((lan) => lan.lang);
  const [chips, setChips] = useState([]);
  const [chipsL, setChipsL] = useState([]);
  const [languagess, setLanguage] = useState([]);
  const [res_lang, setres_lang] = useState([]);
  const [lang_value, setLan_value] = useState("");
  const [lang_prof, setLan_prof] = useState("");

  useEffect(() => {
    let cacheInfo = getOneFromServerCache("language")
    cacheInfo.then(res => res.json())
      .then(res => {
        // console.log("server cache value", res);
        if (res.success == true) {
          let resObj = JSON.parse(res.data);
          // console.log("language respObj", resObj);
          if (resObj !== null && resObj !== undefined) {
            dispatch(dispatchLanguage(resObj));
            setres_lang(resObj);
            setChipsL(resObj);
          }
          // console.log("Done");
        } else if (res.success === false && res.message == "Unauthorized") {
          dispatch(returnHome(res.status));
        }
      })
  }, []);


  useEffect(() => {
    if (language_data.lang !== null) {
      setLanguage(language_data.lang);
      setChips(language_data.lang);
    }

    if (lang_data.lang !== null) {
      setres_lang(lang_data.lang);
      setChipsL(lang_data.lang);
    }
  }, [language_data.lang, lang_data.lang]);


  const handleDelete = (chipToDelete) => {
    let newlan = handleDeleteValue(languagess, chipToDelete, "key");
    setLanguage(newlan);
    setChips(newlan);
    dispatch(dispatchLanguage(newlan));
    // save into server cache
    saveIntoServerCache("language", newlan);
    // console.log("chips", chips);
    // console.log("delete language", languagess);
  };

  const handleAdd = () => {
    if ($('[name="language"]').val() === "") {
      setTimeout(() => {
        toast.error("Add the language you just filled", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
    } else {
      let newlan = languagess;
      let k = languagess?.length;
      const data = {
        key: k,
        language: lang_value,
        rate: lang_prof,
        Review: true
      };

      newlan?.push(data);
      setLanguage(newlan);
      setChips(newlan);
      props.setPropLangRate("");
      props.setPropLang("");
      dispatch(dispatchLanguage(newlan));
      saveIntoServerCache("language", newlan);
      setLan_value("");
      setLan_prof("");
      
    }
  };

  let languages = [];
  for (let i = 0; i <= Languages.length - 1; i++) {
    languages.push(`${Languages[i].value}`);
  }

  let rate = [];
  for (let i = 0; i <= Rate.length - 1; i++) {
    rate.push(`${Rate[i].value}`);
  }

  let rate_sp = [];
  for (let i = 0; i <= Rate_sp.length - 1; i++) {
    rate_sp.push(`${Rate_sp[i].value}`);
  }

  let lang = [];
  for (let i = 0; i <= Langu.length - 1; i++) {
    lang.push(`${Langu[i].value}`);
  }

  const handleNext = () => {
    if (stat === "false" || stat === false) {
      dispatch(saveLanguage(languagess, secret.secret));
    }
    props.handleStepChange();
  };

  return (
    <>
      <HelmetProvide
        title="Moil | Create Your Resume"
        description="Build your professional resume with Moil's easy-to-use resume creation form. Showcase your skills, experience, and qualifications to potential employers."
        className="notranslate"
      />
      <div className="flex-col justify-start items-start gap-4 flex w-full rounded-[16px] bg-white resume_page py-6 px-4 ">

        <p class="text-xl font-semibold leading-8 text-left text-[#5843BD]">
          Languages Spoken
        </p>

        <div className="flex-col justify-start items-start gap-3 flex w-full">
          <div className="flex flex-wrap gap-3">
            {chips?.map((languagess, key) => (
              <AddedItem
                item={languagess.language}
                key={key}
                color={languagess.Review}
                // onClick={() => handleClick(languagess)}
                delete={() => handleDelete(languagess)}
              />
            ))}
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-y-4  items-center w-full gap-x-8">
          <div className="flex-col justify-start items-start gap-2 flex w-full">
            <div className="flex gap-3 items-center">
              <div className="px-1 w-2 h-2 rounded-full bg-black"></div>
              <label
                className="text-black text-base font-medium"
                for="firstName"
              >
                What language do you speak ?
              </label>
            </div>
            <BasicSelect
              placeholder="Language"
              labelId="Language"
              selectMenu="language"
              value={lang_value}
              onChange={(event) => {setLan_value(event.target.value), props.setPropLang(event.target.value)}}
              id="Language"
              menuItems={[...languages]}
            />
          </div>
          <div className="flex-col justify-start items-start gap-2 flex w-full">
            <div className="flex gap-3 items-center">
              <div className="px-1 w-2 h-2 rounded-full bg-black"></div>
              <label
                className="text-black text-base font-medium"
                for="lastName"
              >
                What is your proficiency level?
              </label>
            </div>
            <div className="w-full flex">
              <BasicSelect
                placeholder="Select your proficiency level"
                labelId="Proficiency"
                selectMenu="rate"
                value={lang_prof}
                onChange={(event) => {setLan_prof(event.target.value), props.setPropLangRate(event.target.value)}}
                id="proficiency"
                menuItems={[...rate]}
              />
            </div>
          </div>
        </div>

        <div className="flex-col justify-start items-start gap-3 flex w-full">
          <AddMore text="language" onClick={handleAdd} />
          <p className="text-red-500 text-base font-medium">
            {chips?.length == 0 ? "No language yet." : "You can add more language"}
          </p>
          {/* <div className="flex flex-wrap gap-3">
            {chips?.map((languagess, key) => (
              <AddedItem
                item={languagess.language}
                key={key}
                delete={() => handleDelete(languagess)}
              />
            ))}
          </div> */}
        </div>
        <ToastContainer />
      </div>
    </>
  );
}
