import React from 'react'

function PurpleFillBtn({children, HandlePurpleFillClick}) {
  return (
    <button onClick={HandlePurpleFillClick} className="text-[#ffffff] bg-[#5843BD] border-[#ffffff] hover:bg-[#E1DCF9] border hover:border-[#5843BD] hover:text-[#5843BD]  px-5 py-3 rounded-2xl  font-semibold text-xs desktop:text-base">
      {children}
    </button>
  );
}

export default PurpleFillBtn