
import React, {useState, useEffect, useRef, useContext } from "react"
import reference from "../../common/reference"
import BackButton from "../back_button"
import { createPortal } from "react-dom"

import AttachmentIcon from '@mui/icons-material/Attachment';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import InterviewModal from "../interviewModal";
import { useDispatch, useSelector } from 'react-redux';
import {
	existing_resume_features,
	user_CV_state_back_to_null,
} from '../../../Redux/ActionCreator';
import LoaderModal from '../LoaderModal';

import { useAnalytics } from '../../../context/AnalyticsContext';



const NewUploadModal = (props) => {
    const [ show, setShow ] = useState(false);
    const analytics = useAnalytics();
	const [stat, setStat] = useState(false);
	const [showWarningModal, setShowWarningModal] = useState(true);
	const dispatch = useDispatch();
	const [userFile, setUserFile] = useState('');
	const navigate = useNavigate();
	const uploadInputRef = useRef();
	let labelClasses =
		'flex items-center text-sm font-semibold text-slate-700 ml-[24px]';

	const upload_status = useSelector((prev) => prev.existingCV);
	const user = useSelector(e => e.userInfo);

	const savedResume = useSelector((e) => e.saved_cv);
  	const details = savedResume.message;

	let [ select, setSelect ] = useState(false);
	let [ uploadPc, setUploadPc ] = useState(false);

	const handleUploadPc = () => {
		if (select == true) {
			setSelect(false);
		}
		setUploadPc(true);
	}

	const handleSelect = () => {
		if (uploadPc == true) {
			setUploadPc(false);
		}
		setSelect(true);
	}

	// improvise job
	let job = {
		indeed: false
	};

	const formSubmissionHandle = (e) => {
		e.preventDefault();
		//Sending the file to the backend here
		const file = uploadInputRef.current?.files[0];
        // console.log("file", file);
		if (file === undefined) {
			// console.log("file", file)
			setTimeout(() => {
				toast.error("Select a file to upload", {
						position: toast.POSITION.TOP_RIGHT,
				});
		}, 50);
		} else {      
            const jsonData = { key: 'value' };
            let formData = new FormData();
            formData.append('uploadPDF', file);
            formData.append('jsonData', JSON.stringify(jsonData));
            // Debugging: Check the appended FormData
            for (let pair of formData.entries()) {
                // console.log(pair[0] + ': ' + pair[1]);
            }
            // console.log("formData", formData)
            // console.log("file after", file)
;			setStat(true);
			dispatch(existing_resume_features(formData));
		}
	};

	const triggerUploadFile = (e) => {
		e.preventDefault();
		uploadInputRef.current.click();
	};
	const fileChangeHandle = (e) => {
		e.preventDefault();
		// console.log(1);
		// console.log("target", e.target.value)
		// console.log("uploadInputRef.current?.files[0]", uploadInputRef.current?.files[0])
		let enteredFileName = uploadInputRef.current?.files[0]?.name;
		if (enteredFileName) {
			setUserFile(enteredFileName);
		}
	};

	const skipHandle = (e) => {
		navigate('/generateCV');
	};

	const handleClick = () => {
		navigate("/user/dashboard");
	}

	const handleContinue = () => {
		analytics.track("Resume upload continue btn", {
      Type: "Employee",
      time: new Date(),
      Email: user.user?.email,
			userId: user.user?._id,
    });
		dispatch(user_CV_state_back_to_null())
	}

	let text = "Extracting CV text, this can take a couple of minutes..."

    const handleback = () => {
        let frp = new URLSearchParams(window.location.search).get("frp");
        let noResume = new URLSearchParams(window.location.search).get("noResume");
        if (frp) {
            navigate(`/user/profile/:${user.user.profile_id}?download=true`);
        } else if (noResume) {
            navigate(`/jobs/${noResume}?noResume=true`);
        } else {
            window.history.back();
        }
    }

    let showModal = uploadPc && (createPortal(
        <div className="top-0 left-0 fixed bottom-0 right-0 z-10 h-screen flex justify-center items-center bg-black bg-opacity-70 backdrop-blur-[2px]">
            <form
                onSubmit={formSubmissionHandle}
                className="bg-white px-10 py-5 rounded-xl">
                <div className="flex flex-col md:gap-3 justify-between mb-4">
                    
                    <BackButton onClick={() => setUploadPc(false)}/>
                    <label className="block mb-6 flex-2 relative">
                        <span className={labelClasses}>Upload Resume</span>
                        <span className="hr-span"></span>
                        <div className="w-[200px] h-[200px] mx-auto border-dashed border-[2px] rounded-2xl border-sec-300 cursor-pointer relative my-8">
                            <input
                                ref={uploadInputRef}
                                onInput={fileChangeHandle}
                                type="file"
                                accept=".doc, .docx, .pdf"
                                className="opacity-0 absolute -z-[1] w-full h-full"
                                name="uploadPDF"
                            />
                            {!userFile && (
                                <button
                                    type="button"
                                    onClick={triggerUploadFile}
                                    className="py-[10px] px-[20px] bg-sec-500 border border-sec-300 drop-shadow-btn text-pry-600 rounded-3xl center pointer-events-none">
                                    Select
                                </button>
                            )}
                            {userFile && (
                                <article className="absolute top-[50%] left-[50%] transform -translate-y-[50%] text-center -translate-x-[50%]">
                                    <AttachmentIcon />
                                    <span>{userFile}</span>
                                </article>
                            )}
                        </div>
                        <p className="text-base text-center my-4">
                            Reduce your time for CV generation by uploading an existing Resume
                        </p>
                        {/* <button
                            type="button"
                            onClick={triggerUploadFile}
                            className="py-[10px] px-[20px] bg-pry-800 drop-shadow-btn text-white font-semibold rounded-3xl block mx-auto">
                            {language["Upload_btn_text"]}
                        </button> */}
                    </label>
                </div>
                {upload_status.errMess !== null ? (
                    <h6
                        style={{
                            color: 'red',
                            marginTop: '5px',
                            textAlign: 'center',
                            fontSize: "10px"
                        }}>
                        {upload_status.errMess}
                    </h6>
                ) : null}
                <div className="flex justify-center items-center gap-4">
                    <button
                        type="text"
                        onClick={skipHandle}
                        className="py-2 px-6 rounded-full block bg-sec-300 border border-sec-400 dropshadow-btn text-pry-200 font-semibold">
                        Skip
                    </button>
                    <button
                        type="submit"
                        className="py-2 px-6 rounded-full block bg-sec-300 border border-sec-400 dropshadow-btn text-pry-200 font-semibold"
                        onClick={handleContinue}>
                        Continue
                    </button>
                    <ToastContainer />
                    {stat === true ? (
                        upload_status.errMess === null ? <LoaderModal text={text}/> : null
                    ) : null}
                </div>
            </form>
        </div>, document.getElementById("modal")
    ));
    
    return(
        <>
        <div className="w-full h-[100vh] bg-transparent flex justify-center bg-gray-300">
            <div className="w-full flex md:items-center justify-center">
                <div className="bg-white md:rounded-2xl md:w-[45vw] md:h-[450px] py-8 px-8 w-full xsm:h-[420px] upload border-1 border-[#777DEA]">
                    <BackButton className="ml-0"  onClick={() => handleback()} /> {/* navigate("/user/dashboard") */}
                    <h2 className="text-2xl text-black text-center font-semibold mt-4 mb-4"> Choose a resume</h2>
                    <hr className="h-4 w-full text-[#5843BD] px-3"/>
                    <div className="flex items-center justify-center">
                        <div>
                            <button className="flex items-center p-4 justify-center border-2 border-[#5843BD] rounded-md bg-[#F6F5FB] cursor-pointer vsm:w-[320px] md:w-[380px]" onClick={handleSelect}>
                                <img src={reference.ResumeAppSvg} alt="resume icon" />
                                <h2 className="ml-2 vsm:text-sm md:text-xl text-black font-medium">Select an existing Moil resume</h2>
                            </button>
                            {select && (
									<InterviewModal 
										OpenInterviewModal={select}
                						Closemodal={() => setSelect(false)}
                						indeed={job}
                						purpose="select resume"
									/>
								)}
                            <button className="flex items-center p-4 justify-center border-2 border-[#898989] rounded-md mt-4 mb-4 cursor-pointer vsm:w-[320px] md:w-[380px]" onClick={handleUploadPc}>
                                <img src="https://res.cloudinary.com/drlcisipo/image/upload/v1709729631/Website%20images/Frame_1215_azix3a.png" alt="Upload icon" />
                                <h2 className="ml-2 vsm:text-sm md:text-xl text-[#898989] font-medium">Upload from your device</h2>
                            </button>
                            <button className="flex items-center justify-center p-4 cursor-pointer vsm:w-[320px] md:w-[380px]" onClick={() => navigate("/generateCV")}>
                                <img src="https://res.cloudinary.com/drlcisipo/image/upload/v1709730314/material-symbols_forward_ycnfqy.png" alt="continue arror" />
                                <h2 className="text-orange-500 vsm:text-sm md:text-xl font-medium ml-2">Continue without upload</h2>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {showModal}
        </>
    )
}

export default NewUploadModal;
