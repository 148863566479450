import React, { useState, useEffect } from "react";
import MessageLanding from "./component/message_box/landing";
import MessageVerticalItem from "./component/message_vertical_item";
import { useSelector, useDispatch } from "react-redux";
import { getChatInformation, send_message_loading, end_to_end_chat_loading, decision_loading  } from "../../Redux/ActionCreator";
const { io } = require("socket.io-client");
import { baseURL } from "../../Redux/baseURL";
import timeResolver from "../Job/timeresolver";

export default function UserMessages() {
  const dispatch = useDispatch();
  const userInfo = useSelector(e => e.userInfo.user);
  const chat_info = useSelector(e => e.chat_info.message);
  const login_stat = useSelector( e => e.login);
  const AcctType = login_stat?.profile_status?.AcctType;
  // const login_stat = useSelector(e => e.login);
  // let AcctType = login_stat.profile_status?.AcctType;
  const [ socket, setSocket ] = useState(null);
  const [ onlineUser, setOnlineUser ] = useState([]);
  const [ notification, setNotification ] = useState([]);
  const employee = useSelector(e => e.profile);

  useEffect(() => {
    dispatch(decision_loading(true));
    dispatch(send_message_loading(true));
    dispatch(end_to_end_chat_loading(true));
    if (userInfo !== null) {
      // console.log("caller claled caller");
      dispatch(getChatInformation(userInfo.profile_id));
    }
  }, []);

  useEffect(() => {
    if (userInfo !== null) {
      // console.log("caller claled caller");
      dispatch(getChatInformation(userInfo.profile_id));
    }
  }, [userInfo]);

  // console.log("chat_info", chat_info);

  return (
    <div className="Inbox px-3 sm:px-6 pb-2 h-calc-70">
      <p className="text-black text-opacity-50 text-[32px] font-normal font-['Poppins'] mb-2">Messages</p>
      <div className="ipad:flex ipad:gap-[0.5rem] h-calc-chatarea">
        <div className="w-full max-w bg-white bg-opacity-80 rounded-xl shadow border border-indigo-300 border-opacity-30 xl:max-w-[320px] xtraxl:max-w-[340px]">
          <div className="ChatMessage h-full overflow-y-scroll px-4 pb-4">
          {chat_info !== null && (
              <>{chat_info?.map((data, key) => (
                <MessageVerticalItem
                  name={data?.chatIds[0]?.employerId?.name}
                  position={data?.chatIds[0]?.jobTitle}
                  team="Design Team"
                  messageAge={timeResolver(data?.createdAt)}
                  messageNo={data?.Notification}
                  avatar={data?.chatIds[0]?.employerId?.photo}
                  avatarStyle="bg-rose-300"
                  receiver={data?.chatIds[0]?.employerId?.name}
                  userType="employee"
                  index={chat_info?.indexOf(data)}
                />
              ))}</>
            )}
          </div>
        </div>
        <MessageLanding />
      </div>
    </div>
  );
}