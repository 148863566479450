import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function BasicSelect(props) {
  return (
    <Box sx={{ width: "100%" }}>
      <FormControl
        sx={{
          width: "100%",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#777dea54",
            },
            "&:hover fieldset": {
              borderColor: "#777dea54", // Border color on hover (optional)
            },
            "&.Mui-focused fieldset": {
              borderColor: "#777dea54", // Border color on focus
              borderWidth: "1px", // Optional: Change border width on focus
            },
          },
        }}
        size="small"
      >
        <InputLabel id={props.labelId} sx={{ color: "#0000004D", alignItems: 'center', height: '60%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
          {props.placeholder}
        </InputLabel>
        <Select
          labelId={props.labelId}
          id={props.id}
          value={props.value}
          label={props.selectMenu}
          onChange={props.onChange}
          sx={{ borderRadius: "8px", height: "100%" }}
          name={props.selectMenu}
        >
          {props.menuItems.map((item) => (
            <MenuItem className="" value={item}>{item}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}


