export default function ViewProp({setAllowed, length, normalLength}) {
  const showMore = () => {
    setAllowed(normalLength);
  }

  const showLess = () => {
    setAllowed(4);
  }

  const handleClick = () => {
    length > 4 ? showLess() : showMore();
  }

  return (
    <button className="flex gap-1 items-center" onClick={handleClick}>
      <span className="text-indigo-700 text-base font-normal leading-snug">
        {length > 4
          ? `view less`
          : `view more`}
      </span>
      {length > 4 ? (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_473_2752)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.7073 8.29302C12.5198 8.10555 12.2655 8.00024 12.0003 8.00024C11.7352 8.00024 11.4809 8.10555 11.2933 8.29302L5.63634 13.95C5.54083 14.0423 5.46465 14.1526 5.41224 14.2746C5.35983 14.3966 5.33225 14.5278 5.33109 14.6606C5.32994 14.7934 5.35524 14.9251 5.40552 15.048C5.4558 15.1709 5.53006 15.2825 5.62395 15.3764C5.71784 15.4703 5.82949 15.5446 5.95239 15.5948C6.07529 15.6451 6.20696 15.6704 6.33974 15.6693C6.47252 15.6681 6.60374 15.6405 6.72575 15.5881C6.84775 15.5357 6.9581 15.4595 7.05034 15.364L12.0003 10.414L16.9503 15.364C17.1389 15.5462 17.3915 15.647 17.6537 15.6447C17.9159 15.6424 18.1668 15.5373 18.3522 15.3518C18.5376 15.1664 18.6427 14.9156 18.645 14.6534C18.6473 14.3912 18.5465 14.1386 18.3643 13.95L12.7073 8.29302Z"
              fill="#5843BD"
            />
          </g>
          <defs>
            <clipPath id="clip0_473_2752">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="matrix(1 0 0 -1 0 24)"
              />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_473_2781)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.7073 15.707C12.5198 15.8944 12.2655 15.9998 12.0003 15.9998C11.7352 15.9998 11.4809 15.8944 11.2933 15.707L5.63634 10.05C5.54083 9.95773 5.46465 9.84739 5.41224 9.72538C5.35983 9.60338 5.33225 9.47216 5.33109 9.33938C5.32994 9.2066 5.35524 9.07492 5.40552 8.95202C5.4558 8.82913 5.53006 8.71747 5.62395 8.62358C5.71784 8.52969 5.82949 8.45544 5.95239 8.40515C6.07529 8.35487 6.20696 8.32957 6.33974 8.33073C6.47252 8.33188 6.60374 8.35947 6.72575 8.41188C6.84775 8.46428 6.9581 8.54047 7.05034 8.63598L12.0003 13.586L16.9503 8.63598C17.1389 8.45382 17.3915 8.35302 17.6537 8.3553C17.9159 8.35758 18.1668 8.46275 18.3522 8.64816C18.5376 8.83357 18.6427 9.08438 18.645 9.34658C18.6473 9.60877 18.5465 9.86137 18.3643 10.05L12.7073 15.707Z"
              fill="#5843BD"
            />
          </g>
          <defs>
            <clipPath id="clip0_473_2781">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
    </button>
  );
}
