import React from 'react';
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { reset_password } from '../../Redux/ActionCreator';
import ResetForm from '../common/reset_form';



const Reset = () => {
	const [value, setValue] = useState(false);
	const [sending, setSending] = useState(false);
	// initialize useDispatch and useSelector hook
	const dispatch = useDispatch();
	const r_stat = useSelector(prev => prev.password_reset);
	const [enteredPassword, setEnteredPassword ] = useState({password: "", confirm: ""});
	const navigate = useNavigate();

	const userId = new URLSearchParams(window.location.search).get("userId");

	const formHandle = (e) => {
		e.preventDefault();
		setSending(true);
		if (enteredPassword.password === enteredPassword.confirm && enteredPassword.password.length !== 0) {
			dispatch(reset_password({ password: enteredPassword.password, confirmPassword: enteredPassword.confirm, userId: userId }));
			setEnteredPassword({password: "", confirm: ""});
		} else {
			setValue(true);
			alert("Please make sure your password and confirm password are the same.");
			setEnteredPassword({password: "", confirm: ""});
		}
	};

	if (r_stat.message === "Official") {
		navigate("/hirelogin");
	} else if (r_stat.message === "Client") {
		navigate("/login");
	}

	return (
		<div className="h-screen relative max-w-[100vw] overflow-x-hidden">
			<div className={`absolute z-10 w-full gap-x-4 gap-y-4 justify-center md:top-[50%] md:translate-y-[-50%] lg:max-w-[876px] xl:max-w-[1024px] left-[50%] translate-x-[-50%] top-[80px] flex lg:gap-x-0 lg:justify-between flex-col md:flex-row items-center px-4`}>

				<div className='flex flex-col gap-y-4 items-center'>
					<svg width="79" height="67" viewBox="0 0 79 67" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M25.1001 0.959991C27.6462 0.959991 30.088 1.97142 31.8883 3.77177C33.6887 5.57212 34.7001 8.01391 34.7001 10.56C34.7001 12.336 34.1721 13.968 33.3561 15.36C33.3561 15.36 29.9001 14.4 25.1001 14.4C20.3001 14.4 16.8441 15.36 16.8441 15.36C16.0281 13.968 15.5001 12.336 15.5001 10.56C15.5001 8.01391 16.5115 5.57212 18.3119 3.77177C20.1122 1.97142 22.554 0.959991 25.1001 0.959991ZM53.9001 0.959991C59.2281 0.959991 63.5001 5.23199 63.5001 10.56C63.5001 12.336 62.9721 13.968 62.1561 15.36C62.1561 15.36 58.7001 14.4 53.9001 14.4C49.1001 14.4 45.6441 15.36 45.6441 15.36C44.8281 13.968 44.3001 12.336 44.3001 10.56C44.3001 5.23199 48.5721 0.959991 53.9001 0.959991ZM39.5001 66.72C48.2067 66.7269 56.6972 64.0091 63.7815 58.9477C70.8658 53.8862 76.1887 46.7349 79.0041 38.496L72.6681 36.288C67.8201 50.496 54.4761 60 39.5001 60C24.5241 60 11.1801 50.496 6.33209 36.288L-0.00390625 38.496C2.81147 46.7349 8.13437 53.8862 15.2187 58.9477C22.3029 64.0091 30.7935 66.7269 39.5001 66.72Z" fill="#F8F8F8" />
					</svg>

					<p className="text-white font-poppins text-[24px] md:text-[32px] font-semibold leading-[30px] md:leading-[36px] tracking-normal flex flex-col gap-y-10 max-w-[380px]">Don’t worry!
						Everybody forgets
						their password.
						<span className="font-poppins text-[14px] md:text-[24px] font-[500] leading-[20px] md:leading-[28px] tracking-normal">
							Create a new password
							without stress.
						</span></p>
				</div>
				<ResetForm handle={formHandle} cred={setEnteredPassword} confirm={enteredPassword.confirm} password={enteredPassword.password} />
			</div>


			<div className={`w-full h-screen grid custom-grid`}>

				<div className="w-full max-h-[340px] md:max-h-max md:h-full bg-[#5843BD]"></div>

				<div className="relative h-screen overflow-hidden -z-1000">
					<svg width="851" height="851" viewBox="0 0 1028 992" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute top-[-30%] right-[-30%] -z-10">
						<g opacity="0.1" filter="url(#filter0_f_3436_5351)">
							<circle cx="739.5" cy="252.5" r="425.5" fill="#777DEA" />
						</g>
						<defs>
							<filter id="filter0_f_3436_5351" x="0" y="-487" width="1479" height="1479" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
								<feFlood flood-opacity="0" result="BackgroundImageFix" />
								<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
								<feGaussianBlur stdDeviation="157" result="effect1_foregroundBlur_3436_5351" />
							</filter>
						</defs>
					</svg>

					<svg width="1065" height="1065" viewBox="0 0 1201 1024" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute bottom-[-25%] right-[-20%] -z-10">
						<g opacity="0.1" filter="url(#filter0_f_3436_5429)">
							<circle cx="847.5" cy="695.5" r="533.5" fill="#FF6633" />
						</g>
						<defs>
							<filter id="filter0_f_3436_5429" x="0" y="-152" width="1695" height="1695" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
								<feFlood flood-opacity="0" result="BackgroundImageFix" />
								<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
								<feGaussianBlur stdDeviation="157" result="effect1_foregroundBlur_3436_5429" />
							</filter>
						</defs>
					</svg>
				</div>
			</div>
		</div>
	);
};

export default Reset;
