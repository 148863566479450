import { useSelector, useDispatch } from 'react-redux';
import { saveCvDetails } from '../../Redux/ActionCreator';
import { v4 as uuidv4 } from 'uuid';

export const AdjustGPTResponse = (response, id) => { // response is cv_details.message
  // initialize the hooks from react-redux;
  // const dispatch = useDispatch();

  let res = response;

  if ( res.Education === undefined || res.Education?.length === 0) {
    if (res.School !== null) {
      if (res.School !== undefined && res.School.length !== 0) {
        res.School.forEach(element => {
          if ( element.name !== undefined && element.name.length !== 0 && element.School === undefined ) {
            element.School = element.name;
            delete element.name;
          }
          if ( element.Name !== undefined && element.Name.length !== 0 && element.School === undefined ) {
            element.School = element.Name;
            delete element.Name;
          }
          if ( element.degree !== undefined && element.degree.length !== 0 && element.Degree === undefined ) {
            element.Degree = element.degree;
            delete element.degree;
          }
          if ( element.start_date !== undefined && element.Start_date === undefined) {
            element.Start_date = element.start_date;
            delete element.start_date;
          }
          if ( element.end_date !== undefined && element.End_date === undefined) {
            element.End_date = element.end_date;
            delete element.end_date;
          }
          if (element.End_date === null) {
            element.End_date = "Present";
          }
        })
        res.Education = res.School;
    } else {
      res.Education = [];
    }
    }
  } else {
    if (res.Education !== undefined && res.Education !== null && res.Education?.length !== 0) {
      res.Education.forEach(element => {
        if ( element.name !== undefined && element.name.length !== 0 && element.Education === undefined ) {
          element.School = element.name;
          delete element.name;
        }

        if ( element.Name !== undefined && element.Name.length !== 0 && element.School === undefined ) {
          element.School = element.Name;
          delete element.Name;
        }
        
        if ( element.degree !== undefined && element.degree.length !== 0 && element.Degree === undefined ) {
          element.Degree = element.degree;
          delete element.degree;
        }
        if ( element.start_date !== undefined && element.Start_date === undefined) {
          element.Start_date = element.start_date;
          delete element.start_date;
        }
        if ( element.end_date !== undefined && element.End_date === undefined) {
          element.End_date = element.end_date;
          delete element.end_date;
        }
        if (element.End_date === null) {
          element.End_date = "Present";
        }
      })
    }
  }

  // experience
    if ( res.Experience !== undefined && res.Experience !== null && res.Experience.length !== 0) {
      if (res.Experience[0]?.company !== undefined || res.Experience[0]?.Company !== undefined || res.Experience[0].description !== undefined || res.Experience[0]?.location !== undefined || res.Experience[0]?.title !== undefined || res.Experience[0]?.Description !== undefined || res.Experience[0].Location !== undefined || res.Experience[0]?.Title !== undefined || res.Experience[0]?.Responsibilities !== undefined || res.Experience[0]?.responsibilities !== undefined || res.Experience[0]?.Job_description !== undefined) {
        res.Experience.forEach(element => {
          if (element.company !== undefined && element.company.length !== 0 && element.Where_i_worked === undefined) {
            element.Where_i_worked = element.company;
            delete element.company;
          }
          if (element.description !== undefined && element.description.length !== 0 && element.Day_to_day_work === undefined) {
            element.Day_to_day_work = element.description;
            delete element.description;
          }
          if (element.Job_description !== undefined && element.Job_description.length !== 0 && element.Day_to_day_work === undefined) {
            element.Day_to_day_work = element.Job_description;
            delete element.Job_description;
          }
          if (element.Responsibilities !== undefined && element.Responsibilities.length !== 0 && element.Day_to_day_work === undefined) {
            element.Day_to_day_work = element.Responsibilities;
            delete element.Responsibilities;
          }
          if (element.location !== undefined && element.location.length !== 0 && element.Where_i_worked_location === undefined) {
            element.Where_i_worked_location = element.location;
            delete element.location;
          }
          if (element.title !== undefined && element.title.length !== 0 && element.My_job_title === undefined) {
            element.My_job_title = element.title;
            delete element.title;
          }

          if (element.Company !== undefined && element.Company.length !== 0 && element.Where_i_worked === undefined) {
            element.Where_i_worked = element.Company;
            delete element.Company;
          }
          if (element.Description !== undefined && element.Description.length !== 0 && element.Day_to_day_work === undefined) {
            element.Day_to_day_work = element.Description;
            delete element.Description;
          }
          if (element.Location !== undefined && element.Location.length !== 0 && element.Where_i_worked_location === undefined) {
            element.Where_i_worked_location = element.Location;
            delete element.Location;
          }
          if (element.Title !== undefined && element.Title.length !== 0 && element.My_job_title === undefined) {
            element.My_job_title = element.Title;
            delete element.Title;
          }

          if ( element.start_date !== undefined && element.Work_start_date === undefined) {
            element.Work_start_date = element.start_date;
            delete element.start_date;
          }

          if ( element.Start_date !== undefined && element.Work_start_date === undefined) {
            element.Work_start_date = element.Start_date;
            delete element.start_date;
          }

          if ( element.end_date !== undefined && element.Work_end_date === undefined) {
            element.Work_end_date = element.end_date;
            delete element.end_date;
          }
          
          if ( element.End_date !== undefined && element.Work_end_date === undefined) {
            element.Work_end_date = element.End_date;
            delete element.End_date;
          }

          if ( element.Work_end_date === null || element.Work_end_date === undefined || element.Work_end_date.length === 0 ) {
            element.Work_end_date = "Present";
          }
          if (element.Day_to_day_work !== undefined && element.Day_to_day_work.length !== 0) {
            if (element.Day_to_day_work?.includes("\n")) {
              // element.Day_to_day_work.replace("\n", "");
            }
          }
        })
      }
    } else {
      res.Experience = [];
    }

    // I need to remove the bullet from gpt and put the whole experience text into an array for easy mapping. (Priority here is to align the text properly on the resume.)
    for ( let i = 0; i <= res.Experience.length - 1; i++) {
      if (res.Experience[i]?.Day_to_day_work !== null || res.Experience[i]?.Day_to_day_work !== undefined) {
        if (typeof res.Experience[i]?.Day_to_day_work === "object") {
        } else {
          console.log("The experience to split", res.Experience[i].Day_to_day_work);
          console.log("The experience to split type: ", typeof res.Experience[i]?.Day_to_day_work);
          let exp_array = res.Experience[i]?.Day_to_day_work.split(/[\r\n]+/);
          console.log("exp_array: ", exp_array);
          res.Experience[i].Day_to_day_work = exp_array;
        }
      }
    }

    // language
    if (res.Language !== undefined && res.Language !== null) {
      res.Language.forEach(element => {
        if (element.rate === undefined) {
          if (element.speak_rate !== undefined) {
            element.rate = element.speak_rate;
            delete element.speak_rate;
          }
          if (element.Language_speaking_rate !== undefined) {
            element.rate = element.Language_speaking_rate;
            delete element.Language_speaking_rate;
          }
        }
        if (element.Language !== undefined && element.Language.length !== 0) {
          element.language = element.Language;
          delete element.Language;
        }
        if (element.Speak_rate !== undefined) {
          element.rate = element.Speak_rate;
          delete element.Speak_rate;
        }
        if (element.Speaking_rate !== undefined) {
          element.rate = element.Speaking_rate;
          delete element.Speaking_rate;
        }
      })
    }

    if (typeof res.Reference === "string") {
      if (res.Reference === "none") {
        res.Reference = [{Reference_person_name: ""}];
      } 
    } else if (Array.isArray(res.Reference) === true) {
      if (res.Reference.length !== 0) {
        if (res.Reference[0]?.Reference_person_name === undefined) {
          res.Reference[0].Reference_person_name = "";
        }
      }
    }


  if (res.Award === undefined || res.Award === null) {
    if ( res.Awards !== undefined ) {
      res.Award = res.Awards;
    } else {
      let a = [];
      a.push({Award_name: ""});
      res.Award = a;
    }
  } else if (res.Award?.length !== 0) {
    if (Array.isArray(res.Award) === true) {
      if (res.Award.length > 0) {
        for (let i = 0; i <= res.Award.length - 1; i++) {
          if (res.Award[i] === null) {
            res.Award.push({Award_name: ""});
          } else if (typeof res.Award[i] === "string") {
            let new_awrd = {};
            new_awrd.Award_name = res.Award[i];
            res.Award[i] = new_awrd;
          } else if (typeof res.Award[i] === 'object') {
            if (res.Award[i]?.Award_name === null) {
              res.Award[i].Award_name = "";
            }
        
            let award = [];
            res.Award.forEach(element => {
              if (element.Award_name?.length !== 0 && element.Award_name !== null) {
                award.push(element);
              }
            })
            res.Award = award;
          }
        }
      }
    }
  } else if (res.Award?.length === 0) {
    res.Award.push({Award_name: ""});
  }

  if (res !== null) {
    if (typeof res.Interest === "string") {
      res.Interest = res?.Interest.split(", ");
    }
  }

  if ( Array.isArray(res.Technical_skills) === false ) {
    if (typeof res.Technical_skills === "string") {
      res.Technical_skills = res.Technical_skills.split(", ");
    }
  }

   if (Array.isArray(res.Personal_skills) === false ) {
    if (typeof res.Personal_skills === "string") {
      res.Personal_skills = res.Personal_skills.split(", ");
    }
   }

  // editing ends

  // check if cv_details is not null, if it is not, post the cv_details to the database.
/*   if (res.Secret === undefined) {
    const clientSecret = uuidv4();
    res.Secret = clientSecret;
    // post here
    dispatch(saveCvDetails(res, id, clientSecret ));
  }
 */
  return res; // cv_details.message new object;
}


export const AdjustExperienceDate = (arg) => {
  console.log("initial experience", arg);
  let experience_array = arg;
  let p = [];
  console.log("experience_array b4", experience_array);

  for (let i = 0; i <= experience_array.length - 1; i++) {

    if (((experience_array[i]?.Work_end_date?.split(" ")[1] < experience_array[i + 1]?.Work_end_date?.split(" ")[1]) && (experience_array[i]?.Work_end_date?.split(" ")[1] !== null || experience_array[i]?.Work_end_date?.split(" ")[1] !== undefined) && (experience_array[i + 1]?.Work_end_date?.split(" ")[1] !== null || experience_array[i+ 1]?.Work_end_date?.split(" ")[1] !== undefined)) || ((experience_array[i]?.Work_end_date?.split("/")[1] < experience_array[i + 1]?.Work_end_date?.split("/")[1]) && (experience_array[i]?.Work_end_date?.split("/")[1] !== null || experience_array[i]?.Work_end_date?.split("/")[1] !== undefined) && (experience_array[i + 1]?.Work_end_date?.split("/")[1] !== null || experience_array[i+ 1]?.Work_end_date?.split("/")[1] !== undefined)) ) {
      let temp = experience_array[i];
      experience_array[i] = experience_array[i + 1];
      experience_array[i + 1] = temp;

    } else if (experience_array[i]?.Work_end_date?.includes("Present") || experience_array[i]?.Work_end_date?.includes("present") || experience_array[i]?.Work_end_date === null || experience_array[i]?.Work_end_date === undefined ) {
      console.log("Present status", experience_array[i]);
      p.push(experience_array[i]);
      experience_array.slice(i, 1);
    }
  }
  console.log("p", p);

  const filter_exp_not_present = experience_array.filter(element => {
    if (element?.Work_end_date?.includes("Present") == false && element?.Work_end_date?.includes("present") == false) {
      return element;
    }
  })

  const newExp = [ ...p, ...filter_exp_not_present ];
  p = [];
  console.log("experience_array after", filter_exp_not_present);
  console.log("newExp", newExp);
  console.log("Filter", filter_exp_not_present)
  experience_array = newExp;
  return experience_array;
}

export const arrangeEducationDate = (arg) => {
    console.log("Called!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
    let edu = arg;
    let e = [];
    console.log("Initial education", arg);

    if (edu !== null && edu !== undefined) {
        for (let i = 0; i <= edu.length - 1; i++) {
            const currentDate = edu[i]?.End_date;
            const nextDate = edu[i + 1]?.End_date;

            if (currentDate !== undefined && currentDate !== null && currentDate !== true) {
              const isPresent = currentDate?.includes("present");
                  console.log("currentDate", currentDate);
                if (isPresent || currentDate === null || currentDate === undefined) {
                    e.push(edu[i]);
                } else if (
                    currentDate?.split(/\s|\//).length > 1 &&
                    nextDate?.split(/\s|\//).length > 1 &&
                    currentDate?.split(/\s|\//)[1] < nextDate?.split(/\s|\//)[1]
                ) {
                    swapElements(edu, i, i + 1);
                } else if (
                    currentDate?.split(/\s|\//).length == 1 &&
                    nextDate?.split(/\s|\//).length > 1 &&
                    currentDate?.split(/\s|\//)[0] < nextDate?.split(/\s|\//)[1]
                ) {
                    swapElements(edu, i, i + 1);
                } else if (
                    currentDate?.split(/\s|\//).length > 1 &&
                    nextDate?.split(/\s|\//).length == 1 &&
                    currentDate?.split(/\s|\//)[1] < nextDate?.split(/\s|\//)[0]
                ) {
                    swapElements(edu, i, i + 1);
                }
            }
        }

        console.log("e", e);

        const filter_edu_not_present = edu.filter((element) => element.End_date !== true && element.End_date !== undefined && !element?.End_date?.includes("present"));
        const newEdu = [...e, ...filter_edu_not_present];

        console.log("Education after", newEdu);
        return newEdu;
    }
};

// Helper function to swap elements in an array
const swapElements = (arr, i, j) => {
    const temp = arr[i];
    arr[i] = arr[j];
    arr[j] = temp;
};




// export const arrangeEducationDate = (arg) => {
//   console.log("Called!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
//   let edu = arg;
//   let e = [];
//   console.log("Initial education", arg);

//   if (edu !== null && edu !== undefined) {
//       for (let i = 0; i <= edu.length - 1; i++) {
//         if (edu[i]?.End_date?.includes("Present") || edu[i]?.End_date?.includes("present") || edu[i]?.End_date === null || edu[i]?.End_date === undefined ) {
//           e.push(edu[i]);
//         } else if (edu[i]?.End_date.split(/\s|\//).length > 1 && edu[i + 1]?.End_date.split(/\s|\//).length > 1) {
//             if ( ((edu[i]?.End_date.split(/\s|\//)[1] < edu[i + 1]?.End_date.split(/\s|\//)[1]) && (edu[i]?.End_date.split(/\s|\//)[1] !== null || edu[i]?.End_date.split(/\s|\//)[1] !== undefined) && (edu[i + 1]?.End_date.split(/\s|\//)[1] !== null || edu[i+ 1]?.End_date.split(/\s|\//)[1] !== undefined)) )
            
//             // ((edu[i]?.End_date < edu[i + 1]?.End_date) && edu[i]?.End_date !== null || edu[i]?.End_date !== undefined) && (edu[i + 1]?.End_date !== null || edu[i + 1]?.End_date !== undefined)) 
//             {
//               console.log("bsjdsjhdskdsdsnjsk s ", "called")
//             let edu_temp = edu[i];
//             edu[i] = edu[i + 1];
//             edu[i + 1] = edu_temp;
//           }
//         } else if (edu[i]?.End_date.split(/\s|\//).length == 1 && edu[i + 1]?.End_date.split(/\s|\//).length > 1) {
//             if ( ((edu[i]?.End_date.split(/\s|\//)[0] < edu[i + 1]?.End_date.split(/\s|\//)[1]) && (edu[i]?.End_date.split(/\s|\//)[0] !== null || edu[i]?.End_date.split(/\s|\//)[0] !== undefined) && (edu[i + 1]?.End_date.split(/\s|\//)[1] !== null || edu[i+ 1]?.End_date.split(/\s|\//)[1] !== undefined)) )
            
//             // ((edu[i]?.End_date < edu[i + 1]?.End_date) && edu[i]?.End_date !== null || edu[i]?.End_date !== undefined) && (edu[i + 1]?.End_date !== null || edu[i + 1]?.End_date !== undefined)) 
//             {
              
//             let edu_temp = edu[i];
//             edu[i] = edu[i + 1];
//             edu[i + 1] = edu_temp;
//           }
//         } else if (edu[i]?.End_date.split(/\s|\//).length > 1 && edu[i + 1]?.End_date.split(/\s|\//).length == 1) {
//           if ( ((edu[i]?.End_date.split(/\s|\//)[1] < edu[i + 1]?.End_date.split(/\s|\//)[0]) && (edu[i]?.End_date.split(/\s|\//)[1] !== null || edu[i]?.End_date.split(/\s|\//)[1] !== undefined) && (edu[i + 1]?.End_date.split(/\s|\//)[0] !== null || edu[i+ 1]?.End_date.split(/\s|\//)[0] !== undefined)) )
            
//             // ((edu[i]?.End_date < edu[i + 1]?.End_date) && edu[i]?.End_date !== null || edu[i]?.End_date !== undefined) && (edu[i + 1]?.End_date !== null || edu[i + 1]?.End_date !== undefined)) 
//             {
              
//             let edu_temp = edu[i];
//             edu[i] = edu[i + 1];
//             edu[i + 1] = edu_temp;
//           }
//         }
//       }
//       console.log("e", e)

//       const filter_edu_not_present = edu.filter(element => {
//         if (element?.End_date?.includes("Present") == false && element?.End_date?.includes("present") == false) {
//           return element;
//         }
//       })
//       const newEdu = [ ...e, ...filter_edu_not_present ];
//       console.log("Education after", newEdu);
//       return newEdu;
//   }
// }

// function that scan tru a string and split them based on ",-" or "."
// this func is specifically for experience;
export const splitFunc = (arg) => {
  let result = arg;
  if (Array.isArray(result)) {
    for (let i = 0; i <= result.length - 1; i++) {
      if (Array.isArray(result[i]?.Day_to_day_work)) {
        if (result[i]?.Day_to_day_work.length == 1) {
          for (let j = 0; j <= result[i]?.Day_to_day_work.length - 1; j++) {
            console.log("Splittng experience", result[i]?.Day_to_day_work[j])
            if (result[i]?.Day_to_day_work[j][0] === "-") {
              let arr = result[i]?.Day_to_day_work[j].split("- ");
              result[i].Day_to_day_work = arr.slice(1, arr.length);
            }
          }
        }
      }
    }
    return result;
  }
}

export const extratName = (name) => {
  let n = name?.split(" ")[1]?.slice(1, name.length - 1);
  let r_name = `${name?.split(" ")[1]?.charAt(0)?.toUpperCase()}` + `${n?.toLowerCase()}`
  return r_name;
}


export const AdjustExperienceByDate = (arg) => {
  // console.log("initial experience", arg);
  let experience_array = arg;
  let p = [];
  // console.log("experience_array b4", experience_array);

  for (let i = 0; i <= experience_array.length - 1; i++) {

    if (((experience_array[i]?.Work_end_date?.split(" ")[1] < experience_array[i + 1]?.Work_end_date?.split(" ")[1]) && (experience_array[i]?.Work_end_date?.split(" ")[1] !== null || experience_array[i]?.Work_end_date?.split(" ")[1] !== undefined) && (experience_array[i + 1]?.Work_end_date?.split(" ")[1] !== null || experience_array[i+ 1]?.Work_end_date?.split(" ")[1] !== undefined)) || ((experience_array[i]?.Work_end_date?.split("/")[1] < experience_array[i + 1]?.Work_end_date?.split("/")[1]) && (experience_array[i]?.Work_end_date?.split("/")[1] !== null || experience_array[i]?.Work_end_date?.split("/")[1] !== undefined) && (experience_array[i + 1]?.Work_end_date?.split("/")[1] !== null || experience_array[i+ 1]?.Work_end_date?.split("/")[1] !== undefined)) ) {
      let temp = experience_array[i];
      experience_array[i] = experience_array[i + 1];
      experience_array[i + 1] = temp;

    } else if (experience_array[i]?.Work_end_date?.includes("Present") || experience_array[i]?.Work_end_date?.includes("present") || experience_array[i]?.Work_end_date === null || experience_array[i]?.Work_end_date === undefined ) {
      // console.log("Present status", experience_array[i]);
      p.push(experience_array[i]);
      experience_array.slice(i, 1);
    }
  }
  // console.log("p", p);

  const filter_exp_not_present = experience_array.filter(element => {
    if (element?.Work_end_date?.includes("Present") == false && element?.Work_end_date?.includes("present") == false) {
      return element;
    }
  })

  const newExp = [ ...p, ...filter_exp_not_present ];
  p = [];
  // console.log("experience_array after", filter_exp_not_present);
  // console.log("newExp", newExp);
  // console.log("Filter", filter_exp_not_present)
  experience_array = newExp;
  console.log("experience array", experience_array)
  return experience_array;
}


export const arrangeEducationByDate = async (arg) => {
  let edu = arg;
  let e = [];
  // console.log("Initial education", arg);

  if (edu !== null && edu !== undefined) {
      for (let i = 0; i <= edu.length - 1; i++) {
          const currentDate = edu[i]?.End_date;
          const nextDate = edu[i + 1]?.End_date;

          if (currentDate !== undefined && currentDate !== null && currentDate !== true) {
            const isPresent = currentDate?.includes("present");
                // console.log("currentDate", currentDate);
              if (isPresent || currentDate === null || currentDate === undefined) {
                  e.push(edu[i]);
              } else if (
                  currentDate?.split(/\s|\//).length > 1 &&
                  nextDate?.split(/\s|\//).length > 1 &&
                  currentDate?.split(/\s|\//)[1] < nextDate?.split(/\s|\//)[1]
              ) {
                  swapElements(edu, i, i + 1);
              } else if (
                  currentDate?.split(/\s|\//).length == 1 &&
                  nextDate?.split(/\s|\//).length > 1 &&
                  currentDate?.split(/\s|\//)[0] < nextDate?.split(/\s|\//)[1]
              ) {
                  swapElements(edu, i, i + 1);
              } else if (
                  currentDate?.split(/\s|\//).length > 1 &&
                  nextDate?.split(/\s|\//).length == 1 &&
                  currentDate?.split(/\s|\//)[1] < nextDate?.split(/\s|\//)[0]
              ) {
                swapElements(edu, i, i + 1);
              }
          }
      }

      // console.log("e", e);

      const filter_edu_not_present = edu.filter((element) => element.End_date !== true && element.End_date !== undefined && !element?.End_date?.includes("present"));
      const newEdu = [...e, ...filter_edu_not_present];

      // console.log("Education after", newEdu);
      return newEdu;
  }
};

// Helper function to swap elements in an array
