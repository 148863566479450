import React from 'react';
import { QRCodeCanvas  } from 'qrcode.react';
import { employerUrl } from '../../Redux/baseURL';
import BackButton from '../common/back_button';

const QRCodeGenerator = ({ profileId, name, size }) => {
    if (!profileId) {
        profileId = window.location.pathname.split("/")[2];
    };
  const url = `${employerUrl}/profile/beta/${profileId}`; // Replace with your specific link

  return (
    <div className='border-[#E9E9E9] border-[2.45px] w-max rounded-[16px]'>
       <QRCodeCanvas className='rounded-[16px]'  value={url} size={size ? size : 256} includeMargin={true} />
    </div>
  );
}

export default QRCodeGenerator;
