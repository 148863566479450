export default function AddMore(props) {
  return (
    <button
      onClick={props.onClick}
      className={`h-11 ${props.gamified ? 'px-8' : 'px-4 sm:px-8'} py-2.5 cursor-pointer rounded-3xl justify-start items-center gap-2 sm:gap-2.5 inline-flex bg-orange-500 cursor-pointer`}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 17V7"
          stroke="#FFFFFF"
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M7 12L17 12"
          stroke="#FFFFFF"
          stroke-width="1.5"
          stroke-linecap="round"
        />
      </svg>
      <span className="text-center text-white text-500 text-base font-medium">
        Add {props.text}
      </span>
    </button>
  );
}
