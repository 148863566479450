import React, { useState } from "react";
import { MobileSvg } from "../svgs";
import { NavLink } from "react-router-dom";
import TranslateBtnDropdown from "../translateBTN";

export default function NotAvailable() {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <section className="relative h-max lg:h-screen pb-6 lg:overflow-hidden min-h-[100vh]">
            <div className="absolute h-full w-full top-0 left-0 -z-[10] overflow-hidden">
                <div className="relative bg-[#7d4b9e] top-0 left-0 w-full h-full">
                    <div className="blob_1 absolute top-0 z-10 left-0 bg-[#F63] w-[20%] h-[20%]"></div>
                    <div className="blob_2 absolute top-0 z-10 left-0 bg-[#5843BD] w-[20%] h-[20%]"></div>
                    <div className="element2 absolute top-0 left-0 w-full h-full"></div>
                </div>
            </div>

            <header className={`z-10 top-0 w-full py-3 flex justify-center ${isOpen ? "h-screen bg-[#7d4b9e] flex-col fixed" : "bg-white  md:bg-transparent"}`}>
                <nav className={`${isOpen ? "max-h-[72px]" : ""} justify-between items-center inline-flex w-full mx-auto max-w-[90vw] sm:max-w-[85vw] ipad:max-w-[900px] sticky top-0  xl:max-w-[1100px] h-full`}>
                    <img
                        src='https://res.cloudinary.com/drlcisipo/image/upload/v1705704266/Website%20images/Moil_Logo_wfxno8.svg'
                        alt="Moil Logo"
                        width={103}
                        height={47.5}
                        className="hidden md:block w-16 h-8"
                    />
                    <img
                        src='https://res.cloudinary.com/drlcisipo/image/upload/v1705704261/Website%20images/logo_gox0fw.png'
                        alt="Moil Logo"
                        width={103}
                        height={47.5}
                        className="md:hidden w-16 h-8"
                    />
                    <ul className="header_style justify-start items-center gap-x-6 lg:gap-x-8 xl:gap-x-10 hidden md:flex">
                        <NavLink to={"/login"}>
                            <li className={`text-white text-base font-medium`}>
                                Generate Resume
                            </li>
                        </NavLink>
                        <NavLink to={"/hirelogin"}>
                            <li className="text-white text-base font-medium">Hire Workers</li>
                        </NavLink>
                        <NavLink to={"/login"}>
                            <li className=" gap-3 flex xsm:w-[20px] md:w-[50px] lg:w-[10px]">
                            </li>
                        </NavLink>
                        <div className="items-center md:flex">
                            <TranslateBtnDropdown />
                        </div>
                    </ul>
                    <button className="md:hidden" onClick={handleToggle}>
                        <MobileSvg />
                    </button>
                </nav>

                {isOpen && (
                    <ul className="self-center md:hidden h-full flex flex-col gap-y-6 mt-10 items-center">
                        <NavLink to={"/login"}>
                            <li className={`text-white text-base font-medium`}>
                                Generate Resume
                            </li>
                        </NavLink>
                        <NavLink to={"/hirelogin"}>
                            <li className="text-white text-base font-medium">Hire Workers</li>
                        </NavLink>
                        <div className="items-center md:flex">
                            <TranslateBtnDropdown />
                        </div>
                    </ul>
                )}
            </header>
            <div className="show-tbtn justify-center items-center md:hidden lg:hidden xlg:hidden w-[100vw]">
                <TranslateBtnDropdown className="w-[100vw]" />
            </div>


            <div className="flex w-full justify-center align-center h-[85vh]">
                <div class="flex justify-center items-center flex-col container">
                    <h2 class="text-4xl md:text-5xl lg:text-6xl font-bold text-center mb-4 text-gray-800">
                        <span class="text-white ">We are Down</span>
                    </h2>
                    <p class="text-lg text-center text-white max-w-[450px] mb-4">
                        To be notified when we're back up and running, please leave your email address below:
                    </p>
                    <form action="#" method="post" class="flex flex-col items-center mb-4">
                        <input type="email" name="email" placeholder="Your Email Address" class="border border-gray-300 rounded-md py-2 px-4 w-full mb-2 focus:outline-none focus:border-indigo-500" required />
                            <button type="submit" class="bg-indigo-500 text-white py-2 px-4 rounded-md hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600 transition duration-300">Submit</button>
                    </form>
                    <p class="text-lg text-center text-white">
                        Thank you for your patience!
                    </p>
                </div>
            </div>
        </section>
    );
}